import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tactile',
  templateUrl: 'tactile.component.html',
	styleUrls: [ 'tactile.component.scss' ],
})
export class TactileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
