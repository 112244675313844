import { Component, OnInit } from "@angular/core";
import { DecimalPipe } from "@angular/common";
import { DataService } from "../../data.service";
import { AuthenticationService } from "../../authentication.service";
import { environment } from "../../../environments/environment";
import * as XLSX from "xlsx";
import { Vente } from "../../model/vente";
import { Client } from "../../model/client";
import { Paiement } from "../../model/paiement";
import {Message,MessageService} from 'primeng/api';

@Component({
  selector: "app-situation-paiement-vente.component",
  templateUrl: "situation-paiement-vente.component.html",
  styleUrls: ["situation-paiement-vente.component.scss"],
  providers: [DecimalPipe,MessageService],
})
export class SituationPaiementVenteComponent implements OnInit {
  msgs1: Message[];    
  msgs2: Message[];
  idPaiement: number;
  Paiement: Paiement = {};
  Paiements: Paiement[];
  // recherchePaiement: Paiement = {};
  tableRecapPaiements: Paiement [];
  Vente: Vente = {};
  Ventes: Vente[];
  selectedVente: Vente;
  tableVentes: Vente[];
  tableDetailPaiements: Paiement[];
  Cheques: Paiement[];
  selectedCommandeDetail: Vente;
  tableCommandeVente: Vente = {};
  tableCommandeVentes: any = [];
  requetteCommandeVente: Vente = {};
  requetteCommandeVentes: any = [];
  Client: Client = {};
  Clients: Client[];
  blockedDocument: boolean;
  displayDialog: boolean;
  displaycheque: boolean;
  showButon: boolean;
  showButonSauvgarder: boolean;
  designation: string;
  totalMontant: number;
  totalQte: number;
  total: number;
  montant: number;
  avance: number;
  totalCheque: number;
  reste: number;
  idClient: number;
  index: string;
  commandeDetail: string;
  dateDebut: any;
  dateFin: any;
  idCommande: any;
  nomClient:string;
  myUrl: string = `${environment.urlApi}`;
  constructor(
    private data: DataService,
    private _decimalPipe: DecimalPipe,
    public AuthenticationService: AuthenticationService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    // this.fournisseur();
    this.vente();
    this.paiementDetail();
    this.Paiement.dateEcheance = new Date()
      .toISOString()
      .split("T")[0];
  }
  
  // fournisseur() {
  //   this.Client.action = "Select";
  //   this.data
  //     .fournisseur(this.Client)
  //     .toPromise()
  //     .then((x: Client[]) => (this.Clients = x));
  // }

  vente() {
    this.Vente.action = "Select";
    this.data
      .vente(this.Vente)
      .toPromise()
      .then(
        (x: Vente[]) => ((this.Ventes = x), (this.tableVentes = x), this.paiementRecap())
      );
  }
  paiementDetail() {
    this.Paiement.action = "Select";
    this.data
      .paiementVente(this.Paiement)
      .toPromise()
      .then((x: Paiement[]) => (
        this.tableDetailPaiements = x,
        this.Cheques =x.filter((x) => x.modePaiement != 'espece'),
        this.filter(this.nomClient, 'nom')));
         
  }
  paiementRecap() {
    this.Paiement.action = "Recap";
    this.data
      .paiementVente(this.Paiement)
      .toPromise()
      .then((x: Paiement[]) => (
        this.tableRecapPaiements = x,
        this.tablePaiement()));
  }
  tablePaiement(){
    if (this.tableRecapPaiements.length!=undefined){
      // console.log(this.tableRecapPaiements);
        this.tableVentes.forEach((row ,index )=>{
          let updateItem = this.tableRecapPaiements.find(item=>item.idCommande==row.idCommande);
          // console.log(updateItem);
          if (updateItem===undefined){
            this.tableVentes[index].avance = 0;
            this.tableVentes[index].reste = this.tableVentes[index].total;  
          }else{
          this.tableVentes[index].avance = updateItem.montant;
          this.tableVentes[index].reste = this.tableVentes[index].total-updateItem.montant;      
          }

        }); 
      this.filter(this.Client.nom,'nom');
    }
}

filter(data, colone) {
  if (this.Client.nom===undefined){
    this.Client.nom='';
  }
  let recherche;
  if (colone=="idCommande"){
     recherche = this.Ventes.filter((x) => x.idCommande.toString().includes(data));
  }else{
    recherche = this.Ventes.filter(
    (x) =>
      x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1
    ); 
  }
  // console.log(recherche);
  this.tableVentes = recherche;
  this.totalCommande();  
}
filterCheque(data){
  console.log(data);
  this.Cheques =this.tableDetailPaiements.filter((x) => x.modePaiement != 'espece' && x.nom.toLocaleLowerCase().includes(data.toLocaleLowerCase()));
  this.totalCommande();
}
totalCommande() {
  // console.log(this.tableVentes);
  this.total = 0;
  this.avance = 0;
  this.totalCheque = 0;
  this.reste = 0;
  this.total = this.tableVentes
    .map((t) => (t.total) * 1)
    .reduce((a, value) => a + value, 0);
  this.avance = this.tableVentes
    .map((t) => (t.avance) * 1)
    .reduce((a, value) => a + value, 0);
  this.reste = this.tableVentes
    .map((t) => (t.reste) * 1)
    .reduce((a, value) => a + value, 0);
  this.totalCheque = this.Cheques
    .map((t) => (t.montant) * 1)
    .reduce((a, value) => a + value, 0);
}

  transformDecimal(num) {
    return this._decimalPipe.transform(num, "1.2-2");
  }
  showDialogCheque(){
    this.displaycheque=true;
  }
  
   save() {
    
    if (this.Paiement.id) {
      // alert('update');
      this.Paiement.action = "Update";
      this.Paiement.idCommande = this.idCommande;
      console.log(this.Paiement);
      this.data
        .paiementVente(this.Paiement)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.displayDialog = false;
            this.paiementRecap();
            this.paiementDetail();
          } else {
            alert("Nonnnn");
          }
        });
    } else {
      // alert('add');
        this.Paiement.action = "Insert";
        this.Paiement.idCommande = this.idCommande;
        this.data
          .paiementVente(this.Paiement)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
              this.paiementRecap();
              this.paiementDetail();
            } else {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
            }
          });
    }
  }
  delete(id) {
      // this.messageService.clear();
      // this.messageService.add({key: 'c', sticky: true, severity:'warn', summary:'êtes vous sure de supprimer?', detail:'Confirm to proceed'});
    if (confirm("êtes vous sure?")) {
      this.Paiement.action = "Delete";
      this.Paiement.id = id;
      this.data
        .paiementVente(this.Paiement)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'warn', summary: 'Warning', detail: res.message});
            this.paiementDetail();
            this.paiementRecap();
            this.displayDialog = false;
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  printSituation(): void {
    var params = [
      "height=" + screen.height,
      "width=" + screen.width,
      "fullscreen=yes", // only works in IE, but here for completeness
    ].join(",");
    let printContents, popupWin;
    printContents = document.getElementById("print-table").innerHTML;
    popupWin = window.open("", "_blank", params);
    popupWin.document.open();
    popupWin.document.write(`
			  <html>
				<head>
				  <title>Print tab</title>
				  <style>
				  table {
					// width: 750px;
					border-collapse: collapse;
					margin: 5px auto;
				}				
				/* Zebra striping */
				tr:nth-of-type(odd) {
					// background: #eee;
					
				}				
				th {
					background: #3498db;
					color: white;
					font-weight: bold;
					text-align: center;
				}				
				td,
				th {
					padding: 5px;
					// border: 1px solid #ccc;
					text-align: left;
					font-size: 12px;
				}
				  </style>
				</head>
			<body onload="window.print();window.close()" style="text-align:center;">${printContents}</body>
			  </html>`);
    popupWin.document.close();
  }
  exportexcel(): void {
    let fileName = "Situation des paiements .xlsx";  
      /* table id is passed over here */
      let element = document.getElementById("excel-table");
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  
      /* generate workbook and add the worksheet */
      var title ="Situation du ";
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        wb,
        ws,
        "du " 
      );
  
      /* save to file */
      XLSX.writeFile(wb, fileName);
    }
  cloture(idPaiement,mode) {
    // alert(idPaiement+" | "+mode);

      this.blockedDocument=true;
      this.Paiement.action = "UpdateRegle";
      this.Paiement.id = idPaiement;
      this.Paiement.regle = mode;
      this.data
        .paiementVente(this.Paiement)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            // alert(res.message);
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.blockedDocument=false;
            // this.displaycheque = false;
            this.paiementRecap();
            this.paiementDetail();
            this.filterCheque(this.nomClient);
          } else {
            alert(res.message);
          }
        });
  }
  newDonnee: boolean;
  showDialog() {
    
    this.displayDialog = true;
    this.newDonnee = true;
    this.Vente = {};
  }
  showDialogToAdd() {
    this.showButon = false;
    this.showButonSauvgarder = false;
    this.newDonnee = true;
    this.Vente = {};
    this.tableCommandeVente = {};
    this.tableCommandeVentes = [];
    this.Vente.dateCommandee = new Date().toISOString().split("T")[0];
    this.displayDialog = true;
  }
  addPaiement(data) {
    // console.log(data)
    // if (this.tableCommandeVente.idPaiement) {
      this.tableCommandeVentes.push(data);

      // var index = this.tableCommandeVentes.findIndex(
      //   (x) => x.idPaiement == this.idPaiement
      // );

      this.showButon = false;
      this.totalQantite();
      // this.prepareCommandeDetail();
      this.tableCommandeVente = {};
      // alert("2");
    // } else {
    //   alert("Entrer un Paiement !");
    // }

    // var index = this.tableCommandeVentes.findIndex((x) => x.index == index);
  }
  updatePaiement(data) {
    this.tableCommandeVentes[this.index].idCommande = data.idCommande;
    this.tableCommandeVentes[this.index].dateReglement = data.dateReglement;
    this.tableCommandeVentes[this.index].dateCheque = data.dateCheque;
    this.tableCommandeVentes[this.index].montant = data.montant;
    this.tableCommandeVentes[this.index].modePaiement = data.modePaiement;
    this.tableCommandeVentes[this.index].reference = data.reference;
    this.tableCommandeVentes[this.index].regle = data.regle;
    // this.idClient = this.tableCommandeVente.idClient;
    // this.tableCommandeVente = {};
    // this.tableCommandeVente.idClient = this.idClient;
    // this.tableCommandeVente.dateCommande = new Date()
    //   .toISOString()
    //   .split("T")[0];
    this.showButon = false;
    this.totalQantite();
    // this.prepareCommandeDetail();
    //  this.tableCommandeVente = {};
  }
  findPaiement(idPaiement) {
    
    let recherche = this.Paiements.find((x) => x.id == idPaiement);
    this.designation = recherche.designation;
    this.idPaiement = idPaiement;
    // alert(this.idPaiement);
    // if (recherche != undefined) {
    // 	return recherche.designation;
    // } else {
    // 	return '0';
    // }
  }
  totalQantite() {
    let totalMontant = 0;
    let totalQte = 0;
    for (let data of this.tableCommandeVentes) {
      totalMontant += data.montant*1;
    }
    this.totalMontant = totalMontant;
    // return this._decimalPipe.transform(total, '1.2-2');
    // return total.toFixed(2);
  }

  deleteCommande(idPaiement) {
    // alert(idPaiement);
    var index = this.tableCommandeVentes.findIndex(
      (x) => x.idPaiement == idPaiement
    );
    // alert(index);
    this.tableCommandeVentes.splice(index, 1);
    this.totalQantite();
    // this.prepareCommandeDetail();
    // this.tableCommandeVente = {};
    // console.log(this.vente);
    // var index = this.vente.findIndex((x) => x.idPaiement == idPaiement);
  }
  prepareCommandeDetail() {
    this.commandeDetail = "";
    for (let data of this.tableCommandeVentes) {
      this.commandeDetail +=
        "('idVente','" +
        data.idPaiement +
        "','" +
        data.quantite +
        "','" +
        data.prixVente +
        "','" +
        data.prixVenteDetail +
        "','" +
        data.prixVenteGros +
        "','" +
        data.prixVente +
        "'),";
    }
    this.commandeDetail += "$";
    this.commandeDetail = this.commandeDetail.replace(",$", ";");
    this.initialiser();
    // alert(this.tableCommandeVente.idClient);
  }

  onRowSelect(event) {
    // alert(event.data.idCommande)
    this.displayDialog = true;
    // console.log();
    this.idCommande = event.data.idCommande;
    this.montant = event.data.montant - event.data.remise;
    // alert(this.idCommande)
    // this.Vente.dateCommande = event.data.dateCommande;
    // this.Vente.idClient = event.data.idClient;
    this.initialiser();
    let recherche = this.tableDetailPaiements.filter((x) => x.idCommande == event.data.idCommande);
    this.tableCommandeVentes = recherche;
    console.log(this.tableDetailPaiements)
    // this.tableCommandeVentes = recherche;
    // console.log(this.tableDetailPaiements);
    this.totalQantite();
    // this.prepareCommandeDetail();
    // this.newDonnee = false;
    // this.Vente = this.cloneDonnee(event.data);
    // alert(this.tableCommandeVente.idClient);
  }
  cloneDonnee(c: Vente): Vente {
    let Vente = {};
    for (let prop in c) {
      Vente[prop] = c[prop];
    }
    return Vente;
  }
  onRowSelectCommandeDetail(event) {
    console.log(event);
    this.showButon = true;
    this.showButonSauvgarder = true;
    this.index = this.tableCommandeVentes.findIndex(
      (x) => x.idPaiement == event.data.idPaiement
    );
    // alert(this.index);
    this.newDonnee = false;
    this.Paiement = this.cloneDonneeCommandeDetail(event.data);
    this.displayDialog = true;
  }
  cloneDonneeCommandeDetail(c: Paiement): Paiement {
    let tableCommandeVente = {};
    for (let prop in c) {
      tableCommandeVente[prop] = c[prop];
    }
    return tableCommandeVente;
  }
  close() {
    this.displayDialog = false;
    this.tableCommandeVentes = [];
    this.tableCommandeVente = {};
    this.requetteCommandeVentes = [];
    this.requetteCommandeVente = {};
    this.tableCommandeVente.dateReglement = new Date()
      .toISOString()
      .split("T")[0];
    this.totalQantite();
    this.tableCommandeVente.dateCheque = new Date()
      .toISOString()
      .split("T")[0];
    this.totalQantite();
    // this.prepareCommandeDetail();
  }
  initialiser() {
    this.Paiement = {};
    this.Paiement.modePaiement='espece';
    this.Paiement.regle="o";
    // this.Paiement.modePaiement="";
    // this.tableCommandeVente = {};
    this.Paiement.dateReglement = new Date()
    .toISOString()
    .split("T")[0];
    this.Paiement.dateCheque = new Date()
    .toISOString()
    .split("T")[0];
    this.requetteCommandeVentes = this.tableCommandeVentes;
    // this.tableCommandeVente.idPaiement = null;
    // this.tableCommandeVente.quantite = "";
    // this.tableCommandeVente.prixVente = "";
    // this.tableCommandeVente.prixVente = "";
    // this.tableCommandeVente.prixVenteDetail = "";
    // this.tableCommandeVente.prixVenteGros = "";
    this.showButon = false;
  }
  closeCheque(){
    this.displaycheque=false;
  }
}

