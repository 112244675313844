import { Component, OnInit } from "@angular/core";
import { DataService } from "../../data.service";
import { DecimalPipe } from "@angular/common";
import { Achat } from "../../model/achat";
import { Article } from "../../model/article";
import * as XLSX from "xlsx";
import { Vente } from "../../model/vente";
import { Fournisseur } from "../../model/fournisseur";
import { AuthenticationService } from "../../authentication.service";
import { Variation } from "../../model/variation";

@Component({
  selector: "app-stock",
  templateUrl: "stock.component.html",
  styleUrls: ["stock.component.scss"],
  providers: [DecimalPipe],
})
export class StockComponent implements OnInit {
  tableArticles: Achat[];
  Achat: Achat = {};
  Achats: Achat[];
  Vente: Vente = {};
  Ventes: Vente[];
  Article: Article = {};
  Articles: Article[];
  Variation: Variation = {};
  Variations: Variation[];
  tableVariations: Variation[];
  Fournisseur: Fournisseur = {};
  Fournisseurs: Fournisseur[];
  selectedAchat: Achat;
  able: boolean =false;
  dateSituation:any;
  total: number;
  totalAchat: number;
  totalVente: number;
  totalachatAvoir: number;
  totalAvoir: number;
  stock: number;
  variations: number;
      
  constructor(
    private data: DataService,
    private _decimalPipe: DecimalPipe,
    public AuthenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    if(this.AuthenticationService.currentUserValue.groupe=='Administrateur' || this.AuthenticationService.currentUserValue.groupe=='Administrateurs' ){
      this.able=true;
    }
    this.getStock();
    // this.variation();
    this.fournisseur();
    this.dateSituation  = new Date().toISOString().split("T")[0];
  }
  getStock() {
    this.total = 0;
    this.Achat.action = "Stock";
    this.data
      .stock(this.Achat)
      .toPromise()
      .then((x: Achat[]) => (
          this.Achats = x,
          this.tableArticles = x,
          this.variation(),
          this.total = this.tableArticles
            .map((t) => t.totalAchat * t.prixAchat)
            .reduce((a, value) => a + value, 0)
          // this.getStockVendu(),
          // console.log(x)
      ));
  }
  
  variation() {
    this.Variation.action = "Select";
    this.data
      .variationStock(this.Variation)
      .toPromise()
      .then(
        (x: Variation[]) => (
          (this.Variations = x), (this.tableVariations = x),
          // console.log(x),
          this.remplirTableAchats()
        )
      );
  }
  remplirTableAchats(){
    this.Achats.forEach((row ,index )=>{
      if(row.depot==null){
         this.Achats[index].depot = '---';
      } 
      })
    this.rechercheVariation()
  } 
  	transformDecimal(num) {
		return this._decimalPipe.transform(num, '1.2-2');
	}
  rechercheVariation() {

    if (this.tableArticles!=undefined){
      // console.log(this.tableRecapPaiements);
        this.tableArticles.forEach((row ,index )=>{
          let updateItem = this.Variations.filter(item=>item.idArticle==row.idArticle);
          // console.log(updateItem);
          if (updateItem===undefined){
            this.tableArticles[index].variation =0;  
          }else{
              let total=0;
              total = updateItem.map((t) => t.quantite * 1).reduce((a, value) => a + value, 0);
              this.tableArticles[index].variation =total;       
          }
        }); 
        console.log(this.tableArticles);
      // this.filter(this.Client.nom,'nom');
    }

  }

  fournisseur() {
    this.Fournisseur.action = "Select";
    this.data
      .fournisseur(this.Fournisseur)
      .toPromise()
      .then((x: Fournisseur[]) => (this.Fournisseurs = x));
  }
  rechercheFournisseur(idFournisseur) {
    let recherche = this.Fournisseurs.find((x) => x.id == idFournisseur);
    if (recherche != undefined) {
      return recherche.nom;
    } else {
      return "0";
    }
  }
 rechercheVente(idArticle) {
    if (this.Ventes){
      let recherche = this.Ventes.find((x) => x.idArticle == idArticle);
      if (recherche != undefined) {
        return recherche.totalVente;
      } else {
        return "0";
      }      
    }else{
      return "0";
    }

  }
  filter(data, colone) {
    // if (this.Achats) {
      console.log(this.Achats);
      let recherche = this.Achats.filter(
        (x) =>
          x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1
      );
      this.tableArticles = recherche;

        this.totalAchat = 0;
        this.totalAchat = this.tableArticles
          .map((t) => (t.totalAchat))
          .reduce((a, value) => a + value, 0);
        this.totalVente = 0;
        this.totalVente = this.tableArticles
          .map((t) => (t.totalVente))
          .reduce((a, value) => a + value, 0);
        this.totalAvoir = 0;
        this.totalAvoir = this.tableArticles
          .map((t) => (t.totalAvoir))
          .reduce((a, value) => a + value, 0);
        this.totalachatAvoir = 0;
        this.totalachatAvoir = this.tableArticles
          .map((t) => (t.totalachatAvoir))
          .reduce((a, value) => a + value, 0);
        this.stock = 0;
        this.stock = this.tableArticles
          .map((t) => (t.stock))
          .reduce((a, value) => a + value, 0);
        this.variations = 0;
        this.variations = this.tableArticles
          .map((t) => (t.totalVariation)*1)
          .reduce((a, value) => a + value, 0);
        this.total = 0;
        this.total = this.tableArticles
          .map((t) => (t.totalAchat-t.totalachatAvoir-t.totalVente+t.totalAvoir+t.totalVariation) * t.prixAchat)
          .reduce((a, value) => a + value, 0);
    // }totalAchat totalVente totalAvoir stock variations 
  }
  exportexcel(): void {
    let fileName = "Situation des stocks"+ this.dateSituation +".xlsx";  
      /* table id is passed over here */
      let element = document.getElementById("excel-table");
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  
      /* generate workbook and add the worksheet */
      var title ="Situation du STOCK";
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb,ws,"du " + this.dateSituation);
  
      /* save to file */
      XLSX.writeFile(wb, fileName);
    }

}
