import { Component, OnInit } from "@angular/core";

// import { ConfirmDialogModule } from "primeng/confirmdialog";
// import { ConfirmationService } from "primeng/api";
@Component({
  selector: "app-barreCode",
  templateUrl: "barreCode.component.html",
  styleUrls: ["barreCode.component.scss"],
})
export class BarreCodeComponent implements OnInit {
  elementType = 'svg';
  value = '6933256621283';
  format = 'CODE128';
  lineColor = '#000000';
  width = 2;
  height = 100;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 20;
  background = '#ffffff';
  margin = 10;
  marginTop = 10;
  marginBottom = 10;
  marginLeft = 10;
  marginRight = 10;

  get values(): string[] {
    return this.value.split('\n');
  }
  codeList: string[] = [
    '',
    'CODE128',
    'CODE128A',
    'CODE128B',
    'CODE128C',
    'UPC',
    'EAN8',
    'EAN5',
    'EAN13',
    'EAN12',
    'EAN2',
    'CODE39',
    'ITF14',
    'MSI',
    'MSI10',
    'MSI11',
    'MSI1010',
    'MSI1110',
    'pharmacode',
    'codabar',
  ];
  constructor() {}
  ngOnInit() {
  }
}
