import { Component, OnInit } from "@angular/core";
import { DataService } from "../../data.service";
import { AuthenticationService } from "../../authentication.service";
import { RendezVous } from "../../model/rendezVous";
import {
  CalendarOptions,
  EventHoveringArg,
  DateSelectArg,
  EventClickArg,
  EventApi,
  formatDate,
} from "@fullcalendar/angular";
// import { INITIAL_EVENTS, createEventId } from './event-utils';
import { EventInput } from "@fullcalendar/angular";
import frLocale from "@fullcalendar/core/locales/fr-ch";

@Component({
  selector: "app-rendez-vous",
  templateUrl: "rendez-vous.component.html",
  styleUrls: ["rendez-vous.component.scss"],
})
export class RendezVousComponent implements OnInit {
  debut: string;
  fin: string;
  RendezVous: RendezVous = {};
  RendezVouss: RendezVous[];
  tableRedezVous: RendezVous[];
  displayDialog: boolean;
  displayCalendar: boolean;
  calendarVisible: boolean;
  calendarApi: any;
  selectinfo: any;
  calendarOptions: CalendarOptions = {};
  INITIAL_EVENTS: EventInput[];
  constructor(
    private data: DataService,
    private AuthenticationService: AuthenticationService
  ) {
    // let str = formatDate(new Date(), {
    // 	month: 'long',
    // 	year: 'numeric',
    // 	day: 'numeric',
    // 	timeZoneName: 'short',
    // 	timeZone: 'UTC',
    // 	locale: 'fr'
    // });
    // console.log(str);
  }

  ngOnInit() {
    this.rendezVous();
    this.displayCalendar = true;
    // console.log('zzzz' + TODAY_STR);
  }

  rendezVous() {
    this.calendarVisible = false;
    this.INITIAL_EVENTS = [];
    this.RendezVous.action = "Select";
    this.data
      .rendezVous(this.RendezVous)
      .toPromise()
      .then(
        (x: RendezVous[]) => (
          (this.RendezVouss = x),
          (this.tableRedezVous = x),
          (this.INITIAL_EVENTS = x),
          this.affectRendezVous()
        )
      );
  }
  affectRendezVous() {
    // this.INITIAL_EVENTS = [ this.tableRedezVous ];
    // console.log(this.INITIAL_EVENTS);
    this.calendarVisible = true;
    this.calendarOptions = {
      headerToolbar: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
      },
      initialView: "timeGridWeek",
      initialEvents: this.INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
      weekends: true,
      slotMinTime: "08:00",
      slotMaxTime: "23:00",
      editable: true,
      selectable: true,
      selectMirror: true,

      firstDay: 1,
      businessHours: true,
      locales: [frLocale],
      locale: "fr-ch",
      select: this.handleDateSelect.bind(this),
      eventClick: this.handleEventClick.bind(this),
      eventsSet: this.handleEvents.bind(this),
      eventMouseEnter: this.handleEventMouseEnter.bind(this),
      /* you can update a remote database when these fire:
		eventAdd:
		eventChange:
		eventRemove:
			locales: [ frLocale ],
			weekNumbers: true,
			selectMirror: true,
		*/
    };
    // this.INITIAL_EVENTS = [ this.tableRedezVous ];
    // this.calendarOptions = {
    // 	initialEvents: JSON.stringify(this.tableRedezVous)
    // };
    // console.log(this.INITIAL_EVENTS);
    // this.calendarOptions.initialEvents = [];

    // console.log(this.tableRedezVous);
    // console.log(this.calendarApi);
    // if (this.tableRedezVous) {
    // 	for (let data of this.tableRedezVous) {
    // 		this.calendarApi.addEvent({
    // 			id: data.id,
    // 			title: data.title,
    // 			start: data.start,
    // 			end: data.end,
    // 			color: data.color
    // 		});
    // 	}
    // 	this.displayCalendar = false;
    // }
  }
  removeAllEventSources() {
    alert("");
    this.calendarApi.eventRemove();
  }
  // EventHoveringArg:
  handleEventMouseEnter(selectInfo: EventHoveringArg) {
    // console.log(selectInfo);
  }
  // createEventId() {
  //   return String(eventGuid++);
  // }

  update() {
    this.RendezVous.action = "Update";
    this.data
      .rendezVous(this.RendezVous)
      .toPromise()
      .then((res: { message: string }) => {
        if (res.message == "Opération de modification réuissie") {
          // alert(res.message);
          this.displayDialog = false;
          this.RendezVous = {};
          this.rendezVous();
        } else {
          alert("Nonnnn");
        }
      });
  }
  save() {
    // if (this.RendezVous.id) {
    // 	// alert("save");

    // } else {

    this.RendezVous.action = "Insert";
    // console.log(this.RendezVous);
    this.data
      .rendezVous(this.RendezVous)
      .toPromise()
      .then((res: { message: string }) => {
        if (res.message == "Opération de modification réuissie") {
          // alert(res.message);
          // this.calendarApi.addEvent({
          // 	id: '250',
          // 	title: this.RendezVous.title,
          // 	start: this.RendezVous.start,
          // 	end: this.RendezVous.end
          // });
          this.rendezVous();
          this.displayDialog = false;
          this.RendezVous = {};
          // this.calendarApi.unselect(); // clear date selection
        } else {
          alert(res.message);
          this.displayDialog = false;
        }
      });
  }
  // }
  delete() {
    if (confirm("êtes vous sure?")) {
      this.RendezVous.action = "Delete";
      this.data
        .rendezVous(this.RendezVous)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            alert(res.message);
            this.displayDialog = false;
            // this.salaire();
            this.rendezVous();
          } else {
            alert("Nonnnn");
          }
        });
    }
  }

  // INITIAL_EVENTS: EventInput[] = [ this.tableRedezVous ];

  currentEvents: EventApi[] = [];

  // handleCalendarToggle() {
  // 	// this.calendarVisible = !this.calendarVisible;
  // }

  // handleWeekendsToggle() {
  // 	// const { calendarOptions } = this;
  // 	// calendarOptions.weekends = !calendarOptions.weekends;
  // }

  handleDateSelect(selectInfo: DateSelectArg) {
    // this.calendarApi = selectInfo.view.calendar;
    // console.log(selectInfo);
    // alert(this.calendarApi);
    this.displayDialog = true;
    this.RendezVous.debut = selectInfo.startStr;
    this.RendezVous.fin = selectInfo.endStr;
    // alert(this.RendezVous.fin);
    var daterendez = selectInfo.endStr;
    var splitRendez = daterendez.split("T", 1);
    this.RendezVous.dateRendezVous = splitRendez[0];

    // alert(this.RendezVous.dateRendezVous);
    // const title = prompt('المرجو ادخال');

    //
    // console.log(this.calendarApi);
    // console.log(selectInfo);
    // let title = this.RendezVous.observation;
    // if (this.RendezVous) {
    // this.calendarApi.addEvent({
    // 	id: 250,
    // 	title: 'title',
    // 	start: selectInfo.startStr,
    // 	end: selectInfo.endStr,
    // 	allDay: selectInfo.allDay
    // });
    // this.calendarApi.unselect(); // clear date selection
    // }
  }

  handleEventClick(clickInfo: EventClickArg) {
    // alert('handleEventClick');
    // console.log(clickInfo);
    this.RendezVous.id = clickInfo.event.id;
    this.RendezVous.color = clickInfo.event.borderColor;
    // alert(this.RendezVous.color);
    this.RendezVous.debut = clickInfo.event.startStr;
    // alert(clickInfo);
    this.RendezVous.fin = clickInfo.event.endStr;
    // alert(this.RendezVous.fin);
    var str = clickInfo.event.title;
    var splitted = str.split(" | ", 2);
    this.RendezVous.observation = splitted[0];
    this.RendezVous.agent = splitted[1];
    var daterendez = clickInfo.event.endStr;
    var splitRendez = daterendez.split("T", 1);
    this.RendezVous.dateRendezVous = splitRendez[0];
    // console.log(this.RendezVous);
    this.displayDialog = true;
    // alert(this.RendezVous.dateRendezVous);
    // const title = prompt('modiefir', `${clickInfo.event.title}`);
    // if (confirm(`Voulez-vous la supprimer '${clickInfo.event.title}'`)) {
    // 	clickInfo.event.remove();
    // }
  }

  handleEvents(events: EventApi[]) {
    // alert();
    this.currentEvents = events;
    // console.log(events);
    // this.RendezVous.debut = events.event.startStr;
    // this.RendezVous.fin = clickInfo.event.endStr;
    // var str = clickInfo.event.title;
    // var splitted = str.split(' | ', 2);
    // this.RendezVous.observation = splitted[0];
    // this.RendezVous.agent = splitted[1];
    // var daterendez = clickInfo.event.endStr;
    // var splitRendez = daterendez.split('T', 1);
    // this.RendezVous.dateRendezVous = splitRendez[0];
    // console.log(this.RendezVous);
    // this.save();
    //
    // alert(events);
  }
  //----------------------------------------
  initialiser() {
    this.RendezVous.id = "";
    this.RendezVous.agent = "";
    this.RendezVous.observation = "";
    this.RendezVous.color = "";
  }
  close() {
    this.displayDialog = false;
    this.RendezVous = {};
  }
}
