import { Component, OnInit } from "@angular/core";
import { DecimalPipe } from "@angular/common";
import { DataService } from "../../data.service";
import { AuthenticationService } from "../../authentication.service";
import { environment } from "../../../environments/environment";
import { Vente } from "../../model/vente";
import { Client } from "../../model/client";
import { Paiement } from "../../model/paiement";
import {Message,MessageService} from 'primeng/api';
import { ModePaiement } from "src/app/model/modePaiement";

@Component({
  selector: "app-situation-paiement-facture.component",
  templateUrl: "situation-paiement-facture.component.html",
  styleUrls: ["situation-paiement-facture.component.scss"],
  providers: [DecimalPipe,MessageService],
})
export class SituationPaiementFactureComponent implements OnInit {
  msgs1: Message[];    
  msgs2: Message[];
  idPaiement: number;
  Paiement: Paiement = {};
  Paiements: Paiement[];
  // recherchePaiement: Paiement = {};
  tableRecapPaiements: Paiement [];
  Vente: Vente = {};
  Ventes: Vente[];
  selectedVente: Vente;
  tableVentes: Vente[];
  tableDetailPaiements: Paiement[];
  selectedCommandeDetail: Vente;
  tableCommandeVente: Vente = {};
  tableCommandeVentes: any = [];
  requetteCommandeVente: Vente = {};
  requetteCommandeVentes: any = [];
  Client: Client = {};
  Clients: Client[];
  ModePaiement: ModePaiement = {};
  ModePaiements: ModePaiement[];
  displayDialog: boolean;
  showButon: boolean;
  showButonSauvgarder: boolean;
  designation: string;
  totalMontant: number;
  totalQte: number;
  total: number;
  montant: number;
  avance: number;
  reste: number;
  idClient: number;
  index: string;
  commandeDetail: string;
  dateDebut: any;
  dateFin: any;
  idCommande: any;

  myUrl: string = `${environment.urlApi}`;
  constructor(
    private data: DataService,
    private _decimalPipe: DecimalPipe,
    public AuthenticationService: AuthenticationService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    // this.article();
    this.client();
    this.paiementRecap();
    this.vente();
    this.paiementDetail();
    this.modePaiement();
    // this.tableCommandeVente.dateCommandee = new Date()
    this.Paiement.dateEcheance = new Date()
      .toISOString()
      .split("T")[0];
  }
  // article() {
  //   this.Paiement.action = "Select";
  //   this.data
  //     .article(this.Paiement)
  //     .toPromise()
  //     .then((x: Paiement[]) => (this.Paiements = x,console.log(x)));
  // }
  
  client() {
    this.Client.action = "Select";    
    this.Client.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .client(this.Client)
      .toPromise()
      .then((x: Client[]) => (this.Clients = x));
  }
  vente() {
    this.Vente.action = "Select";
    this.Vente.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .facture(this.Vente)
      .toPromise()
      .then(
        (x: Vente[]) => ((this.Ventes = x), (this.tableVentes = x), this.tablePaiement())
      );
  }
  paiementDetail() {
    this.Paiement.action = "Select";
    this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .paiementFacture(this.Paiement)
      .toPromise()
      .then((x: Paiement[]) => (this.tableDetailPaiements = x));
  }
  paiementRecap() {
    this.Paiement.action = "Recap";
    this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .paiementFacture(this.Paiement)
      .toPromise()
      .then((x: Paiement[]) => (this.tableRecapPaiements = x));
      // alert(this.tableRecapPaiements.length);
  }
  modePaiement() {
    this.ModePaiement.action = "Select";
    this.ModePaiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .modePaiement(this.ModePaiement)
      .toPromise()
      .then((x: ModePaiement[]) => (this.ModePaiements = x));
  }

  findModePaiement(idModePaiement) {
    let rechercheModePaiement = this.ModePaiements.find((x) => x.id == idModePaiement);
    this.Paiement.modePaiement = rechercheModePaiement.modePaiement;
  }
  transformDecimal(num) {
    return this._decimalPipe.transform(num, "1.2-2");
  }
  saveCommandeVente() {
    this.tableCommandeVente.action = "Insert";
    this.tableCommandeVente.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.tableCommandeVente.commandeDetail = this.commandeDetail;
    this.data
      .facture(this.tableCommandeVente)
      .toPromise()
      .then((res: { message: string }) => {
        if (res.message == "Opération de modification réuissie") {
          this.vente();
          alert(res.message);
        } else {
          alert(res.message);
        }
      });
  }
  //   filter(data, colone) {
  //     console.log(data + colone);
  //     let recherche = this.Ventes.filter(
  //       (x) =>
  //         x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1
  //     );
  //     this.tableVentes = recherche;
  //     this.total = this.tableVentes
  //       .map((t) => t.montant * 1)
  //       .reduce((a, value) => a + value, 0);
  //   }
  filter(data, colone) {
    let recherche;
    if (colone=="idCommande"){
       recherche = this.Ventes.filter((x) => x.idCommande.toString().includes(data));
    }else{
      recherche = this.Ventes.filter(
      (x) =>
        x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1
    ); 
    }
    this.tableVentes = recherche;
    this.totalCommande();  
  }
  filterCommande(data, colone) {
    // console.log(data + colone);
    // alert(data + colone);
    let recherche = this.tableCommandeVentes.filter(
      (x) =>
        x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1
    );
    // this.requetteCommandeVentes = recherche;
    // console.log(this.requetteCommandeVentes);
    // this.totalCommande();
  }
  tablePaiement(){
    // this.tableAchats =this.Achats;
    // console.log( this.Achats);
    // console.log( this.tableRecapPaiements);
    if (this.tableRecapPaiements.length!=undefined){
        this.tableRecapPaiements.forEach((row)=>{
          let updateItem = this.tableVentes.find(item=>item.idCommande==row.idCommande);
          let index = this.tableVentes.indexOf(updateItem);
          this.tableVentes[index].avance = row.montant;
          this.tableVentes[index].reste = this.tableVentes[index].total-row.montant;
        }); 
      this.totalCommande();
    }
}
  totalCommande() {
    console.log(this.tableVentes);
    this.total = 0;
    this.avance = 0;
    this.reste = 0;
    this.total = this.tableVentes
      .map((t) => (t.total) * 1)
      .reduce((a, value) => a + value, 0);
    this.avance = this.tableVentes
      .map((t) => (t.avance) * 1)
      .reduce((a, value) => a + value, 0);
    this.reste = this.tableVentes
      .map((t) => (t.reste) * 1)
      .reduce((a, value) => a + value, 0);
  }
  save() {
    
    if (this.Paiement.id) {
      // alert('update');
      this.Paiement.action = "Update";
      this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.Paiement.idCommande = this.idCommande;
      this.data
        .paiementFacture(this.Paiement)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.displayDialog = false;
            this.vente();
            this.paiementDetail();
            this.paiementRecap();
          } else {
            alert("Nonnnn");
          }
        });
    } else {
      // alert('add');
        this.Paiement.action = "Insert";
        this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
        this.Paiement.idCommande = this.idCommande;
        this.data
          .paiementFacture(this.Paiement)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.vente();
              this.paiementDetail();
              this.paiementRecap();
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
            } else {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
            }
          });
    }
  }
  delete(id) {
      // this.messageService.clear();
      // this.messageService.add({key: 'c', sticky: true, severity:'warn', summary:'êtes vous sure de supprimer?', detail:'Confirm to proceed'});
    if (confirm("êtes vous sure?")) {
      this.Paiement.action = "Delete";
      this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.Paiement.id = id;
      this.data
        .paiementFacture(this.Paiement)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'warn', summary: 'Warning', detail: res.message});
            this.vente();
            this.paiementDetail();
            this.paiementRecap();
            this.displayDialog = false;
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  newDonnee: boolean;
  showDialog() {
    
    this.displayDialog = true;
    this.newDonnee = true;
    this.Vente = {};
  }
  showDialogToAdd() {
    this.showButon = false;
    this.showButonSauvgarder = false;
    this.newDonnee = true;
    this.Vente = {};
    this.tableCommandeVente = {};
    this.tableCommandeVentes = [];
    this.Vente.dateCommandee = new Date().toISOString().split("T")[0];
    this.displayDialog = true;
  }
  addPaiement(data) {
    // console.log(data)
    // if (this.tableCommandeVente.idPaiement) {
      this.tableCommandeVentes.push(data);

      // var index = this.tableCommandeVentes.findIndex(
      //   (x) => x.idPaiement == this.idPaiement
      // );

      this.showButon = false;
      this.totalQantite();
      // this.prepareCommandeDetail();
      this.tableCommandeVente = {};
      // alert("2");
    // } else {
    //   alert("Entrer un Paiement !");
    // }

    // var index = this.tableCommandeVentes.findIndex((x) => x.index == index);
  }
  updatePaiement(data) {
    this.tableCommandeVentes[this.index].idCommande = data.idCommande;
    this.tableCommandeVentes[this.index].dateReglement = data.dateReglement;
    this.tableCommandeVentes[this.index].montant = data.montant;
    this.tableCommandeVentes[this.index].modePaiement = data.modePaiement;
    this.tableCommandeVentes[this.index].reference = data.reference;
    this.tableCommandeVentes[this.index].regle = data.regle;
    // this.idClient = this.tableCommandeVente.idClient;
    // this.tableCommandeVente = {};
    // this.tableCommandeVente.idClient = this.idClient;
    // this.tableCommandeVente.dateCommande = new Date()
    //   .toISOString()
    //   .split("T")[0];
    this.showButon = false;
    this.totalQantite();
    // this.prepareCommandeDetail();
    //  this.tableCommandeVente = {};
  }
  findPaiement(idPaiement) {
    
    let recherche = this.Paiements.find((x) => x.id == idPaiement);
    this.designation = recherche.designation;
    this.idPaiement = idPaiement;
    // alert(this.idPaiement);
    // if (recherche != undefined) {
    // 	return recherche.designation;
    // } else {
    // 	return '0';
    // }
  }
  totalQantite() {
    let totalMontant = 0;
    let totalQte = 0;
    for (let data of this.tableCommandeVentes) {
      totalMontant += data.montant*1;
    }
    this.totalMontant = totalMontant;
    // return this._decimalPipe.transform(total, '1.2-2');
    // return total.toFixed(2);
  }

  deleteCommande(idPaiement) {
    // alert(idPaiement);
    var index = this.tableCommandeVentes.findIndex(
      (x) => x.idPaiement == idPaiement
    );
    // alert(index);
    this.tableCommandeVentes.splice(index, 1);
    this.totalQantite();
    // this.prepareCommandeDetail();
    // this.tableCommandeVente = {};
    // console.log(this.vente);
    // var index = this.vente.findIndex((x) => x.idPaiement == idPaiement);
  }
  prepareCommandeDetail() {
    this.commandeDetail = "";
    for (let data of this.tableCommandeVentes) {
      this.commandeDetail +=
        "('idVente','" +
        data.idPaiement +
        "','" +
        data.quantite +
        "','" +
        data.prixVente +
        "','" +
        data.prixVenteDetail +
        "','" +
        data.prixVenteGros +
        "','" +
        data.prixVente +
        "'),";
    }
    this.commandeDetail += "$";
    this.commandeDetail = this.commandeDetail.replace(",$", ";");
    this.initialiser();
    // alert(this.tableCommandeVente.idClient);
  }

  onRowSelect(event) {
    this.displayDialog = true;
    this.idCommande = event.data.idCommande;
    this.montant = event.data.montant - event.data.remise;
    this.initialiser();
    let recherche = this.tableDetailPaiements.filter((x) => x.idCommande === event.data.idCommande);
    this.tableCommandeVentes = recherche;
    this.totalQantite();
  }
  cloneDonnee(c: Vente): Vente {
    let Vente = {};
    for (let prop in c) {
      Vente[prop] = c[prop];
    }
    return Vente;
  }
  onRowSelectCommandeDetail(event) {
    console.log(event);
    this.showButon = true;
    this.showButonSauvgarder = true;
    this.index = this.tableCommandeVentes.findIndex(
      (x) => x.idPaiement == event.data.idPaiement
    );
    // alert(this.index);
    this.newDonnee = false;
    this.Paiement = this.cloneDonneeCommandeDetail(event.data);
    this.displayDialog = true;
  }
  cloneDonneeCommandeDetail(c: Paiement): Paiement {
    let tableCommandeVente = {};
    for (let prop in c) {
      tableCommandeVente[prop] = c[prop];
    }
    return tableCommandeVente;
  }
  close() {
    this.displayDialog = false;
    this.tableCommandeVentes = [];
    this.tableCommandeVente = {};
    this.requetteCommandeVentes = [];
    this.requetteCommandeVente = {};
    this.tableCommandeVente.dateReglement = new Date()
      .toISOString()
      .split("T")[0];
    this.totalQantite();
    // this.prepareCommandeDetail();
  }
  initialiser() {
    this.Paiement = {};
    this.Paiement.modePaiement='Espèce';
    this.Paiement.regle="o";
    // this.Paiement.modePaiement="";
    // this.tableCommandeVente = {};
    this.Paiement.dateReglement = new Date()
    .toISOString()
    .split("T")[0];
    this.requetteCommandeVentes = this.tableCommandeVentes;
    // this.tableCommandeVente.idPaiement = null;
    // this.tableCommandeVente.quantite = "";
    // this.tableCommandeVente.prixVente = "";
    // this.tableCommandeVente.prixVente = "";
    // this.tableCommandeVente.prixVenteDetail = "";
    // this.tableCommandeVente.prixVenteGros = "";
    this.showButon = false;
  }
}
