import { Component, OnInit } from '@angular/core';
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";

@Component({
  selector: 'app-acceuil',
  templateUrl: "acceuil.component.html",
  styleUrls: ["acceuil.component.scss"],
})
export class AcceuilComponent implements OnInit {
  myUrl: string = `${environment.urlApi}`;

  constructor(private router: Router) { }
  itemsMenu: any[];
  itemsSubMenu: any[];
  ngOnInit() {
    this.itemsMenu = [
      {
        id: "1",
        label: "Articles",
        labelAr: "السلعـــة",
        icon: "pi pi-fw pi-microsoft",
        image: "produit.png",
        Link: "articles",
        items: [
          {
              label: "Articles",
              labelAr: "السلعـــة",
              icon: "pi pi-fw pi-microsoft",
              image: "produit.png",
              routerLink: "articles"
          },
          {
              label: "Articles restaures",
              labelAr: "السلعـــة",
              icon: "pi pi-fw pi-microsoft",
              image: "produit.png",
              routerLink: "articlesRestore"
          },
          {
            label: "Stock",
            labelAr: "المخزون",
            icon: "pi pi-fw pi-filter",
            image: "Stock.png",
            routerLink: "VariationStockDepot",
          },
          {            
            label: "Mouvement",
            labelAr: "تعديل المخزون",
            icon: "pi pi-fw pi-spinner",
            image: "variationStock.png",
            routerLink: "MouvementStockDepot",
          },
          {
            label: "Stock Alert",
            labelAr: "تنبيه",
            icon: "pi pi-info-circle",
            image: "Stock.png",
            routerLink: "alertStock",
          },
          {
            label: "Dépots",
            labelAr: "المخزن",
            icon: "pi pi-directions",
            image: "Stock.png",
            routerLink: "depots",
          },
        ]
      },
      {
        id: "2",
        label: "Achat",
        labelAr: "الشراء",
        icon: "pi pi-fw pi-shopping-cart",
        image: "achat.png",
        Link: "Accueil",
        items: [
          {
            label: "Achat",
            labelAr: "الشراء",
            icon: "pi pi-fw pi-plus",
            image: "achat.png",
            routerLink: "achats",
          },
          {
            label: "Fournisseurs",
            labelAr: "الممول",
            icon: "pi pi-fw pi-pencil",
            image: "vendeur.png",
            routerLink: "fournisseurs",
          },
          {
            label: "situation Achat",
            labelAr: "حســب الــسلعة",
            image: "factures.png",
            icon: "pi pi-fw pi-chart-line",
            routerLink: "situationAchat",
          },
          {
            label: "Paiement",
            labelAr: "الحـالة المـالية",
            icon: "pi pi-fw pi-dollar",
            image: "paiement.png",
            routerLink: "situationPaiementFournisseur",
          },
          {
            label: "Avoir",
            labelAr: "إرجاع الــسلعة",
            icon: "pi pi-fw pi-replay",
            image: "retour.png",
            routerLink: "avoirsAchat",
          },
        ],
      },
      {
        id: "3",
        label: "Vente",
        labelAr: "البيـــع",
        icon: "pi pi-fw pi-calendar",
        image: "vente.png",
        Link: "Accueil",
        
        items: [
          {
            label: "Commande",
            labelAr: "البيع",
            icon: "pi pi-fw pi-plus",
            image: "vente.png",
            routerLink: "ventesPro",
          },
          {
            label: "Fournisseurs",
            labelAr: "الممول",
            icon: "pi pi-fw pi-pencil",
            image: "vendeur.png",
            routerLink: "fournisseurs",
          },
          {
            label: "situation Vente",
            labelAr: "حســب الــسلعة",
            image: "factures.png",
            icon: "pi pi-fw pi-chart-line",
            routerLink: "situationsVentes",
          },
          {
            label: "Paiement par BON",
            labelAr: "حســب البون",
            icon: "pi pi-fw pi-sliders-v",
            routerLink: "situationPaiementVente",
          },
          {
            label: "Paiement",
            labelAr: "الحـالة المـالية",
            icon: "pi pi-fw pi-dollar",
            image: "paiement.png",
            routerLink: "situationPaiementClient",
          },
          {
            label: "Avoir",
            labelAr: "إرجاع الــسلعة",
            icon: "pi pi-fw pi-replay",
            image: "retour.png",
            routerLink: "avoirs",
          },
        ],
      },
      {
        id: "4",
        label: "Factures",
        labelAr: "الفـــاتورات",
        icon: "pi pi-fw pi-calendar",
        image: "vente.png",
        Link: "Accueil",
        
        items: [
          {
            label: "Factures",
            labelAr: "الفـــاتورات",
            icon: "pi pi-fw pi-plus",
            image: "vente.png",
            routerLink: "facture",
          },
          {
            label: "Fournisseurs",
            labelAr: "الممول",
            icon: "pi pi-fw pi-pencil",
            image: "vendeur.png",
            routerLink: "fournisseurs",
          },
          {
            label: "situation Vente",
            labelAr: "حســب الــسلعة",
            image: "factures.png",
            icon: "pi pi-fw pi-chart-line",
            routerLink: "situationFacture",
          },
          {
            label: "Evolution Stock",
            labelAr: "مسار المخزون",
            icon: "pi pi-fw pi-sliders-v",
            image: "Stock.png",
            routerLink: "situationStockFacture",
          },
          {
            label: "Paiement",
            labelAr: "الحـالة المـالية",
            icon: "pi pi-fw pi-dollar",
            image: "paiement.png",
            routerLink: "situationPaiementClientFacture",
          },
          {
            label: "Avoir",
            labelAr: "إرجاع الــسلعة",
            icon: "pi pi-fw pi-replay",
            image: "retour.png",
            routerLink: "avoirFacture",
          },
        ],
      },
      { 
        id: "5",
        label: "Parametres",
        labelAr: "ظبــط",
        icon: "pi pi-fw pi-cog",
        image: "vente.png",
        Link: "Accueil",
        items: [
          {
            label: "BACKUP",
            labelAr: "حفـظ البيانات",
            icon: "pi pi-fw pi-save",
            image: "backup.png",
            routerLink: '<a href="{{myUrl}}/API/API/backup.php?DBase={{AuthenticationService.currentUserValue.DBase}}" target="_blank">',
          },
          {
            label: "Utilisateurs",
            labelAr: "المستعمــل",
            icon: "pi pi-fw pi-user-edit",
            image: "vente.png",
            routerLink: "GestionUser",
          },
          {
            label: "Commercials",
            labelAr: "التجاري",
            icon: "pi pi-fw pi-user-edit",
            image: "vente.png",
            routerLink: "commercial",
          },
          {
            label: "Familles",
            labelAr: "النــوع",
            icon: "pi pi-fw pi-tag",
            image: "type.png",
            routerLink: "types",
          },
          {
            label: "Modes paiement",
            labelAr: "الاستخلاص",
            image: "type.png",
            icon: "pi pi-fw pi-tag",
            routerLink: "modePaiement",
          },
          {
            label: "Charges",
            labelAr: "الصـــوائر",
            icon: "pi pi-fw pi-tag",
            image: "charges.png",
            routerLink: "charges",
          },
          // {
          //   label: "Dépôts",
          //   icon: "pi pi-fw pi-tag",
          //   routerLink: "Depots",
          // },
          // {
          //   label: "Paramètre",
          //   icon: "pi pi-fw pi-tag",
          //   routerLink: "parametre",
          // },
        ]
      }, 
    ];
  }
  actionLink(lien) {
    // alert(lien);
    this.router.navigate(["/"+lien]);
  }
  action(id){
    let recherche = this.itemsMenu.find((x) => x.id == id);
    this.itemsSubMenu=recherche.items;
  }
}
