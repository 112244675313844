import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { AuthenticationService } from '../authentication.service';
import { User } from '../model/user';
import { MessageService } from 'primeng/api';

@Component({
	selector: 'app-gestion-user',
	templateUrl: 'gestion-user.component.html',
	styleUrls: [ 'gestion-user.component.scss' ]
})
export class GestionUserComponent implements OnInit {
	User: User = {};
	Users: User[];
	selectedUser: User;
	selectedItemGroupe: User;
	displayDialog: boolean;
	selectedOption: string;
	Apassword: boolean;
	aMotsPass: string;
	nMotsPass: string;
	rnMotsPass: string;
	aMotsPasss: string = 'Aficher';
	nMotsPasss: string = 'Aficher';
	rnMotsPasss: string = 'Aficher';
	aMotsPas: boolean;
	nMotsPas: boolean;
	rnMotsPas: boolean;
	groupes: any;
	blockedDocument: boolean = false;
	constructor(
		private data: DataService, 
		private AuthenticationService: AuthenticationService,
		private messageService: MessageService
		) {
		// this.show = false;
		this.aMotsPas = false;
		this.nMotsPas = false;
		this.rnMotsPas = false;
		// this.groupes = [ 'Administrateur', 'Admin', 'User' ];
		this.groupes = [
			// { label: 'Administrateurs', value: 'Administrateurs' },
			{ label: 'Administrateur', value: 'Administrateur' },
			{ label: 'Global', value: 'Global' },
			{ label: 'Stock', value: 'Stock' },
			{ label: 'Paiement', value: 'Paiement' },
			{ label: 'Admin', value: 'Admin' },
			{ label: 'User', value: 'User' },
			{ label: 'Operateur', value: 'Operateur' },
		];
	}

	ngOnInit() {
		this.user();
	}
	user() {
		this.User.action = 'Select';
		this.User.DBase  = this.AuthenticationService.currentUserValue.DBase;
		this.AuthenticationService.user(this.User).toPromise().then((x: User[]) => (this.Users = x));
	}
	save() {
		// console.log(this.User);
		// const { old_password, new_password } = f.value;
		if (this.User.id) {
			// alert("save");
			this.User.action = 'Update';
			this.User.DBase  = this.AuthenticationService.currentUserValue.DBase;
			console.log(this.User);
			this.AuthenticationService.user(this.User).toPromise().then((res: { message: string }) => {
				if (res.message == 'Opération de modification réuissie') {
					this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
					this.blockedDocument=false;
					this.displayDialog = false;
					this.user();
				} else {
					alert('Nonnnn');
				}
			});
		} else {
			this.User.action = 'Insert';
			this.User.DBase  = this.AuthenticationService.currentUserValue.DBase;
			this.AuthenticationService.user(this.User).toPromise().then((res: { message: string }) => {
				if (res.message == 'Opération de modification réuissie') {
					this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
					this.blockedDocument=false;
					this.displayDialog = false;
					this.user();
				} else {
					this.messageService.add({severity:'warm', summary: 'Attention !!!', detail: res.message});
					this.displayDialog = false;
				}
			});
		}
	}
	delete() {
		if (confirm('êtes vous sure?')) {
			this.User.action = 'Delete';
			this.User.DBase  = this.AuthenticationService.currentUserValue.DBase;
			this.AuthenticationService.deleteUser(this.User).toPromise().then((res: { message: string }) => {
				if ((res.message = 'Opération de supression réuissie')) {
					this.messageService.add({severity:'warn', summary: 'Suppression', detail: res.message});
					this.blockedDocument=false;
					this.displayDialog = false;
					this.user();
				} else {
					alert('Nonnnn');
				}
			});
		}
	}
	newDonnee: boolean;
	showDialogToAdd() {
		this.newDonnee = true;
		this.User = {};
		this.displayDialog = true;
	}
	onRowSelect(event) {
		// console.log(event);
		this.newDonnee = false;
		this.User = this.cloneDonnee(event.data);
		this.displayDialog = true;
	}
	cloneDonnee(c: User): User {
		let User = {};
		for (let prop in c) {
			User[prop] = c[prop];
		}
		return User;
	}
	password() {
		// this.aMotsPass = 'Masquer';
		// this.show = !this.show;
		this.aMotsPas = !this.aMotsPas;
		this.nMotsPas = !this.nMotsPas;
		this.rnMotsPas = !this.rnMotsPas;
	}
}
