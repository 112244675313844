import { Component, OnInit } from "@angular/core";
import * as XLSX from "xlsx";
import { DataService } from "../../data.service";
import { Achat } from "../../model/achat";
import { Article } from "../../model/article";
import { Fournisseur } from "../../model/fournisseur";
import { DecimalPipe } from "@angular/common";
import { AuthenticationService } from "../../authentication.service";
@Component({
  selector: "app-situation-achat",
  templateUrl: "situation-achat.component.html",
  styleUrls: ["situation-achat.component.scss"],
  providers: [DecimalPipe],
})
export class SituationAchatComponent implements OnInit {
  Achat: Achat = {};
  Achats: Achat[];
  tableAchats: Achat[];
  // Achat.avance=0;
  Article: Article = {};
  Articles: Article[];
  Fournisseur: Fournisseur = {};
  Fournisseurs: Fournisseur[];
  tableDetailAchats: Achat[];
  selectedCommandeDetail: Achat;
  totalQte: number;
  totalAchat: number;
  totalVente: number;
  totalBenefice: number;
  dateSituationDebut: any;
  dateSituationFin: any;
  nom:string;
  nomAr:string;
  designation:string;
  able:boolean=false;
  Global:boolean=false;
  constructor(
    private data: DataService,
    private _decimalPipe: DecimalPipe,
    public AuthenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    // this.achatDetail();
    // this.dateSituationDebut = new Date().toISOString().split('T')[0];
    // this.dateSituationFin = new Date().toISOString().split('T')[0];    
    if(this.AuthenticationService.currentUserValue.groupe=='Administrateur' || this.AuthenticationService.currentUserValue.groupe=='Administrateurs' ){
      this.able=true;
    }
    if(this.AuthenticationService.currentUserValue.groupe=='Global' ){
      this.Global=true;
    }
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);
    this.dateSituationDebut = firstDay.toISOString().split("T")[0];
    this.dateSituationFin = lastDay.toISOString().split("T")[0];
    this.filterCommande();
    // alert(
    // 	'firstDay : ' + firstDay.toISOString().split('T')[0] + 'lastDay : ' + lastDay.toISOString().split('T')[0]
    // );
  }
  achatDetail() {
    this.Achat.action = "SelectDetail";
    this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .achat(this.Achat)
      .toPromise()
      .then(
        (x: Achat[]) => (
          (this.Achats = x),
          (this.tableDetailAchats = x),
          console.log(x)
          // this.calculTotal(),
          // ,this.filterCommande()
        )
      );
  }
  filter() {
      if (this.nom===undefined){this.nom='';}
      if (this.nomAr===undefined){this.nomAr='';}
      if (this.designation===undefined){this.designation='';}
      let recherche = this.Achats.filter(
        (x) =>
          x.nom.toUpperCase().indexOf(this.nom.toUpperCase()) > -1 && 
          x.nomAr.toUpperCase().indexOf(this.nomAr.toUpperCase()) > -1 && 
          x.designation.toUpperCase().indexOf(this.designation.toUpperCase()) > -1 
      );
      if (recherche){      
        this.tableDetailAchats = recherche;
        this.calculTotal();
      } 
  }
  filterCommande() {
    this.Achat.action = "Filter";
    this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.Achat.dateCommandeDebut = this.dateSituationDebut;
    this.Achat.dateCommandeFin = this.dateSituationFin;
    this.data
      .achat(this.Achat)
      .toPromise()
      .then(
        (x: Achat[]) => (
          (this.Achats = x), this.tableDetailAchats = x, this.filter()
        )
      );
  }
  calculTotal() {
    if (this.tableDetailAchats) {
      this.totalQte = this.tableDetailAchats.map((t) => t.quantite).reduce((a, value) => a + value, 0);
      this.totalAchat = this.tableDetailAchats.map((t) => t.total).reduce((a, value) => a + value, 0);
      this.totalVente = this.tableDetailAchats.map((t) => t.totalVente).reduce((a, value) => a + value, 0);
      this.totalBenefice = this.tableDetailAchats.map((t) => t.marge).reduce((a, value) => a + value, 0);
    }
    return 0;
  }
  transformDecimal(num) {
    return this._decimalPipe.transform(num, "1.2-2");
  }
  onRowSelectCommandeDetail() {}
  printSituation(): void {
    var params = [
      "height=" + screen.height,
      "width=" + screen.width,
      "fullscreen=yes", // only works in IE, but here for completeness
    ].join(",");
    let printContents, popupWin;
    printContents = document.getElementById("print-section").innerHTML;
    popupWin = window.open("", "_blank", params);
    popupWin.document.open();
    popupWin.document.write(`
			  <html>
				<head>
				  <title>Print tab</title>
				  <style>
				  table {
					// width: 750px;
					border-collapse: collapse;
					margin: 5px auto;
				}				
				/* Zebra striping */
				tr:nth-of-type(odd) {
					// background: #eee;
					
				}				
				th {
					background: #3498db;
					color: white;
					font-weight: bold;
					text-align: center;
				}				
				td,
				th {
					padding: 5px;
					// border: 1px solid #ccc;
					text-align: left;
					font-size: 12px;
				}
				  </style>
				</head>
			<body onload="window.print();window.close()" style="text-align:center;">${printContents}</body>
			  </html>`);
    popupWin.document.close();
  }
  fileName = "Situation des Achats.xlsx";

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById("excel-table");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    var title =
      "Situation du " +
      this.dateSituationDebut +
      " au " +
      this.dateSituationFin;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      ws,
      "du " + this.dateSituationDebut + " au " + this.dateSituationFin
    );

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }
}
