import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { DataService } from "../../data.service";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Data } from "../../model/stockDepot";
import { DecimalPipe } from '@angular/common';
import {Message,MessageService} from 'primeng/api';
import { AuthenticationService } from '../../authentication.service';
import swal from 'sweetalert';
import { Article } from '../../model/article';
import { Depot } from '../../model/depot';
import { Achat } from '../../model/achat';
@Component({
  selector: "app-stock-depots",
  templateUrl: "stock-depots.component.html",
  styleUrls: ["stock-depots.component.scss"],
  providers: [DecimalPipe,MessageService],
})
export class StockDepotsComponent implements OnInit {
  myUrl: string = `${environment.urlApi}`;
  Data: Data = {};
  Datas: Data[];
  Depot: Depot = {};
  Depots: Depot[];
  Datass: Data[];
  depots: Data[];
  Achat: Achat = {};
  Achats: Achat[];
  // depot: Data;
  selectedClient: Data;
  displayDialog: boolean;
  afficher: boolean;
  selectedValue: string;
  designation: string;
  depott: string;
  able:boolean;
  Article: Article = {};
  Articles: Article[];
  StockDepot: Data = {};
  StockDepots: Data[];
  filteredArticles: any[];
  constructor(
    private data: DataService,
    private http: HttpClient,
    private _decimalPipe: DecimalPipe,
    public AuthenticationService: AuthenticationService,
    private messageService: MessageService
    ) {}
  ngOnInit() { 
    if(this.AuthenticationService.currentUserValue.groupe=='Administrateur' || this.AuthenticationService.currentUserValue.groupe=='Administrateurs' ){
      this.able=true;
      this.afficher=false;
      
    }
    this.article();
    this.depot();
    this.stockDepot();
    // this.achatMagazin(); 
  }
  stockDepot() {
    this.Data.action = "SelectRecapp";
    this.data
      .stockDepot(this.Data)
      .toPromise()
      .then((x: Data[]) => (this.Datas = x,this.Datass=x,this.filter()));
  }
  depot() {
    this.Data.action = "SelectDepot";
    this.data
      .stockDepot(this.Data)
      .toPromise()
      .then((x: Data[]) => (this.Depots = x));
  }
  article() {
    this.Article.action = "Select";
    this.data
      .article(this.Article)
      .toPromise()
      .then((x: Article[]) => (this.Articles = x));
  }
  // achatMagazin() {
  //   this.Achat.action = "SelectMagazin";
  //   this.data
  //     .achat(this.Achat)
  //     .toPromise()
  //     .then(
  //       (x: Achat[]) => (
  //         (this.Achats = x)
  //       )
  //     );
  // }
  filter() {
    if (this.depott==undefined){
      this.depott='';
    }
    if (this.designation==undefined){
      this.designation='';
    }
    let recherche = this.Datas.filter((x) => 
     (x.designation.toLocaleLowerCase().includes(this.designation.toLocaleLowerCase())
     &&
     x.depot.toLocaleLowerCase().includes(this.depott.toLocaleLowerCase())
     )
     
     );
    this.Datass = recherche;
  }
}
