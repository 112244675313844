import { Component, OnInit } from "@angular/core";
import { DataService } from "../data.service";
import { HttpClient } from "@angular/common/http";
import {Message,MessageService} from 'primeng/api';
import { Crypt } from '../model/crypt';

// import { ConfirmDialogModule } from "primeng/confirmdialog";
// import { ConfirmationService } from "primeng/api";
@Component({
  selector: "app-crypt",
  templateUrl: "crypt.component.html",
  styleUrls: ["crypt.component.scss"],
  providers: [MessageService],
})
export class CryptComponent implements OnInit {

  fileContent: any;
  encryption: any;
  fileToUpload: File = null;
  // encryption: any[];
  // encryption: string;
  // encryptions: Crypt[];
  constructor(private data: DataService, private http: HttpClient,private messageService: MessageService) {}

  ngOnInit() {
  }
  public onChange(fileList: FileList): void {
    let file = fileList[0];
    let fileReader: FileReader = new FileReader();
    let self = this;
    fileReader.onloadend = function(x) {
      self.encryption= fileReader.result;
      self.crypt(self.encryption);
    }
    fileReader.readAsText(file);
    // this.crypt();
  }
  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.readAsDataURL(this.fileToUpload);
  }
  onUpload() {
    this.data
      .posteFileCrypt(
        this.fileToUpload
      )
      .subscribe((data: { message: string; url: string }) => {
        console.log(data.message);
      });
  }
  crypt(data) {
    // console.log(data);
    this.data
      .crypt(data)
      .toPromise()
      .then((x: string) => (this.fileContent=x));
  }
}
