import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { AuthenticationService } from '../authentication.service';
import { User } from '../model/user';
import { MessageService } from 'primeng/api';
@Component({
	selector: 'app-register',
	templateUrl: 'register.component.html',
	styleUrls: [ 'register.component.scss' ]
})
export class RegisterComponent implements OnInit {
	User: User = {};
	Users: User[];
	selectedUser: User;
	displayDialog: boolean;
	selectedOption: string;
	Apassword: boolean;
	aMotsPass: string;
	nMotsPass: string;
	rnMotsPass: string;
	aMotsPasss: string = 'Aficher';
	nMotsPasss: string = 'Aficher';
	rnMotsPasss: string = 'Aficher';
	aMotsPas: boolean;
	nMotsPas: boolean;
	rnMotsPas: boolean;
	blockedDocument: boolean = false;
	constructor(
		private data: DataService, 
		private AuthenticationService: AuthenticationService,
		private messageService: MessageService) {
		// this.show = false;
		this.aMotsPas = false;
		this.nMotsPas = false;
		this.rnMotsPas = false;
	}

	ngOnInit() {
	}
	changePassword(){
		console.log(this.User);
		if(
			this.User.nMotsPass==this.User.rnMotsPass && 
			this.User.nMotsPass && 
			this.User.rnMotsPass && 
			this.User.aMotsPass
			){
			this.User.action = 'Updatee';
			this.User.id 		= this.AuthenticationService.currentUserValue.id;
			this.User.username 	= this.AuthenticationService.currentUserValue.username;
			this.User.nom 		= this.AuthenticationService.currentUserValue.nom;
			this.User.prenom 	= this.AuthenticationService.currentUserValue.prenom;
			this.User.groupe 	= this.AuthenticationService.currentUserValue.groupe;
			this.User.visible 	= '1';
			this.User.password 	= this.User.aMotsPass;


			this.AuthenticationService.user(this.User).toPromise().then((res: { message: string }) => {
				if (res.message == 'Opération de modification réuissie') {
					this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
					this.blockedDocument=false;
				} else {
					this.messageService.add({severity:'warn', summary: 'Attention', detail: res.message});
					this.blockedDocument=false;
				}
			});
		}else{			
			this.messageService.add({severity:'warn', summary: 'Attention', detail: 'les mots de passe ne sont pas les même ou vide  !!!'});
		}
		
	}
}
