import { Component, OnInit } from "@angular/core";
import { DataService } from "../../data.service";
import { DecimalPipe } from "@angular/common";
import { Achat } from "../../model/achat";
import { Article } from "../../model/article";
import { Vente } from "../../model/vente";
import { Fournisseur } from "../../model/fournisseur";
import { AuthenticationService } from "../../authentication.service";
import { Variation } from "../../model/variation";
import { MessageService } from 'primeng/api';
import { environment } from "../../../environments/environment";
@Component({
  selector: "app-initial-stock",
  templateUrl: "initial-stock.component.html",
  styleUrls: ["initial-stock.component.scss"],
  providers: [DecimalPipe],
})
export class InitialStockComponent implements OnInit {

  tableArticles: Achat[];
  Achat: Achat = {};
  Achats: Achat[];
  Variation: Variation = {};
  Variations: Variation[];
  tableVariations: Variation[];
  displayDialog: boolean;
  Vente: Vente = {};
  Ventes: Vente[];
  Article: Article = {};
  Articles: Article[];
  Fournisseur: Fournisseur = {};
  Fournisseurs: Fournisseur[];
  selectedAchat: Achat;
  selectedVariation: Variation;
  blockedDocument: boolean = false;
  displayDialogArticle: boolean = false;
  myUrl: string = `${environment.urlApi}`;
  able: boolean =false;
  constructor(
    private data: DataService,
    private _decimalPipe: DecimalPipe,
    public AuthenticationService: AuthenticationService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    if(this.AuthenticationService.currentUserValue.groupe=='Administrateur' || this.AuthenticationService.currentUserValue.groupe=='Administrateurs' ){
      this.able=true;
    }
    this.Variation.dateVariation = new Date().toISOString().split("T")[0];
    this.getStock();
    // this.variation();
    // this.getStockVendu();
    // this.fournisseur();
  }
getStock() {
    this.Achat.action = "Stock";
    this.data
      .stock(this.Achat)
      .toPromise()
      .then((x: Achat[]) => (
          this.Achats = x,
          this.tableArticles = x,
          this.variation() 
          // this.getStockVendu(),
          // console.log(x)
      ));
  }
  variation() {
    this.Variation.action = "Select";
    this.data
      .variationStock(this.Variation)
      .toPromise()
      .then(
        (x: Variation[]) => ((this.Variations = x), (this.tableVariations = x),this.tableVariation())
      );
  }
  // getStockVendu() {
  //   this.data
  //     .getStockVendu()
  //     .toPromise()
  //     .then((x: Vente[]) => (this.Ventes = x));
  // }
  // fournisseur() {
  //   this.Fournisseur.action = "Select";
  //   this.data
  //     .fournisseur(this.Fournisseur)
  //     .toPromise()
  //     .then((x: Fournisseur[]) => (this.Fournisseurs = x));
  // }
  // rechercheFournisseur(idFournisseur) {
  //   let recherche = this.Fournisseurs.find((x) => x.id == idFournisseur);
  //   if (recherche != undefined) {
  //     return recherche.nom;
  //   } else {
  //     return "0";
  //   }
  // }
  tableVariation(){
    if (this.tableArticles.length!=undefined){
      // console.log(this.tableRecapPaiements);
        this.tableArticles.forEach((row ,index )=>{
          let updateItem = this.Variations.filter(item=>item.idArticle==row.idArticle);
          // console.log(updateItem);
          if (updateItem===undefined){
            this.tableArticles[index].quantite =0;  
          }else{
              let total=0;
              total = updateItem
              .map((t) => t.quantite * 1)
              .reduce((a, value) => a + value, 0);
              this.tableArticles[index].quantite =total;       
          }

        }); 
      // this.filter(this.Client.nom,'nom');
    }
}
  filter(data, colone) {
    if (this.Achats) {
      // console.log(data + colone);
      let recherche = this.Achats.filter(
        (x) =>
          x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1
      );
      this.tableArticles = recherche;
    }
  }
  // rechercheVente(idArticle) {
  //   let recherche = this.Ventes.find((x) => x.idArticle == idArticle);
  //   if (recherche != undefined) {
  //     return recherche.totalVente;
  //   } else {
  //     return "0";
  //   }
  // }
  // rechercheVariation(idArticle) {
  //   let recherche = this.Variations.filter((x) => x.idArticle == idArticle);
  //   if (recherche != undefined) {
  //     return recherche
  //       .map((t) => t.quantite * 1)
  //       .reduce((a, value) => a + value, 0);
  //   } else {
  //     return "0";
  //   }
  // }
  showArticle(){
    this.displayDialogArticle=true;
    this.displayDialog = false;
  }
  save() {
    // alert('update');
    this.Variation.action = "Update";
    // console.log(this.Variation);
    this.data
      .variationStock(this.Variation)
      .toPromise()
      .then((res: { message: string }) => {
        if (res.message == "Opération de modification réuissie") {
          this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
          this.Variation = {};
          this.displayDialog = false;
          this.variation();
        } else {
          alert(res.message);
        }
      });
  }
  addVariation() {
    // alert('add');

    this.Variation.action = "Insert";
    // console.log(this.Variation);
    this.Variation.quantite = this.Variation.stock - (this.Variation.totalAchat-this.Variation.totalVente+this.Variation.quantite*1);
    this.data
      .variationStock(this.Variation)
      .toPromise()
      .then((res: { message: string }) => {
        if (res.message == "Opération de modification réuissie") {
          this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
          this.Variation = {};
          this.variation();
          this.displayDialog = false;
        } else {
          alert(res.message);
          this.displayDialog = false;
        }
      });
  }

  deleteVariation() {
    if (confirm("êtes vous sure?" + this.Variation.id)) {
      this.Variation.action = "Delete";
      // console.log(this.Variation);
      this.data
        .variationStock(this.Variation)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
            this.displayDialog = false;
            this.Variation = {};
            this.variation();
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  deleteArticle(idArticle) {
    if (confirm("êtes vous sure de supprimer cet article ?")) {
      this.blockedDocument=true;
      this.Article.action = "Delete";
      this.Article.id = idArticle;
      this.Article.dateNow = new Date().toISOString().split("T")[0]; 
      console.log(this.Article);
      this.data
        .article(this.Article)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
            this.blockedDocument=false;
            this.displayDialog = false;
            this.getStock();
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  newDonnee: boolean;
  showDialogToAdd() {
    this.newDonnee = true;
    this.Variation = {};
    this.displayDialog = true;
  }
  onRowSelect(event) {
    this.displayDialog = true;
    this.Variation.dateVariation = new Date().toISOString().split("T")[0];
    this.Variation.idArticle = event.data.idArticle;
    this.Variation.totalAchat = event.data.totalAchat;
    this.Variation.totalVente = event.data.totalVente;
    this.Variation.quantite = event.data.quantite;
    let recherche = this.Variations.filter(
      (x) => x.idArticle == event.data.idArticle
    );
    if (recherche != undefined) {
      this.tableVariations = recherche;
    } else {
      // return 'vide';
    }
    // alert(this.tableVariations);
  }
  onRowSelectVariation(event) {
    // alert(event.data.dateVariation);
    // console.log(event.data);
    this.newDonnee = false;
    this.Variation = this.cloneDonnee(event.data);
  }
  cloneDonnee(c: Variation): Variation {
    let Variation = {};
    for (let prop in c) {
      Variation[prop] = c[prop];
    }
    return Variation;
  }
  close() {
    this.displayDialog = false;
    this.Variation = {};
  }
}
