import { Component, OnInit } from "@angular/core";
import { DataService } from "../../data.service";
import { Fournisseur } from "../../model/fournisseur";
import { environment } from "../../../environments/environment";
import {Message,MessageService} from 'primeng/api';
import { AuthenticationService } from '../../authentication.service';
import { Client } from "src/app/model/client";
@Component({
  selector: "app-fournisseurs",
  templateUrl: "fournisseurs.component.html",
  styleUrls: ["fournisseurs.component.scss"],
  providers: [MessageService],
})
export class FournisseursComponent implements OnInit {
  selectedFournisseur: any;
  action: any;
  Fournisseur: Fournisseur = {};
  Fournisseurs: Fournisseur[];
  Fournisseurss: Fournisseur[];
  Client: Client = {};
  Clients: Client[];
  selectedClient: Fournisseur;
  nom : string="";
  nomAr : string="";
  code : string="";
  displayDialog: boolean;
  able:boolean;
  myUrl: string = `${environment.urlApi}`;
  constructor(private data: DataService,
    public AuthenticationService: AuthenticationService,
    private messageService: MessageService) {}
  ngOnInit() {
    if(this.AuthenticationService.currentUserValue.groupe=='Administrateur' || this.AuthenticationService.currentUserValue.groupe=='Administrateurs' ){
      this.able=true;
    }   
    this.getFournisseurs();
    this.client();
    this.nom='';
    this.code ='';
    this.Fournisseur.mixte=false;
  }
  getFournisseurs() {
    this.Fournisseur.action = "Select";
    this.Fournisseur.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .fournisseur(this.Fournisseur)
      .toPromise()
      .then((x: Fournisseur[]) => (this.Fournisseurs = x,this.Fournisseurss=x));
  }
  client() {
    this.Client.action = "Select";
    this.Client.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .client(this.Client)
      .toPromise()
      .then((x: Client[]) => (this.Clients = x));
  }
  filter() {
    if (this.nomAr==undefined){ this.nomAr=''; }
    if (this.nom==undefined){ this.nom=''; }
    if (this.code==undefined){ this.code=''; }
    let recherche = this.Fournisseurs.filter((x) =>
        x.nom.toLocaleLowerCase().includes(this.nom.toLocaleLowerCase()) && 
        x.nomAr.toLocaleLowerCase().includes(this.nomAr.toLocaleLowerCase()) &&
        x.code.toLocaleLowerCase().includes(this.code.toLocaleLowerCase())
     );
    this.Fournisseurss = recherche;
  }
  save() {
    if (this.Fournisseur.nom) {
      if (this.Fournisseur.id) {
        // alert("save");
        this.Fournisseur.action = "Update";
        this.Fournisseur.DBase  = this.AuthenticationService.currentUserValue.DBase;
        // console.log(this.Fournisseur);
        this.data
          .fournisseur(this.Fournisseur)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.getFournisseurs();
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
            } else {
              this.messageService.add({severity:'warn', summary: 'ATTENTION', detail: res.message});
            }
          });
      } else {
        this.Fournisseur.action = "Insert";
        this.Fournisseur.DBase  = this.AuthenticationService.currentUserValue.DBase;
        this.data
          .fournisseur(this.Fournisseur)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.getFournisseurs();
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
            } else {
              this.messageService.add({severity:'warn', summary: 'ATTENTION', detail: res.message});
            }
          });
      }
    }else{
      this.messageService.add({severity:'warn', summary: 'ATTENTION', detail: "Il manque le Nom !!!"});
    }
  }
  delete() {
    if (confirm("êtes vous sure?")) {
      this.Fournisseur.action = "Delete";
      this.Fournisseur.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.Fournisseur.dateNow = new Date().toISOString().split("T")[0];
      // console.log(this.Fournisseur);
      this.data
        .fournisseur(this.Fournisseur)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
            this.displayDialog = false;
            this.getFournisseurs();
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  close() {
    this.displayDialog = false;
    this.Fournisseur = {};
  }
  newDonnee: boolean;
  showDialogToAdd() {
    this.newDonnee = true;
    this.Fournisseur = {};
    this.Fournisseur.mixte=false;
    this.displayDialog = true;
  }
  onRowSelect(event) {
    this.newDonnee = false;
    this.Fournisseur = this.cloneDonnee(event.data);
    this.Fournisseur.idClient=JSON.stringify(event.data.idClient);
    this.displayDialog = true;
  }
  cloneDonnee(c: Fournisseur): Fournisseur {
    let Fournisseur = {};
    for (let prop in c) {
      Fournisseur[prop] = c[prop];
    }
    return Fournisseur;
  }
}
