import { Component, OnInit } from "@angular/core";
import { DataService } from "../data.service";
import { Achat } from "../model/achat";
import { Article } from "../model/article";
import { Paiement } from "../model/paiement";
import { Fournisseur } from "../model/fournisseur";
import { DecimalPipe } from "@angular/common";

@Component({
  selector: "app-echeancier",
  templateUrl: "echeancier.component.html",
  styleUrls: ["echeancier.component.scss"],
  providers: [DecimalPipe],
})
export class EcheancierComponent implements OnInit {
  Fournisseur: Fournisseur = {};
  Fournisseurs: Fournisseur[];
  Paiement: Paiement = {};
  Paiements: Paiement[];
  PaiementEncourr: Paiement = {};
  PaiementEncours: Paiement[];
  selectedAchat: Paiement[];
  total: number;

  constructor(private data: DataService, private _decimalPipe: DecimalPipe) {}

  ngOnInit() {
    this.fournisseur();
    this.paiementEncour();
  }
  fournisseur() {
    this.Fournisseur.action = "Select";
    this.data
      .fournisseur(this.Fournisseur)
      .toPromise()
      .then((x: Fournisseur[]) => (this.Fournisseurs = x));
  }
  paiementEncour() {
    this.Paiement.action = "paiementEncour";
    this.data
      .paiement(this.Paiement)
      .toPromise()
      .then(
        (x: Paiement[]) => (
          (this.Paiements = x), (this.PaiementEncours = x), this.calculTotal()
        )
      );
  }
  filter(data, colone) {
    // console.log(event);
    // let recherche = this.Paiements.filter((x) => x.idFournisseur == data);
    let recherche = this.Paiements.filter(
      (x) =>
        x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1
    );
    this.PaiementEncours = recherche;
    this.calculTotal();
  }
  transformDecimal(num) {
    return this._decimalPipe.transform(num, "1.2-2");
  }
  calculTotal() {
    if (this.PaiementEncours) {
      this.total = this.PaiementEncours.map((t) => t.avance * 1).reduce(
        (a, value) => a + value,
        0
      );
      // console.log('total : ' + this.total);
    }
    return 0;
  }
}
