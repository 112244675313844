import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../environments/environment";
import {Observable} from 'rxjs';
import {ResDataModal} from './model/resDataModal';
interface JobApplication {
  name: string;
  email: string;
  memo: string;
  resume: File | null;
  sample: File | null;
}
@Injectable({
  providedIn: "root",
})
export class DataService {
  myUrl: string = `${environment.urlApi}`;
  constructor(private http: HttpClient) {}

  public async submitApplication(application: JobApplication): Promise<void> {
    var formData = new FormData();

    // The FormData object provides a way to programmatically submit data that the
    // Browser could have natively submitted using a "<form/>" tag. Each entry here
    // represents a form-control field.
    formData.append("name", application.name);
    formData.append("email", application.email);
    formData.append("memo", application.memo);

    // While the above values are "simple" values, we can add File Blobs to the
    // FormData in the exactly same way.
    // --
    // NOTE: An optional "filename" can be provided for Files. But, for this demo,
    // we're going to allow the native filename to be used for the uploads.
    application.resume && formData.append("resume", application.resume);
    application.sample && formData.append("sample", application.sample);

    var result = await this.http
      .post<void>(
        "./API/upload.cfm",
        formData

        // NOTE: When using a FormData instance to define the request BODY, the
        // correct Content-Type will be automatically provided, along with the
        // necessary "boundary" option that delimits the field values. If you
        // attempt to define the Content-Type explicitly, the "boundary" value
        // will be omitted from the post which will prevent the Lucee Server
        // parsing the request into the Form scope properly.
        // --
        // {
        // 	headers: {
        // 		"Content-Type": "multipart/form-data"
        // 	}
        // }
      )
      .toPromise();
  }

  public getStocks() {
    return this.http.get(`${this.myUrl}/API/API/getStock.php`);
  }
  public getStockVendu() {
    return this.http.get(`${this.myUrl}/API/API/getStockVendu.php`);
  }
  public getUsers() {
    return this.http.get(`${this.myUrl}/API/API/getUsers.php`);
  }

  public getClients() {
    return this.http.get(`${this.myUrl}/API/API/getClients.php`);
  }
  public updateClient(Client) {
    return this.http.post(
      `${environment.urlApi}/API/API/updateClient.php`,
      Client
    );
  }
  public addClient(Client) {
    return this.http.post(
      `${environment.urlApi}/API/API/addClient.php`,
      Client
    );
  }
  public deleteClient(Client) {
    return this.http.post(
      `${environment.urlApi}/API/API/deleteClient.php`,
      Client
    );
  }
  //-----------------------------------------------------------------------------------

  public donnee(datatable,data) {
    return this.http.post(`${this.myUrl}/API/API/${datatable}.php`, data);
  }

  public article(data) {
    return this.http.post(`${this.myUrl}/API/API/article.php`, data);
  }
  getData(data): Observable<ResDataModal> {
    return this.http.post<ResDataModal>(`${this.myUrl}/API/API/article.php`, data);
  }
  public fournisseur(data) {
    return this.http.post(`${this.myUrl}/API/API/fournisseur.php`, data);
  }
  public achat(data) {
    return this.http.post(`${this.myUrl}/API/API/achat.php`, data);
  }
  public recapFournisseur(data) {
    return this.http.post(`${this.myUrl}/API/API/paiementFournisseur.php`, data);
  }
  public vente(data) {
    return this.http.post(`${this.myUrl}/API/API/vente.php`, data);
  }  
  public avoirAchat(data) {
    return this.http.post(`${this.myUrl}/API/API/avoirAchat.php`, data);
  }
  public avoir(data) {
    return this.http.post(`${this.myUrl}/API/API/avoir.php`, data);
  }
  public facture(data) {
    return this.http.post(`${this.myUrl}/API/API/facture.php`, data);
  }
  public factureStock(data) {
    return this.http.post(`${this.myUrl}/API/API/factureStock.php`, data);
  }
  public paiementFacture(data) {
    return this.http.post(`${this.myUrl}/API/API/paiementFacture.php`, data);
  }
  public devis(data) {
    return this.http.post(`${this.myUrl}/API/API/devis.php`, data);
  }
  public client(data) {
    return this.http.post(`${this.myUrl}/API/API/client.php`, data);
  }
  public commande(data) {
    return this.http.post(`${this.myUrl}/API/API/commande.php`, data);
  }
  public personnel(data) {
    return this.http.post(`${this.myUrl}/API/API/personnel.php`, data);
  }
  public salaire(data) {
    return this.http.post(`${this.myUrl}/API/API/salaire.php`, data);
  }
  public modePaiement(data) {
    return this.http.post(`${this.myUrl}/API/API/modePaiement.php`, data);
  }
  public type(data) {
    return this.http.post(`${this.myUrl}/API/API/type.php`, data);
  }
  public tva(data) {
    return this.http.post(`${this.myUrl}/API/API/tva.php`, data);
  }
  public liste(data) {
    return this.http.post(`${this.myUrl}/API/API/liste.php`, data);
  }
  public listeDetail(data) {
    return this.http.post(`${this.myUrl}/API/API/listeDetail.php`, data);
  }
  public charge(data) {
    return this.http.post(`${this.myUrl}/API/API/charge.php`, data);
  }
  public paiement(data) {
    return this.http.post(`${this.myUrl}/API/API/paiementAchat.php`, data);
  }
  public paiementAchat(data) {
    return this.http.post(`${this.myUrl}/API/API/paiementAchat.php`, data);
  }
  public paiementVente(data) {
    return this.http.post(`${this.myUrl}/API/API/paiementVente.php`, data);
  }
  public paiementClient(data) {
    return this.http.post(`${this.myUrl}/API/API/paiementClient.php`, data);
  }
  public avoirClient(data) {
    return this.http.post(`${this.myUrl}/API/API/avoir.php`, data);
  }
  public recapClient(data) {
    return this.http.post(`${this.myUrl}/API/API/paiementClient.php`, data);
  }
  public recapClientFacture(data) {
    return this.http.post(`${this.myUrl}/API/API/paiementClientFacture.php`, data);
  }
  public stock(data) {
    return this.http.post(`${this.myUrl}/API/API/stock.php`, data);
  }
  public depot(data) {
    return this.http.post(`${this.myUrl}/API/API/depot.php`, data);
  }
  public stockDepot(data) {
    return this.http.post(`${this.myUrl}/API/API/stockDepot.php`, data);
  }
  public StockInitial(data) {
    return this.http.post(`${this.myUrl}/API/API/StockInitial.php`, data);
  }
  public variationStock(data) {
    return this.http.post(`${this.myUrl}/API/API/variationStock.php`, data);
  }
  public variationStockDepot(data) {
    return this.http.post(`${this.myUrl}/API/API/variationStockDepot.php`, data);
  }
  public rendezVous(data) {
    return this.http.post(`${this.myUrl}/API/API/rendezVous.php`, data);
  }
  public crypt(data) {
    return this.http.post(`${this.myUrl}/API/API/crypt.php`, data);
  }
  public backup() {
    return this.http.get(`${this.myUrl}/API/API/backup.php`);
  }
  public parametre(data) {
		return this.http.post(`${environment.urlApi}/API/API/parametre.php`, data);
	}
  //-----------------------------------------------------------------------------------
  public getAchats() {
    return this.http.get(`${this.myUrl}/API/API/getAchats.php`);
  }
  public updateAchat(Client) {
    return this.http.post(
      `${environment.urlApi}/API/API/updateAchat.php`,
      Client
    );
  }
  public addAchat(Client) {
    // console.log(Client);
    return this.http.post(`${environment.urlApi}/API/API/addAchat.php`, Client);
  }
  public deleteAchat(Client) {
    return this.http.post(
      `${environment.urlApi}/API/API/deleteAchat.php`,
      Client
    );
  }
  public getProduits() {
    return this.http.get(`${this.myUrl}/API/API/getProduits.php`);
  }
  public getArticles() {
    return this.http.get(`${this.myUrl}/API/API/getArticles.php`);
  }
  public getPersonnels() {
    return this.http.get(`${this.myUrl}/API/API/getPersonnels.php`);
  }
  public getPersonnelsActif() {
    return this.http.get(`${this.myUrl}/API/API/getPersonnelsActif.php`);
  }
  public updateArticle(Article) {
    return this.http.post(
      `${environment.urlApi}/API/API/updateArticle.php`,
      Article
    );
  }
  public addArticle(Article) {
    console.log("salut ", Article);
    return this.http.post(
      `${environment.urlApi}/API/API/addArticle.php`,
      Article
    );
  }
  public deleteArticle(Article) {
    return this.http.post(
      `${environment.urlApi}/API/API/deleteArticle.php`,
      Article
    );
  }

  posteFileCrypt(fileToUpload: File) {
    console.log(fileToUpload);
    const endpoint = `${this.myUrl}/API/API/crypt.php`;
    const formData: FormData = new FormData();
    formData.append("image", fileToUpload, fileToUpload.name);
    return this.http.post(endpoint, formData);
  }
  posteFileModePaiement(id, type, DBase, fileToUpload: File) {
    const endpoint = `${this.myUrl}/API/API/uploadModePaiement.php`;
    const formData: FormData = new FormData();
    formData.append("image", fileToUpload, fileToUpload.name);
    formData.append("DBase", DBase);
    formData.append("id", id);
    formData.append("type", type);
    console.log(formData);
    return this.http.post(endpoint, formData);
  }
  posteFile(id, type, DBase, fileToUpload: File) {
    const endpoint = `${this.myUrl}/API/API/uploadType.php`;
    const formData: FormData = new FormData();
    formData.append("image", fileToUpload, fileToUpload.name);
    formData.append("DBase", DBase);
    formData.append("id", id);
    formData.append("type", type);
    console.log(formData);
    return this.http.post(endpoint, formData);
  }
  posteFileArticle(id,type,DBase,fileToUpload: File) {
    const endpoint = `${this.myUrl}/API/API/uploadArticle.php`;
    const formData: FormData = new FormData();
    formData.append("image", fileToUpload, fileToUpload.name);
    formData.append("DBase", DBase);
    formData.append("id", id);
    formData.append("designation", type);
    // console.log(formData);
    return this.http.post(endpoint, formData);
  }
  public addPersonnel(user) {
    // console.log('salut ', user);
    return this.http.post(
      `${environment.urlApi}/API/API/addPersonnel.php`,
      user
    );
  }
  public deletePersonnel(user) {
    return this.http.post(
      `${environment.urlApi}/API/API/deletePersonnel.php`,
      user
    );
  }
  public getPersonnel() {
    return this.http.get(`${this.myUrl}/API/API/getPersonnels.php`);
  }
  public updatePersonnel(type) {
    return this.http.post(
      `${environment.urlApi}/API/API/updatePersonnel.php`,
      type
    );
  }
  public addType(user) {
    // console.log('salut ', user);
    return this.http.post(`${environment.urlApi}/API/API/addType.php`, user);
  }
  public deleteType(user) {
    return this.http.post(`${environment.urlApi}/API/API/deleteType.php`, user);
  }
  public getTypes() {
    return this.http.get(`${this.myUrl}/API/API/getTypes.php`);
  }
  public updateType(type) {
    return this.http.post(`${environment.urlApi}/API/API/updateType.php`, type);
  }
  public getSousTypes() {
    return this.http.get(`${this.myUrl}/API/API/getSousTypes.php`);
  }
  public updateSousType(sousType) {
    return this.http.post(
      `${environment.urlApi}/API/API/updateSousType.php`,
      sousType
    );
  }
  public addSousType(sousType) {
    return this.http.post(
      `${environment.urlApi}/API/API/addSousType.php`,
      sousType
    );
  }
  public deleteSousType(sousType) {
    return this.http.post(
      `${environment.urlApi}/API/API/deleteSousType.php`,
      sousType
    );
  }
}
