import { Component, OnInit } from "@angular/core";
import { DecimalPipe } from "@angular/common";
import { DataService } from "../../data.service";
import { AuthenticationService } from "../../authentication.service";
import { environment } from "../../../environments/environment";
import { Achat } from "../../model/achat";
import { Fournisseur } from "../../model/fournisseur";
import { Paiement } from "../../model/paiement";
import {Message,MessageService} from 'primeng/api';

@Component({
  selector: "app-situation-paiement-achat.component",
  templateUrl: "situation-paiement-achat.component.html",
  styleUrls: ["situation-paiement-achat.component.scss"],
  providers: [DecimalPipe,MessageService],
})
export class SituationPaiementAchatComponent implements OnInit {
  msgs1: Message[];    
  msgs2: Message[];
  idPaiement: number;
  Paiement: Paiement = {};
  Paiements: Paiement[];
  // recherchePaiement: Paiement = {};
  tableRecapPaiements: Paiement [];
  Achat: Achat = {};
  Achats: Achat[];
  selectedAchat: Achat;
  tableAchats: Achat[];
  tableDetailPaiements: Paiement[];
  Cheques: Paiement[];
  selectedCommandeDetail: Achat;
  tableCommandeAchat: Achat = {};
  tableCommandeAchats: any = [];
  requetteCommandeAchat: Achat = {};
  requetteCommandeAchats: any = [];
  Fournisseur: Fournisseur = {};
  Clients: Fournisseur[];
  blockedDocument: boolean;
  displayDialog: boolean;
  displaycheque: boolean;
  showButon: boolean;
  showButonSauvgarder: boolean;
  designation: string;
  totalMontant: number;
  totalQte: number;
  total: number;
  montant: number;
  avance: number;
  totalCheque: number;
  reste: number;
  idClient: number;
  index: string;
  commandeDetail: string;
  dateDebut: any;
  dateFin: any;
  idCommande: any;
  nomFournisseur:string;
  myUrl: string = `${environment.urlApi}`;
  constructor(
    private data: DataService,
    private _decimalPipe: DecimalPipe,
    public AuthenticationService: AuthenticationService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    // this.fournisseur();
    this.achat();
    this.paiementDetail();
    this.Paiement.dateEcheance = new Date()
      .toISOString()
      .split("T")[0];
  }
  
  // fournisseur() {
  //   this.Fournisseur.action = "Select";
  //   this.data
  //     .fournisseur(this.Fournisseur)
  //     .toPromise()
  //     .then((x: Fournisseur[]) => (this.Clients = x));
  // }

  achat() {
    this.Achat.action = "Select";
    this.data
      .achat(this.Achat)
      .toPromise()
      .then(
        (x: Achat[]) => ((this.Achats = x), (this.tableAchats = x), this.paiementRecap())
      );
  }
  paiementDetail() {
    this.Paiement.action = "Select";
    this.data
      .paiementAchat(this.Paiement)
      .toPromise()
      .then((x: Paiement[]) => (
        this.tableDetailPaiements = x,
        console.log(x),
        this.Cheques =x.filter((x) => x.modePaiement != 'espece'),
        this.filter(this.nomFournisseur, 'nom')));
         
  }
  paiementRecap() {
    this.Paiement.action = "Recap";
    this.data
      .paiementAchat(this.Paiement)
      .toPromise()
      .then((x: Paiement[]) => (
        this.tableRecapPaiements = x,
        this.tablePaiement()));
  }
  tablePaiement(){
    if (this.tableRecapPaiements.length!=undefined){
      // console.log(this.tableRecapPaiements);
        this.tableAchats.forEach((row ,index )=>{
          let updateItem = this.tableRecapPaiements.find(item=>item.idCommande==row.idCommande);
          // console.log(updateItem);
          if (updateItem===undefined){
            this.tableAchats[index].avance = 0;
            this.tableAchats[index].reste = this.tableAchats[index].total;  
          }else{
          this.tableAchats[index].avance = updateItem.montant;
          this.tableAchats[index].reste = this.tableAchats[index].total-updateItem.montant;      
          }

        }); 
      this.filter(this.Fournisseur.nom,'nom');
    }
}

filter(data, colone) {
  if (this.Fournisseur.nom===undefined){
    this.Fournisseur.nom='';
  }
  let recherche;
  if (colone=="idCommande"){
     recherche = this.Achats.filter((x) => x.idCommande.toString().includes(data));
  }else{
    recherche = this.Achats.filter(
    (x) =>
      x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1
    ); 
  }
  // console.log(recherche);
  this.tableAchats = recherche;
  this.totalCommande();  
}
filterCheque(data){
  console.log(data);
  this.Cheques =this.tableDetailPaiements.filter((x) => x.modePaiement != 'espece' && x.nom.toLocaleLowerCase().includes(data.toLocaleLowerCase()));
  this.totalCommande();
}
totalCommande() {
  // console.log(this.tableAchats);
  this.total = 0;
  this.avance = 0;
  this.totalCheque = 0;
  this.reste = 0;
  this.total = this.tableAchats
    .map((t) => (t.total) * 1)
    .reduce((a, value) => a + value, 0);
  this.avance = this.tableAchats
    .map((t) => (t.avance) * 1)
    .reduce((a, value) => a + value, 0);
  this.reste = this.tableAchats
    .map((t) => (t.reste) * 1)
    .reduce((a, value) => a + value, 0);
  this.totalCheque = this.Cheques
    .map((t) => (t.montant) * 1)
    .reduce((a, value) => a + value, 0);
}

  transformDecimal(num) {
    return this._decimalPipe.transform(num, "1.2-2");
  }
  showDialogCheque(){
    this.displaycheque=true;
  }
  
   save() {
    
    if (this.Paiement.id) {
      // alert('update');
      this.Paiement.action = "Update";
      this.Paiement.idCommande = this.idCommande;
      console.log(this.Paiement);
      this.data
        .paiementAchat(this.Paiement)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.displayDialog = false;
            this.paiementRecap();
            this.paiementDetail();
          } else {
            alert("Nonnnn");
          }
        });
    } else {
      // alert('add');
        this.Paiement.action = "Insert";
        this.Paiement.idCommande = this.idCommande;
        this.data
          .paiementAchat(this.Paiement)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
              this.paiementRecap();
              this.paiementDetail();
            } else {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
            }
          });
    }
  }
  delete(id) {
      // this.messageService.clear();
      // this.messageService.add({key: 'c', sticky: true, severity:'warn', summary:'êtes vous sure de supprimer?', detail:'Confirm to proceed'});
    if (confirm("êtes vous sure?")) {
      this.Paiement.action = "Delete";
      this.Paiement.id = id;
      this.data
        .paiementAchat(this.Paiement)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'warn', summary: 'Warning', detail: res.message});
            this.paiementDetail();
            this.paiementRecap();
            this.displayDialog = false;
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  cloture(idPaiement,mode) {
    // alert(idPaiement+" | "+mode);

      this.blockedDocument=true;
      this.Paiement.action = "UpdateRegle";
      this.Paiement.id = idPaiement;
      this.Paiement.regle = mode;
      this.data
        .paiementAchat(this.Paiement)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            // alert(res.message);
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.blockedDocument=false;
            // this.displaycheque = false;
            this.paiementRecap();
            this.paiementDetail();
            this.filterCheque(this.nomFournisseur);
          } else {
            alert(res.message);
          }
        });
  }
  newDonnee: boolean;
  showDialog() {
    
    this.displayDialog = true;
    this.newDonnee = true;
    this.Achat = {};
  }
  showDialogToAdd() {
    this.showButon = false;
    this.showButonSauvgarder = false;
    this.newDonnee = true;
    this.Achat = {};
    this.tableCommandeAchat = {};
    this.tableCommandeAchats = [];
    this.Achat.dateCommandee = new Date().toISOString().split("T")[0];
    this.displayDialog = true;
  }
  addPaiement(data) {
    // console.log(data)
    // if (this.tableCommandeAchat.idPaiement) {
      this.tableCommandeAchats.push(data);

      // var index = this.tableCommandeAchats.findIndex(
      //   (x) => x.idPaiement == this.idPaiement
      // );

      this.showButon = false;
      this.totalQantite();
      // this.prepareCommandeDetail();
      this.tableCommandeAchat = {};
      // alert("2");
    // } else {
    //   alert("Entrer un Paiement !");
    // }

    // var index = this.tableCommandeAchats.findIndex((x) => x.index == index);
  }
  updatePaiement(data) {
    this.tableCommandeAchats[this.index].idCommande = data.idCommande;
    this.tableCommandeAchats[this.index].dateReglement = data.dateReglement;
    this.tableCommandeAchats[this.index].dateCheque = data.dateCheque;
    this.tableCommandeAchats[this.index].montant = data.montant;
    this.tableCommandeAchats[this.index].modePaiement = data.modePaiement;
    this.tableCommandeAchats[this.index].reference = data.reference;
    this.tableCommandeAchats[this.index].regle = data.regle;
    // this.idClient = this.tableCommandeAchat.idClient;
    // this.tableCommandeAchat = {};
    // this.tableCommandeAchat.idClient = this.idClient;
    // this.tableCommandeAchat.dateCommande = new Date()
    //   .toISOString()
    //   .split("T")[0];
    this.showButon = false;
    this.totalQantite();
    // this.prepareCommandeDetail();
    //  this.tableCommandeAchat = {};
  }
  findPaiement(idPaiement) {
    
    let recherche = this.Paiements.find((x) => x.id == idPaiement);
    this.designation = recherche.designation;
    this.idPaiement = idPaiement;
    // alert(this.idPaiement);
    // if (recherche != undefined) {
    // 	return recherche.designation;
    // } else {
    // 	return '0';
    // }
  }
  totalQantite() {
    let totalMontant = 0;
    let totalQte = 0;
    for (let data of this.tableCommandeAchats) {
      totalMontant += data.montant*1;
    }
    this.totalMontant = totalMontant;
    // return this._decimalPipe.transform(total, '1.2-2');
    // return total.toFixed(2);
  }

  deleteCommande(idPaiement) {
    // alert(idPaiement);
    var index = this.tableCommandeAchats.findIndex(
      (x) => x.idPaiement == idPaiement
    );
    // alert(index);
    this.tableCommandeAchats.splice(index, 1);
    this.totalQantite();
    // this.prepareCommandeDetail();
    // this.tableCommandeAchat = {};
    // console.log(this.achat);
    // var index = this.achat.findIndex((x) => x.idPaiement == idPaiement);
  }

  onRowSelect(event) {
    console.log(event.data)
    this.displayDialog = true;
    // console.log();
    this.idCommande = event.data.idCommande;
    this.montant = event.data.total ;
    // alert(this.idCommande)- event.data.remise
    // this.Achat.dateCommande = event.data.dateCommande;
    // this.Achat.idClient = event.data.idClient;
    this.initialiser();
    let recherche = this.tableDetailPaiements.filter((x) => x.idCommande == event.data.idCommande);
    this.tableCommandeAchats = recherche;
    console.log(this.tableDetailPaiements)
    // this.tableCommandeAchats = recherche;
    // console.log(this.tableDetailPaiements);
    this.totalQantite();
    // this.prepareCommandeDetail();
    // this.newDonnee = false;
    // this.Achat = this.cloneDonnee(event.data);
    // alert(this.tableCommandeAchat.idClient);
  }
  cloneDonnee(c: Achat): Achat {
    let Achat = {};
    for (let prop in c) {
      Achat[prop] = c[prop];
    }
    return Achat;
  }
  onRowSelectCommandeDetail(event) {
    console.log(event);
    this.showButon = true;
    this.showButonSauvgarder = true;
    this.index = this.tableCommandeAchats.findIndex(
      (x) => x.idPaiement == event.data.idPaiement
    );
    // alert(this.index);
    this.newDonnee = false;
    this.Paiement = this.cloneDonneeCommandeDetail(event.data);
    this.displayDialog = true;
  }
  cloneDonneeCommandeDetail(c: Paiement): Paiement {
    let tableCommandeAchat = {};
    for (let prop in c) {
      tableCommandeAchat[prop] = c[prop];
    }
    return tableCommandeAchat;
  }
  close() {
    this.displayDialog = false;
    this.tableCommandeAchats = [];
    this.tableCommandeAchat = {};
    this.requetteCommandeAchats = [];
    this.requetteCommandeAchat = {};
    this.tableCommandeAchat.dateReglement = new Date()
      .toISOString()
      .split("T")[0];
    this.totalQantite();
    this.tableCommandeAchat.dateCheque = new Date()
      .toISOString()
      .split("T")[0];
    this.totalQantite();
    // this.prepareCommandeDetail();
  }
  initialiser() {
    this.Paiement = {};
    this.Paiement.modePaiement='espece';
    this.Paiement.regle="o";
    // this.Paiement.modePaiement="";
    // this.tableCommandeAchat = {};
    this.Paiement.dateReglement = new Date()
    .toISOString()
    .split("T")[0];
    this.Paiement.dateCheque = new Date()
    .toISOString()
    .split("T")[0];
    this.requetteCommandeAchats = this.tableCommandeAchats;
    this.showButon = false;
  }
  closeCheque(){
    this.displaycheque=false;
  }
}

