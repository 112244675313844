import { Component, OnInit } from "@angular/core";
import { DataService } from "../../data.service";
import { Article } from "../../model/article";
import { Stock } from "../../model/stock";

@Component({
  selector: "app-add-stock",
  templateUrl: "add-stock.component.html",
  styleUrls: ["add-stock.component.scss"],
})
export class AddStockComponent implements OnInit {
  Article: Article = {};
  Articles: Article[];
  Stock: Stock = {};
  Stocks: Stock[];
  selectedStock: Stock;
  displayDialog: boolean = false;
  constructor(private data: DataService) {}

  ngOnInit() {
    this.stockInitial();
    this.article();
  }
  article() {
    this.Article.action = "Select";
    this.data
      .article(this.Article)
      .toPromise()
      .then((x: Article[]) => (this.Articles = x));
  }
  stockInitial() {
    this.Stock.action = "Select";
    this.data
      .personnel(this.Stock)
      .toPromise()
      .then((x: Stock[]) => (this.Stocks = x));
  }
  save() {
    if (this.Stock.id) {
      // this.Personnel.activite = this.activite;
      this.Stock.action = "Update";
      this.data
        .StockInitial(this.Stock)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            alert(res.message);
            this.displayDialog = false;
            this.stockInitial();
          } else {
            // console.log(res);
            alert(res.message);
            alert("Nonnnn");
          }
        });
    } else {
      this.Stock.action = "Insert";
      this.data
        .StockInitial(this.Stock)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            alert(res.message);
            this.stockInitial();
            this.displayDialog = false;
          } else {
            alert(res.message);
            this.displayDialog = false;
          }
        });
    }
  }
  delete() {
    if (confirm("êtes vous sure?")) {
      this.Stock.action = "Delete";
      this.data
        .StockInitial(this.Stock)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            alert(res.message);
            this.displayDialog = false;
            this.stockInitial();
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  newDonnee: boolean;
  showDialogToAdd() {
    this.newDonnee = true;
    this.Stock = {};
    this.displayDialog = true;
  }
  onRowSelect(event) {
    console.log(event);
    this.newDonnee = false;
    this.Stock = this.cloneDonnee(event.data);
    this.displayDialog = true;
  }
  cloneDonnee(c: Stock): Stock {
    let Stock = {};
    for (let prop in c) {
      Stock[prop] = c[prop];
    }
    return Stock;
  }
}
