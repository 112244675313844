import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { DataService } from "../../data.service";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Data } from "../../model/stockDepot";
import { DecimalPipe } from '@angular/common';
import {Message,MessageService} from 'primeng/api';
import { AuthenticationService } from '../../authentication.service';
import swal from 'sweetalert';
import { Article } from '../../model/article';
import { Depot } from '../../model/depot';
@Component({
  selector: "app-stock-depot",
  templateUrl: "stock-depot.component.html",
  styleUrls: ["stock-depot.component.scss"],
  providers: [DecimalPipe,MessageService],
})
export class StockDepotComponent implements OnInit {
  myUrl: string = `${environment.urlApi}`;
  Data: Data = {};
  Datas: Data[];
  Depot: Depot = {};
  Depots: Depot[];
  Datass: Data[];
  depots: Data[];
  // depot: Data;
  selectedClient: Data;
  displayDialog: boolean;
  afficher: boolean;
  selectedValue: string;
  designation: string;
  depott: string;
  able:boolean;
  Article: Article = {};
  Articles: Article[];
  StockDepot: Data = {};
  StockDepots: Data[];
  filteredArticles: any[];
  constructor(
    private data: DataService,
    private http: HttpClient,
    private _decimalPipe: DecimalPipe,
    public AuthenticationService: AuthenticationService,
    private messageService: MessageService
    ) {}
  ngOnInit() { 
    if(this.AuthenticationService.currentUserValue.groupe=='Administrateur' || this.AuthenticationService.currentUserValue.groupe=='Administrateurs' ){
      this.able=true;
      this.afficher=false;
    }
    // this.depots = [
    //   { nom: "DEPOT LA MAISON" },
    //   { nom: "DEPOT LHAJA" },
    //   { nom: "DEPOT SIDI MBARK" },
    //   { nom: "DEPOT MOUSSA" },
    //   { nom: "MAGASIN" },
    // ];
    this.article();
    this.depot();
    this.stockDepot();
  }
  stockDepot() {
    this.Data.action = "Selectt";
    this.data
      .stockDepot(this.Data)
      .toPromise()
      .then((x: Data[]) => (this.Datas = x,this.Datass=x,this.filter()));
  }
  depot() {
    this.Data.action = "SelectDepot";
    this.data
      .stockDepot(this.Data)
      .toPromise()
      .then((x: Data[]) => (this.Depots = x));
  }
  article() {
    this.Article.action = "Select";
    this.data
      .article(this.Article)
      .toPromise()
      .then((x: Article[]) => (this.Articles = x));
  }
  cloture(){
    if (this.afficher!=true){
      this.afficher=true;
    }else{
      this.afficher=false;
    }
  }
  @ViewChild('inputQte', {static: false}) inputQte:ElementRef;
  setFocusQte(){
    setTimeout(() => this.inputQte.nativeElement.focus());
  }
  findPrix(idArticle) {
    // idArticle=idArticle.idArticle;
    this.setFocusQte();
    let recherche = this.Articles.find((x) => x.id === idArticle);
    this.Data.idArticle = recherche.idArticle;
    this.Data.designation = recherche.designation;
  }
  filterArticle(event) {
    let filtered : any[] = [];
    let query = event.query;
    // console.log(this.Articles);
    filtered = this.Articles.filter((x) => x.designation.toString().toLocaleLowerCase().includes(query.toLocaleLowerCase()));   
    this.filteredArticles = filtered;
  }
  filter() {
    if (this.depott==undefined){
      this.depott='';
    }
    if (this.designation==undefined){
      this.designation='';
    }
    let recherche = this.Datas.filter((x) => 
     (x.designation.toLocaleLowerCase().includes(this.designation.toLocaleLowerCase())
     &&
     x.depot.toLocaleLowerCase().includes(this.depott.toLocaleLowerCase())
     )
     
     );
    this.Datass = recherche;
  }
  save() {
    this.Data.depot=this.StockDepot.depot;
    this.Data.dataDate=this.StockDepot.dataDate
    console.log(this.Data);
    if (!this.Data.id) {
      // alert("save");
      this.Data.action = "Insert";
      this.data
        .stockDepot(this.Data)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.stockDepot();
            this.displayDialog = false;
          } else {
            alert(res.message);
          }
        });
    } else {
      this.Data.action = "Update";
      this.data
        .stockDepot(this.Data)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.stockDepot();
            this.displayDialog = false;
          } else {
            alert(res.message);
          }
        });
    }
  }
onConfirm() {
    this.messageService.clear('c');
}

onReject() {
    this.messageService.clear('c');
}

clear() {
    this.messageService.clear();
}
showConfirm() {
    this.messageService.clear();
    this.messageService.add({key: 'c', sticky: true, severity:'warn', summary:'Are you sure?', detail:'Confirm to proceed'});
}
delete() {
    if (confirm("êtes vous sure?")) {
      this.Data.action = "Delete";
      this.data
        .stockDepot(this.Data)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            alert(res.message);
            this.displayDialog = false;
            this.stockDepot();
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  newDonnee: boolean;
  showDialogToAdd() {
    // alert(this.Data.dataDate);
    this.afficher=true;
    this.Data = {};
    this.StockDepot = {};
     this.StockDepot.dataDate = new Date().toISOString().split("T")[0];
    this.newDonnee = true;
    this.displayDialog = true;
  }
  onRowSelect(event) {
    // console.log(event.data);
    this.StockDepot.dataDate = event.data.dateMouvement;
    this.StockDepot.depot = event.data.idDepot;
    this.afficher = false;
    this.newDonnee = false;
    this.Data = this.cloneDonnee(event.data);
    this.displayDialog = true;
  }
  cloneDonnee(c: Data): Data {
    let Data = {};
    for (let prop in c) {
      Data[prop] = c[prop];
    }
    return Data;
  }
  close() {
    this.displayDialog = false;
    this.Datas = [];
    this.Data = {};
    this.Data.dataDate = new Date()
      .toISOString()
      .split("T")[0];
  }
}
