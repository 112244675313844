import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
	name: 'filter'
	// pure: false
})
@Injectable()
export class MyFilterPipe implements PipeTransform {
	// transform(items: any[], filter: Object): any {
	// 	if (!items || !filter) {
	// 		return items;
	// 	}
	// 	// filter items array, items which match and return true will be
	// 	// kept, false will be filtered out
	// 	return items.filter((item) => item.nom.indexOf(filter) !== -1);
	// }
	transform(items: any[], field: string, value: string): any[] {
		if (!items) return [];
		if (!value || value.length == 0) return items;
		return items.filter((it) => it[field].toLowerCase().indexOf(value.toLowerCase()) != -1);
	}
}
