import { Component, OnInit } from "@angular/core";
import { DataService } from "../../data.service";
import { HttpClient } from "@angular/common/http";
import { Client } from "../../model/client";
import { DecimalPipe } from '@angular/common';
import {Message,MessageService} from 'primeng/api';
import { AuthenticationService } from '../../authentication.service';
import swal from 'sweetalert';
@Component({
  selector: "app-clientCamion",
  templateUrl: "clientCamion.component.html",
  styleUrls: ["clientCamion.component.scss"],
  providers: [DecimalPipe,MessageService],
})
export class ClientCamionComponent implements OnInit {
  Client: Client = {};
  Clients: Client[];
  Clientss: Client[];
  categories: any;
  selectedClient: Client;
  displayDialog: boolean;
  selectedValue: string;
  first :number=0;
  nom: string="";
  nomAr: string ="";
  code: string="";
  able:boolean;
  constructor(
    private data: DataService,
    private http: HttpClient,
    private _decimalPipe: DecimalPipe,
    public AuthenticationService: AuthenticationService,
    private messageService: MessageService
    ) {}
  ngOnInit() { 
    if(this.AuthenticationService.currentUserValue.groupe=='Administrateur' || this.AuthenticationService.currentUserValue.groupe=='Administrateurs' ){
      this.able=true;
    }   
    this.categories = [
      { name: "Grossiste" },
      { name: "Demi Grossiste" },
      { name: "Comptoir" },
    ];
    this.client();
  }
  client() {
    this.Client.action = "SelectCamion";
    this.Client.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .client(this.Client)
      .toPromise()
      .then((x: Client[]) => (this.Clients = x,this.Clientss=x,this.filter()));
  }
  filter() {
    if (this.nomAr==undefined){
      this.nomAr='';
    }
    if (this.nom==undefined){
      this.nom='';
    }
    if (this.code==undefined){
      this.code='';
    }
    let recherche = this.Clients.filter((x) => 
        x.nom.toLocaleLowerCase().includes(this.nom.toLocaleLowerCase()) && 
        x.nomAr.toLocaleLowerCase().includes(this.nomAr.toLocaleLowerCase()) &&
        x.code.toLocaleLowerCase().includes(this.code.toLocaleLowerCase())
     );
    this.Clientss = recherche;
    this.first=0;
  }
  save() {
    // console.log(this.Client);
    if (this.Client.nom && this.Client.ice) {
    if (!this.Client.id) {
      // alert("save");
      this.Client.action = "InsertCamion";
      this.Client.idDepot = 3;
      this.Client.DBase  = this.AuthenticationService.currentUserValue.DBase;
      console.log(this.Client);
      this.data
        .client(this.Client)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.client();
            this.displayDialog = false;
          } else {
              this.messageService.add({severity:'warn', summary: 'ATTENTION', detail: res.message});
          }
        });
    } else {
      this.Client.action = "UpdateCamion";
      this.Client.idDepot = 3;
      this.Client.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.data
        .client(this.Client)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.client();
            this.displayDialog = false;
          } else {
            this.messageService.add({severity:'warn', summary: 'ATTENTION', detail: res.message});
          }
        });
    }
  }else{
    this.messageService.add({severity:'warn', summary: 'ATTENTION', detail: "Il manque le Nom ou le ICE !!!"});
  }
  }
onConfirm() {
    this.messageService.clear('c');
}

onReject() {
    this.messageService.clear('c');
}

clear() {
    this.messageService.clear();
}
  showConfirm() {
    this.messageService.clear();
    this.messageService.add({key: 'c', sticky: true, severity:'warn', summary:'Are you sure?', detail:'Confirm to proceed'});
}
  delete() {
    if (confirm("êtes vous sure?")) {
      this.Client.action = "Delete";
      this.Client.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.data
        .client(this.Client)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
            this.displayDialog = false;
            this.client();
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  newDonnee: boolean;
  showDialogToAdd() {
    this.newDonnee = true;
    this.Client = {};
    this.displayDialog = true;
    this.Client.categorie="Comptoir";
    this.Client.exclut="n";
  }
  onRowSelect(event) {
    // console.log(event);
    this.newDonnee = false;
    this.Client = this.cloneDonnee(event.data);
    this.displayDialog = true;
  }
  cloneDonnee(c: Client): Client {
    let Client = {};
    for (let prop in c) {
      Client[prop] = c[prop];
    }
    return Client;
  }
}
