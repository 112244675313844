import { Component, OnInit } from "@angular/core";
import { DecimalPipe } from "@angular/common";
import { DataService } from "../../data.service";
import { AuthenticationService } from "../../authentication.service";
import { environment } from "../../../environments/environment";
import * as XLSX from "xlsx";
import { Achat } from "../../model/achat";
import { Fournisseur } from "../../model/fournisseur";
import { Paiement } from "../../model/paiement";
import {Message,MessageService} from 'primeng/api';
import { Avoir } from "../../model/avoir";
import { ModePaiement } from "src/app/model/modePaiement";

@Component({
  selector: "app-situation-paiement-fournisseur.component",
  templateUrl: "situation-paiement-fournisseur.component.html",
  styleUrls: ["situation-paiement-fournisseur.component.scss"],
  providers: [DecimalPipe,MessageService],
})
export class SituationPaiementFournisseurComponent implements OnInit {
  msgs1: Message[];    
  msgs2: Message[];
  idPaiement: number;
  Paiement: Paiement = {};
  Paiements: Paiement[];
  // recherchePaiement: Paiement = {};
  Avoir: Avoir = {};
  Avoirs: Avoir[];
  tableDetailAvoirs: Achat[];
  tableRecapAvoirs: Achat[];
  tableRecapeAvoirs: Achat[];

  tableRecapPaiements: Paiement [];
  tableRecapSoldeInitial: Paiement [];
  tableFournisseurPaiements: Achat [];
  tableFournisseurPaiementss: Achat [];
  tableDetailsAchatsRequetteBetwin: Achat[];
  Achat: Achat = {};
  Achats: Achat[];
  selectedAchat: Achat;
  tableAchats: Achat[];
  tableDetailsAchats: Achat[];
  tableDetailAchats: Achat[];
  tableCommandeDetail: Achat[];
  tableDetailsAchatsRequette: Achat[];
  tableDetailPaiements: Paiement[];
  Cheques: Paiement[];
  selectedCommandeDetail: Achat;
  tableCommandeAchat: Achat = {};
  tableCommandeAchats: any = [];
  requetteCommandeAchat: Achat = {};
  requetteCommandeAchats: any = [];
  Fournisseur: Fournisseur = {};
  Fournisseurs: Fournisseur[];
  ModePaiement: ModePaiement = {};
  ModePaiements: ModePaiement[];
  blockedDocument: boolean;
  displayDialog: boolean;
  displayDialogHistorique: boolean;
  displayDialogDetailBon: boolean;
  displayDialogDetailAvoir: boolean;
  displaycheque: boolean;
  francais: boolean =false;
  langueDirection: string ="ltr";
  langueLbl: string ="العربية";
  dateSituationDebut: string;
  dateSituationFin: string;
  showButon: boolean;
  showButonSauvgarder: boolean;
  designation: string;
  code: string;
  nomFilter: string;
  nomArFilter: string;
  nom: string;
  nomAr: string;
  nomm: string;
  dateD: any ;
  totalMontant: number;
  totaldetailBon: number;
  totalQte: number;
  total: number;  
  soldeInitial : number;
  montant: number;
  avance: number;
  avoir: number;
  totalCheque: number;
  reste: number;
  idFournisseur: number;
  index: string;
  commandeDetail: string;
  dateDebut: any;
  dateFin: any;
  nomFournisseur:string;
  myUrl: string = `${environment.urlApi}`;
  constructor(
    private data: DataService,
    private _decimalPipe: DecimalPipe,
    public AuthenticationService: AuthenticationService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    // this.fournisseur();
    // this.fournisseur();
    if(this.AuthenticationService.currentUserValue.francais){
      this.francais=false;
      this.langueDirection="ltr"
      this.langueLbl="العربية"
    }else{
      this.francais=true;
      this.langueDirection="rtl"
      this.langueLbl="الفرنسية"
    }
    this.achat();
    this.achatDetail();
    this.achatRecap();
    this.paiementDetail();
    this.modePaiement();   
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);
    // this.paiementDetailFournisseur();
    this.Paiement.dateReglement = new Date().toISOString().split("T")[0];
    this.Paiement.dateCommande  = new Date().toISOString().split("T")[0];
    this.Paiement.dateCheque    = new Date().toISOString().split("T")[0];
    this.Paiement.dateEcheance  = new Date().toISOString().split("T")[0];
    this.dateSituationDebut     = firstDay.toISOString().split("T")[0];
    this.dateSituationFin       = new Date().toISOString().split("T")[0];
  }
  // fournisseur() {
  //   this.Fournisseur.action = "Select";
  //   this.data
  //     .fournisseur(this.Fournisseur)
  //     .toPromise()
  //     .then((x: Fournisseur[]) => (this.Fournisseurs = x));
  // }
  achatDetail() {
    this.Achat.action = "SelectDetail";
    this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .achat(this.Achat)
      .toPromise()
      .then((x: Achat[]) => (this.tableDetailAchats = x));
  }
  // fournisseur() {
  //   this.Fournisseur.action = "Select";
  //   this.data
  //     .fournisseur(this.Fournisseur)
  //     .toPromise()
  //     .then((x: Fournisseur[]) => (this.Fournisseurs = x));
  // }

  achat() {
    this.Achat.action = "RecapByFournisseur";
    this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .recapFournisseur(this.Achat)
      .toPromise()
      .then(
        (x: Achat[]) => (this.Achats = x, this.tableAchats = x,this.paiementRecap())
      );
  }
  paiementRecap() {
    this.Paiement.action = "Recap";
    this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .recapFournisseur(this.Paiement)
      .toPromise()
      .then((x: Paiement[]) => (
        this.tableRecapPaiements = x,
        this.soldesInitial()));
  }
  soldesInitial() {
    this.Paiement.action = "soldeInitial";
    this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .recapFournisseur(this.Paiement)
      .toPromise()
      .then((x: Paiement[]) => (
        this.tableRecapSoldeInitial = x,
        this.avoirRecap()));
  }
  avoirRecap() {
    this.Avoir.action = "SelectRecapByIdFournisseur";
    this.Avoir.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .avoirAchat(this.Avoir)
      .toPromise()
      .then((x: Achat[]) => (
        this.tableRecapAvoirs = x,
        this.tablePaiement()));
  }

  tablePaiement(){
    if (this.tableRecapPaiements.length!=undefined){
      // console.log(this.tableRecapPaiements);
      // console.log(this.tableAchats);
        this.tableAchats.forEach((row ,index )=>{
          let updateItem = this.tableRecapPaiements.find(item=>item.idFournisseur==row.idFournisseur);
         
          if (updateItem===undefined){
            this.tableAchats[index].avance = 0;
            this.tableAchats[index].reste = this.tableAchats[index].total;  
          }else{
          this.tableAchats[index].avance = updateItem.montant;
          this.tableAchats[index].reste = this.tableAchats[index].total-updateItem.montant;      
          }
          // console.log(this.tableAchats);
        }); 
        this.tableSoldeInitial();
    }
}
tableSoldeInitial(){
  if (this.tableRecapSoldeInitial.length!=undefined){
      this.tableAchats.forEach((row ,index )=>{
        let updateItem = this.tableRecapSoldeInitial.find(item=>item.idFournisseur==row.idFournisseur);
        // console.log(updateItem);
        if (updateItem===undefined){
          this.tableAchats[index].soldeInitial = 0;
          // this.tableVentes[index].reste += -updateItem.montant;  
        }else{
        this.tableAchats[index].soldeInitial = updateItem.montant;
        this.tableAchats[index].reste += -updateItem.montant;      
        }
      }); 
  }
  this.tableAvoir();
}

tableAvoir(){
  if (this.tableRecapAvoirs.length!=undefined){
    // console.log(this.tableRecapAvoirs);
    // console.log(this.tableAchats);
      this.tableAchats.forEach((row ,index )=>{
        let updateItem = this.tableRecapAvoirs.find(item=>item.idFournisseur==row.idFournisseur);
        
        if (updateItem===undefined){
          this.tableAchats[index].avoir = 0; 
          // this.tableVentes[index].reste += -updateItem.montant;
          // console.log(updateItem);
        }else{
          this.tableAchats[index].avoir = updateItem.montant;
          this.tableAchats[index].reste += -updateItem.montant;
        }
      }); 
  }
  // console.log(this.tableAchats);
  //  this.filter(this.nomFilter,'nom');
   this.filter();
}

  paiementDetail() {
    this.Paiement.action = "Select";
    this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .recapFournisseur(this.Paiement)
      .toPromise()
      .then((x: Paiement[]) => (
        this.tableDetailPaiements = x,
        this.Cheques =x.filter((x) => x.modePaiement != 'Espèce'),
        this.filter()));
  }
  modePaiement() {
    this.ModePaiement.action = "Select";
    this.ModePaiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .modePaiement(this.ModePaiement)
      .toPromise()
      .then((x: ModePaiement[]) => (this.ModePaiements = x));
  }

  findModePaiement(idModePaiement) {
    let rechercheModePaiement = this.ModePaiements.find((x) => x.id == idModePaiement);
    this.Paiement.modePaiement = rechercheModePaiement.modePaiement;
  }
  achatRecap() {
    this.Achat.action = "Select";
    this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .achat(this.Achat)
      .toPromise()
      .then(
        (x: Achat[]) => (
          (this.tableDetailsAchats = x),
          // console.log(x),
          this.paiementDetailFournisseur()
        )
      );
  }

  paiementDetailFournisseur() {
    this.Paiement.action = "Detail";
    this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .recapFournisseur(this.Paiement)
      .toPromise()
      .then((x: Achat[]) => (
        this.tableFournisseurPaiements = x,
        this.tableFournisseurPaiementss = x,
        this.avoirRecape()
      ));
  }
  avoirRecape() {
    // alert('eee');
    this.Avoir.action = "Select";
    this.Avoir.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .avoirAchat(this.Avoir)
      .toPromise()
      .then((x: Achat[]) => (
        this.tableRecapeAvoirs = x,
        this.tablePaiementDetail()
      ));
  }

  tablePaiementDetail(){
    // alert('dd');
    if (this.tableDetailsAchats.length!=undefined){
        this.tableDetailsAchatsRequette = this.tableDetailsAchats;
        if (this.tableFournisseurPaiements.length!=undefined){
          this.tableFournisseurPaiements.forEach((row ,index )=>{
          this.tableDetailsAchatsRequette.push(row);
          })
        }
        if (this.tableRecapeAvoirs){
          this.tableRecapeAvoirs.forEach((row ,index )=>{
          this.tableDetailsAchatsRequette.push(row);
          // console.log(row);
          })
        }
        this.tableDetailsAchatsRequette.sort((b, a) => new Date(b.dateCommande).getTime() - new Date(a.dateCommande).getTime());
        this.filterPaiement();
    }
}

filterPaiement(){
  // alert(this.tableFournisseurPaiements.length);
  if (this.tableFournisseurPaiements.length!=undefined){
    let rechercheP = this.tableFournisseurPaiements.filter((x) => x.idFournisseur==this.idFournisseur ); 
    this.tableFournisseurPaiementss = rechercheP;
    this.avoirDetail();
  }
}

avoirDetail() {
  // alert('eee');
  this.Avoir.action = "Detail";
  this.Avoir.DBase  = this.AuthenticationService.currentUserValue.DBase;
  this.data
    .avoirAchat(this.Avoir)
    .toPromise()
    .then((x: Achat[]) => (
      this.tableDetailAvoirs = x
    ));
    this.filter();
}

filter() {
  //data, colone
  // if (this.nomFilter===undefined){  //   this.nomFilter='';  // }
  // if (data===undefined){  //   data='';  // }
  // let recherche;  
  // if (colone=="idFournisseur"){
  //    recherche = this.Achats.filter((x) => x.idFournisseur.toString().includes(data));
  // }else{
  //   recherche = this.Achats.filter((x) =>x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1
  //   ); 
  // }
  if(!this.nom){this.nom="";}
  if(!this.nomAr){this.nomAr="";}
  if(!this.code){this.code="";}
  let recherche = this.Achats.filter((x) => 
    x.nom.toString().toLocaleLowerCase().includes(this.nom.toLocaleLowerCase()) &&
    x.nomAr.toString().toLocaleLowerCase().includes(this.nomAr.toLocaleLowerCase()) &&
    x.code.toString().toLocaleLowerCase().includes(this.code.toLocaleLowerCase())
  );
  if (recherche){
    // console.log(recherche);
    this.tableAchats = recherche;
    this.totalCommande();    
  }
}
filterCommande(startDate,endDate){
  let recherche = this.tableDetailsAchatsRequette.filter((x) => x.dateCommande >= startDate && x.dateCommande <= endDate+1 );
  this.tableDetailsAchatsRequetteBetwin = recherche;
  // console.log(recherche);
}

changeLangue(){
  // alert(this.francais); 
  if(this.francais==true){
    this.francais=false;
    this.langueDirection="ltr"
    this.langueLbl="العربية"
  }else{
    this.francais=true;
    this.langueDirection="rtl"
    this.langueLbl="الفرنسية"
  }
  
} 
filterCheque(data){
  // console.log(data);
  this.Cheques =this.tableDetailPaiements.filter((x) => 
      x.modePaiement != 'Espèce' && 
      x.nom.toLocaleLowerCase().includes(data.toLocaleLowerCase()));
  this.totalCommande();
}
totalCommande() {
  // console.log(this.tableAchats);
  this.soldeInitial = 0;
  this.total = 0;
  this.avance = 0;
  this.avoir = 0;
  this.totalCheque = 0;
  this.reste = 0;
  this.soldeInitial = this.tableAchats.map((t) => (t.soldeInitial) * 1).reduce((a, value) => a + value, 0);
  this.total = this.tableAchats.map((t) => (t.total) * 1).reduce((a, value) => a + value, 0);
  this.avoir = this.tableAchats.map((t) => (t.avoir) * 1).reduce((a, value) => a + value, 0);
  this.avance = this.tableAchats.map((t) => (t.avance) * 1).reduce((a, value) => a + value, 0);
  this.reste = this.tableAchats.map((t) => (t.reste) * 1).reduce((a, value) => a + value, 0);
  this.totalCheque = this.Cheques.map((t) => (t.montant) * 1).reduce((a, value) => a + value, 0);
}

  transformDecimal(num) {
    return this._decimalPipe.transform(num, "1.2-2");
  }
  showDialogCheque(){
    this.displaycheque=true;
  }
  espece(){
   
    if (this.Paiement.modePaiement=='Espèce'){
      this.Paiement.reference=""; 
      this.Paiement.dateEcheance = new Date()
      .toISOString()
      .split("T")[0];
    }
  }
   save() {
    
    if (this.Paiement.id) {
      // alert(this.Paiement.id);
      this.Paiement.action = "Update";
      this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
      // console.log(this.Paiement);
      this.data
        .recapFournisseur(this.Paiement)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.displayDialog = false;
            this.displayDialogDetailBon = false;
            this.displayDialogHistorique = false;
            this.achat();
            this.achatDetail();
            this.achatRecap();
            this.paiementDetail();
            this.filter();

          } else {
            alert("Nonnnn");
          }
        });
    } else {
      // alert('add');
      if (!this.idFournisseur ){
        alert("Vous avez oublier le Fournisseur !")
      }else{
        this.Paiement.action = "Insert";
        this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
        this.Paiement.idFournisseur = this.idFournisseur;
        // console.log(this.Paiement);
        this.data
          .recapFournisseur(this.Paiement)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
              this.displayDialogDetailBon = false;
              this.displayDialogHistorique = false;
              this.achat();
              this.achatDetail();
              this.achatRecap();
              this.paiementDetail();
              this.filter();
            } else {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
            }
          });
      }
    }
  }
  delete(id) {
      // this.messageService.clear();
      // this.messageService.add({key: 'c', sticky: true, severity:'warn', summary:'êtes vous sure de supprimer?', detail:'Confirm to proceed'});
    if (confirm("êtes vous sure?")) {
      this.Paiement.action = "Delete";
      this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.Paiement.id = id;
      this.data
        .recapFournisseur(this.Paiement)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'warn', summary: 'Warning', detail: res.message});
            // this.paiementDetail();
            // this.paiementRecap();
            // this.achatRecap();
            // this.paiementDetailFournisseur();
            this.displayDialog = false;
            this.displayDialogDetailBon = false;
            this.displayDialogHistorique = false;
            this.achat();
            this.achatDetail();
            this.achatRecap();  
            this.filter();
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  printSituation(): void {
    var params = [
      "height=" + screen.height,
      "width=" + screen.width,
      "fullscreen=yes", // only works in IE, but here for completeness
    ].join(",");
    let printContents, popupWin;
    printContents = document.getElementById("print-section-fournisseur").innerHTML;
    popupWin = window.open("", "_blank", params);
    popupWin.document.open();
    popupWin.document.write(`
			  <html>
				<head>
				  <title>Print tab</title>
          <style>          
          .fontArab {
            font-family: droidkufi, lato, sans-serif !important;
            font-weight: bold;
          }
            table {
            // width: 750px;
            border-collapse: collapse;
            margin: 5px auto;
            border:1px solid;
          }
          td, th {
            border:1px solid;
            font-family: droidkufi, lato, sans-serif !important;
          }
          tr:nth-child(odd) {background: none repeat scroll 0 0 #F2F5F9;}
          tr:nth-child(even) {background-color: #FFFFFF;}
          /* Zebra striping */
          tr:nth-of-type(odd) {
            // background: #eee;					
          }				
          th {
            background: #3498db;
            color: white;
            font-weight: bold;
            text-align: center;
          }				
          td,
          th {
            padding: 5px;
            border: 1px solid #ccc;
            text-align: left;
            font-size: 12px;
          }
        </style>
				</head>
			<body onload="window.print();window.close()" style="text-align:center;font-family: droidkufi, lato, sans-serif !important;">${printContents}</body>
			  </html>`);
    popupWin.document.close();
  }
  exportExcelFournisseur(): void {
    let fileName = "Situation des paiements du Fournisseur " + this.nom +  ".xlsx";  
      /* table id is passed over here */
      let element = document.getElementById("excel-table-Fournisseur");
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  
      /* generate workbook and add the worksheet */
      var title ="Situation du Fournisseur : "+ this.nom ;
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        wb,
        ws,
        this.nom
      );
  
      /* save to file */
      XLSX.writeFile(wb, fileName);
    }
  exportExcelFournisseurs(): void {
    let fileName = "Situation des paiements.xlsx";  
      /* table id is passed over here */
      let element = document.getElementById("excel-table-Fournisseurs");
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  
      /* generate workbook and add the worksheet */
      var title ="Situation " ;
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        wb,
        ws,
        'Situation Paiment'
      );
  
      /* save to file */
      XLSX.writeFile(wb, fileName);
    }
  cloture(idPaiement,mode) {
    // alert(idPaiement+" | "+mode);

      this.blockedDocument=true;
      this.Paiement.action = "UpdateRegle";
      this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.Paiement.id = idPaiement;
      this.Paiement.regle = mode;
      this.data
        .recapFournisseur(this.Paiement)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            // alert(res.message);
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.blockedDocument=false;
            // this.displaycheque = false;
            this.achat();
            this.achatDetail();
            this.achatRecap();
            this.paiementDetail();
            this.filterCheque(this.nomFournisseur);
          } else {
            alert(res.message);
          }
        });
  }
  newDonnee: boolean;
  showDialog() {
    this.filterPaiement();
    this.displayDialog = true;
    this.newDonnee = true;
    this.Achat = {};
  }
  showDialogToAdd() {
    this.displayDialog = true;
    this.filterPaiement();
    this.showButon = false;
    this.showButonSauvgarder = false;
    // this.newDonnee = true;
    this.Paiement={};
    this.Paiement.modePaiement='Espèce';
    this.Paiement.regle="o";
    this.Achat = {};
    this.tableCommandeAchat = {};
    this.tableCommandeAchats = [];
    this.Achat.dateCommandee = new Date().toISOString().split("T")[0];
    this.Paiement.dateCommande = new Date()
    .toISOString()
    .split("T")[0];
    this.Paiement.dateReglement = new Date()
    .toISOString()
    .split("T")[0];
    this.Paiement.dateEcheance = new Date()
    .toISOString()
    .split("T")[0];
    this.Paiement.dateCheque = new Date()
    .toISOString()
    .split("T")[0];
    this.Paiement.idFournisseur=this.idFournisseur;
    
  }
  addPaiement(data) {
    // console.log(data)
    // if (this.tableCommandeAchat.idPaiement) {
      this.tableCommandeAchats.push(data);

      // var index = this.tableCommandeAchats.findIndex(
      //   (x) => x.idPaiement == this.idPaiement
      // );

      this.showButon = false;
      this.totalQantite();
      // this.prepareCommandeDetail();
      this.tableCommandeAchat = {};
      // alert("2");
    // } else {
    //   alert("Entrer un Paiement !");
    // }

    // var index = this.tableCommandeAchats.findIndex((x) => x.index == index);
  }
  updatePaiement(data) {
    this.tableCommandeAchats[this.index].idFournisseur = data.idFournisseur;
    this.tableCommandeAchats[this.index].dateReglement = data.dateCommande;
    this.tableCommandeAchats[this.index].dateCheque = data.dateCheque;
    this.tableCommandeAchats[this.index].montant = data.montant;
    this.tableCommandeAchats[this.index].modePaiement = data.modePaiement;
    this.tableCommandeAchats[this.index].reference = data.reference;
    this.tableCommandeAchats[this.index].regle = data.regle;
    // this.idFournisseur = this.tableCommandeAchat.idFournisseur;
    // this.tableCommandeAchat = {};
    // this.tableCommandeAchat.idFournisseur = this.idFournisseur;
    // this.tableCommandeAchat.dateCommande = new Date()
    //   .toISOString()
    //   .split("T")[0];
    this.showButon = false;
    this.totalQantite();
    // this.prepareCommandeDetail();
    //  this.tableCommandeAchat = {};
  }
  findPaiement(idPaiement) {
    
    let recherche = this.Paiements.find((x) => x.id == idPaiement);
    this.designation = recherche.designation;
    this.idPaiement = idPaiement;
    // alert(this.idPaiement);
    // if (recherche != undefined) {
    // 	return recherche.designation;
    // } else {
    // 	return '0';
    // }
  }
  totalQantite() {
    let totalMontant = 0;
    let totalQte = 0;
    for (let data of this.tableCommandeAchats) {
      totalMontant += data.montant*1;
    }
    this.totalMontant = totalMontant;
    // return this._decimalPipe.transform(total, '1.2-2');
    // return total.toFixed(2);
    
    this.filterCommande(this.dateSituationDebut,this.dateSituationFin);
  }

  deleteCommande(idPaiement) {
    // alert(idPaiement);
    var index = this.tableCommandeAchats.findIndex(
      (x) => x.idPaiement == idPaiement
    );
    // alert(index);
    this.tableCommandeAchats.splice(index, 1);
    this.totalQantite();
    // this.prepareCommandeDetail();
    // this.tableCommandeAchat = {};
    // console.log(this.achat);
    // var index = this.achat.findIndex((x) => x.idPaiement == idPaiement);
  }
  prepareCommandeDetail() {
    this.commandeDetail = "";
    for (let data of this.tableCommandeAchats) {
      this.commandeDetail +=
        "('idAchat','" +
        data.idPaiement +
        "','" +
        data.quantite +
        "','" +
        data.prixAchat +
        "','" +
        data.prixAchatDetail +
        "','" +
        data.prixAchatGros +
        "','" +
        data.prixAchat +
        "'),";
    }
    this.commandeDetail += "$";
    this.commandeDetail = this.commandeDetail.replace(",$", ";");
    this.initialiser();
    // alert(this.tableCommandeAchat.idFournisseur);
  }

  onRowSelect(event) {
    // alert(event.data.idFournisseur)
    this.displayDialogHistorique = true;   
    this.idFournisseur = event.data.idFournisseur;
    this.nom = event.data.nom;
    this.nomAr = event.data.nomAr;
    this.montant = event.data.total ;
    // this.montant = event.data.montant - event.data.remise;
    // alert(this.idFournisseur)
    // this.Achat.dateCommande = event.data.dateCommande;
    // this.Achat.idFournisseur = event.data.idFournisseur;
    // this.initialiser();
    // this.filterPaiement();
    // console.log(this.tableDetailsAchats);
    let recherche = this.tableDetailsAchats.filter((x) => x.idFournisseur == event.data.idFournisseur);
    this.tableDetailsAchatsRequette = recherche;
    // console.log(this.tableDetailsAchatsRequette);
    let cumul=0;
    if (this.tableDetailsAchatsRequette.length!=undefined){
      this.tableDetailsAchatsRequette.forEach((row ,index )=>{
        if(row.total==undefined){
          if (row.regle=='o'){
              cumul += -row.avance*1;
          }else{
            cumul += 0;
          }
        }else if(row.mode == "Avoir"){
          cumul += -row.total*1;
        }else if(row.avance==undefined){
          cumul += row.total*1;
        }
        // console.log(row);
        
        // console.log(cumul +"+"+ row.montant+"+" + row.avance );
        this.tableDetailsAchatsRequette[index].cumul = cumul;
        })
    }
    // console.log(this.tableDetailPaiements)
    // this.tableCommandeAchats = recherche;
    // console.log(this.tableDetailPaiements);
    this.totalQantite();
    // this.prepareCommandeDetail();
    // this.newDonnee = false;
    // this.Achat = this.cloneDonnee(event.data);
    // alert(this.tableCommandeAchat.idFournisseur);
  }
  cloneDonnee(c: Achat): Achat {
    let Achat = {};
    for (let prop in c) {
      Achat[prop] = c[prop];
    }
    return Achat;
  }

  onRowSelectDetailBon(event) {
    // alert(event.data.mode);
    this.initialiser();
    if (event.data.mode=='Bon'){
      this.displayDialogDetailBon = true;
      // this.Achat.remise = event.data.remise;
      let recherche = this.tableDetailAchats.filter((x) => x.id == event.data.idCommande);
      this.tableCommandeDetail= recherche;
      this.totalBonDetail();
    }else if (event.data.mode=='Avoir'){
      // console.log(event);
      // console.log(this.tableDetailAvoirs);
      this.displayDialogDetailAvoir = true;
      let recherche = this.tableDetailAvoirs.filter((x) => x.idCommande == event.data.idCommande);
      this.tableCommandeDetail= recherche;

      // console.log(recherche);
      this.totalBonDetail();
    }else if(event.data.mode=='Avance'){
      this.displayDialog=true;
      let rechercheP = this.tableFournisseurPaiements.filter((x) => 
        x.idFournisseur==this.idFournisseur  && x.id==event.data.id      
      ); 
      this.tableFournisseurPaiementss = rechercheP;
      this.onRowSelectCommandeDetail(event);
    }

    // this.prepareCommandeDetail();
  }

  totalBonDetail(){
    let totalMontant = 0;
    for (let data of this.tableCommandeDetail) {
      totalMontant += data.total;
    }
    this.totaldetailBon = totalMontant;
    // return this._decimalPipe.transform(total, '1.2-2');
    // return total.toFixed(2);
  }
  onRowSelectCommandeDetail(event) {
    this.showButon = true;
    this.showButonSauvgarder = true;
    this.index = this.tableCommandeAchats.findIndex(
      (x) => x.idPaiement == event.data.idPaiement
    );
    // alert( event.data.idFournisseur);
    this.Paiement.idFournisseur = event.data.idFournisseur;
    this.newDonnee = false;
    this.Paiement = this.cloneDonneeCommandeDetail(event.data);
    this.displayDialog = true;
  }
  cloneDonneeCommandeDetail(c: Paiement): Paiement {
    let tableCommandeAchat = {};
    for (let prop in c) {
      tableCommandeAchat[prop] = c[prop];
    }
    return tableCommandeAchat;
  }
  close() {
    this.displayDialog = false;
    this.tableCommandeAchats = [];
    this.tableCommandeAchat = {};
    this.requetteCommandeAchats = [];
    this.requetteCommandeAchat = {};
    this.tableCommandeAchat.dateReglement = new Date()
      .toISOString()
      .split("T")[0];
    this.totalQantite();
    this.tableCommandeAchat.dateCheque = new Date()
      .toISOString()
      .split("T")[0];
    this.totalQantite();
    // this.prepareCommandeDetail();
  }
  initialiser() {
    this.Paiement = {};
    this.Paiement.modePaiement='Espèce';
    this.Paiement.regle="o";
    // this.Paiement.modePaiement="";
    // this.tableCommandeAchat = {};
    this.Paiement.dateCommande  = new Date().toISOString().split("T")[0];
    this.Paiement.dateEcheance  = new Date().toISOString().split("T")[0];
    this.Paiement.dateReglement = new Date().toISOString().split("T")[0];
    this.Paiement.dateCheque    = new Date().toISOString().split("T")[0];
    // this.requetteCommandeAchats = this.tableCommandeAchats;
    // this.tableCommandeAchat.idPaiement = null;
    // this.tableCommandeAchat.quantite = "";
    // this.tableCommandeAchat.prixAchat = "";
    // this.tableCommandeAchat.prixAchat = "";
    // this.tableCommandeAchat.prixAchatDetail = "";
    // this.tableCommandeAchat.prixAchatGros = "";
    this.showButon = false;
  }
  closeCheque(){
    this.displaycheque=false;
  }
  closeHistorique(){
    this.displayDialogHistorique=false;
  }
  closeDetailBon(){
    this.displayDialogDetailBon=false;
  }
  closeDetailAvoir(){
    this.displayDialogDetailAvoir=false;
  }
}

