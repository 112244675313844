import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from "@angular/core";
import { DataService } from "../../data.service";
import { AuthenticationService } from "../../authentication.service";
import { environment } from "../../../environments/environment";
import { Article } from "../../model/article";
import { Vente } from "../../model/vente";
import { DecimalPipe, UpperCasePipe } from "@angular/common";
import * as XLSX from "xlsx";
import { Client } from "../../model/client";
import { Achat } from "../../model/achat";
import {Message,MessageService,ConfirmationService} from 'primeng/api';
import { Charge } from '../../model/charge';
import { Paiement } from '../../model/paiement';
import { Depot } from "src/app/model/depot";
import { DataBase } from "src/app/model/dataBase";
// import {CheckboxModule} from 'primeng/checkbox';
@Component({
  selector: "app-avoirs",
  templateUrl: "avoirs.component.html",
  styleUrls: ["avoirs.component.scss"],
  providers: [DecimalPipe,MessageService,ConfirmationService],
})
export class AvoirsComponent implements OnInit {
  msgs1: Message[];    
  msgs2: Message[];

  idArticle: number;
  nbre:number;
  higthCol:string;
  idArticleTest: number;
  venteX: number;
  Article: Article = {};
  Articles: Article[];
  ArticlesRecherche: Article[];
  stock: Article[];
  Achat: Achat = {};
  Achats: Achat[];
  rechercheArticle: Article = {};
  rechercheArticles: Article[];
  Vente: Vente = {};
  Ventes: Vente[];
  Depot: Depot = {};
  Depots: Depot[];
  selectedVente: Vente;
  selectedArticle:string;
  tableVentes: Vente[];
  tableDetailVentes: Vente[];
  tableDetailVentess: Vente[];
  artilcesClient: Vente[];
  artilceClient: Vente={};
  selectedCommandeDetail: Vente;
  tableCommandeVente: Vente = {};
  tableCommandeVentes: any = [];
  requetteCommandeVente: Vente = {};
  requetteCommandeVentes: any = [];
  prepareRetour: any = [];
  Client: Client = {};
  Clients: Client[];
  Donnees     : DataBase[];
  Donnee      : DataBase = {};   
  dataTable   : string;

  displayDialog: boolean;
  desablePrint: boolean ;
  showButon: boolean;
  showButonSauvgarder: boolean;
  blockedDocument: boolean;
  designation: string='';
  nomComplet:string='';
  nbon:string='';
  numm:string='';
  nAvoir:string='';
  nom:string;
  totalMontant: number;
  totalMarge: number;
  totalQte: number;
  total: number;
  remise: number;
  margeTotal: number;
  idClient: number;
  idCommande: number;
  qte: number;
  quantiteVenteGros: number;
  quantiteVenteDetail: number;
  prixVenteGros: number;
  prixVenteDetail: number;
  prixVente1: number;
  prixAchat: number;
  Exclut:string;  
  index: string;
  commandeDetail: string;
  commandeDetailDepot: string;
  dateDebut: any;
  dateFin: any;
  dateSituationDebut: string;
  dateSituationFin: string;
  msgs: Message[] = [];
  position: string;
  displaybarcode: boolean;
  displayCharge: boolean;
  afficher: boolean;
  BarCode:string;

  able: boolean =false;
  depotActivate: boolean =false;
  dropdownArticle: boolean =false;
  afficherCodebarre: boolean =false;
  myUrl: string = `${environment.urlApi}`;
  
	Charge: any = {};
  Charges: any[];
  totalCharge:number;
  nbreBon:number;

  Paiement: any = {};
  Paiements: any[];
  totalPaiement:number;
  
  filteredArticles: any[];
  constructor(
    private data: DataService,
    private _decimalPipe: DecimalPipe,
    public AuthenticationService: AuthenticationService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}
  addSingle() {
    this.messageService.add({severity:'success', summary:'Service Message', detail:'Via MessageService'});
}

  ngOnInit() {
    if(this.AuthenticationService.currentUserValue.groupe=='Administrateur' || this.AuthenticationService.currentUserValue.groupe=='Administrateurs' ){
      this.able=true;
    }
    if(this.AuthenticationService.currentUserValue.depot== true){
      this.depotActivate=true;
    }
    this.dropdownArticle=true;
    this.commandeDetail="";
    this.msgs1 = [
      {severity:'success', summary:'Success', detail:'Message Content'},
      {severity:'info', summary:'Info', detail:'Message Content'},
      {severity:'warn', summary:'Warning', detail:'Message Content'},
      {severity:'error', summary:'Error', detail:'Message Content'}
     ];
    this.dateSituationDebut = new Date().toISOString().split("T")[0];
    this.dateSituationFin = new Date().toISOString().split("T")[0];
    this.article();
    // this.getArticles();
    // this.achat();
    this.client();
    this.depot();
    this.venteDetail();
    this.donnee();
    this.tableCommandeVente.dateCommandee = new Date().toISOString().split("T")[0];
    this.Vente.dateCommandee = new Date().toISOString().split("T")[0];
    this.Vente.nom="";
    this.Vente.nomComplet="";
  }
  donnee() {
    this.Donnee.action = "Select";
    this.dataTable = "database";
    this.Donnee.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .donnee(this.dataTable,this.Donnee)
      .toPromise()
      .then((x: DataBase[]) => (this.Donnees = x));
  }

  // getArticles() {
	// 	this.Article.action = 'Select';
  //   this.Article.DBase  = this.AuthenticationService.currentUserValue.DBase;
	// 	this.data.stock(this.Article).toPromise().then((x: Article[]) => (this.stock = x));
  // }
  filterArticle(event) {
    let filtered : any[] = [];
    let query = event.query;
    // console.log(this.Articles);
    filtered = this.Articles.filter((x) => x.designation.toString().toLocaleLowerCase().includes(query.toLocaleLowerCase()));   
    this.filteredArticles = filtered;
  }

  // showDialogCharge(){
  //   this.displayCharge=true;
  // }

  article() {
    this.Article.action = "Select";
    this.Article.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .article(this.Article)
      .toPromise()
      .then((x: Article[]) => (
        this.Articles = x,
        this.filteredArticles = x,
        // console.log(x),
        this.ArticlesRecherche = x));
  }
  achat() {
    this.Achat.action = "Select";
    this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .achat(this.Achat)
      .toPromise()
      .then((x: Achat[]) => (this.Achats = x));
  }

  findCommande(numm){
    this.requetteCommandeVentes=[];
    this.totalQantite();
    this.prepareCommandeDetail();
    this.Vente.numm=numm;
    this.Vente.action = "SelectDetailParClientCommande";
    this.Vente.DBase  = this.AuthenticationService.currentUserValue.DBase;
    //  console.log( this.Vente);
    this.data
      .vente(this.Vente)
      .toPromise()
      .then((x: Vente[]) => (
        this.tableDetailVentess = x
        ));
        // console.log(this.tableDetailVentess);
    if(!this.tableDetailVentess){
       alert("L'article choisi n'est pas dans la liste de votre Client !!!");
    }
  }

  findArticle(idArticle,colone){
    // console.log(idArticle);
    // console.log(colone);
    // if(colone=='designation'){
    //   this.rechercheArticle = this.stock.find((x) => x.designation == idArticle.designation);
    //   console.log(this.stock);
    // }else if(colone=='idArticle'){
    //   this.rechercheArticle = this.stock.find((x) => x.id == idArticle.idArticle);
    // }
    if(colone=="DataBase"){
      this.tableCommandeVente.idArticle =this.tableCommandeVente.idArticle;
    }else{
      this.tableCommandeVente.idArticle = idArticle.idArticle;      
    }

    if(this.Vente.idClient && this.Vente.DataBase && this.tableCommandeVente.idArticle){
        this.tableDetailVentess=[];
        this.Vente.idArticle=this.tableCommandeVente.idArticle;
        this.Vente.action = "SelectDetailParClientArticle";
        this.Vente.DBase  = this.Vente.DataBase;
        //  console.log( this.Vente);
        this.data
          .vente(this.Vente)
          .toPromise()
          .then((x: Vente[]) => (
            this.tableDetailVentess = x
            ));
        if(!this.tableDetailVentess){
          this.messageService.add({severity:'warn', summary: 'Sauvegarde', detail: "L'article choisi n'est pas dans la liste de votre Client !!!"});
        }
           
    }else{
      this.messageService.add({severity:'warn', summary: 'Sauvegarde', detail: "Choisir un client ET un article"});
    }
  }
  
  client() {
    this.Client.action = "Select";
    this.Client.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .client(this.Client)
      .toPromise()
      .then((x: Client[]) => (this.Clients = x));
  }
  
  // exclut(idClient){
  //   // alert(idClient);
  //   let recherche = this.Ventes.filter((x) => (x.idClient == idClient));
  //   this.nbreBon =recherche.length; 
  //   let recherchee = this.Clients.find((x) => x.id == idClient);
  //   this.Exclut=recherchee.exclut;
  //   this.artilcesClient = this.tableDetailVentes.filter((x) => x.idClient == idClient);
  // }

 

  // charge(dateDebut,dateFin) {
	// 	this.Charge.action = 'FilterChargeCaisse';
	// 	this.Charge.dateDebut = dateDebut;
	// 	this.Charge.dateFin   = dateFin;
	// 	this.data
	// 		.charge(this.Charge)
	// 		.toPromise()
	// 		.then(
	// 			(x: Charge[]) => (this.Charges = x, this.calculTotalCharge())
	// 		);
  // }
  
  // calculTotalCharge(){
  //   this.totalCharge = 0;    
  //   if(this.Charges.length>0){
  //     this.totalCharge = this.Charges
  //       .map((t) => t.montant * 1)
  //       .reduce((a, value) => a + value, 0);  
  //   }    
  // }

  // paiementRecap(dateDebut,dateFin) {
  //   this.Paiement.action = "RecapByDate";
  //   this.Paiement.dateDebut = dateDebut;
	// 	this.Paiement.dateFin   = dateFin;  
  //   this.data
  //     .paiementVente(this.Paiement)
  //     .toPromise()
  //     .then((x: Paiement[]) => (this.Paiements = x,this.calculTotalPaiement()));
  // }
  // calculTotalPaiement() {
  //   this.totalPaiement = 0;    
  //   if(this.Paiements.length>0){
  //     this.totalPaiement = this.Paiements
  //       .map((t) => t.montant * 1)
  //       .reduce((a, value) => a + value, 0);  
  //   }  
  // }
  venteDetail() {
    this.Vente.action = "SelectDetail";
    this.Vente.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .avoir(this.Vente)
      .toPromise()
      .then((x: Vente[]) => (this.tableDetailVentes = x,this.vente()));
  }

  vente() {
    this.Vente.action = "Select";
    this.Vente.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .avoir(this.Vente)
      .toPromise()
      .then(
        (x: Vente[]) => (
          (this.Ventes = x), 
          (this.tableVentes = x),
          // this.paiementRecap(this.dateSituationDebut,this.dateSituationFin),
          this.filterCommande(this.dateSituationDebut,this.dateSituationFin)
        )
      );
  }
  filterCommande(dateDebut,dateFin){
    if (this.nom==undefined){
      this.nom='';
    }
    if (this.nomComplet==undefined){
      this.nomComplet='';
    }

    let recherche = this.Ventes.filter((x) => 
      (x.dateAvoir >= dateDebut && x.dateAvoir <= dateFin) && 
      x.nom.toLocaleLowerCase().includes(this.nom.toLocaleLowerCase()) &&
      x.nomComplet.toLocaleLowerCase().includes(this.nomComplet.toLocaleLowerCase()) &&
      x.numm.toString().includes(this.numm) &&
      x.nAvoir.toString().includes(this.nAvoir)
      );
    this.tableVentes = recherche; 
    // console.log(this.tableVentes);
    this.totalCommande();
    // this.charge(dateDebut,dateFin);
    // this.paiementRecap(dateDebut,dateFin);
    // this.recherhceMarge();
  }

  totalCommande() {
    this.total = 0;
    this.total = this.tableVentes
      .map((t) => t.montant * 1)
      .reduce((a, value) => a + value, 0);
    this.remise = 0;
    this.remise = this.tableVentes
      .map((t) => t.remise * 1)
      .reduce((a, value) => a + value, 0);
    this.margeTotal = 0;
    this.margeTotal = this.tableVentes
      .map((t) => t.marge * 1)
      .reduce((a, value) => a + value, 0);
  }
  depot() {
    this.Depot.action = "SelectDepot";
    this.Depot.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .depot(this.Depot)
      .toPromise()
      .then((x: Depot[]) => (this.Depots = x));
  }
  // this.recherhceMarge()
  figer(){
    if (this.afficher!=true){
      this.afficher=true;
    }else{
      this.afficher=false;
    }
  }
  cloture(idCommande,cloture) {
    // alert(idCommande+" | "+cloture);
    let etat;
    if (cloture==0){
      etat=1;
    }else{
      etat=0;
    }
      this.blockedDocument=true;
      this.tableCommandeVente.action = "UpdateCloure";
      this.tableCommandeVente.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.tableCommandeVente.etat = etat;
      this.tableCommandeVente.id = idCommande;
      this.data
        .avoir(this.tableCommandeVente)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            // alert(res.message);
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.blockedDocument=false;
            this.displayDialog = false;
            this.vente();
            this.venteDetail();
          } else {
            alert(res.message);
          }
        });
  }

  transformDecimal(num) {
    return this._decimalPipe.transform(num, "1.2-2");
  }
  recherhceMarge(){

        this.tableVentes.forEach((row)=>{
        let recherche = this.tableDetailVentes.filter((x) => x.idCommande == row.idCommande);

        let updateItem = this.tableVentes.find(item=>item.idCommande==row.idCommande);
        let index = this.tableVentes.indexOf(updateItem);
        let marge =  recherche
          .map((t) => t.marge)
          .reduce((a, value) => a + value, 0);
        this.tableVentes[index].marge = marge;
      }); 
      // console.log(this.tableVentes);
      this.totalCommande();
  }
  saveCommandeVente() {
    this.tableCommandeVente.action = "Insert";
    this.tableCommandeVente.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.tableCommandeVente.commandeDetail = this.commandeDetail;
    this.data
      .avoir(this.tableCommandeVente)
      .toPromise()
      .then((res: { message: string }) => {
        if (res.message == "Opération de modification réuissie") {
          this.vente();
          alert(res.message);
        } else {
          alert(res.message);
        }
      });
  }
  //   filter(data, colone) {
  //     console.log(data + colone);
  //     let recherche = this.Achats.filter(
  //       (x) =>
  //         x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1
  //     );
  //     this.tableAchats = recherche;
  //     this.total = this.tableAchats
  //       .map((t) => t.montant * 1)
  //       .reduce((a, value) => a + value, 0);
  //   }
  filter(data, colone) {
    let recherche;
    if (colone=="nbon" ){
       recherche = this.Ventes.filter((x) => x.nbon.toString().includes(data));
    }else if ( colone =="numm"){
      recherche = this.Ventes.filter((x) => x.numm.toString().includes(data));
    }else{
      recherche = this.Ventes.filter(
      (x) =>
        x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1
    ); 
    }
    if (recherche!=undefined){
    this.tableVentes = recherche;
    }
    this.totalCommande();
  }
  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

  @ViewChild('input1', {static: false}) inputEl:ElementRef;

  barcode(){
    this.displaybarcode=true;
    this.close();
    // this.Vente.nbon='0';
    // this.Vente.remise=0;
    // setTimeout(() => this.inputEl.nativeElement.focus());
  }

  codebarre(){
    this.close();
    if (this.afficherCodebarre==true){
      this.afficherCodebarre=false;
      this.displaybarcode=false;
    }else{
      this.afficherCodebarre=true;
      this.displaybarcode=true;
    }
    // this.displaybarcode=false;
  }
  // setFocus(){
  //   setTimeout(() => this.inputEl.nativeElement.focus());
  // }
  // @ViewChild('inputArticle', {static: false}) inputArticle:ElementRef;
  // setFocusArticle(){
  //   setTimeout(() => this.inputArticle.nativeElement.focus());
  // }  
  @ViewChild('inputCodeBarre', {static: false}) inputCodeBarre:ElementRef;
  setFocusCodeBarre(){
    setTimeout(() => this.inputCodeBarre.nativeElement.focus());
  }  
  @ViewChild('inputQte', {static: false}) inputQte:ElementRef;
  setFocusQte(){
    setTimeout(() => this.inputQte.nativeElement.focus());
  }
  barcodeHide(){
    this.displaybarcode=false;
  }
  factureSelect(event){
    this.displayDialog = false; 
    this.displaybarcode=true;       
    this.Vente.id = event.idCommande;
    this.Vente.num = event.num;
    this.Vente.numm = event.numm;
    this.Vente.dateCommandee = event.dateCommande;
    this.Vente.idClient = JSON.stringify(event.idClient);
    this.Vente.remise = event.remise;
    this.Vente.nom = event.nom;
    this.Vente.nbon = event.nbon;
    this.Vente.idUser = event.idUser;
    this.Vente.nomComplet = event.nomComplet;
    this.Vente.cloture = event.cloture;
    // alert (this.Vente.idClient);
    let recherche = this.tableDetailVentes.filter(
      (x) => x.id == event.idCommande
    );
    this.tableCommandeVentes = recherche;
    this.requetteCommandeVentes = recherche;
    this.totalQantite();
    this.prepareCommandeDetail();
  }
  save() {
    if (this.Vente.nbon=='0' ){
      this.Vente.nbon=this.Vente.nbon.toString();
    }
    // console.log('idclient' + this.Vente.idClient);
    // console.log('nbon' + this.Vente.nbon);
    // console.log('commandeDetail' + this.commandeDetail);
    if (this.Vente.idClient && this.commandeDetail!='') {
    this.blockedDocument=true;
    if (this.Vente.id) {
      // alert('update');
      this.tableCommandeVente.action = "Update";
      this.tableCommandeVente.DBase  = this.AuthenticationService.currentUserValue.DBase;
      if (this.Vente.idUser){
        this.tableCommandeVente.idUser = this.Vente.idUser;
      }else{
          this.tableCommandeVente.idUser = this.AuthenticationService.currentUserValue.id;
      }  
      this.tableCommandeVente.commandeDetail        = this.commandeDetail;
      this.tableCommandeVente.commandeDetailDepot   = this.commandeDetailDepot  ;
      this.tableCommandeVente.id                    = this.Vente.id;
      this.tableCommandeVente.num                   = this.Vente.num;
      this.tableCommandeVente.nAvoir                = this.Vente.nAvoir;
      this.tableCommandeVente.remise                = this.Vente.remise;
      this.tableCommandeVente.idClient              = this.Vente.idClient;
      this.tableCommandeVente.dateCommandee         = this.Vente.dateCommandee;
      this.tableCommandeVente.nbon                  = this.Vente.nbon;
      this.tableCommandeVente.nbreBon               = this.nbreBon;
      // console.log(this.tableCommandeVente);
      this.data
        .avoir(this.tableCommandeVente)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            // alert(res.message);
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.displaybarcode   = false;  
            this.blockedDocument  = false;
            this.displayDialog    = false;
            this.desablePrint     = false;
            this.vente();
            // this.getArticles();
            this.venteDetail();
            this.displaybarcode=false;
          } else {
            alert(res.message);
          }
        });
    } else {
      // alert('add')
        this.tableCommandeVente.action                = "Insert";
        this.tableCommandeVente.DBase                 = this.AuthenticationService.currentUserValue.DBase;
        this.tableCommandeVente.idUser                = this.AuthenticationService.currentUserValue.id;
        this.tableCommandeVente.commandeDetailDepot   = this.commandeDetailDepot  ;
        // this.tableCommandeVente.idCommande      = this.idCommande;
        this.tableCommandeVente.remise                = this.Vente.remise;
        this.tableCommandeVente.commandeDetail        = this.commandeDetail;
        this.tableCommandeVente.idClient              = this.Vente.idClient;
        this.tableCommandeVente.dateCommandee         = this.Vente.dateCommandee;
        this.tableCommandeVente.nbon                  = this.Vente.nbon;
        this.tableCommandeVente.nbreBon               = this.nbreBon;
        // console.log(this.tableCommandeVente);
        this.data
          .avoir(this.tableCommandeVente)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.vente();
              // this.getArticles();
              this.venteDetail();
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displaybarcode   = false;  
              this.displayDialog = false;
              this.blockedDocument=false;
              this.desablePrint = false;
              this.displaybarcode=false;
            } else {
              alert(res.message);
              this.displayDialog = false;
            }
          });
      }
    } else {
        alert("Vous-avez oublier le clients !!!");
    }
  }
  delete() {
    // alert();
    //   this.confirmationService.confirm({
    //       message: 'Do you want to delete this record?',
    //       header: 'Delete Confirmation',
    //       icon: 'pi pi-info-circle',
    //       accept: () => {
      if (confirm("êtes vous sure?")) {
        // console.log(this.Vente);
            this.Vente.action = "Delete";
            this.Vente.DBase  = this.AuthenticationService.currentUserValue.DBase;
            this.data
              .avoir(this.Vente)
              .toPromise()
              .then((res: { message: string }) => {
                if ((res.message = "Opération de supression réuissie")) {
                  this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
                  this.vente();
                  this.venteDetail();
                  this.displayDialog = false;
                } else {
                  alert("Nonnnn");
                }
              });
    // });
  }
  }
  
  exportexcel(): void {
  let fileName = "Situation des ventes du "+ this.dateSituationDebut +  " au " +  this.dateSituationFin+".xlsx";  
    /* table id is passed over here */
    let element = document.getElementById("excel-table");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    var title =
      "Situation du " +
      this.dateSituationDebut +
      " au " +
      this.dateSituationFin;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      ws,
      "du " + this.dateSituationDebut + " au " + this.dateSituationFin
    );

    /* save to file */
    XLSX.writeFile(wb, fileName);
  }
  printSituation(): void {
    var params = [
      "height=" + screen.height,
      "width=" + screen.width,
      "fullscreen=yes", // only works in IE, but here for completeness
    ].join(",");
    let printContents, popupWin;
    printContents = document.getElementById("print-table").innerHTML;
    popupWin = window.open("", "_blank", params);
    popupWin.document.open();
    popupWin.document.write(`
			  <html>
				<head>
				  <title>Print tab</title>
				  <style>
				  table {
					// width: 750px;
					border-collapse: collapse;
					margin: 5px auto;
				}				
				/* Zebra striping */
				tr:nth-of-type(odd) {
					// background: #eee;
					
				}				
				th {
					background: #3498db;
					color: white;
					font-weight: bold;
					text-align: center;
				}				
				td,
				th {
					padding: 5px;
					// border: 1px solid #ccc;
					text-align: left;
					font-size: 12px;
				}
				  </style>
				</head>
			<body onload="window.print();window.close()" style="text-align:center;">${printContents}</body>
			  </html>`);
    popupWin.document.close();
  }
  printBL(idCommande) {
    this.displayDialog = false;
    // console.log(event);
    // this.Vente.id = event.data.idCommande;
    // this.Vente.dateCommandee = event.data.dateCommande;
    // this.Vente.idClient = event.data.idClient;
    // this.Vente.remise = event.data.remise;

    let recherche = this.tableDetailVentes.filter((x) => x.id == idCommande);
    this.tableCommandeVentes = recherche;
    this.requetteCommandeVentes = recherche;
    // console.log(this.requetteCommandeVentes);
    this.totalQantite();
    this.prepareCommandeDetail();
  }
  newDonnee: boolean;
  showDialog() {
    this.newDonnee = true;
    this.Vente = {};
    this.displayDialog = true;
  }
  showDialogToAdd() {
    this.afficher= true;
    this.tableCommandeVente = {};
    this.tableCommandeVentes = [];
    this.tableDetailVentess = [];
    this.requetteCommandeVentes = [];
    this.showButon = false;
    this.showButonSauvgarder = false;
    this.newDonnee = true;
    this.Vente = {};
    this.Vente.remise = 0;
    this.Vente.dateCommandee = new Date().toISOString().split("T")[0];
    this.Vente.idClient='1';
    this.tableCommandeVente.idDepot=3;
    this.displayDialog = true;
    this.setFocusCodeBarre();        
    let rechercheDB =this.Donnees.find((x) => x.actif == true);
    if(rechercheDB){
      this.Vente.DataBase= rechercheDB.data;
    }
  }
  retourArticle(data) {
    // console.log(data);
      this.prepareRetour              = [];
      this.prepareRetour.id           = data.id;
      this.prepareRetour.dateCommande = data.dateCommande;
      this.prepareRetour.idArticle    = data.idArticle;
      this.prepareRetour.designation  = data.designation;
      this.prepareRetour.idClient     = data.idClient;
      this.prepareRetour.idCommande   = data.idCommande;
      this.prepareRetour.idDetail     = data.idDetail;
      this.prepareRetour.marge        = data.marge;
      this.prepareRetour.nom          = data.nom;
      this.prepareRetour.num          = data.num;
      this.prepareRetour.numm         = data.numm;
      this.prepareRetour.prixAchat    = data.prixAchat;
      this.prepareRetour.prixVente    = data.prixVente;
      this.prepareRetour.remise       = data.remise;
      this.prepareRetour.total        = data.prixVente;
      this.prepareRetour.type         = data.type;
      this.idCommande                 = this.prepareRetour.idCommande;
      this.qte                        = data.quantite;
      let rechercheDepot = this.Depots.find((x) => x.designation="MAGASIN");
      // console.log(rechercheDepot);
      
      this.prepareRetour.depot = rechercheDepot.designation;
      this.prepareRetour.idDepot == rechercheDepot.id;
      this.prepareRetour.quantite =1;
        if(this.showButon==false){
            if( this.prepareRetour.hasOwnProperty('prixAchat')==false){
              this.prepareRetour.prixAchat=0;
            }
            this.prepareRetour.total=this.prepareRetour.prixVente;
            this.prepareRetour.marge=(this.prepareRetour.prixVente - this.prepareRetour.prixAchat);
            // alert(data.marge);
      
          // if (this.tableCommandeVente.idArticle || data.codeBarre || data.idArticle ) {
            
            let recherche = this.requetteCommandeVentes.find((x) => x.idArticle == data.idArticle);
            if(!recherche){
            this.requetteCommandeVentes.push(this.prepareRetour);
            }else{
              var index = this.requetteCommandeVentes.findIndex((x) => x.idArticle == data.idArticle);
              // alert(this.qte +' <> '+this.requetteCommandeVentes[index].quantite);
              if((this.requetteCommandeVentes[index].quantite) < this.qte){
                this.requetteCommandeVentes[index].quantite=recherche.quantite+1;
                this.requetteCommandeVentes[index].total = recherche.prixVente*this.tableCommandeVentes[index].quantite;
              }else{
                this.messageService.add({severity:'warn', summary: 'Sauvegarde', detail: 'Quantité est superieur a Bon !!'});
              }
            }
            // console.log( this.requetteCommandeVentes);
            
            this.showButon = false;
            this.totalQantite();
            this.prepareCommandeDetail();
            this.tableCommandeVente = {};
            this.selectedArticle="";
      
          // } else {
          //   alert("Entrer un Article !");
          // }
          this.desablePrint=true;
          // this.setFocusArticle();
          // var index = this.tableCommandeAchats.findIndex((x) => x.index == index);
        }else{
          if( data.hasOwnProperty('prixAchat')==false){
            data.prixAchat=0;
          }
          this.tableCommandeVentes[this.index].idArticle = data.idArticle;
          this.tableCommandeVentes[this.index].designation = data.designation;
          this.tableCommandeVentes[this.index].quantite = data.quantite;
          this.tableCommandeVentes[this.index].prixVente = data.prixVente;
          this.tableCommandeVentes[this.index].total = data.prixVente*data.quantite;
          this.tableCommandeVentes[this.index].marge = (data.prixVente-data.prixAchat)*data.quantite;
          this.idClient = this.tableCommandeVente.idClient;
          this.showButon = false;
          this.totalQantite();
          this.prepareCommandeDetail();
        }
  }
  findDepot(idDepot) {
    // console.log(idDepot);
    
    let rechercheDepot = this.Depots.find((x) => x.id == idDepot);
    // this.tableDepot = rechercheDepot;
    this.tableCommandeVente.depot = rechercheDepot.designation;
    // this.idDepotVente=this.tableDepot.idDepotVente
  }
  updateArticle(data) {
    // console.log(data)
    if( data.hasOwnProperty('prixAchat')==false){
      data.prixAchat=0;
    }

    if(data.idArticle && data.idDepot && data.quantite && data.prixVente){
      var index = this.requetteCommandeVentes.findIndex((x) => x.idArticle == data.idArticle);
      // alert(index);
      // this.requetteCommandeVentes[index].dateCommande     = data.dateCommande;
      // this.requetteCommandeVentes[index].idArticle        = data.idArticle;
      // this.requetteCommandeVentes[index].designation      = data.designation;
      // this.requetteCommandeVentes[index].idDepot          = data.idDepot;
      // this.requetteCommandeVentes[index].depot            = data.depot;
      // this.requetteCommandeVentes[index].quantite         = data.quantite;
      // this.requetteCommandeVentes[index].prixVente        = data.prixVente;
      // this.requetteCommandeVentes[index].total            = data.prixVente*data.quantite;
      // this.requetteCommandeVentes[index].marge            = (data.prixVente-data.prixAchat)*data.quantite;
      // this.idClient = this.tableCommandeVente.idClient;

      // this.requetteCommandeVentes[index].id           = data.id;
      // this.requetteCommandeVentes[index].dateCommande = data.dateCommande;
      // this.requetteCommandeVentes[index].idArticle    = data.idArticle;
      // this.requetteCommandeVentes[index].designation  = data.designation;
      // this.requetteCommandeVentes[index].idClient     = data.idClient;
      // this.requetteCommandeVentes[index].idCommande   = data.idCommande;
      // this.requetteCommandeVentes[index].idDetail     = data.idDetail;
      // this.requetteCommandeVentes[index].marge        = data.marge;
      // this.requetteCommandeVentes[index].nom          = data.nom;
      // this.requetteCommandeVentes[index].num          = data.num;
      // this.requetteCommandeVentes[index].numm         = data.numm;
      // this.requetteCommandeVentes[index].prixAchat    = data.prixAchat;
      // this.requetteCommandeVentes[index].prixVente    = data.prixVente;
      // this.requetteCommandeVentes[index].remise       = data.remise;
      // this.requetteCommandeVentes[index].total        = data.prixVente;
      // this.requetteCommandeVentes[index].type         = data.type;
      // this.qte = data.quantite;
      let recherche = this.tableDetailVentess.find((x) => x.idArticle == data.idArticle);
      this.qte = recherche.quantite;
      if((this.requetteCommandeVentes[index].quantite) <= this.qte){
        this.requetteCommandeVentes[index].quantite = data.quantite;
        this.requetteCommandeVentes[index].total    = data.prixVente*this.tableCommandeVentes[index].quantite;
        this.idCommande                             = this.requetteCommandeVentes[index].idCommande;
        this.showButon = false;
        this.totalQantite();
        this.prepareCommandeDetail();
      }else{
        alert('Qté Supp !! ');
      }
    }else{
      if(!data.idArticle ){
        this.messageService.add({severity:'warn', summary: 'انتباه', detail: 'السلعة'});
      }
      if(!data.idDepot ){
        this.messageService.add({severity:'warn', summary: 'انتباه', detail: 'المستودع'});
      }
      if(!data.quantite ){
        this.messageService.add({severity:'warn', summary: 'انتباه', detail: 'العدد'});
      }
      if(!data.prixVente ){
        this.messageService.add({severity:'warn', summary: 'انتباه', detail: 'ثمن البيع'});
      }
    }
  }

  addArticleBarCode(data,colonne){
    
    // setTimeout(()=>{ // this will make the execution after the above boolean has changed
    //   this.BarCode.nativeElement.focus();
    // },0); 
    // let codeBarre="";
    // if (colonne=="codeBarre"){
      let recherche:any;
      if (colonne=="codeBarre"){
        recherche = this.Articles.find((x) => x.codeBarre == data);
      }else{
        recherche = this.Articles.find((x) => x.idArticle == data.idArticle);
      }
      
      data=recherche;
      data.total=recherche.prixVente;
      data.marge=(recherche.prixVente-recherche.prixAchat);
      data.idArticle=recherche.idArticle;
      // data.quantite=1;
      data.nbon=0;
      let exist = this.requetteCommandeVentes.find((x) => x.idArticle == data.idArticle);
      if(!exist){
        data.quantite=1;
        this.retourArticle(data);
      }else{      
        var index = this.tableCommandeVentes.findIndex((x) => x.idArticle == data.idArticle);
        this.tableCommandeVentes[index].quantite=exist.quantite+1;
        this.tableCommandeVentes[index].total = recherche.prixVente*this.tableCommandeVentes[index].quantite;
        this.totalQantite();
        this.prepareCommandeDetail();
        this.tableCommandeVente = {};
        this.selectedArticle="";
      }
    // console.log(this.tableCommandeVentes); 
    this.showButon = false;
    // this.totalQantite();
    // this.prepareCommandeDetail();
    // this.tableCommandeVente = {};
    this.BarCode="";
  }

  // findArticle(idArticle) {
  //   let recherche = this.Articles.find((x) => x.id == idArticle);
  //   this.designation = recherche.designation;
  //   this.idArticle = idArticle;
  //   // alert(this.idArticle);
  //   // if (recherche != undefined) {
  //   // 	return recherche.designation;
  //   // } else {
  //   // 	return '0';
  //   // }
  // }
  totalQantite() {
    let totalMontant = 0;
    let totalMarge = 0;
    let totalQte = 0;
    for (let data of this.requetteCommandeVentes) {
      totalMontant += data.total;
      totalMarge += data.marge * 1;
      totalQte += data.quantite * 1;
    }
    this.totalQte = totalQte;
    this.totalMarge = totalMarge;
    this.totalMontant = totalMontant;
    // return this._decimalPipe.transform(total, '1.2-2');
    // return total.toFixed(2);
  }

  deleteCommande(idDetail,idArticle) {
    // alert(idDetail +'|' +idArticle);
    this.desablePrint=true;
    // alert(this.desablePrint);
    if (idDetail !=""){
      var index = this.tableCommandeVentes.findIndex(
        (x) => x.idArticle == idArticle
      );
    }else{
      var index = this.tableCommandeVentes.findIndex(
        (x) => x.idDetail == idDetail
      );      
    }

    // alert(index);
    this.tableCommandeVentes.splice(index, 1);
    this.totalQantite();
    this.prepareCommandeDetail(); 
    // this.tableCommandeAchat = {};
    // console.log(this.vente);
    // var index = this.vente.findIndex((x) => x.idArticle == idArticle);
    // alert(this.desablePrint);
  }
  prepareCommandeDetail() {
    
    this.commandeDetail = "";
    for (let data of this.requetteCommandeVentes) {
      this.commandeDetail +=
        "('idCommande','" +
        data.idDepot      + "','" +
        data.dateCommande + "','" +
        data.idCommande   + "','" +
        data.idArticle    + "','" +
        data.quantite     + "','" +
        data.prixVente    +
        "'),";
    }
    this.commandeDetail += "$";
    this.commandeDetail = this.commandeDetail.replace(",$", ";");
    //-----------------vers Variation du Stock ----------------//

    this.commandeDetailDepot = "";
    for (let data of this.tableCommandeVentes) {
      this.commandeDetailDepot +=
        "('idCommande','"         +
        this.Vente.dateCommandee  +  "','" +
        data.idDepot              +  "','" +
        data.idArticle            +  "','" +
        data.quantite             +  
        "','Avoir Vente'),";
    }
    this.commandeDetailDepot += "$";
    this.commandeDetailDepot = this.commandeDetailDepot.replace(",$", ";");

    this.initialiser();
    // console.log(this.commandeDetail);
    // alert(this.tableCommandeVente.idFournisseur);
  }

  onRowSelect(event) {
    
    this.afficher= false;
    this.tableDetailVentess = [];
    // if (!event.data.cloture){
      // this.AuthenticationService.currentUserValue.username= this.AuthenticationService.currentUserValue.username.toUpperCase();
      if (this.able  || this.AuthenticationService.currentUserValue.groupe =="Admin" ) {
        this.displayDialog = true;
        // console.log(event.data);
        this.artilcesClient=[];
        this.artilceClient={}; 
        this.Vente.id = event.data.idCommande;
        this.Vente.id = event.data.idCommande;
        this.Vente.num = event.data.num;
        this.Vente.numm = event.data.numm;
        this.tableCommandeVentes.idClient = event.data.idClient;
        this.findCommande( event.data.numm);
        this.tableCommandeVentes.dateAvoir = event.data.dateAvoir;
        this.Vente.nbreBon = event.data.nbreBon;
        // alert(this.Vente.nbreBon);
        this.Vente.dateCommandee = event.data.dateAvoir;

        // let vvv=JSON.stringify(event.data.idClient);
        this.Vente.idClient     = JSON.stringify(event.data.idClient);
        this.Vente.remise       = event.data.remise;
        this.Vente.nom          = event.data.nom;
        this.Vente.nAvoir       = event.data.nAvoir;
        this.Vente.idUser       = event.data.idUser;        
        this.Vente.nomComplet   = event.data.nomComplet;
        this.Vente.adresse      = event.data.adresse;
        this.Vente.ville        = event.data.ville;
        this.Vente.abreviation  = event.data.abreviation;
        this.Vente.codeClient   = event.data.codeClient;
        this.Vente.cloture      = event.data.cloture;
        this.Exclut             = event.data.exclut;
        // alert (this.Vente.idClient);
        let recherche = this.tableDetailVentes.filter((x) => x.id == event.data.idCommande);
        this.tableCommandeVentes = recherche;
        this.requetteCommandeVentes = recherche;
        this.nbre=this.tableCommandeVentes.length-1;
        if(this.tableCommandeVentes.length==1){
          this.higthCol='500px';
        }else if(this.tableCommandeVentes.length>1) {
          let hight=500-(this.tableCommandeVentes.length-1)*20;
          this.higthCol=hight + 'px';
        }
        this.totalQantite();
        this.prepareCommandeDetail();
        let rechercheDB =this.Donnees.find((x) => x.actif == true);
        if(rechercheDB){
          this.Vente.DataBase= rechercheDB.data;
        }
      }
    // }
  }
  cloneDonnee(c: Vente): Vente {
    let Vente = {};
    for (let prop in c) {
      Vente[prop] = c[prop];
    }
    return Vente;
  }
  onRowSelectCommandeDetail(event) {
    // console.log(event.data);
    this.tableCommandeVente = event.data;
    // tableCommandeVente.idDepot
    this.showButon = true;
    this.showButonSauvgarder = true;
    // this.index = this.tableCommandeVentes.findIndex(
    //   (x) => x.idArticle == event.data.idArticle
    // );
    // // alert(this.index);
    this.newDonnee = false;
    // this.requetteCommandeVentes = this.cloneDonneeCommandeDetail(event.data);
    this.displayDialog = true;
    // console.log( this.tableCommandeVente);
  }
  cloneDonneeCommandeDetail(c: Vente): Vente {
    let tableCommandeVente = {};
    for (let prop in c) {
      tableCommandeVente[prop] = c[prop];
    }
    return tableCommandeVente;
  }
  close() {
    // this.setFocusArticle();
    this.displayDialog = false;
    this.displayCharge = false;
    this.tableCommandeVentes = [];
    this.tableCommandeVente = {};
    this.Charge={};
    this.Charges=[];
    this.requetteCommandeVentes = [];
    this.requetteCommandeVente = {};
    this.Vente.id=null;
    this.Vente.idClient="";
    this.Vente.dateCommandee = new Date()
    .toISOString()
    .split("T")[0];
    this.tableCommandeVente.dateCommandee = new Date()
      .toISOString()
      .split("T")[0];
    this.totalQantite();
    this.prepareCommandeDetail();
    this.Vente.nbon='0';
    this.Vente.remise=0;
    // setTimeout(() => this.inputEl.nativeElement.focus());
  }
  initialiser() {
    // this.desablePrint=false;
    this.tableCommandeVente = {};
    this.requetteCommandeVentes = this.tableCommandeVentes;
    this.showButon=false;
    this.prixVenteGros=undefined;
    this.prixVenteDetail=undefined;
    this.prixVente1=undefined;
    // this.tableCommandeAchat.idArticle = null;
    // this.tableCommandeAchat.quantite = "";
    // this.tableCommandeAchat.prixAchat = "";
    // this.tableCommandeAchat.prixVente = "";
    // this.tableCommandeAchat.prixVenteDetail = "";
    // this.tableCommandeAchat.prixVenteGros = "";
    // this.showButon = false;
  }
}
