import { Component, OnInit } from '@angular/core';
import { Personnel } from '../model/personnel';
import { DecimalPipe } from '@angular/common';
import { DataService } from '../data.service';
import { Salaire } from '../model/salaire';
import * as XLSX from 'xlsx';
@Component({
	selector: 'app-salaire',
	templateUrl: 'salaire.component.html',
	styleUrls: [ 'salaire.component.scss' ],
	providers: [ DecimalPipe ]
})
export class SalaireComponent implements OnInit {
	Personnel: Personnel = {};
	Personnels: Personnel[];
	Salaire: Salaire = {};
	Salaires: Salaire[];
	dateDebut: any;
	dateFin: any;
	tableSalaire: Salaire[];
	selectedPersonnel: Personnel;
	selectedSalaire: Salaire;
	idPersonnel: number;
	total: number;
	totalAvance: number;
	displayDialog: boolean;
	constructor(private data: DataService, private _decimalPipe: DecimalPipe) {}

	ngOnInit() {
		this.personnel();
		this.salaire();
		var date = new Date(),
			y = date.getFullYear(),
			m = date.getMonth();
		var firstDay = new Date(y, m, 1);
		var lastDay = new Date(y, m + 1, 0);
		// alert(firstDay);
		// alert(lastDay);
		var date = new Date();
		this.dateDebut = firstDay.toISOString().split('T')[0];
		this.dateFin = lastDay.toISOString().split('T')[0];
		// alert(lastDay);
	}
	personnel() {
		this.Personnel.action = 'Filter';
		this.data.personnel(this.Personnel).toPromise().then((x: Personnel[]) => (this.Personnels = x));
	}
	salaire() {
		this.Salaire.action = 'Select';
		this.data
			.salaire(this.Salaire)
			.toPromise()
			.then(
				(x: Personnel[]) => (
					(this.Salaires = x),
					(this.tableSalaire = x),
					console.log(x),
					this.filterSalaire(),
					this.calculTotal()
				)
			);
	}
	save() {
		if (this.Salaire.id) {
			// alert("save");
			this.Salaire.action = 'Update';
			this.data.salaire(this.Salaire).toPromise().then((res: { message: string }) => {
				if (res.message == 'Opération de modification réuissie') {
					alert(res.message);
					this.displayDialog = false;
					this.salaire();
				} else {
					alert('Nonnnn');
				}
			});
		} else {
			this.Salaire.action = 'Insert';
			this.Salaire.idPersonnel = this.idPersonnel;
			this.data.salaire(this.Salaire).toPromise().then((res: { message: string }) => {
				if (res.message == 'Opération de modification réuissie') {
					alert(res.message);
					this.displayDialog = false;
					this.salaire();
				} else {
					alert(res.message);
					this.displayDialog = false;
				}
			});
		}
	}
	delete() {
		if (confirm('êtes vous sure?')) {
			this.Salaire.action = 'Delete';
			this.data.salaire(this.Salaire).toPromise().then((res: { message: string }) => {
				if ((res.message = 'Opération de supression réuissie')) {
					alert(res.message);
					this.displayDialog = false;
					// this.salaire();
					this.filterSalaire();
				} else {
					alert('Nonnnn');
				}
			});
		}
	}
	newDonnee: boolean;
	showDialogToAdd() {
		this.newDonnee = true;
		this.Salaire = {};
		this.Salaire.montant = 0;
		this.Salaire.avance = 0;
		this.displayDialog = true;
	}
	onRowSelect(event) {
		console.log(event);
		this.newDonnee = false;
		this.Salaire = this.cloneDonnee(event.data);
		this.displayDialog = true;
	}
	cloneDonnee(c: Salaire): Salaire {
		let Salaire = {};
		for (let prop in c) {
			Salaire[prop] = c[prop];
		}
		return Salaire;
	}
	filter(data, colone) {
		console.log(data + colone);
		let recherche = this.Salaires.filter(
			(x) => x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1
		);
		this.tableSalaire = recherche;
		this.total = this.tableSalaire.map((t) => t.montant * 1).reduce((a, value) => a + value, 0);
		this.totalAvance = this.tableSalaire.map((t) => t.avance * 1).reduce((a, value) => a + value, 0);
	}
	transformDecimal(num) {
		return this._decimalPipe.transform(num, '1.2-2');
	}
	calculTotal() {
		if (this.tableSalaire) {
			this.total = this.tableSalaire.map((t) => t.montant * 1).reduce((a, value) => a + value, 0);
			this.totalAvance = this.tableSalaire.map((t) => t.avance * 1).reduce((a, value) => a + value, 0);
		}
		return 0;
	}
	filterSalaire() {
		this.Salaire.action = 'Filter';
		this.Salaire.dateDebut = this.dateDebut;
		this.Salaire.dateFin = this.dateFin;
		this.data
			.salaire(this.Salaire)
			.toPromise()
			.then((x: Salaire[]) => ((this.Salaires = x), (this.tableSalaire = x), console.log(x), this.calculTotal()));
	}

	fileName = 'Salaire.xlsx';
	exportexcel(): void {
		/* table id is passed over here */
		let element = document.getElementById('excel-table');
		const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

		/* generate workbook and add the worksheet */
		var title = 'Situation du ' + this.dateDebut + ' au ' + this.dateFin;
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Situation');

		/* save to file */
		XLSX.writeFile(wb, this.fileName);
	}
	printSituation(): void {
		var params = [
			'height=' + screen.height,
			'width=' + screen.width,
			'fullscreen=yes' // only works in IE, but here for completeness
		].join(',');
		let printContents, popupWin;
		printContents = document.getElementById('print-section').innerHTML;
		popupWin = window.open('', '_blank', params);
		popupWin.document.open();
		popupWin.document.write(`
			  <html>
				<head>
				  <title>Print tab</title>
				  <style>
				  table {
					// width: 750px;
					border-collapse: collapse;
					margin: 5px auto;
				}				
				/* Zebra striping */
				tr:nth-of-type(odd) {
					// background: #eee;
					
				}				
				th {
					background: #3498db;
					color: white;
					font-weight: bold;
					text-align: center;
				}				
				td,
				th {
					padding: 5px;
					// border: 1px solid #ccc;
					text-align: left;
					font-size: 12px;
				}
				  </style>
				</head>
			<body onload="window.print();window.close()" style="text-align:center;">${printContents}</body>
			  </html>`);
		popupWin.document.close();
	}
}
