import { Component, OnInit } from "@angular/core";
import * as XLSX from "xlsx";
import { DataService } from "../../data.service";
import { Achat } from "../../model/achat";
import { Article } from "../../model/article";
import { Fournisseur } from "../../model/fournisseur";
import { DecimalPipe } from "@angular/common";
import { AuthenticationService } from "../../authentication.service";
import * as FileSaver from 'file-saver';
@Component({
  selector: "app-controle-stock",
  templateUrl: "controle-stock.component.html",
  styleUrls: ["controle-stock.component.scss"],
  providers: [DecimalPipe],
})
export class controleStockComponent implements OnInit {
  Achat: Achat = {};
  Achats: Achat[];
  tableAchats: Achat[];
  // Achat.avance=0;
  Article: Article = {};
  Articles: Article[];
  Articless: Article[];
  Fournisseur: Fournisseur = {};
  Fournisseurs: Fournisseur[];
  tableDetailAchat: Achat[];
  tableDetailAchats: Achat[];
  tableDetailAchatsAvoir: Achat[];
  tableDetailAchatAvoir: Achat[];
  tableDetailVente: Achat[];
  tableDetailVentes: Achat[];
  tableDetailVentesAvoir: Achat[];
  tableDetailVenteAvoir: Achat[];
  tableDetails: Achat[];
  tableDetail: Achat[];
  selectedCommandeDetail: Achat;
  totalAchat: number;
  totalAchatDiff: number;
  totalVente: number;
  totalVenteDiff: number;
  totalAchatDepot: number;
  totalAchatDepotAvoir: number;
  totalAchatAvoir: number;
  totalAchatAvoirDiff: number;
  totalVenteDepot: number;
  totalVenteAvoir: number;
  totalVenteDepotAvoir: number;
  totalVenteAvoirDiff: number;
  totalDiff: number;
  totalVenteDetail: number;
  totalVenteDiffDetail: number;
  totalAchatDetail: number;
  totalAchatDiffDetail: number;
  totalAchatDepotDetail: number;
  totalAchatDepotAvoirDetail: number;
  totalVenteDepotDetail: number;
  totalVenteDepotAvoirDetail: number;
  totalDiffDetail: number;
  totalBenefice: number;
  dateSituationDebut: any;
  dateSituationFin: any;
  nom:string;
  nomAr:string;
  designation:string;
  displayDialog: boolean;
  radio:any;
  radioAchat:any;
  radioVente:any;
  radioVenteAvoir:any;

  constructor(
    private data: DataService,
    private _decimalPipe: DecimalPipe,
    public AuthenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.article();
    // this.achatDetail();
    // this.dateSituationDebut = new Date().toISOString().split('T')[0];
    // this.dateSituationFin = new Date().toISOString().split('T')[0];
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);
    this.dateSituationDebut = firstDay.toISOString().split("T")[0];
    this.dateSituationFin = lastDay.toISOString().split("T")[0];
    // this.filterCommande();
    // alert(
    // 	'firstDay : ' + firstDay.toISOString().split('T')[0] + 'lastDay : ' + lastDay.toISOString().split('T')[0]
    // );
  }
  article() {
    this.Article.action = "Select";
    this.Article.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .article(this.Article)
      .toPromise()
      .then((x: Article[]) => (this.Articles = x, this.Articless = x,this.achatDetail()));
  }
  achatDetail() {
    this.Achat.action = "SelectAchatDiff";
    this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .stock(this.Achat)
      .toPromise()
      .then(
        (x: Achat[]) => (
          this.tableDetailAchats = x,
          // console.log(x),
          this.achat()
        )
      );
  }
  achat() {
    this.Achat.action = "SelectAchat";
    this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .stock(this.Achat)
      .toPromise()
      .then(
        (x: Achat[]) => (
          this.tableDetailAchat = x,
          // console.log(x),
          this.achatDetailAvoir()
        )
      );
  }
  achatDetailAvoir() {
    this.Achat.action = "SelectAchatAvoirDiff";
    this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .stock(this.Achat)
      .toPromise()
      .then(
        (x: Achat[]) => (
          this.tableDetailAchatsAvoir = x,
          // console.log(x),
          this.achatAvoir()
        )
      );
  }
  achatAvoir() {
    this.Achat.action = "SelectAchatAvoir";
    this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .stock(this.Achat)
      .toPromise()
      .then(
        (x: Achat[]) => (
          this.tableDetailAchatAvoir = x,
          console.log(x),
          this.venteDetail()
        )
      );
  }
  venteDetail() {
    this.Achat.action = "SelectVenteDiff";
    this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .stock(this.Achat)
      .toPromise()
      .then(
        (x: Achat[]) => (
          this.tableDetailVentes = x,
          // console.log(x),
          this.vente()
          // ,this.filterCommande()
        )
      );
  }
  vente() {
    this.Achat.action = "SelectVente";
    this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .stock(this.Achat)
      .toPromise()
      .then(
        (x: Achat[]) => (
          this.tableDetailVente = x,
          // console.log(x),
          this.venteDetailAvoir()
        )
      );
  }
  venteDetailAvoir() {
    this.Achat.action = "SelectVenteAvoirDiff";
    this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .stock(this.Achat)
      .toPromise()
      .then(
        (x: Achat[]) => (
          this.tableDetailVentesAvoir = x,
          console.log(x),
          this.venteAvoir()
        )
      );
  }
  venteAvoir() {
    this.Achat.action = "SelectVenteAvoir";
    this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .stock(this.Achat)
      .toPromise()
      .then(
        (x: Achat[]) => (
          this.tableDetailVenteAvoir = x,
          console.log(x),
          this.reconsituer()
        )
      );
  }
  reconsituer(){
      this.Articles.forEach((row ,index )=>{

        // AchatDepot        
        let tableDetailAchats = this.tableDetailAchats.find(item=>item.idArticle==row.id);
        if (tableDetailAchats===undefined){
          this.Articless[index].AchatDepot = 0; 
        }else{
            this.Articless[index].AchatDepot = tableDetailAchats.AchatDepot;
            this.Articless[index].idDepot = tableDetailAchats.idDepot;
        }

        // tableDetailAchat        
        let tableDetailAchat = this.tableDetailAchat.find(item=>item.idArticle==row.id);
        if (tableDetailAchat===undefined){
          this.Articless[index].Achat = 0; 
            this.Articless[index].AchatDiff = this.Articless[index].AchatDepot;
        }else{
            this.Articless[index].Achat = tableDetailAchat.Achat;            
            this.Articless[index].AchatDiff = tableDetailAchat.Achat-this.Articless[index].AchatDepot;
            this.Articless[index].idDepot = tableDetailAchat.idDepot;
        }

        // tableDepotAchatsAvoir        
        let tableDetailAchatsAvoir = this.tableDetailAchatsAvoir.find(item=>item.idArticle==row.id);
        if (tableDetailAchatsAvoir===undefined){
          this.Articless[index].AchatDepotAvoir = 0; 
        }else{
            this.Articless[index].AchatDepotAvoir = tableDetailAchatsAvoir.AchatDepotAvoir;
            this.Articless[index].idDepot = tableDetailAchatsAvoir.idDepot;
        }
        // tableDetailAchatAvoir       
        let tableDetailAchatAvoir = this.tableDetailAchatAvoir.find(item=>item.idArticle==row.id);
        if (tableDetailAchatAvoir===undefined){
          this.Articless[index].AchatAvoir = 0; 
            this.Articless[index].AchatAvoirDiff = this.Articless[index].AchatDepotAvoir;
        }else{
            this.Articless[index].AchatAvoir = tableDetailAchatAvoir.AchatAvoir;            
            this.Articless[index].AchatAvoirDiff = tableDetailAchatAvoir.AchatAvoir-this.Articless[index].AchatDepotAvoir;
            this.Articless[index].idDepot = tableDetailAchatAvoir.idDepot;
        }
        // tableDetailVentes        
        let tableDetailVentes = this.tableDetailVentes.find(item=>item.idArticle==row.id);
        // console.log(tableDetailVentes);
        if (tableDetailVentes===undefined){
          this.Articless[index].VenteDepot = 0; 
        }else{
            this.Articless[index].VenteDepot = tableDetailVentes.VenteDepot;
            this.Articless[index].idDepot = tableDetailVentes.idDepot;
        }

        // tableDetailVente        
        let tableDetailVente = this.tableDetailVente.find(item=>item.idArticle==row.id);
        // console.log(tableDetailVentes);
        if (tableDetailVente===undefined){
          this.Articless[index].Vente = 0; 
          this.Articless[index].VenteDiff = this.Articless[index].VenteDepot;
        }else{
            this.Articless[index].Vente = tableDetailVente.Vente;
            this.Articless[index].VenteDiff = tableDetailVente.Vente-this.Articless[index].VenteDepot;
            this.Articless[index].idDepot = tableDetailVente.idDepot;
        }

        // tableDetailVentesAvoirDepot        
        let tableDetailVentesAvoir = this.tableDetailVentesAvoir.find(item=>item.idArticle==row.id);
        if (tableDetailVentesAvoir===undefined){
          this.Articless[index].VenteDepotAvoir = 0; 
        }else{
            this.Articless[index].VenteDepotAvoir = tableDetailVentesAvoir.VenteDepotAvoir;
            this.Articless[index].idDepot = tableDetailVentesAvoir.idDepot;
        }

        // tableDetailVentesAvoir        
        let tableDetailVenteAvoir = this.tableDetailVenteAvoir.find(item=>item.idArticle==row.id);
        if (tableDetailVenteAvoir===undefined){
          this.Articless[index].VenteAvoir = 0; 
          this.Articless[index].VenteAvoirDiff = this.Articless[index].VenteDepotAvoir;
        }else{
            // console.log(this.Articless[index]);
            // console.log(tableDetailVenteAvoir);
            this.Articless[index].VenteAvoir = tableDetailVenteAvoir.VenteAvoir;
            this.Articless[index].VenteAvoirDiff = tableDetailVenteAvoir.VenteAvoir-this.Articless[index].VenteDepotAvoir;
            this.Articless[index].idDepot = tableDetailVenteAvoir.idDepot;
        }
      });
      this.onFilter(this.Articless);
  }
  onFilter(data){
    // console.log(data);
    this.totalAchatDepot=data.map((t) => (t.AchatDepot)).reduce((a, value) => a + value, 0);
    this.totalAchat=data.map((t) => (t.Achat)).reduce((a, value) => a + value, 0);
    this.totalAchatDiff=data.map((t) => (t.AchatDiff)).reduce((a, value) => a + value, 0);
    this.totalAchatDepotAvoir=data.map((t) => (t.AchatDepotAvoir)).reduce((a, value) => a + value, 0);
    this.totalAchatAvoir=data.map((t) => (t.AchatAvoir)).reduce((a, value) => a + value, 0);
    this.totalAchatAvoirDiff=data.map((t) => (t.AchatAvoirDiff)).reduce((a, value) => a + value, 0);
    this.totalVenteDepot=data.map((t) => (t.VenteDepot)).reduce((a, value) => a + value, 0);
    this.totalVente=data.map((t) => (t.Vente)).reduce((a, value) => a + value, 0);
    this.totalVenteDiff=data.map((t) => (t.VenteDiff)).reduce((a, value) => a + value, 0);
    this.totalVenteAvoir=data.map((t) => (t.VenteAvoir)).reduce((a, value) => a + value, 0);
    this.totalVenteDepotAvoir=data.map((t) => (t.VenteDepotAvoir)).reduce((a, value) => a + value, 0);
    this.totalVenteAvoirDiff=data.map((t) => (t.VenteAvoirDiff)).reduce((a, value) => a + value, 0);
    this.totalDiff=data.map((t) => (t.diff)).reduce((a, value) => a + value, 0);
  }

  onFilterDetail(data){
    // console.log(data);
    this.totalAchatDepotDetail=data.map((t) => (t.AchatDepot)).reduce((a, value) => a + value, 0);
    this.totalAchatDetail=data.map((t) => (t.Achat)).reduce((a, value) => a + value, 0);
    this.totalAchatDiffDetail=data.map((t) => (t.AchatDiff)).reduce((a, value) => a + value, 0);
    this.totalAchatDepotAvoirDetail=data.map((t) => (t.AchatDepotAvoir)).reduce((a, value) => a + value, 0);
    this.totalVenteDepotDetail=data.map((t) => (t.VenteDepot)).reduce((a, value) => a + value, 0);
    this.totalVenteDetail=data.map((t) => (t.Vente)).reduce((a, value) => a + value, 0);
    this.totalVenteDiffDetail=this.totalVenteDetail-this.totalVenteDepotDetail;
    this.totalVenteDepotAvoirDetail=data.map((t) => (t.VenteDepotAvoir)).reduce((a, value) => a + value, 0);
    this.totalDiffDetail=data.map((t) => (t.diff)).reduce((a, value) => a + value, 0);
  }

  transformDecimal(num) {
    return this._decimalPipe.transform(num, "1.2-2");
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.Articless);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "Articles");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
  onRowSelect(event) {   
    console.log(this.radio) ;
      this.displayDialog = true;
    if(this.radio=="Achat"){    
      this.Achat.action = "SelectDiffAchat";
    }else if(this.radio=="AchatAvoir"){    
      this.Achat.action = "SelectDiffAchatAvoir";
    }else if(this.radio=="Vente") {
      this.Achat.action = "SelectDiffVente";
    }else if(this.radio=="VenteAvoir") {
      this.Achat.action = "SelectDiffVenteAvoir";
    }
    this.Achat.idArticle  = event.data.idArticle;
    this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
    console.log(this.Achat);
    this.data
      .stock(this.Achat)
      .toPromise()
      .then(
        (x: Achat[]) => (
          this.tableDetails = x,
          this.onFilterDetail(this.tableDetails)
        )
    );
  }
}
