import { Component, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { DataService } from '../../data.service';
import * as XLSX from 'xlsx';
import { Vente } from '../../model/vente';
import { Commande } from '../../model/commande';
import { Article } from '../../model/article';
import { Personnel } from '../../model/personnel';
import { AuthenticationService } from '../../authentication.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-modifier-facture',
	templateUrl: 'modifier-facture.component.html',
	styleUrls: [ 'modifier-facture.component.scss' ],
	providers: [ DecimalPipe ]
})
export class ModifierFactureComponent implements OnInit {
	rowGroupMetadata: any;
	Article: Article = {};
	Articles: Article[];
	Personnel: Personnel = {};
	Personnels: Personnel[];
	dateSituationn = Date.now();
	dateSituation: Date;
	total: number;
	// montant: number;
	totalFacture: number;
	selectedVente: Vente[];
	myUrl: string = `${environment.urlApi}`;
	activite: string = 'Service';
	Vente: Vente = {};
	Ventes: Vente[];
	VentesDetail: Vente[];
	VenteDetail: Commande = {};
	totalDetailCommande: number;
	totalDetailServie: number;
	Commande: Commande = {};
	Commandes: Commande[];
	commandeDetail: string;
	tableVentes: Vente[];
	tableArticle: Vente = {};
	tableCommandes: Commande[];
	selectedVenteDetail: Vente[];
	tableArticles: Vente[];
	displayDialog: boolean;
	selectedOption: string;
	selectedType: number;
	dateSituationDebut: any;
	dateSituationFin: any;
	index: number;
	showButon: boolean;
	constructor(
		private data: DataService,
		public AuthenticationService: AuthenticationService,
		private _decimalPipe: DecimalPipe
	) {}

	ngOnInit() {
		this.vente();
		this.venteDetail();
		this.article();
		this.getPersonnelsActif();
		this.dateSituationDebut = new Date().toISOString().split('T')[0];
		this.dateSituationFin = new Date().toISOString().split('T')[0];
		this.filterCommande();
	}
	getPersonnelsActif() {
		this.data.getPersonnelsActif().toPromise().then((x: Personnel[]) => (this.Personnels = x));
	}
	article() {
		this.Article.action = 'Select';
		this.data.article(this.Article).toPromise().then((x: Article[]) => (this.Articles = x));
	}
	vente() {
		this.Vente.action = 'Select';
		this.data
			.vente(this.Vente)
			.toPromise()
			.then((x: Vente[]) => ((this.Ventes = x), (this.tableVentes = x), this.calculTotal()));
	}
	venteDetail() {
		this.Vente.action = 'SelectDetail';
		this.data
			.vente(this.Vente)
			.toPromise()
			.then((x: Vente[]) => ((this.VentesDetail = x), (this.tableCommandes = x)));
	}
	filter(data, colone) {
		// console.log(data + colone);
		let recherche = this.Ventes.filter((x) => x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1);
		this.tableVentes = recherche;
		this.calculTotal();
	}

	calculTotal() {
		if (this.tableCommandes) {
			this.totalFacture = this.tableVentes.map((t) => t.montant * 1).reduce((a, value) => a + value, 0);
		}
		return 0;
	}
	calculCommandeDetail() {
		if (this.tableCommandes) {
			this.totalDetailCommande = 0;
			this.totalDetailCommande = this.tableCommandes
				.map((t) => t.prixVente * t.quantite)
				.reduce((a, value) => a + value, 0);
			this.totalDetailServie = 0;
			this.totalDetailServie = this.tableCommandes
				.map((t) => t.prixService * t.quantite)
				.reduce((a, value) => a + value, 0);
			this.tableCommandes.forEach((obj) => {
				if (obj.prixService < 1) {
					this.totalDetailServie +=
						obj.prixService * obj.quantite * obj.prixVente - obj.prixService * obj.quantite;
				}
			});
			this.Vente.montant = this.totalDetailCommande;
		}
		return 0;
	}
	// commande() {
	// 	this.Vente.action = 'Commande';
	// 	this.data.vente(this.Vente).toPromise().then((x: Vente[]) => ((this.Commandes = x), console.log('55555' + x)));
	// }

	filterCommande() {
		this.Vente.action = 'FilterGroup';
		this.Vente.dateCommandeDebut = this.dateSituationDebut;
		this.Vente.dateCommandeFin = this.dateSituationFin;
		// console.log(this.Vente);
		this.data
			.vente(this.Vente)
			.toPromise()
			.then((x: Vente[]) => ((this.Ventes = x), (this.tableVentes = x), this.calculTotal()));
	}
	transformDecimal(num) {
		return this._decimalPipe.transform(num, '1.2-2');
	}
	recherhcePrixVente() {
		// alert();
		let recherche = this.Articles.find((x) => x.id === this.VenteDetail.idArticle);
		// console.log(recherche);
		if (recherche != undefined) {
			// alert(recherche.prix);
			this.VenteDetail.prixVente = recherche.prix;
			this.VenteDetail.designation = recherche.designation;
			this.VenteDetail.prixService = recherche.prixService;
		} else {
			return '0';
		}
	}
	recherhcePersonnel() {
		// alert();
		let recherche = this.Personnels.find((x) => x.id === this.VenteDetail.idPersonnel);
		// console.log(recherche);
		if (recherche != undefined) {
			// alert(recherche.prix);
			this.VenteDetail.nom = recherche.nom;
		} else {
			return '0';
		}
	}

	addArticle(data) {
		// console.log(data);
		this.tableCommandes.push(data);

		var index = this.tableCommandes.findIndex((x) => x.idArticle === data.idArticle);
		this.tableCommandes[index].idArticle = this.VenteDetail.idArticle;
		this.tableCommandes[index].designation = this.VenteDetail.designation;
		this.tableCommandes[index].nom = this.VenteDetail.nom;
		this.tableCommandes[index].prixService = this.VenteDetail.prixService;
		this.tableCommandes[index].prixVente = this.VenteDetail.prixVente;
		// console.log(this.tableCommandes);
		this.VenteDetail = {};
		this.showButon = true;
		// this.tableCommandeAchat.idPersonnel = this.idPersonnel;
		// this.tableCommandeAchat.dateCommande = new Date().toISOString().split('T')[0];
		// this.showButon = false;
		this.calculCommandeDetail();
		this.prepareCommandeDetail();
		// var index = this.tableCommandeAchats.findIndex((x) => x.index == index);
	}
	updateArticle(data) {
		this.tableCommandes[this.index].idArticle = data.idArticle;
		this.tableCommandes[this.index].designation = data.designation;
		this.tableCommandes[this.index].quantite = data.quantite;
		this.tableCommandes[this.index].prixVente = data.prixVente;
		this.tableCommandes[this.index].idPersonnel = data.idPersonnel;
		this.tableCommandes[this.index].nom = data.nom;
		// this.idFournisseur = this.tableCommandeAchat.idFournisseur;
		this.VenteDetail = {};
		// this.tableCommandeAchat.idFournisseur = this.idFournisseur;
		// this.tableCommandeAchat.dateCommande = new Date().toISOString().split('T')[0];
		this.showButon = true;
		// this.totalQantite();
		this.calculCommandeDetail();
		this.prepareCommandeDetail();
	}
	deleteCommande(idCommandeDetail) {
		// alert(this.idType + ' / ' + idType);
		var index = this.tableCommandes.findIndex((x) => x.id == idCommandeDetail);
		this.tableCommandes.splice(index, 1);
		this.VenteDetail = {};
		this.vente();
		this.calculCommandeDetail();
		this.prepareCommandeDetail();
		this.showButon = true;
		// console.log(this.vente);
		// var index = this.vente.findIndex((x) => x.idArticle == idArticle);
	}
	prepareCommandeDetail() {
		this.commandeDetail = '';
		for (let data of this.tableCommandes) {
			this.commandeDetail +=
				"('idCommande','" +
				data.idArticle +
				"','" +
				data.idPersonnel +
				"','" +
				data.quantite +
				"','" +
				data.prixVente +
				"'),";
		}
		this.commandeDetail += '$';
		this.commandeDetail = this.commandeDetail.replace(',$', ';');
	}
	save() {
		this.Vente.action = 'RInsert';
		this.Vente.montant = this.totalDetailCommande;
		// alert('this.commandeDetail :' + this.commandeDetail);
		this.Vente.commandeDetail = this.commandeDetail;
		// console.log(this.Vente);
		this.data.vente(this.Vente).toPromise().then((res: { message: string }) => {
			if (res.message == 'Opération de modification réuissie') {
				this.vente();
				this.venteDetail();
				alert(res.message);
				this.displayDialog = false;
			} else {
				alert(res.message);
			}
		});
	}
	delete() {
		if (confirm('êtes vous sure?')) {
			this.Vente.action = 'Delete';
			this.data.vente(this.Vente).toPromise().then((res: { message: string }) => {
				if ((res.message = 'Opération de supression réuissie')) {
					alert(res.message);
					this.displayDialog = false;
					this.vente();
				} else {
					alert('Nonnnn');
				}
			});
		}
	}
	newDonnee: boolean;
	onRowSelect(event) {
		// alert(event.data);
		// console.log(event);
		this.showButon = true;
		this.newDonnee = false;
		this.Vente = this.cloneDonnee(event.data);
		this.displayDialog = true;
		let recherche = this.VentesDetail.filter((x) => x.idCommande == event.data.idCommande);
		if (recherche != undefined) {
			this.tableCommandes = recherche;
			this.calculCommandeDetail();
			this.prepareCommandeDetail();
			// return recherche;
		} else {
			// return 'vide';
		}
	}
	cloneDonnee(c: Vente): Vente {
		let Vente = {};
		for (let prop in c) {
			Vente[prop] = c[prop];
		}
		return Vente;
	}
	onRowSelectVenteDetail(event) {
		this.VenteDetail.idArticle = event.data.idArticle;
		this.VenteDetail.designation = event.data.designation;
		this.VenteDetail.idPersonnel = event.data.idPersonnel;
		this.VenteDetail.nom = event.data.nom;
		this.showButon = false;
		// alert(event.data.idArticle);
		this.index = this.tableCommandes.findIndex((x) => x.idArticle == event.data.idArticle);
		this.VenteDetail = this.cloneDonneeDetail(event.data);
	}
	cloneDonneeDetail(c: Vente): Vente {
		let VenteDetail = {};
		for (let prop in c) {
			VenteDetail[prop] = c[prop];
		}
		return VenteDetail;
	}
	close() {
		this.VenteDetail = {};
		this.displayDialog = false;
	}
	fileName = 'ExcelSheet.xlsx';

	exportexcel(): void {
		/* table id is passed over here */
		let element = document.getElementById('excel-table');
		const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

		/* generate workbook and add the worksheet */
		var title = 'Situation du ' + this.dateSituationDebut + ' au ' + this.dateSituationFin;
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'du ' + this.dateSituationDebut + ' au ' + this.dateSituationFin);

		/* save to file */
		XLSX.writeFile(wb, this.fileName);
	}
	printSituation(): void {
		var params = [
			'height=' + screen.height,
			'width=' + screen.width,
			'fullscreen=yes' // only works in IE, but here for completeness
		].join(',');
		let printContents, popupWin;
		printContents = document.getElementById('print-section').innerHTML;
		popupWin = window.open('', '_blank', params);
		popupWin.document.open();
		popupWin.document.write(`
			  <html>
				<head>
				  <title>Print tab</title>
				  <style>
				  table {
					// width: 750px;
					border-collapse: collapse;
					margin: 5px auto;
				}				
				/* Zebra striping */
				tr:nth-of-type(odd) {
					// background: #eee;
					
				}				
				th {
					background: #3498db;
					color: white;
					font-weight: bold;
					text-align: center;
				}				
				td,
				th {
					padding: 5px;
					// border: 1px solid #ccc;
					text-align: left;
					font-size: 12px;
				}
				  </style>
				</head>
			<body onload="window.print();window.close()" style="text-align:center;">${printContents}</body>
			  </html>`);
		popupWin.document.close();
	}
}
