import { Component, OnInit } from "@angular/core";
import { DataService } from "../../data.service";
import { Personnel } from "../../model/personnel";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-personnel",
  templateUrl: "personnel.component.html",
  styleUrls: ["personnel.component.scss"],
})
export class PersonnelComponent implements OnInit {
  actif: boolean;
  activite: string;
  // Personnel.actif:boolean;
  Personnel: Personnel = {};
  Personnels: Personnel[];
  selectedPersonnel: Personnel;
  displayDialog: boolean = false;
  myUrl: string = `${environment.urlApi}`;
  imageUrl: string = "./assets/images/default.png";
  fileToUpload: File = null;
  selectedFile = null;
  constructor(private data: DataService) {}

  ngOnInit() {
    this.personnel();
  }
  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    };
    reader.readAsDataURL(this.fileToUpload);
  }

  onUpload() {
    this.Personnel.chemin = this.myUrl + "/API/API/";
    this.data
      .posteFile(
        this.Personnel.id,
        this.Personnel.nom,
        this.Personnel.chemin,
        this.fileToUpload
      )
      .subscribe((data) => {
        // alert(data);
        // console.log(data);
        this.personnel();
        this.displayDialog = false;
      });
  }
  personnel() {
    this.Personnel.action = "Select";
    this.data
      .personnel(this.Personnel)
      .toPromise()
      .then((x: Personnel[]) => (this.Personnels = x));
  }
  save() {
    if (this.Personnel.id) {
      // this.Personnel.activite = this.activite;
      this.Personnel.action = "Update";
      this.data
        .personnel(this.Personnel)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            alert(res.message);
            this.displayDialog = false;
            this.personnel();
          } else {
            // console.log(res);
            alert(res.message);
            alert("Nonnnn");
          }
        });
    } else {
      this.Personnel.action = "Insert";
      this.data
        .personnel(this.Personnel)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            alert(res.message);
            this.personnel();
            this.displayDialog = false;
          } else {
            alert(res.message);
            this.displayDialog = false;
          }
        });
    }
  }
  delete() {
    if (confirm("êtes vous sure?")) {
      this.Personnel.action = "Delete";
      this.data
        .personnel(this.Personnel)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            alert(res.message);
            this.displayDialog = false;
            this.personnel();
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  newDonnee: boolean;
  showDialogToAdd() {
    this.newDonnee = true;
    // this.imageUrl = 'http://User-PC/SPA/SPA Angular/API/API/uploads/default.png';
    this.Personnel = {};
    this.displayDialog = true;
    this.actif = true;
  }
  onRowSelect(event) {
    console.log(event);
    this.newDonnee = false;
    // var leet = 'http://User-PC/SPA/SPA Angular/API/API/uploads/' + event.data.image;
    // this.imageUrl = leet;
    // alert(leet);
    // if (event.data.actif == 1) {
    // 	this.Personnel.actif = true;
    // } else {
    // 	this.Personnel.actif = false;
    // }

    this.Personnel = this.cloneDonnee(event.data);
    this.displayDialog = true;
  }
  cloneDonnee(c: Personnel): Personnel {
    let Personnel = {};
    for (let prop in c) {
      Personnel[prop] = c[prop];
    }
    return Personnel;
  }
}
