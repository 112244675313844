import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataService } from '../../data.service';
import { environment } from '../../../environments/environment';
import { Article } from '../../model/article';
import { Type } from '../../model/type';
import { Vente } from '../../model/vente';
import { Personnel } from '../../model/personnel';
import { Router } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { AuthenticationService } from '../../authentication.service';
import { Achat } from '../../model/achat';
import { Variation } from '../../model/variation';
import {BlockUIModule} from 'primeng/blockui';
import { Client } from '../../model/client';
import {Message,MessageService,ConfirmationService} from 'primeng/api';
@Component({
	selector: 'app-vente',
	templateUrl: 'vente.component.html',
	styleUrls: [ 'vente.component.scss' ],
	providers: [DecimalPipe,MessageService],
	encapsulation: ViewEncapsulation.None
})
export class VenteComponent implements OnInit {
	recherche: string;
	// filterargs = { title: 'hello' };
	// items = [ { title: 'hello world' }, { title: 'hello kitty' }, { title: 'foo bar' } ];
	currentNumber = '0';
	firstOperand = null;
	operator = null;
	waitForSecondNumber = false;
	public getNumber(v: string){
		// console.log(v);
		if(this.waitForSecondNumber)
		{
		  this.currentNumber = v;
		  this.waitForSecondNumber = false;
		   
		}else{
		  this.currentNumber === '0'? this.currentNumber = v: this.currentNumber += v;
		 
		}
		//  this.total = this.currentNumber * this.vente.prixVente;
	  }
	
	  getDecimal(){
		if(!this.currentNumber.includes('.')){
			this.currentNumber += '.'; 
		}
	  }
	
	  private doCalculation(op , secondOp){
		switch (op){
		  case '+':
		  return this.firstOperand += secondOp; 
		  case '-': 
		  return this.firstOperand -= secondOp; 
		  case '*': 
		  return this.firstOperand *= secondOp; 
		  case '/': 
		  return this.firstOperand /= secondOp; 
		  case '=':
		  return secondOp;
		}
	  }
	  public getOperation(op: string){
		console.log(op);
	
		if(this.firstOperand === null){
		  this.firstOperand = Number(this.currentNumber);
	
		}else if(this.operator){
		  const result = this.doCalculation(this.operator , Number(this.currentNumber))
		  this.currentNumber = String(result);
		  this.firstOperand = result;
		}
		this.operator = op;
		this.waitForSecondNumber = true;
	
		console.log(this.firstOperand);
	 
	  }
	
	  public clear(){
		this.currentNumber = '0';
		this.firstOperand = null;
		this.operator = null;
		this.waitForSecondNumber = false;
	  }  
	  public ok(){
		//   alert(this.currentNumber);
		  let nbre = parseInt(this.currentNumber);
		//   alert(nbre);
		  var index = this.vente.findIndex((x) => x.id == this.idArticle);
		if (nbre>0){
			// if (nbre>=100){
			// 	this.vente[index].prixVente = this.prixVente100;
			// }else if(nbre>=10 && nbre<100){
			// 	this.vente[index].prixVente = this.prixVente10;
			// }else if(nbre<10){
			// 	this.vente[index].prixVente = this.prixVente;
			// }
			this.vente[index].prixVente = this.prixVente;
			this.vente[index].quantite = this.currentNumber; 			
			this.currentNumber = '0';
			this.firstOperand = null;
			this.operator = null;
			this.waitForSecondNumber = false;
		  }else if(this.vente[index].quantite==0){
			this.vente.splice(index, 1);
		  }
		this.blockedDocument= false;
		this.prepareCommandeDetail() ;
	  }
	public maxSize: number = 7;
	public directionLinks: boolean = true;
	public autoHide: boolean = false;
	public responsive: boolean = true;
	public labels: any = {
		previousLabel: '',
		nextLabel: '',
		screenReaderPaginationLabel: 'Pagination',
		screenReaderPageLabel: 'page',
		screenReaderCurrentLabel: `You're on page`
	};
	onPageChange(event) {
		// alert(event);
		this.config.currentPage = event;
	}

	items = [];
	pageOfItems: Array<any>;

	myUrl: string = `${environment.urlApi}`;
	activite: string = '';
	commandeDetail: string = '';
	dateSituation = Date.now();
	config: any = {};
	Article: Article = {};
	Articles: Article[];
	Variation: Variation = {};
	Variations: Variation[];
	tableVariations: Variation[];
	Vente: Vente = {};
	Ventes: Vente[];
	tableVentes: Vente[];
	tableDetailVentes: Vente[];
	Client: Client = {};
	Clients: Client[];
	Personnel: Personnel = {};
	Personnels: Personnel[];
	displayDialog: boolean = false;
	displayDialogTable: boolean = false;
	displayDialogModePaiement: boolean = false;
	rechercheArticle: Article[];
	idType: any;
	Type: Type = {};
	Types: Type[];
	vente: any = [];
	tableArticle: Achat = {};
	tableArticles: Achat[];
	prixVente: any;
	prixVente10: any;
	prixVente100: any;
	prix: any;
	total: any;
	quantite: number = 1;
	nom: any;
	prenom: any;
	showMe: boolean = true;
	showGlobal: boolean = true;
	showArticle: boolean = true;
	blockedDocument: boolean = false;
	type: string;
	images: string;
	designation: string;
	modePaiement: string;
	idArticle: number;
	dataTable: string;
	
	constructor(
		public AuthenticationService: AuthenticationService,
		private data: DataService,
		private router: Router,
		private _decimalPipe: DecimalPipe,
		private messageService: MessageService,
		
	) {this.Vente.remise=0;
		this.showGlobal = true;
		this.showMe = false;
		this.showArticle = false;}
	transformDecimal(num) {
		return this._decimalPipe.transform(num, '1.2-2');
	}
	public innerWidth: any;
	public innerHeight: any;
	public innerHeightCard: any;
	ngOnInit() {
		this.getTypes();
		this.getArticles();
		this.client();
		this.ventee();
		this.venteDetail();
		this.article();
		// this.modePaiement = 'Espece';
		this.innerWidth = window.innerWidth;
		this.innerHeight = (window.innerHeight-130) +"px";
		this.innerHeightCard = (window.innerHeight-95) +"px";
		// alert("innerWidth" + this.innerWidth);
		// alert("innerHeight" + this.innerHeight);
		// alert("Screen width is " + screen.width + " Screen Hieth is " + screen.height);
		// alert("Screen width is " + window.innerWidth + " Screen Hieth is " + window.innerHeight);
	}

	ventee() {
		// alert( this.AuthenticationService.currentUserValue.id);
		this.Vente.action = "SelectVenteUser";
		this.Vente.DBase  = this.AuthenticationService.currentUserValue.DBase;
		this.Vente.idUser = this.AuthenticationService.currentUserValue.id;
		this.data
		  .vente(this.Vente)
		  .toPromise()
		  .then(
			(x: Vente[]) => (
			  (this.Ventes = x,this.tableVentes=x)
			)
		  );
	  }
	venteDetail() {
		this.Vente.action = "SelectDetail";
		this.Vente.DBase = this.AuthenticationService.currentUserValue.DBase;
		this.data
		  .vente(this.Vente)
		  .toPromise()
		  .then((x: Vente[]) => (this.tableDetailVentes = x));
	  }
	  article() {
		this.dataTable = "stockDepot";
		this.Article.action = "RecapDepot";
		this.Article.DBase  = this.AuthenticationService.currentUserValue.DBase;
		this.data
		  .donnee(this.dataTable,this.Article)
		  .toPromise()
		  .then((x: Article[]) => (this.Articles = x,console.log(x)));
	  }
	client() {
		this.Client.action = "Select";
		this.Client.DBase = this.AuthenticationService.currentUserValue.DBase;
		this.data
		  .client(this.Client)
		  .toPromise()
		  .then((x: Client[]) => (this.Clients = x));
	  }
	stock() {
		this.tableArticle.action = 'situationStock';
		this.tableArticle.DBase = this.AuthenticationService.currentUserValue.DBase;
		this.data.stock(this.tableArticle).toPromise().then((x: Achat[]) => (this.tableArticles = x));
	}
	variation() {
		this.Variation.action = 'Select';
		this.Variation.DBase = this.AuthenticationService.currentUserValue.DBase;
		this.data
			.variationStock(this.Variation)
			.toPromise()
			.then((x: Variation[]) => ((this.Variations = x), (this.tableVariations = x)));
	}
	rechercheVariation(idArticle) {
		let recherche = this.Variations.filter((x) => x.idArticle == idArticle);
		if (recherche != undefined) {
			return recherche.map((t) => t.quantite * 1).reduce((a, value) => a + value, 0);
		} else {
			return '0';
		}
	}
	getTypes() {
		this.Type.action = 'Select';
		this.Type.DBase = this.AuthenticationService.currentUserValue.DBase;
		this.data.type(this.Type).toPromise().then((x: Type[]) => (this.Types = x));
	}
	getArticles() {
		// this.Article.action = 'Select';
		// this.Article.DBase = this.AuthenticationService.currentUserValue.DBase;
		// this.data.stock(this.Article).toPromise().then((x: Article[]) => (this.Articles = x,console.log(x)));
	}
	getPersonnelsActif() {
		this.data.getPersonnelsActif().toPromise().then((x: Personnel[]) => (this.Personnels = x));
	}
	navigateUrl() {
		this.showMe = true;
		this.showGlobal = false;
		this.showArticle = false;
	}
	ShowGlobal() {
		this.showGlobal = true;
		this.showMe = false;
		this.showArticle = false;
	}
	
	selectArticle(data) {

		let recherhce = this.vente.filter((x) => x.id == data.id);
		// alert(this.rechercheArticle.length)	;
		if (recherhce.length>0){
			alert("l'article selectionné est déja dans la liste ! \n\t"+ data.designation  +" \t\n السلعة المختارة متواجة في الفاتورة")	;
		}else{
			// alert(data.prixVente + ' | ' + data.prixVente10 + ' | ' + data.prixVente100)
			this.vente.push(data);
			this.blockedDocument=true;
			this.prixVente100=data.prixVente100;
			this.prixVente10=data.prixVente10;
			this.prixVente=data.prixVente;
			this.idArticle=data.id;
		}
	}
	addArticle(data, chemin) {
		// console.log(data);
		// console.log('avant :' + this.vente);
		// alert(data.nom + ' | ' + chemin);
		this.displayDialog = false;
		var index = this.vente.findIndex((x) => x.id == this.idArticle);

		if (chemin == 'prix') {
			this.vente[index].prix = data.prix;
		} else if (chemin == 'quantite') {
			this.vente[index].quantite = data.quantite;
		} else {
			this.vente[index].idPersonnel = data.id;
			this.vente[index].nom = data.nom;
			this.vente[index].prenom = data.prenom;
		}
		// console.log(this.vente);
		this.prepareCommandeDetail();
		// this.vente.push({ idArticle: idArticle, designation: designation, idType: idType, type: type });
		// console.log(this.vente);
	}
	filter(data, colone) {
		// if (this.Achats) {
		  console.log(data + colone);
		  let filtered = this.Articles.filter((x) => x[colone].toString().toLocaleLowerCase().
		  includes(data.toLocaleLowerCase())); 
		//   let recherche = this.Articles.filter(
		// 	(x) => x[colone].toLocaleLowerCase().indexOf(
		// 		data.toLocaleLowerCase()) > -1 &&
		// 		x.idType==this.idType
		//   );
		//   this.idType
		  this.rechercheArticle = filtered;
		  console.log(this.rechercheArticle);
		  this.showArticle = true;
		  this.showMe = false;
		  this.showGlobal = false;
		// }
	  }
	selectType(data) {
		console.log(data);
		this.showMe = false;
		this.showGlobal = false;
		this.showArticle = true;
		this.images = data.images;
		this.type = data.type;
		this.idType = data.id;
		// console.log(this.Articles);
		this.rechercheArticle = this.Articles.filter((x) => x.idType == data.id);
		console.log(this.rechercheArticle);
		this.config = {
			itemsPerPage: 16,
			currentPage: 1,
			totalItems: this.rechercheArticle.length
		};
		// console.log(this.rechercheArticle);
	}

	deleteVente(idArticle) {
		// alert(idArticle);
		var index = this.vente.findIndex((x) => x.idArticle == idArticle);
		if (index != undefined) {
			// if (this.idType == idType) {
			// 	this.rechercheArticle.push(this.vente[index]);
			// }
			// this.Articles.push(this.vente[index]);
			this.vente.splice(index, 1);
		}
		this.prepareCommandeDetail();
		// console.log(this.vente);
		// var index = this.vente.findIndex((x) => x.idArticle == idArticle);
	}
	getSize(size) {
		if (size < 25) {
			return '100px';
		} else {
			return '40px';
		}
	}
	switchh() {
		alert('');
	}
	prepareCommandeDetail() {
		this.commandeDetail = "";
		for (let data of this.vente) {
		  this.commandeDetail +=
			"('idCommande','" +
			data.id +
			"','" +
			data.quantite +
			"','" +
			data.prixVente +
			"'),";
		}
		this.commandeDetail += '$';
		this.commandeDetail = this.commandeDetail.replace(',$', ';');
		console.log(this.commandeDetail);
		// this.config = {
		// 	itemsPerPage: 16,
		// 	currentPage: 1,
		// 	totalItems: this.rechercheArticle.length
		// };
		// alert(this.commandeDetail);
	}
	choixModePaiement(mode) {
		this.modePaiement = mode;
		// alert(this.modePaiement);
		this.valider();
	}
	valider(): void {
		var params = [
			'height=' + screen.height,
			'width=' + screen.width,
			'fullscreen=yes' // only works in IE, but here for completeness
		].join(',');
		let printContents, popupWin;
		printContents = document.getElementById('print-section').innerHTML;
		popupWin = window.open('', '_blank', params);
		popupWin.document.open();
		popupWin.document.write(`
			  <html>
				<head>
				  <title>Print tab</title>
				  <style>
				  table {
					// width: 750px;
					border-collapse: collapse;
					margin: 5px auto;
				}				
				/* Zebra striping */
				tr:nth-of-type(odd) {
					// background: #eee;
					
				}				
				th {
					background: #3498db;
					color: white;
					font-weight: bold;
					text-align: center;
				}				
				td,
				th {
					padding: 5px;
					// border: 1px solid #ccc;
					text-align: left;
					font-size: 12px;
				}
				  </style>
				</head>
			<body onload="window.print();window.close()" style="text-align:center;">${printContents}</body>
			  </html>`);
		popupWin.document.close();
		if (this.total > 0) {
			this.displayDialogModePaiement = true;
			this.save();
		}
	}
	openTiroir(): void {
		var params = [
			'height=' + screen.height,
			'width=' + screen.width,
			'fullscreen=yes' // only works in IE, but here for completeness
		].join(',');
		let printContents, popupWin;
		printContents = '.';
		popupWin = window.open('', '_blank', params);
		popupWin.document.open();
		popupWin.document.write(`
			  <html>
				<head>
				  <title>Print tab</title>
				</head>
			<body onload="window.print();window.close()" >${printContents}</body>
			  </html>`);
		popupWin.document.close();
	}
	totalPrice() {
		let total = 0;
		for (let data of this.vente) {
			total += data.prixVente * data.quantite;
		}
		this.total = total;

		return this._decimalPipe.transform(total, '1.2-2');
		// return total.toFixed(2);
	}
	save() {
		// console.log(this.commandeDetail);
		if (!this.Vente.idClient || !this.Vente.nbon){
			alert("Il Manque quelque champs !")
		}else{
			this.blockedDocument=true;
			if (this.Vente.id) {
			// alert('update');
			this.Vente.action = "Update";
			this.Vente.DBase = this.AuthenticationService.currentUserValue.DBase;
			this.Vente.idUser = this.AuthenticationService.currentUserValue.id;
			this.Vente.commandeDetail = this.commandeDetail;
			this.Vente.id = this.Vente.id;
			this.Vente.remise = this.Vente.remise;
			this.Vente.idClient = this.Vente.idClient;
			this.Vente.dateCommandee = this.Vente.dateCommandee;
			this.Vente.nbon = this.Vente.nbon;
			this.data
				.vente(this.Vente)
				.toPromise()
				.then((res: { message: string }) => {
				if (res.message == "Opération de modification réuissie") {
					// alert(res.message);
					this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
					this.blockedDocument=false;
					this.displayDialog = false;
					this.displayDialogModePaiement = false;
					this.deleterechercheArticlee();
					this.ventee();
					this.venteDetail();
				} else {
					alert(res.message);
				}
				});
			} else {
				this.Vente.action          = "Insert";
				this.Vente.DBase 		   = this.AuthenticationService.currentUserValue.DBase;
				this.Vente.idUser          = this.AuthenticationService.currentUserValue.id;
				this.Vente.remise          = this.Vente.remise;
				this.Vente.commandeDetail  = this.commandeDetail;
				this.Vente.idClient        = this.Vente.idClient;
				this.Vente.dateCommandee   = new Date().toISOString().split("T")[0];
				this.Vente.nbon            = this.Vente.nbon;
				this.data
				.vente(this.Vente)
				.toPromise()
				.then((res: { message: string }) => {
					if (res.message == "Opération de modification réuissie") {
					this.deleterechercheArticlee();
					this.ventee();
					this.venteDetail();
					this.displayDialogModePaiement = false;
					this.showMe = false;
					this.showGlobal = true;
					this.showArticle = false;
					this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
					this.displayDialog = false;
					this.blockedDocument=false;
					} else {
					alert(res.message);
					this.displayDialog = false;
					}
				});
			}
		}
	}
	delete(idArticle) {
		// alert(idArticle);
		if (confirm("êtes vous sure?")) {
			this.Vente.action = "Delete";
			this.Vente.DBase = this.AuthenticationService.currentUserValue.DBase;
			this.Vente.id = idArticle;
			this.data
				.vente(this.Vente)
				.toPromise()
				.then((res: { message: string }) => {
					if ((res.message = "Opération de supression réuissie")) {
						this.showMe = false;
						this.showGlobal = true;
						this.showArticle = false;
						this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
						this.ventee();
						this.venteDetail();
						this.displayDialog = false;
					} else {
						alert("Nonnnn");
					}
				});
	 	}
	}
	deleterechercheArticle() {
		// this.showMe = true;
		// this.showGlobal = false;
		if (confirm('êtes vous sure?')) {
			this.deleterechercheArticlee();
		}
	}
	deleterechercheArticlee() {
		this.vente = [];
		this.getArticles();
		this.showMe = false;
		this.showGlobal = true;
		this.showArticle = false;
		
	}

	changeValueArticle(data) {
		console.log(data);
		this.idArticle = data.id;
		this.blockedDocument = true;
		this.type = data.type;
		this.prixVente = data.prixVente;
		this.quantite = data.quantite;
		this.designation = data.designation.toLocaleUpperCase();
	}
	  onRowSelect(event) {
    // console.log(event);
    // if () {
	  this.showMe = true;
	  this.showGlobal = false;
	  this.showArticle = false;

      this.Vente.id = event.data.idCommande;
      this.Vente.dateCommandee = event.data.dateCommande;

      // let vvv=JSON.stringify(event.data.idClient);
      this.Vente.idClient = JSON.stringify(event.data.idClient);
      this.Vente.remise = event.data.remise;
      this.Vente.nom = event.data.nom;
      this.Vente.nbon = event.data.nbon;
      this.Vente.idUser = event.data.idUser;
      this.Vente.nomComplet = event.data.nomComplet;
      // alert (this.Vente.idClient);
      let recherche = this.tableDetailVentes.filter(
        (x) => x.id == event.data.idCommande
      );
      this.vente = recherche;
      this.prepareCommandeDetail();
    // }
  }
	close() {
		this.displayDialogTable = false;
	}
}
