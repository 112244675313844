import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { DataService } from "../../data.service";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Data } from "../../model/stockDepot";
import { DecimalPipe, UpperCasePipe } from "@angular/common";
import {Message,MessageService} from 'primeng/api';
import { AuthenticationService } from '../../authentication.service';
import { Article } from '../../model/article';
import { Depot } from '../../model/depot';
import { Achat } from '../../model/achat';
import * as FileSaver from 'file-saver';

@Component({
  selector: "app-variation-stock-depot",
  templateUrl: "variation-stock-depot.component.html",
  styleUrls: ["variation-stock-depot.component.scss"],
  providers: [DecimalPipe,MessageService],
})
export class VariationStockDepotComponent implements OnInit {
  myUrl: string = `${environment.urlApi}`;
  Data: Data = {};
  Datas: Data[];
  Depot: Depot = {};
  Variation: Depot = {};
  Depots: Depot[];
  Datass: Data[];
  DataTable: Data[];
  DatasDetail: Data[];
  tableVariations: Data[];
  depots: Data[];
  Observations: Data[];
  Achat: Achat = {};
  Achats: Achat[];
  // depot: Data;
  selectedClient: Data;
  displayDialog: boolean;
  afficher: boolean;
  selectedValue: string;
  designation: string;
  codeBarre: string;
  depott: string;
  reference: string;
  obser: string;
  able:boolean;
  Global:boolean=false;
  depotActivate:boolean=false;
  blockedDocument:boolean=false;
  stockInital:boolean=false;
  Article: Article = {};
  Articles: Article[];
  StockDepot: Data = {};
  StockDepots: Data[];
  filteredArticles: any[];
  stock :number;
  entree :number;
  sortie :number;
  totalEntree :number;
  totalSortie :number;
  StockInitial :number;
  Achatt :number;
  AvoirAchat :number;
  Vente :number;
  AvoirVente :number;
  MouvementPlus :number;
  MouvementMoins :number;
  MouvementStock :number;
  VariationStock :number;
  capital :number;
  totalAvoir :number;
  totalVariation :number;
  idArticle :number;
  DBase :string;
  colspan:number=3;
  constructor(
    private data: DataService,
    private _decimalPipe: DecimalPipe,
    private http: HttpClient,
    public AuthenticationService: AuthenticationService,
    private messageService: MessageService
    ) {}
  ngOnInit() { 
    if(this.AuthenticationService.currentUserValue.groupe=='Administrateur' || this.AuthenticationService.currentUserValue.groupe=='Administrateurs' ){
      this.able=true;
      this.afficher=false;
    }
    if(this.AuthenticationService.currentUserValue.groupe=='Global' ){
      this.Global=true;
    }
    const month = ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'];
    const d = new Date();
    let name = month[d.getMonth()];
    let year = new Date().getFullYear();      
    this.Variation.observation='Inventaire Mois '+name + ' '+year;
    this.Variation.stock=0;
    this.DBase =this.AuthenticationService.currentUserValue.DBase;
    if(this.AuthenticationService.currentUserValue.depot== true){
      this.depotActivate=true;
    }
    if(this.AuthenticationService.currentUserValue.depot== true){
      this.depotActivate=true;
      this.colspan=this.colspan-1;
    }
    // if(this.AuthenticationService.currentUserValue.codebarre== true){
    //   this.codebarre=true;
    //   this.colspan=this.colspan-1;
    // }
    // this.article();
    this.depot();
    this.stockDepot();
    this.stockDepotDetail(); 
    this.observations(); 
  }
  stockDepot() {
    this.Data.action = "SelectRecapDepotAllArticle";
    this.Data.DBase  = this.DBase;
    this.data
      .stockDepot(this.Data)
      .toPromise()
      .then((x: Data[]) => (this.Datas = x,this.Datass=x,this.DataTable=x,this.filterStock()));
  }
  totalCommande() {
    // this.Datass.forEach((row,index)=>{
    //   if(!row.depot) {this.DataTable[index].depot=""};
    //   if(!row.codeBarre) {this.DataTable[index].codeBarre=""};
    //   if(!row.reference) {this.DataTable[index].reference=""};
    // });
    // console.log(this.DataTable);
    // this.totalEntree = 0;
    // this.totalEntree = this.Datass.map((t) => t.entree * 1).reduce((a, value) => a + value, 0);
    // this.totalSortie = 0;
    // this.totalSortie = this.Datass.map((t) => t.sortie * 1).reduce((a, value) => a + value, 0);
    // this.capital = 0;
    // this.capital  =  this.Datass.map((t) => t.capital * 1).reduce((a, value) => a + value, 0);
  }
  transformDecimal(num) {
    return this._decimalPipe.transform(num, "1.2-2");
  }
  stockDepotDetail() {
    this.Data.action = "SelectVariation";
    this.Data.DBase  = this.DBase;
    this.data
      .stockDepot(this.Data)
      .toPromise()
      .then((x: Data[]) => (this.DatasDetail=x));
  }
  depot() {
    this.Data.action = "SelectDepot";
    this.Data.DBase  = this.DBase;
    this.data
      .stockDepot(this.Data)
      .toPromise()
      .then((x: Data[]) => (this.Depots = x));
  }
  observations() {
    this.Data.action = "SelectObservation";
    this.Data.DBase  = this.DBase;
    this.data
      .stockDepot(this.Data)
      .toPromise()
      .then((x: Data[]) => (this.Observations = x));
  }
  // article() {
  //   this.Article.action = "Select";
  //   this.data
  //     .article(this.Article)
  //     .toPromise()
  //     .then((x: Article[]) => (this.Articles = x));
  // }
  findStockDepot(idArticle,idDepot){
    // alert (idArticle +" | " + idDepot);
    if (idArticle && idDepot){
      let recherche = this.Datas.find(
        (x) => x.idArticle == idArticle 
              && x.idDepot == idDepot 
      );
      // console.log(recherche);
      if (recherche != undefined) {
        // alert(recherche.stock);
        this.Variation.idDepot = recherche.idDepot;
        this.Variation.idArticle = recherche.idArticle;
        this.Variation.designation = recherche.designation;
        this.stock = recherche.stock;

        let recherchee = this.DatasDetail.filter(
          (x) => x.idArticle == idArticle 
                && x.idDepot == idDepot 
                &&  x.variation==1
        );
        if (recherchee != undefined) {
          this.tableVariations = recherchee;
          // console.log(recherchee);
        } else {
          // return 'vide';
        }
      } else {
        // alert(this.stock);
        this.stock = 0;
        // return 'vide';
      }   
    }
  }
  filterStock(){
    if(!this.designation){this.designation="";}
    if(!this.depott){this.depott="";}
    if(!this.codeBarre){this.codeBarre="";}
    if(!this.reference){this.reference="";}
    let recherche = this.Datass.filter((x) => 
      x.designation.toString().toLocaleLowerCase().includes(this.designation.toLocaleLowerCase()) &&
      x.depot.toString().toLocaleLowerCase().includes(this.depott.toLocaleLowerCase()) &&
      // x.reference.toString().toLocaleLowerCase().includes(this.reference.toLocaleLowerCase()) 
      // &&
      x.codeBarre.toString().toLocaleLowerCase().includes(this.codeBarre.toLocaleLowerCase())    
    );
    if (recherche){
      this.DataTable=recherche;
      this.StockInitial = 0;
      this.StockInitial = this.DataTable.map((t) => t.StockInitial * 1).reduce((a, value) => a + value, 0);
      this.Achatt = 0;
      this.Achatt = this.DataTable.map((t) => t.Achat * 1).reduce((a, value) => a + value, 0);
      this.AvoirAchat = 0;
      this.AvoirAchat = this.DataTable.map((t) => t.AvoirAchat * 1).reduce((a, value) => a + value, 0);
      this.Vente = 0;
      this.Vente  =  this.DataTable.map((t) => t.Vente * 1).reduce((a, value) => a + value, 0);
      this.AvoirVente = 0;
      this.AvoirVente  =  this.DataTable.map((t) => t.AvoirVente * 1).reduce((a, value) => a + value, 0);
      this.MouvementPlus = 0;
      this.MouvementPlus  =  this.DataTable.map((t) => t.mouvementsPlus * 1).reduce((a, value) => a + value, 0);
      this.MouvementMoins = 0;
      this.MouvementMoins  =  this.DataTable.map((t) => t.mouvementsMoins * 1).reduce((a, value) => a + value, 0);
      this.MouvementStock = 0;
      this.MouvementStock  =  this.DataTable.map((t) => t.mouvements * 1).reduce((a, value) => a + value, 0);
      this.VariationStock = 0;
      this.VariationStock  =  this.DataTable.map((t) => t.variations * 1).reduce((a, value) => a + value, 0);
      this.capital = 0;
      this.capital  =  this.DataTable.map((t) => t.capital * 1).reduce((a, value) => a + value, 0);
    }
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.DataTable);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "DataTable");
    });
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
}
  @ViewChild('inputQte', {static: false}) inputQte:ElementRef;
  setFocusQte(){
    setTimeout(() => this.inputQte.nativeElement.focus());
  }
  verifierStock(observation) {
    let recherchee = this.DatasDetail.filter(
      (x) => x.observation == observation
      );
    if (recherchee != undefined) {
      recherchee.forEach((row)=>{
        var index = this.DataTable.findIndex((x) => x.idArticle == row.idArticle && x.idDepot==row.idDepot);
        this.DataTable[index].verifier="Bon";
      });
    } else {
      // return 'vide';
    }      

  }
  // exportexcel(): void {
  //   let fileName = "Situation des stocks.xlsx";  
  //     /* table id is passed over here */
  //     let element = document.getElementById("excel-table");
  //     const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  
  //     /* generate workbook and add the worksheet */
  //     var title =
  //       "Situation des stock " ;
  //     const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(
  //       wb,
  //       ws,
  //       "stock "
  //     );
  
  //     /* save to file */
  //     XLSX.writeFile(wb, fileName);
  //   }

  addVariation() {
    this.Variation.action = "Insert";  
    this.Variation.DBase  = this.DBase;  
    if(this.stockInital==true){
      this.Variation.observation="Stock Initial";
      this.Variation.quantite = this.Variation.stock ;
    }else{
      // console.log(this.Variation.stock);
      // console.log(this.stock);      
      this.Variation.quantite = this.Variation.stock - this.stock;
    }
    // console.log(this.Variation);
    // alert(this.Variation.dateVariation + " : " + this.Variation.idDepot  + " : " +  this.Variation.quantite)
    if(this.Variation.dateVariation && this.Variation.idDepot && this.Variation.quantite ){
      this.data
        .variationStockDepot(this.Variation)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.Variation = {};
            this.stockDepot();
            this.stockDepotDetail(); 
            this.observations(); 
            this.displayDialog = false;
            const month = ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'];
            const d = new Date();
            let name = month[d.getMonth()];
            let year = new Date().getFullYear();      
            this.Variation.observation='Inventaire Mois '+name + ' '+year;
            this.Variation.stock=0;
          } else {
            alert(res.message);
            this.displayDialog = false;
          }
        });      
    }else{
      this.messageService.add({severity:'info', summary: 'Attention', detail: 'Vous avez oublier un champ !!!'});
    }

  }

  deleteVariation() {
    if (confirm("êtes vous sure?" + this.Variation.id)) {
      this.Variation.action = "Delete";
      this.Variation.DBase  = this.DBase;
      // console.log(this.Variation);
      this.data
        .variationStockDepot(this.Variation)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
            this.displayDialog = false;
            this.Variation = {};
            this.stockDepot();
            this.stockDepotDetail(); 
            this.observations(); 
            const month = ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'];
            const d = new Date();
            let name = month[d.getMonth()];
            let year = new Date().getFullYear();      
            this.Variation.observation='Inventaire Mois '+name + ' '+year;
            this.Variation.stock=0;
          } else {
            alert("Nonnnn");
          }
        });
    }
  }

  deleteArticle(id) {
    if (confirm("êtes vous sure?")) {
      // this.blockedDocument=true;
      this.Article.action   = "Delete";
      this.Article.id       = id;
      this.Article.DBase    = this.AuthenticationService.currentUserValue.DBase;
      this.Article.dateNow  = new Date().toISOString().split("T")[0]; 
      // console.log(this.Article);
      this.data
        .article(this.Article)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
            // this.blockedDocument=false;
            this.displayDialog = false;
            this.stockDepot();
            this.stockDepotDetail(); 
            this.observations();
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  newDonnee: boolean;
  showDialogToAdd() {
    this.newDonnee = true;
    this.Variation = {};
    this.displayDialog = true;
  }
  onRowSelect(event) {
    if (this.able || this.Global || this.AuthenticationService.currentUserValue.groupe=="Admin" || this.AuthenticationService.currentUserValue.groupe=="Stock"){
      // console.log(event.data);
        this.stockInital              = false;
        this.displayDialog            = true;
        this.Variation.dateVariation  = new Date().toISOString().split("T")[0];
        this.Variation.idDepot        = event.data.idDepot;
        this.Variation.idArticle      = event.data.id;
        this.idArticle                = event.data.id;
        this.Variation.designation    = event.data.designation;
        this.Variation.entree         = event.data.entree;
        this.Variation.sortie         = event.data.sortie;
        this.Variation.idDepot        = event.data.idDepot;
        const month = ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'];
        const d = new Date();
        let name = month[d.getMonth()];
        let year = new Date().getFullYear();      
        this.Variation.observation='Inventaire Mois '+name + ' '+year;
        this.Variation.stock          = 0;
        // this.Variation.idDepot        = 1;
        this.stock = event.data.stockReel;
        this.entree = event.data.entree;
        this.sortie = event.data.sortie;
        this.setFocusQte();
        // console.log(event.data);
        // console.log(this.DatasDetail);
        let recherche = this.DatasDetail.filter(
          (x) => x.idArticle == event.data.idArticle 
                && x.idDepot == event.data.idDepot 
                &&  x.variation==1 
                // &&  x.mouvement==0 
                // &&  x.idVariation==null
        );
        if (recherche != undefined) {
          this.tableVariations = recherche;
          // console.log(recherche);
        } else {
          // return 'vide';
        }
        // alert(this.tableVariations);      
    }
 
  }
  onRowSelectVariation(event) {
    // alert(event.data.dateVariation);
    // console.log(event.data);
    this.newDonnee = false;
    this.Variation = this.cloneDonnee(event.data);
  }
  cloneDonnee(c: Data): Data {
    let Variation = {};
    for (let prop in c) {
      Variation[prop] = c[prop];
    }
    return Variation;
  }
  close() {
    this.displayDialog = false;
    this.Variation = {};
  }

}
