import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { AuthenticationService } from '../../authentication.service';
import { RendezVous } from '../../model/rendezVous';

@Component({
	selector: 'app-calendar',
	templateUrl: 'calendar.component.html',
	styleUrls: [ 'calendar.component.scss' ]
})
export class CalendarComponent implements OnInit {
	RendezVous: RendezVous = {};
	RendezVouss: RendezVous[];
	tableRedezVous: RendezVous[];
	RedezVous: RendezVous[];
	displayDialog: boolean;
	constructor(private data: DataService, private AuthenticationService: AuthenticationService) {}

	ngOnInit() {
		this.rendezVous();
	}
	rendezVous() {
		this.RendezVous.action = 'Select';
		this.data
			.rendezVous(this.RendezVous)
			.toPromise()
			.then((x: RendezVous[]) => ((this.RendezVouss = x), (this.tableRedezVous = x)));
	}
	save() {
		if (this.RendezVous.id) {
			// alert("save");
			this.RendezVous.action = 'Update';
			this.data.salaire(this.RendezVous).toPromise().then((res: { message: string }) => {
				if (res.message == 'Opération de modification réuissie') {
					alert(res.message);
					this.displayDialog = false;
					this.rendezVous();
				} else {
					alert('Nonnnn');
				}
			});
		} else {
			this.RendezVous.action = 'Insert';
			this.data.rendezVous(this.RendezVous).toPromise().then((res: { message: string }) => {
				if (res.message == 'Opération de modification réuissie') {
					alert(res.message);
					this.displayDialog = false;
					this.rendezVous();
					this.RendezVous = {};
				} else {
					alert(res.message);
					this.displayDialog = false;
				}
			});
		}
	}
	delete() {
		if (confirm('êtes vous sure?')) {
			this.RendezVous.action = 'Delete';
			this.data.rendezVous(this.RendezVous).toPromise().then((res: { message: string }) => {
				if ((res.message = 'Opération de supression réuissie')) {
					alert(res.message);
					this.displayDialog = false;
					// this.salaire();
					this.rendezVous();
				} else {
					alert('Nonnnn');
				}
			});
		}
	}
	newDonnee: boolean;
	showDialogToAdd() {
		this.newDonnee = true;
		// this.Charge = {};
		this.displayDialog = true;
	}
	onRowSelect(event) {
		// console.log(event);
		this.newDonnee = false;
		this.RendezVous = this.cloneDonnee(event.data);
		this.displayDialog = true;
	}
	cloneDonnee(c: RendezVous): RendezVous {
		let RendezVous = {};
		for (let prop in c) {
			RendezVous[prop] = c[prop];
		}
		return RendezVous;
	}
}
