import { Component, OnInit } from "@angular/core";
import { DataService } from "../../data.service";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Liste } from "../../model/liste";
import {Message,MessageService} from 'primeng/api';
import { Router } from '@angular/router';
import { Vente } from "src/app/model/vente";

// import { ConfirmDialogModule } from "primeng/confirmdialog";
// import { ConfirmationService } from "primeng/api";
@Component({
  selector: "app-listes",
  templateUrl: "listes.component.html",
  styleUrls: ["listes.component.scss"],
  providers: [MessageService],
})
export class ListesComponent implements OnInit {
  myUrl: string = `${environment.urlApi}`;
  imageUrl: string = this.myUrl + "/API/API/uploads/default.png";
  fileToUpload: File = null;
  Liste: Liste = {};
  Listes: Liste[];
  selectedType: Liste;
  displayDialog: boolean;
  selectedOption: string;
  selectedFile = null;
  tableCommandeVente: Vente = {};
  tableCommandeVentes: any = [];
  constructor(
    private data: DataService, private http: HttpClient,
    private messageService: MessageService,
    private router: Router) {}
  ngOnInit() {
    this.liste();
  }

  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    };
    reader.readAsDataURL(this.fileToUpload);
  }

  liste() {
    this.Liste.action = "Select";
    this.data
      .liste(this.Liste)
      .toPromise()
      .then((x: Liste[]) => (this.Listes = x));
  }
  save() {
    if (this.Liste.liste == null) {
      alert("Liste est vide - النوع فارغ");
    } else {
      if (this.Liste.id) {
        this.Liste.action = "Update";
        this.data
          .liste(this.Liste)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
              this.liste();
            } else {
              this.messageService.add({severity:'warn', summary: 'Attention !!', detail: res.message});
              alert("Nonnnn");
            }
          });
      } else {
        this.Liste.action = "Insert";
        console.log(this.Liste);
        this.data
          .liste(this.Liste)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
              this.liste();
            } else {
              this.messageService.add({severity:'warn', summary: 'Attention !!', detail: res.message});
              this.displayDialog = false;
            }
          });
      }
    }
  }
  delete() {
    if (confirm("êtes vous sure?")) {
      this.Liste.action = "Delete";
      this.data
        .liste(this.Liste)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'warn', summary: 'Suppression : ', detail: res.message});
            this.displayDialog = false;
            this.liste();
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  actionLink(lien) {
    this.router.navigate(["/"+lien]);
  }
  close() {
    this.displayDialog = false;
    this.Liste = {};
  }
  newDonnee: boolean;
  showDialogToAdd() {
    this.newDonnee = true;
    this.imageUrl = this.myUrl + "/API/API/uploads/default.png";
    this.Liste = {};
    this.displayDialog = true;
  }
  onRowSelect(event) {
    console.log(event);
    this.newDonnee = false;
    var leet = this.myUrl + "/API/API/uploads/images/" + event.data.images;
    this.imageUrl = leet;
    // alert(leet);
    this.Liste = this.cloneDonnee(event.data);
    this.displayDialog = true;
  }
  cloneDonnee(c: Liste): Liste {
    let Liste = {};
    for (let prop in c) {
      Liste[prop] = c[prop];
    }
    return Liste;
  }
}
