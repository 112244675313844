import { Component, OnInit, ElementRef, ViewChild,  ViewEncapsulation } from "@angular/core";
import { DataService } from "../../data.service";
import { AuthenticationService } from "../../authentication.service";
import { environment } from "../../../environments/environment";
import { Article } from "../../model/article";
import { Vente } from "../../model/vente";
import { DecimalPipe, UpperCasePipe } from "@angular/common";
import * as XLSX from "xlsx";
import { Client } from "../../model/client";
import {Message,MessageService,ConfirmationService} from 'primeng/api';
import { Paiement } from '../../model/paiement';
import { Depot } from "src/app/model/depot";
import { ModePaiement } from "src/app/model/modePaiement";
import { AutoComplete } from "primeng/autocomplete";
import { DataBase } from "src/app/model/dataBase";
// import {CheckboxModule} from 'primeng/checkbox';
@Component({
  selector: "app-deviss",
  templateUrl: "deviss.component.html",
  styleUrls: ["deviss.component.scss"],
  providers: [DecimalPipe,MessageService,ConfirmationService],
})
export class DevissComponent implements OnInit {
  msgs1: Message[];    
  msgs2: Message[];

  idArticle: number;
  Article: Article = {};
  Articles: Article[];
  stock: Article[];
  rechercheArticle: Article = {};
  rechercheArticles: Article[];
  Vente: Vente = {};
  Ventes: Vente[];
  selectedVente: Vente;
  tableVentes: Vente[];
  tableDetailVentes: Vente[];
  selectedCommandeDetail: Vente;
  tableCommandeVente: Vente = {};
  tableCommandeVentes: any = [];
  requetteCommandeVente: Vente = {};
  requetteCommandeVentes: any = [];
  requetteCommandeVentesFacture: any = [];
  Familles: any = [];
  Client      : Client = {};
  Clients     : Client[];
  Depot       : Depot = {};
  Depots      : Depot[];
  Donnees     : DataBase[];
  Donnee      : DataBase = {};   
  displayDialog: boolean;
  displayModePaiement: boolean;
  desablePrint: boolean ;
  showButon: boolean;
  showButonSauvgarder: boolean;
  showButonAvance: boolean = false;
  dataBase: boolean = true;
  blockedDocument: boolean;
  designation: string;  
  colorInput: string;
  designationAutoComplete: string;
  designationAutocomplete: string;
  DBase: string;
  idArticleTest: number;
  totalMontant: number;
  prixAchat: number;  
  quantiteVenteGros: number;
  quantiteVenteDetail: number;
  prixVenteGros: number;
  prixVenteDetail: number;
  prixVente1: number;
  totalMontantTTC: number;
  totalMarge: number;
  totalQte: number;
  total: number;
  remise: number;
  totalAvance:number;
  totalReste:number;
  margeTotal: number;
  idClient: number;
  index: string;
  indexAvance: string;
  commandeDetail: string;
  commandeDetailFacture: string;
  commandePaiement: string;
  commandeDetailDepot: string;
  dateDebut: any;
  dateFin: any;
  dateSituationDebut: string;
  dateSituationFin: string;
  msgs: Message[] = [];
  position: string;
  t:any;
  t2:any;
  t3:any;
  nbre2Lettre: string;
  myUrl: string = `${environment.urlApi}`;
  Paiement: Paiement = {};
  Paiements: Paiement[];
  tableDetailPaiements: Paiement[];
  tableRecapPaiements: Paiement[];
  tableCommandeFactures: Paiement[];
  ModePaiement: ModePaiement = {};
  ModePaiements: ModePaiement[];
  tableAvance: any = {};
  tableAvances: any = [];
  higthCol:string;
  dataTable:string;
  nbre:number;
  able: boolean =false;
  Global: boolean =false;
  AdminAble: boolean =false;
  dropdownArticle: boolean =true;
  filteredArticles: any[];
  filteredCodeBare: any[];
  filteredReference: any[];
  constructor(
    private data: DataService,
    private _decimalPipe: DecimalPipe,
    public AuthenticationService: AuthenticationService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}
  addSingle() {
    this.messageService.add({severity:'success', summary:'Service Message', detail:'Via MessageService'});
}

  ngOnInit() {
    if(this.AuthenticationService.currentUserValue.groupe=='Administrateur' || this.AuthenticationService.currentUserValue.groupe=='Administrateurs' ){
      this.able=true;
    }
    if(this.AuthenticationService.currentUserValue.groupe=='Admin'){
      this.AdminAble=true;
    } 
    if(this.AuthenticationService.currentUserValue.groupe=='Global' ){
      this.Global=true;
    }
    this.t=["","Un","Deux","Trois","Quatre","Cinq","Six","Sept","Huit","Neuf"];
    this.t2=["Dix","Onze","Douze","Treize","Quatorze","Quinze","Seize","Dix-sept","Dix-Huit","Dix-Neuf"];
    this.t3=["","","Vingt","Trente","Quarante","Cinquante","Soixante","Soixante","Quatre-Vingt","Quatre-Vingt"];
    this.commandeDetail="";
    this.msgs1 = [
      {severity:'success', summary:'Success', detail:'Message Content'},
      {severity:'info', summary:'Info', detail:'Message Content'},
      {severity:'warn', summary:'Warning', detail:'Message Content'},
      {severity:'error', summary:'Error', detail:'Message Content'}
     ];
    this.dateSituationDebut = new Date().toISOString().split("T")[0];
    this.dateSituationFin = new Date().toISOString().split("T")[0];
    // this.depot();
    // this.modePaiement();
    if(!this.DBase){
      this.DBase = this.AuthenticationService.currentUserValue.DBase;
    }
    this.article();
    this.getArticles();
    this.client();
    this.vente();
    this.venteDetail();
    this.donnee();
    this.tableCommandeVente.dateCommandee = new Date().toISOString().split("T")[0];
    // this.paiementDetail();
    this.Vente.espece=0;
    this.Vente.traite=0;
    this.Vente.cheque=0;
    this.Vente.refTraite="";
    this.Vente.refVirement="";
    this.Vente.refCheque="";
    this.Vente.dateEcheanceTraite = new Date().toISOString().split("T")[0];
    this.Vente.dateEcheanceCheque = new Date().toISOString().split("T")[0];
  }
  donnee() {
    this.Donnee.action  = "Select";
    this.dataTable      = "database";
    this.Donnee.DBase   = this.DBase;
    this.data
      .donnee(this.dataTable,this.Donnee)
      .toPromise()
      .then((x: DataBase[]) => (this.Donnees = x,this.setActifDb()));
  }
  setActifDb(){
    let rechercheDB =this.Donnees.find((x) => x.actif == true);
    if(rechercheDB){
      this.Vente.DataBase= rechercheDB.data;
    }
  }
  // depot() {
  //   this.Depot.action = "SelectDepot";
  //   this.Depot.DBase  = this.AuthenticationService.currentUserValue.DBase;
  //   this.data
  //     .depot(this.Depot)
  //     .toPromise()
  //     .then((x: Depot[]) => (this.Depots = x));
  // }
  getArticles() {
		this.Article.action = 'RecapDepot';
    this.Article.DBase  = this.DBase;
		this.data.stockDepot(this.Article).toPromise().then((x: Article[]) => (this.stock = x));
	}
  filterArticle(event) {
    let filtered : any[] = [];
    let query = event.query;
    // console.log(this.Articles);
    filtered = this.Articles.filter((x) => x.designation.toString().toLocaleLowerCase().includes(query.toLocaleLowerCase()));   
    this.filteredArticles = filtered;
  }
  filterCodeBare(event) {
    let filtered : any[] = [];
    let query = event.query;
    // console.log(event.query);
    // console.log(this.Articles);
    filtered = this.Articles.filter((x) => x.codeBarre.toString().toLocaleLowerCase().includes(query.toLocaleLowerCase()));   
    this.filteredCodeBare = filtered;
  }
  filterReference(event) {
    let filtered : any[] = [];
    let query = event.query;
    filtered = this.Articles.filter((x) => x.reference.toString().toLocaleLowerCase().includes(query.toLocaleLowerCase()));   
    this.filteredReference = filtered;
  }
  article() {
    this.Article.action = "Select";
    this.Article.DBase  = this.DBase;
    this.data
      .article(this.Article)
      .toPromise()
      .then((x: Article[]) => (this.Articles = x,this.famille()));
  }
  famille(){
    this.ModePaiement.action = "Select";
    this.ModePaiement.DBase  = this.DBase;
    let datatable="type";
    this.data
      .donnee(datatable,this.ModePaiement)
      .toPromise()
      .then((x: ModePaiement[]) => (this.Familles = x, this.generateArticle()));    
  }
  generateArticle(){
    this.Articles.forEach((row,index)=>{
      if(!row.codeBarre) {this.Articles[index].codeBarre=""};
      if(!row.reference) {this.Articles[index].reference=""};
    });
  }
  // modePaiement() {
  //   this.ModePaiement.action = "Select";
  //   this.ModePaiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
  //   this.data
  //     .modePaiement(this.ModePaiement)
  //     .toPromise()
  //     .then((x: ModePaiement[]) => (this.ModePaiements = x));
  // }
  // calculTotalAvance(){
  //   this.totalAvance = 0;    
  //   if(this.tableAvances.length>0){
  //     this.totalAvance = this.tableAvances
  //       .map((t) => t.montant * 1)
  //       .reduce((a, value) => a + value, 0);  
  //   };
  //   this.prepareCommandeDetail();
  // }
  onEnter() {}
  rechercheDesignation(idArticle) {
    // this.rechercheArticle = this.Achats.find((x) => x.idArticle === idArticle);
    // this.tableCommandeVente.designation = this.rechercheArticle.designation;
  }
  findModePaiement(idModePaiement) {
    let rechercheModePaiement = this.ModePaiements.find((x) => x.id == idModePaiement);
    this.tableAvance.modePaiement = rechercheModePaiement.modePaiement;
  }
  setDropdownArticle(){
    // alert('kkk');
    this.dropdownArticle=true;
    if(this.Vente.cloture==false){
      this.setFocusDesignation();
    }
    this.autoCompleteObject.focusInput();
  }

  findPrix(idArticle,colone) {
    // console.log(idArticle);
    //   alert(typeof idArticle);
    if(typeof idArticle =="string" && colone=="codeBarre-"){
      idArticle=this.tableCommandeVente.autocomplete;
      colone="codeBarre";        
    }else{    
      colone="codeBarre-";
    }
    this.setFocusQte();
    if (idArticle!=undefined){

      if(colone=="codeBarre"){
        this.rechercheArticle = this.Articles.find((x) => x.codeBarre == idArticle);
        this.tableCommandeVente.autocomplete = this.rechercheArticle; 
      }else if(colone=="designation"){
        this.rechercheArticle = this.Articles.find((x) => x.designation == idArticle.designation);
        this.tableCommandeVente.autocomplete = idArticle; 
      }else {
        this.rechercheArticle = this.Articles.find((x) => x.id == idArticle.idArticle*1);        
        this.tableCommandeVente.autocomplete = idArticle; 
      } 
      // alert(this.idArticleTest);
      // if (this.idArticleTest!=this.rechercheArticle.idArticle){
      //   this.idArticleTest=this.rechercheArticle.idArticle;
      //   this.setFocusQte();
      // }

      this.dropdownArticle=false;
      this.tableCommandeVente.idArticle         = this.rechercheArticle.id;
      this.tableCommandeVente.designation       = this.rechercheArticle.designation;      
      this.tableCommandeVente.prixAchat         = this.rechercheArticle.prixAchat;
      this.prixAchat                            = this.rechercheArticle.prixAchat;
      this.tableCommandeVente.prixVenteDetail   = this.rechercheArticle.prixVenteDetail;
      this.tableCommandeVente.prixVenteGros     = this.rechercheArticle.prixVenteGros;
      this.tableCommandeVente.prixVente         = this.rechercheArticle.prixVente;
      this.prixVenteGros                        = idArticle.prixVenteGros;
      this.prixVenteDetail                      = idArticle.prixVenteDetail;
      this.prixVente1                           = idArticle.prixVente;
      // console.log(this.stock);
      let rechercheArticle = this.stock.find((x) => x.idArticle == this.tableCommandeVente.idArticle);
      this.tableCommandeVente.stock             = rechercheArticle.stockTotal;      
    }

  }
  findIce(){
    let rechercheIce = this.Clients.find((x) => x.id == this.Vente.idClient);
    // this.tableDepot = rechercheDepot;
    this.Vente.ice = rechercheIce.ice;
    // alert(rechercheIce.ice);
  }
  // findDepot(idDepot) {
  //   let rechercheDepot = this.Depots.find((x) => x.id == idDepot);
  //   // this.tableDepot = rechercheDepot;
  //   this.tableCommandeVente.depot = rechercheDepot.designation;
  //   // this.idDepotVente=this.tableDepot.idDepotVente
  // }

  @ViewChild('inputQte', {static: false}) inputQte:ElementRef;
  setFocusQte(){
    setTimeout(() => this.inputQte.nativeElement.focus());
  }
  @ViewChild('autoCompleteObject') private autoCompleteObject: AutoComplete ;

  @ViewChild('inputDesignation', {static: false}) inputDesignation:ElementRef;
  setFocusDesignation(){
    setTimeout(() => this.inputDesignation.nativeElement.focus());
  }
  client() {
    this.Client.action = "Select";
    this.Client.DBase  = this.DBase;
    this.data
      .client(this.Client)
      .toPromise()
      .then((x: Client[]) => (this.Clients = x));
  }
  vente() {
    this.dataTable    = "devis";
    this.Vente.action = "Select";
    this.Vente.DBase  = this.DBase;
    console.log(this.Vente.DBase);
    this.data
      .donnee(this.dataTable, this.Vente)
      .toPromise()
      .then(
        (x: Vente[]) => (
          this.Ventes = x, 
          this.tableVentes = x,
          // console.log(x),
          this.totalCommande()
        )
      );
  }
  // paiementRecap() {
  //   this.Paiement.action = "RecapByIdCommande";
  //   this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
  //   this.data
  //     .paiementFacture(this.Paiement)
  //     .toPromise()
  //     .then((x: Paiement[]) => (this.tableRecapPaiements = x,this.totalCommande()));
  // }
  totalCommande() {
    if(this.Vente.DataBase == this.AuthenticationService.currentUserValue.DBase){
      this.dataBase = true;
      // this.messageService.add({severity:'succes', summary: 'Parcourir', detail: "Même Data Base"});
    }else{
      this.dataBase = false;
      // this.messageService.add({severity:'warn', summary: 'DataBase', detail: "Data Base Differente"});
    }
    // this.tableVentes.forEach((row,index)=>{
    //   let recherche = this.tableRecapPaiements.find((x) => x.idCommande == row.idCommande);
    //   if(recherche){
    //     this.tableVentes[index].avance = recherche.montant;
    //     this.tableVentes[index].reste = this.tableVentes[index].montant*1.2-recherche.montant;
    //   }else{
    //     this.tableVentes[index].avance = 0;
    //     this.tableVentes[index].reste = this.tableVentes[index].montant*1.2;
    //   }
    // }); 
    // console.log(this.tableVentes);
    this.total = 0;
    this.total = this.tableVentes.map((t) => t.montant * 1).reduce((a, value) => a + value, 0);
    this.totalReste = 0;
    // this.totalReste = this.tableVentes.map((t) => t.reste * 1).reduce((a, value) => a + value, 0);
    this.remise = 0;
    this.remise = this.tableVentes.map((t) => t.remise * 1).reduce((a, value) => a + value, 0);
    this.margeTotal = 0;
    this.margeTotal = this.tableVentes.map((t) => t.marge * 1).reduce((a, value) => a + value, 0);
  }

  // paiementDetail() {
  //   this.Paiement.action = "Select";
  //   this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
  //   this.data
  //     .paiementFacture(this.Paiement)
  //     .toPromise()
  //     .then((x: Paiement[]) => (this.tableDetailPaiements = x));
  // }
  venteDetail() {
    this.dataTable    = "devis";
    this.Vente.action = "SelectDetail";
    this.Vente.DBase  = this.DBase;
    this.data
      .donnee(this.dataTable, this.Vente)
      .toPromise()
      .then((x: Vente[]) =>(
        this.tableDetailVentes = x,
        // console.log(x),
        this.recherhceMarge())
        );
  }

  cloture(idCommande,cloture) {
    // alert(idCommande+" | "+cloture);
    let etat;
    if (cloture==0){
      etat=1;
    }else{
      etat=0;
    }
      this.blockedDocument            = true;
      this.dataTable                  = "devis";
      this.tableCommandeVente.action  = "UpdateCloure";
      this.tableCommandeVente.DBase   = this.Vente.DBase;
      this.tableCommandeVente.etat    = etat;
      this.tableCommandeVente.id      = idCommande;
      this.data
        .donnee(this.dataTable, this.tableCommandeVente)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            // alert(res.message);
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.blockedDocument=false;
            this.displayDialog = false;
            this.vente();
            this.venteDetail();
          } else {
            alert(res.message);
          }
        });
  }
//-------Debut NbreToLettre ----------------------
 
nombreToLettre(n) {
  // n = n.toString();
  // n=n*1.2;
  // vérification de la valeur saisie
  if(!/^\d+[.,]?\d*$/.test(n)){
    this.nbre2Lettre = "L'expression entrée n'est pas un nombre."
  }
 
  // séparation entier + décimales
  n=n.replace(/(^0+)|(\.0+$)/g,"");
  n=n.replace(/([.,]\d{2})\d+/,"$1");
  let n1=n.replace(/[,.]\d*/,"");
  let n2= n1!=n ? n.replace(/\d*[,.]/,"") : false;
  
  // variables de mise en forme
  let ent= !n1 ? "" : this.decint(n1);
  let deci= !n2 ? "" : this.decint(n2);
  if(!n1 && !n2){
    this.nbre2Lettre =  "Zéro Dirham. (Mais, de préférence, entrez une valeur non nulle!)"
  }
  var conj= !n2 || !n1 ? "" : "  et ";
  var euro= !n1 ? "" : !/[23456789]00$/.test(n1) ? " Dirham" : "s Dirham";
  var centi= !n2 ? "" : " Centime";
  var pl=  n1>1 ? "s" : "";
  var pl2= n2>1 ? "s" : "";
 
  // expression complète en toutes lettres
  this.nbre2Lettre = (ent + euro + pl + conj + deci + centi + pl2).replace(/\s+/g," ").replace("Cent s E","CSents E");
}
// traitement des deux parties du nombre;
decint(n){
  switch(n.length){
      case 1 : return this.dix(n);
      case 2 : return this.dix(n);
      case 3 : return this.cent(n.charAt(0)) + " " + this.decint(n.substring(1));
      default: var mil=n.substring(0,n.length-3);
          if(mil.length<4){
              var un= (mil==1) ? "" : this.decint(mil);
              return un + this.mille(mil)+ " " + this.decint(n.substring(mil.length));
          }
          else{    
              var mil2=mil.substring(0,mil.length-3);
              return this.decint(mil2) + this.million(mil2) + " " + this.decint(n.substring(mil2.length));
          }
  }
}
// traitement des nombres entre 0 et 99, pour chaque tranche de 3 chiffres;
dix(n){
 
  if(n<10){
      return this.t[parseInt(n)]
  }
  else if(n>9 && n<20){
      return this.t2[n.charAt(1)]
  }
  else {
      var plus= n.charAt(1)==0 && n.charAt(0)!=7 && n.charAt(0)!=9 ? "" : (n.charAt(1)==1 && n.charAt(0)<8) ? " et " : "-";
      var diz= n.charAt(0)==7 || n.charAt(0)==9 ? this.t2[n.charAt(1)] : this.t[n.charAt(1)];
      var s= n==80 ? "s" : "";

      return this.t3[n.charAt(0)] + s + plus + diz;
  }
}
// traitement des mots "cent", "mille" et "million"
cent(n){
  return n>1 ? this.t[n]+ " Cent" : (n==1) ? " Cent" : "";
  }
   
mille(n){
  return n>=1 ? " Mille" : "";
  }
   
million(n){
  return n>=1 ? " Millions" : " Million";
  }
//-------Fin NbreToLettre ---------------------- 
  transformDecimal(num) {
    return this._decimalPipe.transform(num, "1.2-2");
  }
  recherhceMarge(){

        this.tableVentes.forEach((row,index)=>{
        let recherche = this.tableDetailVentes.filter((x) => x.idCommande == row.idCommande);

        // let updateItem = this.tableVentes.find(item=>item.idCommande==row.idCommande);
        // let index = this.tableVentes.indexOf(updateItem);
        let marge =  recherche.map((t) => t.marge).reduce((a, value) => a + value, 0);
        this.tableVentes[index].marge = marge;
        // let rechercheAvance = this.tableAvances.filter((x) => x.idCommande == row.idCommande);

      }); 
      // console.log(this.tableVentes);
      this.totalCommande();
  }
  // saveCommandeVente() {
  //   this.tableCommandeVente.action = "Insert";
  //   this.tableCommandeVente.DBase  = this.AuthenticationService.currentUserValue.DBase;
  //   this.tableCommandeVente.commandeDetail = this.commandeDetail;
  //   this.data
  //     .facture(this.tableCommandeVente)
  //     .toPromise()
  //     .then((res: { message: string }) => {
  //       if (res.message == "Opération de modification réuissie") {
  //         this.vente();
  //         alert(res.message);
  //       } else {
  //         alert(res.message);
  //       }
  //     });
  // }

  filter(data, colone) {
    let recherche;
    // if (colone=="nbon" ){
    //    recherche = this.Ventes.filter((x) => x.nbon.toString().includes(data));
    // }else 
    if ( colone =="numm"){
      recherche = this.Ventes.filter((x) => x.numm.toString().includes(data));
    }else{
      recherche = this.Ventes.filter(
      (x) =>
        x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1
    ); 
    }
    
    this.tableVentes = recherche;
    this.totalCommande();  
  
  }
  filterCommande(startDate,endDate){
    let recherche = this.Ventes.filter((x) => x.dateCommande >= startDate && x.dateCommande <= endDate );
    this.tableVentes = recherche; 
    this.totalCommande();
  }
  export() {
    this.blockedDocument=true;
    if (this.Vente.id) {
      this.dataTable                                = "devis";
      this.tableCommandeVente.action                = "InsertBon";
      this.tableCommandeVente.DBase                 = this.AuthenticationService.currentUserValue.DBase;
      this.tableCommandeVente.idUser                = this.AuthenticationService.currentUserValue.id;
      this.tableCommandeVente.remise                = this.Vente.remise;
      this.tableCommandeVente.idDevis               = this.Vente.id;
      this.tableCommandeVente.commandeDetail        = this.commandeDetailFacture;
      // this.tableCommandeVente.commandePaiement      = this.commandePaiement;        
      this.tableCommandeVente.commandeDetailDepot   = this.commandeDetailDepot  ;
      this.tableCommandeVente.idClient              = this.Vente.idClient;
      this.tableCommandeVente.idCommercial          = this.Vente.idCommercial;
      this.tableCommandeVente.dateCommandee         = this.Vente.dateCommandee;
      this.tableCommandeVente.bonSortie             = this.Vente.bonSortie;
      this.tableCommandeVente.bonLivraison          = this.Vente.bonLivraison;
      this.tableCommandeVente.bonCommande           = this.Vente.bonCommande;

      console.log(this.tableCommandeVente);
      this.data
        .donnee(this.dataTable, this.tableCommandeVente)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            // alert(res.message);
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.blockedDocument  = false;
            this.displayDialog    = false;
          } else {
            this.messageService.add({severity:'warn', summary: 'Erreur', detail: res.message});
            this.blockedDocument  = false;
            this.displayDialog    = false;
          }
        });
    }  
  }
  
  importer() {
    this.blockedDocument=true;
        this.dataTable                                = "devis";
        this.tableCommandeVente.action                = "Insert";
        this.tableCommandeVente.DBase                 = this.AuthenticationService.currentUserValue.DBase;
        this.tableCommandeVente.idUser                = this.AuthenticationService.currentUserValue.id;
        this.tableCommandeVente.remise                = this.Vente.remise;
        this.tableCommandeVente.commandeDetail        = this.commandeDetail;      
        this.tableCommandeVente.commandeDetailDepot   = this.commandeDetailDepot  ;
        this.tableCommandeVente.idClient              = this.Vente.idClient;
        this.tableCommandeVente.dateCommandee         = new Date().toISOString().split("T")[0];
        // this.tableCommandeVente.nbon            = this.Vente.nbon;
        console.log(this.tableCommandeVente);
        
        this.data
          .donnee(this.dataTable, this.tableCommandeVente)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.vente();
              this.getArticles();
              this.venteDetail();
              // this.paiementDetail();
              this.initialiser();
              this.messageService.add({severity:'success', summary: 'Importation', detail: res.message});
              this.displayDialog = false;
              this.blockedDocument=false;
              this.desablePrint = false;
            } else {
              alert(res.message);
              this.displayDialog = false;
            }
          });
  }

  save() {
    // if (this.Vente.nbon=='0'){
    //   this.Vente.nbon=this.Vente.nbon.toString();
    // }
    this.prepareCommandeDetail();
    if (this.Vente.idClient && this.commandeDetail!='') {
    this.blockedDocument=true;
    if (this.Vente.id) {
      // alert('update');
      this.dataTable = "devis";
      this.tableCommandeVente.action = "Update";
      this.tableCommandeVente.DBase = this.DBase;
      if (this.Vente.idUser){
        this.tableCommandeVente.idUser = this.Vente.idUser;
      }else{
          this.tableCommandeVente.idUser = this.AuthenticationService.currentUserValue.id;
      }
      this.tableCommandeVente.commandeDetail        = this.commandeDetail;
      this.tableCommandeVente.commandeDetailDepot   = this.commandeDetailDepot ;
      this.tableCommandeVente.id                    = this.Vente.id;
      this.tableCommandeVente.num                   = this.Vente.num;
      this.tableCommandeVente.remise                = this.Vente.remise;
      this.tableCommandeVente.idClient              = this.Vente.idClient;
      this.tableCommandeVente.dateCommandee         = this.Vente.dateCommandee; 
      // this.tableCommandeVente.nbon = this.Vente.nbon;
      // console.log(this.tableCommandeVente);
      this.data
        .donnee(this.dataTable, this.tableCommandeVente)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            // alert(res.message);
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.blockedDocument=false;
            this.displayDialog = false;
            this.desablePrint = false;
            this.vente();
            this.getArticles();
            this.venteDetail();
            // this.paiementDetail();
            this.initialiser();

          } else {
            alert(res.message);
          }
        });
    } else {
      // alert('add')
        this.dataTable                                = "devis";
        this.tableCommandeVente.action                = "Insert";
        this.tableCommandeVente.DBase                 = this.DBase;
        this.tableCommandeVente.idUser                = this.AuthenticationService.currentUserValue.id;
        this.tableCommandeVente.remise                = this.Vente.remise;
        this.tableCommandeVente.commandeDetail        = this.commandeDetail;      
        this.tableCommandeVente.commandeDetailDepot   = this.commandeDetailDepot  ;
        this.tableCommandeVente.idClient              = this.Vente.idClient;
        this.tableCommandeVente.dateCommandee         = this.Vente.dateCommandee;
        // this.tableCommandeVente.nbon            = this.Vente.nbon;
        console.log(this.tableCommandeVente);
        
        this.data
          .donnee(this.dataTable, this.tableCommandeVente)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.vente();
              this.getArticles();
              this.venteDetail();
              // this.paiementDetail();
              this.initialiser();
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
              this.blockedDocument=false;
              this.desablePrint = false;
            } else {
              alert(res.message);
              this.displayDialog = false;
            }
          });
      }
    } else {
        alert("Vous-avez oublier le clients !!!");
    }
  }
  delete() {
    // alert();
    //   this.confirmationService.confirm({
    //       message: 'Do you want to delete this record?',
    //       header: 'Delete Confirmation',
    //       icon: 'pi pi-info-circle',
    //       accept: () => {
      if (confirm("êtes vous sure?")) {
            this.dataTable    = "devis";
            this.Vente.action = "Delete";
            this.Vente.DBase  = this.DBase;
            this.data
              .donnee(this.dataTable, this.Vente)
              .toPromise()
              .then((res: { message: string }) => {
                if ((res.message = "Opération de supression réuissie")) {
                  this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
                  this.vente();
                  this.venteDetail();
                  this.displayDialog = false;
                } else {
                  alert("Nonnnn");
                }
              });
            // this.messageService.add({severity:'info', summary:'Confirmed', detail:'Record deleted'});
              
          // },
          // reject: () => {
          //     // this.messageService.add({severity:'info', summary:'Rejected', detail:'لقذ تم حذف'});
          //     this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
          // }
      // });
    //
    // });
  }
  }
  
  exportexcel(): void {
  let fileName = "Situation des devis du "+ this.dateSituationDebut +  " au " +  this.dateSituationFin+".xlsx";  
    /* table id is passed over here */
    let element = document.getElementById("excel-table");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    var title =
      "Situation du " +
      this.dateSituationDebut +
      " au " +
      this.dateSituationFin;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      ws,
      "du " + this.dateSituationDebut + " au " + this.dateSituationFin
    );

    /* save to file */
    XLSX.writeFile(wb, fileName);
  }
  
  exportexcelFacture(): void {
  let fileName = "Facture n°" + this.Vente.id + " du " + this.Vente.dateCommandee + ".xlsx";  
    /* table id is passed over here */
    let element = document.getElementById("excel-facture");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    var title =
      "Facture" ;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      ws,
      "Facture n° " + this.Vente.id +" du "+this.Vente.dateCommandee
    );

    /* save to file */
    XLSX.writeFile(wb, fileName);
  }
  printSituation(): void {
    var params = [
      "height=" + screen.height,
      "width=" + screen.width,
      "fullscreen=yes", // only works in IE, but here for completeness
    ].join(",");
    let printContents, popupWin;
    printContents = document.getElementById("print-table").innerHTML;
    popupWin = window.open("", "_blank", params);
    popupWin.document.open();
    popupWin.document.write(`
			  <html>
				<head>
				  <title>Print tab</title>
				  <style>
				  table {
					// width: 750px;
					border-collapse: collapse;
					margin: 5px auto;
				}				
				/* Zebra striping */
				tr:nth-of-type(odd) {
					// background: #eee;
					
				}				
				th {
					background: #3498db;
					color: white;
					font-weight: bold;
					text-align: center;
				}				
				td,
				th {
					padding: 5px;
					// border: 1px solid #ccc;
					text-align: left;
					font-size: 12px;
				}
				  </style>
				</head>
			<body onload="window.print();window.close()" style="text-align:center;">${printContents}</body>
			  </html>`);
    popupWin.document.close();
  }
  printBL(idCommande) {
    this.displayDialog = false;
    // console.log(event);
    // this.Vente.id = event.data.idCommande;
    // this.Vente.dateCommandee = event.data.dateCommande;
    // this.Vente.idClient = event.data.idClient;
    // this.Vente.remise = event.data.remise;

    let recherche = this.tableDetailVentes.filter((x) => x.id == idCommande);
    this.tableCommandeVentes = recherche;
    this.requetteCommandeVentes = recherche;
    // this.requetteCommandeVentesFacture = Object.assign({},recherche);

    // console.log(this.requetteCommandeVentes);
    this.totalQantite();
    this.prepareCommandeDetail();
  }
  newDonnee: boolean;
  showDialog() {
    this.newDonnee = true;
    this.Vente = {};
    this.displayDialog = true;
  }
  showDialogToAdd() {
    this.tableAvances = [];
    this.totalAvance = 0;
    this.showButon = false;
    this.showButonSauvgarder = false;
    this.newDonnee = true;
    this.Vente = {};
    this.Vente.remise = 0;
    this.Vente.espece=0;
    this.Vente.traite=0;
    this.Vente.cheque=0;
    this.Vente.refVirement="";
    this.Vente.refTraite="";
    this.Vente.refCheque="";
    this.Vente.dateEcheanceTraite = new Date().toISOString().split("T")[0];
    this.Vente.dateEcheanceCheque = new Date().toISOString().split("T")[0];
    this.tableCommandeVente = {};
    this.tableCommandeVentes = [];
    this.requetteCommandeVentes = [];
    this.requetteCommandeVentesFacture = [];
    this.tableAvance ={};
    this.tableAvances =[];
    this.Vente.DataBase=this.DBase;
    this.Vente.dateCommandee = new Date().toISOString().split("T")[0];
    this.displayDialog = true;
    // this.tableCommandeVente.idDepot=2;
  }
  showDialogModePaiement(){
    this.displayModePaiement=true;
    this.tableAvance.dateEcheance = new Date().toISOString().split("T")[0];
  }
  // deleteAvance(idModePaiement) {
  //   var index = this.tableAvances.findIndex((x) => x.idModePaiement == idModePaiement);
  //   this.tableAvances.splice(index, 1);
  //   // this.calculTotalAvance();
  // }
  changeInput(element,data,idArticle) {
    var index = this.tableCommandeVentes.findIndex((x) => x.idArticle == idArticle)
    if (element=="qte"){
      this.tableCommandeVentes[index].quantite         = data;
      this.tableCommandeVentes[index].total            = this.tableCommandeVentes[index].prixVente *data;
      this.tableCommandeVentes[index].marge            = (this.tableCommandeVentes[index].prixVente-this.tableCommandeVentes[index].prixAchat)*data;
    }else{
      this.tableCommandeVentes[index].prixVente        = data;
      this.tableCommandeVentes[index].total            = this.tableCommandeVentes[index].quantite *data;
      this.tableCommandeVentes[index].marge            = (data-this.tableCommandeVentes[index].prixAchat)*this.tableCommandeVentes[index].quantite;
    }
    ;
    // this.requetteCommandeVentes=this.tableCommandeVentes;
    this.totalQantite();
    this.prepareCommandeDetail();
  }
  addArticle(data) {
    if((data.prixVente*1) <= (this.prixAchat*1)){
      this.colorInput='red';
      this.messageService.add({severity:'warn', summary: 'Attention !!!', detail: "Prix de vente est inférieur au prix d'achat !!!"});
    }else{      
      this.colorInput='';
    if(data.idArticle  && data.quantite && data.prixVente){
      // this.findDepot(data.idDepot);
      if(this.showButon==false){
          if( data.hasOwnProperty('prixAchat')==false){
            data.prixAchat=0;
          }
          data.total=data.prixVente*data.quantite;
          data.marge=(data.prixVente - data.prixAchat)*data.quantite;
          data.codeBarre=this.tableCommandeVente.autocomplete.codeBarre;
          data.reference=this.tableCommandeVente.autocomplete.reference;
        if (this.tableCommandeVente.idArticle || data.codeBarre || data.idArticle ) {
          this.tableCommandeVentes.push(data);
          this.showButon = false;
          this.nbre=this.tableCommandeVentes.length-1;
          if(this.tableCommandeVentes.length==1){
            this.higthCol='500px';
          }else if(this.tableCommandeVentes.length>1) {
            let hight=500-(this.tableCommandeVentes.length-1)*20;
            this.higthCol=hight + 'px';
          }
          this.totalQantite();
          this.prepareCommandeDetail();
          this.tableCommandeVente = {};
          // this.selectedArticle="";
          // this.tableCommandeVente.idDepot=data.idDepot;

        } else {
          alert("Entrer un Article !");
        }
        this.desablePrint=true;
        this.autoCompleteObject.focusInput();
        // var index = this.tableCommandeAchats.findIndex((x) => x.index == index);
      }else{
        // console.log(data)
        if( data.hasOwnProperty('prixAchat')==false){
          data.prixAchat=0;
        }
        // console.log( this.tableCommandeVentes)
        // var index = this.tableCommandeVentes.findIndex((x) => x.idArticle == data.idArticle);
        // alert (index);
        this.tableCommandeVentes[this.index].idArticle        = data.idArticle;
        // this.tableCommandeVentes[this.index].idDepot          = data.idDepot;
        this.tableCommandeVentes[this.index].depot            = data.depot;
        this.tableCommandeVentes[this.index].designation      = data.designation;
        this.tableCommandeVentes[this.index].quantite         = data.quantite;
        this.tableCommandeVentes[this.index].prixAchat        = data.prixAchat;
        this.tableCommandeVentes[this.index].prixVente        = data.prixVente;
        this.tableCommandeVentes[this.index].prixVenteGros    = data.prixVenteGros;
        this.tableCommandeVentes[this.index].prixVenteDetail  = data.prixVenteDetail;
        this.tableCommandeVentes[this.index].total            = data.prixVente*data.quantite;
        this.tableCommandeVentes[this.index].marge            = (data.prixVente-data.prixAchat)*data.quantite;
        this.tableCommandeVentes[this.index].codeBarre        = this.tableCommandeVente.autocomplete.codeBarre;
        this.tableCommandeVentes[this.index].reference        = this.tableCommandeVente.autocomplete.reference;
        this.idClient                                         = this.tableCommandeVente.idClient;
        
        // this.tableCommandeAchat = {};
        // this.tableCommandeAchat.idFournisseur = this.idFournisseur;
        // this.tableCommandeAchat.dateCommande = new Date()
        //   .toISOString()
        //   .split("T")[0];
        this.showButon = false;
        this.totalQantite();
        this.prepareCommandeDetail();
        //  this.tableCommandeAchat = {};
        
      }
      this.dropdownArticle=true;
      this.autoCompleteObject.focusInput();
      // this.tableCommandeVente.designation.focusInput();
    }else{
      this.messageService.add({severity:'warn', summary: 'Atterntion !!!', detail: "Attention des Vides فارغ"});
      this.autoCompleteObject.focusInput();
    }
  }
    //---------------------------

    // this.findDepot(data.idDepot);
    //   // alert( this.desablePrint);
    //   data.total=data.prixVente*data.quantite;
    //   data.marge=(data.prixVente-data.prixAchat)*data.quantite;   
    // if (this.tableCommandeVente.idArticle) {
    // // console.log(data);
    //   this.tableCommandeVentes.push(data);

    //   var index = this.tableCommandeVentes.findIndex(
    //     (x) => x.idArticle == this.idArticle
    //   );

      // alert(data.designation);
      // alert("article : " + this.idArticle);
      // this.tableCommandeAchats[index].designation = data.designation;
      // alert("1");
      // this.idFournisseur = this.tableCommandeAchat.idFournisseur;
      // this.tableCommandeAchat.idFournisseur = this.idFournisseur;
      // this.tableCommandeAchat.dateCommande = new Date()
      // .toISOString()
      // .split("T")[0];

    //   this.showButon = false;
    //   this.totalQantite();
    //   this.prepareCommandeDetail();
    //   this.tableCommandeVente = {};
    //   this.tableCommandeVente.idDepot=data.idDepot;
    //   // alert("2");
    // } else {
    //   alert("Entrer un Article !");
    // }
    // this.desablePrint=true;
    // var index = this.tableCommandeAchats.findIndex((x) => x.index == index);
  }
  // addAvance(data) {
  //   //  console.log(data);
  //   if(data.idModePaiement && data.montant){
  //       this.findModePaiement(data.idModePaiement);
  //       if(this.showButonAvance==false){
  //           this.tableAvances.push(data);
  //           this.showButonAvance = false;
  //           // this.calculTotalAvance();
  //           this.tableAvance = {};
  //           this.tableAvance.dateEcheance = new Date().toISOString().split("T")[0];
  //       }else{
  //         // alert(data.idModePaiement);
  //           this.tableAvances[this.indexAvance].idModePaiement    = data.idModePaiement;
  //           this.tableAvances[this.indexAvance].montant           = data.montant;
  //           this.tableAvances[this.indexAvance].modePaiement      = data.modePaiement;
  //           this.tableAvances[this.indexAvance].dateEcheance      = data.dateEcheance;
  //           this.tableAvances[this.indexAvance].reference         = data.reference;
  //           this.showButonAvance                                  = false;
  //           // this.totalQantite();
  //           // this.calculTotalAvance();
  //           this.tableAvance = {};
  //           this.tableAvance.dateEcheance = new Date().toISOString().split("T")[0];
          
  //       }
  //   }else{
  //     this.messageService.add({severity:'warn', summary: 'Atterntion !!!', detail: "Attention des Vides فارغ"});
  //   }
  // }
  updateArticle(data) {
    if((data.prixVente*1) <= (this.prixAchat*1)){
      this.colorInput='red';
      this.messageService.add({severity:'warn', summary: 'Attention !!!', detail: "Prix de vente est inférieur au prix d'achat !!!"});
    }else{
      if(data.idArticle && data.quantite && data.prixVente){
        
        // console.log(data);
        // console.log(this.tableCommandeVentes);
        // var index = this.tableCommandeVentes.findIndex((x) => x.idArticle == data.idArticle);
        // alert (index);
        this.tableCommandeVentes[this.index].idArticle        = data.idArticle;
        // this.tableCommandeVentes[this.index].idDepot          = data.idDepot;
        this.tableCommandeVentes[this.index].depot            = data.depot;
        this.tableCommandeVentes[this.index].designation      = data.designation;
        this.tableCommandeVentes[this.index].quantite         = data.quantite;
        this.tableCommandeVentes[this.index].prixAchat        = data.prixAchat;
        this.tableCommandeVentes[this.index].prixVente        = data.prixVente;
        this.tableCommandeVentes[this.index].prixVenteGros    = data.prixVenteGros;
        this.tableCommandeVentes[this.index].prixVenteDetail  = data.prixVenteDetail;
        this.tableCommandeVentes[this.index].total            = data.prixVente*data.quantite;
        this.tableCommandeVentes[this.index].marge            = (data.prixVente-data.prixAchat)*data.quantite;
        this.idClient                                         = this.tableCommandeVente.idClient;
        
        // this.tableCommandeAchat = {};
        // this.tableCommandeAchat.idFournisseur = this.idFournisseur;
        // this.tableCommandeAchat.dateCommande = new Date()
        //   .toISOString()
        //   .split("T")[0];
        this.showButon = false;
        this.totalQantite();
        this.prepareCommandeDetail();
        //  this.tableCommandeAchat = {};
        // this.findDepot(data.idDepot);
        // this.tableCommandeVente.idDepot=data.idDepot;
        this.designationAutoComplete="";
      }else{
        this.messageService.add({severity:'warn', summary: 'Atterntion !!!', detail: "Attention des Vides فارغ"});
      }
    }
    // this.tableCommandeVentes[this.index].idArticle = data.idArticle;
    // this.tableCommandeVentes[this.index].designation = data.designation;
    // this.tableCommandeVentes[this.index].quantite = data.quantite;
    // this.tableCommandeVentes[this.index].prixVenteDetail = data.prixVenteDetail;
    // this.tableCommandeVentes[this.index].prixVenteGros = data.prixVenteGros;
    // this.tableCommandeVentes[this.index].prixVente = data.prixVente;
    // this.tableCommandeVentes[this.index].total = data.prixVente*data.quantite;
    // this.tableCommandeVentes[this.index].marge = (data.prixVente-data.prixAchat)*data.quantite;
    // this.idClient = this.tableCommandeVente.idClient;

    // this.showButon = false;
    // this.totalQantite();
    // this.prepareCommandeDetail();
  }

  findDbase(dbase,colone){
    // console.log(dbase);
    // console.log(colone);
    if(this.Vente.DataBase){
      if(!this.DBase){
        this.DBase = this.AuthenticationService.currentUserValue.DBase;
      }else{
        this.DBase = this.Vente.DataBase;
      }
      this.tableVentes=[];
      this.article();
      this.getArticles();
      this.client();
      this.vente();
      this.venteDetail();
      // this.donnee();
    // this.tableVentes =[];
    //   this.dataTable = "devis";
    //   this.Vente.action = "Select";
    //   this.Vente.DBase = dbase;
    //   this.data
    //     .donnee(this.dataTable, this.Vente)
    //     .toPromise()
    //     .then(
    //       (x: Vente[]) => (
    //         this.Ventes = x, 
    //         this.tableVentes = x,
    //         // console.log(x),
    //         this.totalCommande()
    //       )
    //     );           
    }else{
      this.messageService.add({severity:'warn', summary: 'Parcourir', detail: "Choisir une base de donnée"});
    }
  }

  findArticle(idArticle) {
    let recherche = this.Articles.find((x) => x.id == idArticle);
    this.designation = recherche.designation;
    this.idArticle = idArticle;
    // alert(this.idArticle);
    // if (recherche != undefined) {
    // 	return recherche.designation;
    // } else {
    // 	return '0';
    // }
  }
  totalQantite() {
    let totalMontant = 0;
    let totalMarge = 0;
    let totalQte = 0;
    for (let data of this.tableCommandeVentes) {
      totalMontant += data.total;
      totalMarge += data.marge * 1;
      totalQte += data.quantite * 1;
    }
    this.totalQte = totalQte;
    this.totalMarge = totalMarge;
    this.totalMontant = totalMontant;
    this.totalMontantTTC = Math.floor(totalMontant);

    // return this._decimalPipe.transform(total, '1.2-2');
    // return total.toFixed(2);
    this.nombreToLettre((this.totalMontantTTC).toString());
  }

  deleteCommande(idArticle) {
    // alert(idArticle);
    var index = this.tableCommandeVentes.findIndex(
      (x) => x.idArticle == idArticle
    );
    // alert(index);
    this.tableCommandeVentes.splice(index, 1);
    this.totalQantite();
    this.prepareCommandeDetail();
    // this.tableCommandeAchat = {};
    // console.log(this.vente);
    // var index = this.vente.findIndex((x) => x.idArticle == idArticle);
  }
  prepareCommandePaiement() {
    var i=0;
    // this.commandePaiement = "INSERT INTO situationpaiementvente (idCommande, montant,modePaiement,reference,dateReglement,dateEcheance,regle) VALUES ";
    this.commandePaiement = "";
    if(this.Vente.espece>0){
      this.commandePaiement +="(idCommande," + this.Vente.espece+",'Espece','', '"+this.Vente.dateCommandee+"' , NULL,'o')" ;
      i++;
    }
    if(this.Vente.cheque>0){
      if (i>0){
        this.commandePaiement += ", ";
        i=0;
      }
      this.commandePaiement += "(idCommande," + this.Vente.cheque+",'Cheque', '"+
                    this.Vente.refCheque+ "', '" + this.Vente.dateCommandee+"','"+this.Vente.dateEcheanceCheque+"','o')" ;
      i++;
    }

    if(this.Vente.traite>0){
      if (i>0){
        this.commandePaiement += ", ";
      }
      this.commandePaiement += "(idCommande," + this.Vente.traite+", 'Traite', '"+
                    this.Vente.refTraite+ "','" + this.Vente.dateCommandee+"','"+this.Vente.dateEcheanceTraite+"','o')" ;
    }
  }

  prepareCommandeDetail() {
console.log(this.tableCommandeVentes);

    this.commandeDetail = "";
    for (let data of this.tableCommandeVentes) {
      this.commandeDetail +=
        "('idCommande','" +
        data.idArticle    + "','" +
        data.quantite     + "','" +
        data.prixAchat    + "','" +
        data.prixVente +
        "'),";
    }
    this.commandeDetail += "$";
    this.commandeDetail = this.commandeDetail.replace(",$", ";");

    //-----------------vers Facture ----------------//
    this.commandeDetailFacture = "";
    for (let data of this.tableCommandeVentes) {
      this.commandeDetailFacture +=
        "('idCommande','1','"      +
        data.idArticle    + "','" +
        data.quantite     + "','" +
        data.prixAchat    + "','" +
        data.prixVente +
        "'),";
    }
    this.commandeDetailFacture += "$";
    this.commandeDetailFacture = this.commandeDetailFacture.replace(",$", ";");
    console.log(this.commandeDetailFacture);

    //-----------------vers Variation du Stock ----------------//

    this.commandeDetailDepot = "";
    for (let data of this.tableCommandeVentes) {
      this.commandeDetailDepot +=
        "('idCommande','"         +
        this.Vente.dateCommandee  +  "','1','" +
        data.idArticle            +  "','" +
        data.quantite             +  
        "','Vente'),";
    }
    this.commandeDetailDepot += "$";
    this.commandeDetailDepot = this.commandeDetailDepot.replace(",$", ";");
    // console.log(this.commandeDetailDepot);
    this.initialiser();
    // alert(this.tableCommandeVente.idFournisseur);
  }

  onRowSelect(event) {
    // alert(event.data.ice);
    // console.log(event);
    // if (!event.data.cloture){
      // this.AuthenticationService.currentUserValue.username= this.AuthenticationService.currentUserValue.username.toUpperCase();
      // if (this.able==true ) {
        this.displayDialog = true;
        this.showButon = false;
        // console.log(event.data);
        
        this.Vente.id = event.data.idCommande;
        this.Vente.num = event.data.num;
        this.Vente.numm = event.data.numm;
        this.Vente.dateCommandee = event.data.dateCommande;

        // let vvv=JSON.stringify(event.data.idClient);
        this.Vente.idClient           = JSON.stringify(event.data.idClient);
        this.Vente.idClientFacture    = event.data.idClientFacture;
        this.Vente.remise             = event.data.remise;
        this.Vente.nom                = event.data.nom;
        this.Vente.bonSortie          = event.data.bonSortie;
        this.Vente.bonLivraison       = event.data.bonLivraison;
        this.Vente.bonCommande        = event.data.bonCommande;
        this.Vente.ice                = event.data.ice.replace('Entretien',' ');;
        this.Vente.idClientFacture    = event.data.idClientFacture.replace('Entretien',' ');
        // this.Vente.ice = JSON.stringify(event.data.ice);

        // this.Vente.nbon = event.data.nbon;
        this.Vente.idUser = event.data.idUser;
        this.Vente.nomComplet = event.data.nomComplet;
        this.Vente.cloture = event.data.cloture;
        // this.tableCommandeVente.idDepot=2;
        // alert (this.Vente.idClient);
        let recherche = this.tableDetailVentes.filter((x) => x.id == event.data.idCommande);
        // console.log(recherche);
        this.tableCommandeVentes = recherche;
        this.requetteCommandeVentes = recherche;
        // this.requetteCommandeVentesFacture = Object.assign({},recherche);
        
        this.nbre=this.requetteCommandeVentes.length-1;
        var hightFacture=450;
        if(this.requetteCommandeVentes.length==1){
          this.higthCol=hightFacture+'px';
        }else if(this.requetteCommandeVentes.length>1) {
          let hight=hightFacture-(this.requetteCommandeVentes.length-1)*20;
          this.higthCol=hight + 'px';       
        }
        this.totalQantite();
        this.prepareCommandeDetail();
        this.requetteCommandeVentesFacture = JSON.parse(JSON.stringify(this.requetteCommandeVentes));
        // console.log(this.requetteCommandeVentesFacture);
        this.requetteCommandeVentesFacture.forEach((row,index)=>{
          this.Familles.forEach((rowFamilles)=>{
            var regEx = new RegExp(rowFamilles.type, "ig");
            this.requetteCommandeVentesFacture[index].designation=row.designation.replace(regEx,' ');
          });
          // this.requetteCommandeVentesFacture[index].designation=row.designation.replace('Entretien',' ');
          // this.requetteCommandeVentesFacture[index].designation=row.designation.replace('Recharge',' ');
          // this.requetteCommandeVentesFacture[index].designation=row.designation.replace('Fourniture',' ');
          // this.requetteCommandeVentesFacture[index].designation=row.designation.replace('Vérification',' ');
        })
        
        let rechercher = this.tableDetailPaiements.filter((x) => x.idCommande === event.data.idCommande);
        if(rechercher){
          this.tableCommandeFactures  = rechercher;
          this.tableAvances           = rechercher;
        }
        // this.calculTotalAvance();
        // console.log(this.tableCommandeFactures);
        this.commandePaiement = "";
        this.Vente.remise = 0;
        this.Vente.espece=0;
        this.Vente.traite=0;
        this.Vente.cheque=0;
        this.Vente.refVirement="";
        this.Vente.refTraite="";
        this.Vente.refCheque="";
        this.Vente.dateEcheanceTraite = new Date().toISOString().split("T")[0];
        this.Vente.dateEcheanceCheque = new Date().toISOString().split("T")[0];
        // if (this.tableCommandeFactures != undefined) {
        //   this.tableCommandeFactures.forEach((row)=>{
        //     if(row.modePaiement=="Espece"){
        //         this.Vente.espece = row.montant;
        //     }else if(row.modePaiement=="Cheque"){
        //         this.Vente.cheque = row.montant;
        //         this.Vente.refCheque = row.reference;
        //         this.Vente.dateEcheanceCheque = row.dateEcheance;
        //     }else if(row.modePaiement =="Traite"){
        //         this.Vente.traite = row.montant;
        //         this.Vente.refTraite = row.reference;
        //         this.Vente.dateEcheanceTraite = row.dateEcheance;                
        //     }
        //     this.prepareCommandePaiement();
        //   });
        // }         
        // console.log(this.tableCommandeFactures);
      
      // }
    // }
  }
  cloneDonnee(c: Vente): Vente {
    let Vente = {};
    for (let prop in c) {
      Vente[prop] = c[prop];
    }
    return Vente;
  }
  onRowSelectCommandeDetail(event) {
    // console.log(event);
    this.dropdownArticle=false;
    this.showButon = true;
    this.showButonSauvgarder = true;
    this.index = this.tableCommandeVentes.findIndex(
      (x) => x.idArticle == event.data.idArticle
    );
    // alert(this.index);
    this.newDonnee = false;
    this.tableCommandeVente = this.cloneDonneeCommandeDetail(event.data);
    this.tableCommandeVente.autocomplete=event.data;
    this.tableCommandeVente.autocompleteCodeBarre=event.data;
    this.displayDialog = true;
  }
  cloneDonneeCommandeDetail(c: Vente): Vente {
    let tableCommandeVente = {};
    for (let prop in c) {
      tableCommandeVente[prop] = c[prop];
    }
    return tableCommandeVente;
  }
  // onRowSelectAvance(event) {
    
  //   this.tableAvance.idModePaiement   = event.data.idModePaiement;
  //   this.tableAvance.modePaiement     = event.data.modePaiement;
  //   this.tableAvance.montant          = event.data.montant;
  //   this.tableAvance.dateEcheance     = event.data.dateEcheance;
  //   this.tableAvance.reference        = event.data.reference;
  //   this.showButonAvance              = true;
  //   this.showButonSauvgarder          = true;
  //   this.indexAvance = this.tableAvances.findIndex((x) => x.idModePaiement == event.data.idModePaiement && x.montant ==event.data.montant);
  //   this.setFocusAvance();
  // }
  @ViewChild('inputAvance', {static: false}) inputAvance:ElementRef;
  setFocusAvance(){
    setTimeout(() => this.inputAvance.nativeElement.focus());
  }
  close() {
    this.displayDialog = false;
    this.tableCommandeVentes = [];
    this.tableCommandeVente = {};
    this.requetteCommandeVentes = [];
    this.requetteCommandeVentesFacture = [];
    this.requetteCommandeVente = {};
    this.Vente.DataBase=this.DBase;
    this.tableCommandeVente.dateCommandee = new Date().toISOString().split("T")[0];
    this.totalQantite();
    this.prepareCommandeDetail();
  }
  initialiser() {
    this.colorInput='';
    this.desablePrint=false;
    this.dropdownArticle=false;
    this.tableCommandeVente = {};
    this.requetteCommandeVentes = this.tableCommandeVentes;
    // this.requetteCommandeVentesFacture = this.tableCommandeVentes;
    // this.tableCommandeVente.idDepot=2;
    this.designationAutoComplete="";
    // this.tableCommandeAchat.idArticle = null;
    // this.tableCommandeAchat.quantite = "";
    // this.tableCommandeAchat.prixAchat = "";
    // this.tableCommandeAchat.prixVente = "";
    // this.tableCommandeAchat.prixVenteDetail = "";
    // this.tableCommandeAchat.prixVenteGros = "";
    // this.showButon = false;
  }
}
