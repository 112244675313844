import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { AuthenticationService } from '../authentication.service';
import { MessageService } from 'primeng/api';
import { Parametre } from '../model/parametre';

@Component({
	selector: 'app-parametre',
	templateUrl: 'parametre.component.html',
	styleUrls: [ 'parametre.component.scss' ]
})
export class ParametreComponent implements OnInit {
	Parametre: Parametre = {};
	Parametres: Parametre[];
	selectedParametre: Parametre;
	displayDialog: boolean;
	selectedOption: string;
	Apassword: boolean;
	blockedDocument: boolean = false;
	constructor(
		private data: DataService,
		private messageService: MessageService
		) {
	}

	ngOnInit() {
		this.parametre();
	}
	parametre() {
		this.Parametre.action = 'Select';
		this.data.parametre(this.Parametre).toPromise().then((x: Parametre[]) => ((this.Parametres = x)));
	}
	save() {
		// alert();
		// console.log(this.User);
		// const { old_password, new_password } = f.value;
		if (this.Parametre.id) {
			// alert("save");
			this.Parametre.action = 'Update';
			this.data.parametre(this.Parametre).toPromise().then((res: { message: string }) => {
				if (res.message == 'Opération de modification réuissie') {
					this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
					this.blockedDocument=false;
					this.displayDialog = false;
					this.parametre();
				} else {
					alert('Nonnnn');
				}
			});
		} else {
			this.Parametre.action = 'Insert';
			this.data.parametre(this.Parametre).toPromise().then((res: { message: string }) => {
				if (res.message == 'Opération de modification réuissie') {
					this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
					this.blockedDocument=false;
					this.displayDialog = false;
					this.parametre();
				} else {
					alert(res.message);
					this.displayDialog = false;
				}
			});
		}
	}
	delete() {
		if (confirm('êtes vous sure?')) {
			this.Parametre.action = 'Delete';
			this.data.parametre(this.Parametre).toPromise().then((res: { message: string }) => {
				if ((res.message = 'Opération de supression réuissie')) {
					this.messageService.add({severity:'warn', summary: 'Suppression', detail: res.message});
					this.blockedDocument=false;
					this.displayDialog = false;
					this.parametre();
				} else {
					alert('Nonnnn');
				}
			});
		}
	}
	newDonnee: boolean;
	showDialogToAdd() {
		this.newDonnee = true;
		this.Parametre = {};
		this.displayDialog = true;
	}
	onRowSelect(event) {
		this.newDonnee = false;
		this.Parametre = this.cloneDonnee(event.data);
		this.displayDialog = true;
	}
	cloneDonnee(c: Parametre): Parametre {
		let Parametre = {};
		for (let prop in c) {
			Parametre[prop] = c[prop];
		}
		return Parametre;
	}
}
