import { Component, OnInit } from '@angular/core';
import { Article } from '../../model/article';
import { DataService } from '../../data.service';
import { AuthenticationService } from '../../authentication.service';
import { Achat } from '../../model/achat';
import { Vente } from '../../model/vente';
import { Fournisseur } from '../../model/fournisseur';
import { DecimalPipe } from '@angular/common';
import { Variation } from '../../model/variation';

@Component({
	selector: 'app-alert-stock',
	templateUrl: 'alert-stock.component.html',
	styleUrls: [ 'alert-stock.component.scss' ],
	providers: [ DecimalPipe ]
})
export class AlertStockComponent implements OnInit {
	tableArticles: Achat[];
	Achat: Achat = {};
	Achats: Achat[];
	Vente: Vente = {};
	Ventes: Vente[];
	Variation: Variation = {};
	Variations: Variation[];
	tableVariations: Variation[];
	Article: Article = {};
	Articles: Article[];
	Fournisseur: Fournisseur = {};
	Fournisseurs: Fournisseur[];
	selectedAchat: Achat;
	constructor(
		private data: DataService,
		public AuthenticationService: AuthenticationService,
		private _decimalPipe: DecimalPipe
	) {}

	ngOnInit() {
		this.stock();
		// this.variation();
	}
	stock() {
		this.Achat.action = 'StockAlert';
		this.Achat.DBase = this.AuthenticationService.currentUserValue.DBase;
		this.data.stockDepot(this.Achat).toPromise().then((x: Achat[]) => (
			this.Achats = x, 
			this.tableArticles = x,
			this.justMoin()));
	}
	justMoin(){
		this.tableArticles=this.tableArticles.filter((x) => (x.stockAlert > x.stock));
	}
	variation() {
		this.Variation.action = 'Select';
		this.Variation.DBase = this.AuthenticationService.currentUserValue.DBase;
		this.data
			.variationStock(this.Variation)
			.toPromise()
			.then((x: Variation[]) => ((this.Variations = x), (this.tableVariations = x)));
	}
	rechercheVariation(idArticle) {
		let recherche = this.Variations.filter((x) => x.idArticle == idArticle);
		if (recherche != undefined) {
			return recherche.map((t) => t.quantite * 1).reduce((a, value) => a + value, 0);
		} else {
			return '0';
		}
	}
	filter(data, colone) {
		if (this.Achats) {
			let recherche = this.Achats.filter(
				(x) => 
						x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1 
						// &&
						// x.Diff>0
			);
			this.tableArticles = recherche;
		}
	}
}
