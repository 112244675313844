import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { DataService } from "../../data.service";
import { AuthenticationService } from "../../authentication.service";
import { environment } from "../../../environments/environment";
import { Article } from "../../model/article";
import { Achat } from "../../model/achat";
import { DecimalPipe } from "@angular/common";
import { Fournisseur } from "../../model/fournisseur";
import {Message,MessageService} from 'primeng/api';
import { AutoComplete } from 'primeng/autocomplete';
import { Depot } from "../../model/depot";
import { ModePaiement } from "src/app/model/modePaiement";
import { Paiement } from "src/app/model/paiement";
@Component({
  selector: "app-achat",
  templateUrl: "achat.component.html",
  styleUrls: ["achat.component.scss"],
  providers: [DecimalPipe,MessageService],
})
export class AchatComponent implements OnInit {
  msgs1: Message[];    
  msgs2: Message[];
  idArticle: number;
  idArticleTest: number;
  Article: Article = {};
  Articles: Article[];
  ArticlesFilter: Article[];
  rechercheArticle: Article = {};
  Achat: Achat = {};
  Achats: Achat[];
  tableAvances: any = [];
  totalAvance:number;
  selectedAchat: Achat;
  tableAchats: Achat[];
  tableDetailAchats: Achat[];
  selectedCommandeDetail: Achat;
  tableCommandeAchat: Achat = {};
  tableCommandeAchats: any = [];
  requetteCommandeAchat: Achat = {};
  requetteCommandeAchats: any = [];
  Fournisseur: Fournisseur = {};
  Fournisseurs: Fournisseur[];
  ModePaiement: ModePaiement = {};
  ModePaiements: ModePaiement[];
  displayDialog: boolean;
  blockedDocument: boolean;
  showButon: boolean;
  afficher: boolean;
  versDepot: boolean =false;
  showButonSauvgarder: boolean;
  showButonAvance: boolean=false;
  designation: string;
  nomFilter: string ='';
  depotFilter: string='';
  totalMontant: number;
  totalMarge: number;
  totalQte: number;
  total: number;
  marge: number;
  idFournisseur: number;
  index: string;  
  nom:string;
  nomAr:string;
  nbon:string;
  idCommande:string;
  nomDepot:string;
  tableDepot: Depot = {};
  Depot: Depot = {};
  idDepot: Depot = {};
  Depots: Depot[];
  Depotss: Depot[];
  idDepotAchat: number;
  commandeDetail: string;
  commandeDetailVente: string;
  commandeDetailDepot: string;
  prixArticle: string;
  dateDebut: any;
  dateFin: any;
  able: boolean =false;
  Global: boolean =false;
  administrateur: boolean =false;
  depotActivate: boolean =false;
  dropdownArticle: boolean =true;
  displayModePaiement: boolean = false;
  tableAvance: any = {};
  filteredArticles: any[];
  filteredCodeBare: any[];
  filteredReference: any[];
  commandePaiement: string;
  indexAvance: string;
  colspan: string;
  Paiement: any = {};
  Paiements: any[];
  totalPaiement:number;
  tableDetailPaiements: Paiement[];
  Paiementss: Paiement[];
  myUrl: string = `${environment.urlApi}`;
  styleOBJ :{};
  constructor(
    private data: DataService,
    private _decimalPipe: DecimalPipe,
    public AuthenticationService: AuthenticationService,
    private messageService: MessageService
    
  ) {}

  ngOnInit() {
    if(this.AuthenticationService.currentUserValue.groupe=='Administrateurs'){
      this.administrateur=true;
    }
    if(this.AuthenticationService.currentUserValue.groupe=='Administrateur' ||
       this.AuthenticationService.currentUserValue.groupe=='Administrateurs'){
      this.able=true;
    }
    if(this.AuthenticationService.currentUserValue.groupe=='Global' ){
      this.Global=true;
    }
    if(this.AuthenticationService.currentUserValue.depot== true){
      this.depotActivate=true;
    }
    if(!this.depotActivate){this.colspan="3";
    }else if(this.depotActivate){this.colspan="2";}
    // this.findDepott();
    this.article();
    this.fournisseur();
    if(this.able || this.Global){
      this.achat();
    }else{
      this.achatMagazin(); 
    }
    this.modePaiement();
    this.depot();
    this.achatDetail();
    this.tableCommandeAchat.dateCommande = new Date().toISOString().split("T")[0];
  }
  article() {
    this.Article.action = "Select";    
    this.Article.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .article(this.Article)
      .toPromise()
      .then((x: Article[]) => (
        this.Articles = JSON.parse(JSON.stringify(x)),
        this.ArticlesFilter = JSON.parse(JSON.stringify(x)),
        this.generateArticle()));
  }
  generateArticle(){
    this.Articles.forEach((row,index)=>{
      if(!row.codeBarre) {this.Articles[index].codeBarre="",this.Articles[index].codeBarre=""};
      if(!row.reference) {this.ArticlesFilter[index].reference="",this.ArticlesFilter[index].reference=""};
    });
    this.changeArticleFacture();
  }
  depot() {
    this.Depot.action = "Select";
    this.Depot.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .depot(this.Depot)
      .toPromise()
      .then((x: Depot[]) => (this.Depots = x,this.Depotss = x,this.filterDepot()));
  }
  filterDepot(){
    this.Depots = this.Depotss.filter((x) => x.designation.toUpperCase() !='FACTURE');
    let depots  = this.Depotss.find((x) => x.designation.toUpperCase() =='FACTURE');
    this.idDepot.id =depots.id;
  }
  changeArticleFacture(){
    
    if(this.Achat.facture){
      this.styleOBJ = {'background':'coral','margin': '10px', 'width': '99%'}
      this.Articles = this.ArticlesFilter.filter((x) => x.type.toString().toLocaleLowerCase().includes("facture"));
    }else{
      this.styleOBJ = {'margin': '10px', 'width': '99%'}
      this.Articles = this.ArticlesFilter.filter((x) => !x.type.toString().toLocaleLowerCase().includes("facture"));
    }
  }
  filterArticle(event) {
    let filtered : any[] = [];
    let query = event.query;
    // console.log(this.Articles);
    filtered = this.Articles.filter((x) => x.designation.toString().toLocaleLowerCase().includes(query.toLocaleLowerCase()));   
    this.filteredArticles = filtered;
  }
  filterCodeBare(event) {
    let filtered : any[] = [];
    let query = event.query;
    // console.log(event.query);
    // console.log(this.Articles);
    filtered = this.Articles.filter((x) => x.codeBarre.toString().toLocaleLowerCase().includes(query.toLocaleLowerCase()));   
    this.filteredCodeBare = filtered;
  }
  filterReference(event) {
    let filtered : any[] = [];
    let query = event.query;
    filtered = this.Articles.filter((x) => x.reference.toString().toLocaleLowerCase().includes(query.toLocaleLowerCase()));   
    this.filteredReference = filtered;
  }
  @ViewChild('inputQte', {static: false}) inputQte:ElementRef;
  setFocusQte(){
    setTimeout(() => this.inputQte.nativeElement.focus());
  }
  @ViewChild('inputCodeBarre', {static: false}) inputCodeBarre:ElementRef;
  setFocusCodeBarre(){setTimeout(() => this.inputCodeBarre.nativeElement.focus());}
  @ViewChild('autoCompleteObject') private autoCompleteObject: AutoComplete ;
  @ViewChild('inputDesignation', {static: false}) inputDesignation:ElementRef;
  setFocusDesignation(){
    setTimeout(() => this.inputDesignation.nativeElement.focus());
  }
  @ViewChild('inputAvance', {static: false}) inputAvance:ElementRef;
  setFocusAvance(){
    setTimeout(() => this.inputAvance.nativeElement.focus());
  }
  findFournisseur(idFourisseur){
    let rechercheDepot = this.Fournisseurs.find((x) => x.id == idFourisseur);
    if(rechercheDepot){
      this.nom = rechercheDepot.nom;
    }
  }
  findDepot(idDepot) {
    let rechercheDepot                  = this.Depots.find((x) => x.id == idDepot);
    this.tableDepot                     = rechercheDepot;
    this.nomDepot                       = rechercheDepot.designation;
    this.tableCommandeAchat.depot       = rechercheDepot.designation;
    this.idDepotAchat                   = this.tableDepot.idDepotAchat;
    // alert ( this.tableCommandeAchat.depot );
  } 
  findModePaiement(idModePaiement) {
    // console.log(this.ModePaiements);
    // console.log(idModePaiement);
    let rechercheModePaiement = this.ModePaiements.find((x) => x.id == idModePaiement);
    this.tableAvance.modePaiement = rechercheModePaiement.modePaiement;
  }
  modePaiement() {
    this.ModePaiement.action = "Select";
    this.ModePaiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .modePaiement(this.ModePaiement)
      .toPromise()
      .then((x: ModePaiement[]) => (this.ModePaiements = x));
  }
  setDropdownArticle(){
    // alert('kkk');
    this.dropdownArticle=true;
    if(this.Achat.cloture==false || this.Achat.cloture==undefined ){
      // this.setFocusDesignation();
      this.autoCompleteObject.focusInput();
    }
  }
  findPrix(idArticle,colone) {
    // console.log(idArticle,colone);
    if (idArticle!=undefined || this.tableCommandeAchat.autocomplete!=undefined){
      this.dropdownArticle=false;
      if(typeof idArticle =="string" && colone=="codeBarre-"){
        idArticle=this.tableCommandeAchat.autocomplete;
        colone="codeBarre";        
      }else{    
        colone="codeBarre-";
      }
      // console.log(idArticle,colone);
      if (idArticle && this.idArticleTest!=idArticle){
        // this.idArticleTest=idArticle;
        // if(this.Achat.cloture==false){
        //   this.setFocusQte();
        // }
        if (this.idArticleTest!=idArticle){
          this.idArticleTest=idArticle;
          this.setFocusQte();
        }
      }
      if(colone=="codeBarre"){
        this.rechercheArticle = this.Articles.find((x) => x.codeBarre == idArticle);
        this.tableCommandeAchat.autocomplete = this.rechercheArticle; 
      }else if(colone=="designation"){
        this.rechercheArticle = this.Articles.find((x) => x.id == idArticle.idArticle);
        this.tableCommandeAchat.autocomplete = idArticle; 
      }else{
        this.rechercheArticle = this.Articles.find((x) => x.id == idArticle.idArticle);
        this.tableCommandeAchat.autocomplete = idArticle; 
      }
      
      this.tableCommandeAchat.idArticle       = this.rechercheArticle.id;
      this.tableCommandeAchat.designation     = this.rechercheArticle.designation;
      this.tableCommandeAchat.prixAchat       = this.rechercheArticle.prixAchat;
      this.tableCommandeAchat.prixVenteDetail = this.rechercheArticle.prixVenteDetail;
      this.tableCommandeAchat.prixVenteGros   = this.rechercheArticle.prixVenteGros;
      this.tableCommandeAchat.prixVente       = this.rechercheArticle.prixVente;
      this.tableCommandeAchat.remise          = 0;
      let recherche = this.tableCommandeAchats.filter((x) => x.idArticle == idArticle);
      this.requetteCommandeAchats = recherche;
      this.setFocusQte();
    }
  }
  fournisseur() {
    this.Fournisseur.action = "Select";
    this.Fournisseur.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .fournisseur(this.Fournisseur)
      .toPromise()
      .then((x: Fournisseur[]) => (this.Fournisseurs = x));
  }
  achat() {
    this.Achat.action = "Select";
    this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .achat(this.Achat)
      .toPromise()
      .then(
        (x: Achat[]) => (
          (this.Achats = x),
          (this.tableAchats = x)
          // ,console.log(x)
          ,this.totalCommande()
          // ,this.alimenterAchat()
        )
      );
  }
  achatMagazin() {
    this.Achat.action = "SelectMagazin";
    this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .achat(this.Achat)
      .toPromise()
      .then(
        (x: Achat[]) => (
          (this.Achats = x),
          (this.tableAchats = x), 
          // console.log(x) ,
          this.totalCommande()
          // this.alimenterAchat()
        )
      );
  }
  achatDetail() {
    this.Achat.action = "SelectDetail";
    this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .achat(this.Achat)
      .toPromise()
      .then((x: Achat[]) => (this.tableDetailAchats = x));
  }
  transformDecimal(num) {
    return this._decimalPipe.transform(num, "1.2-2");
  }
  cloture(){
    if (this.afficher!=true){
      this.afficher=true;
    }else{
      this.afficher=false;
    }
  }
  calculTotalAvance(){
    this.totalAvance = 0;    
    if(this.tableAvances.length>0){
      this.totalAvance = this.tableAvances
        .map((t) => t.montant * 1)
        .reduce((a, value) => a + value, 0);  
    };
    this.prepareCommandeDetail();
  }
  clotureCommande(idCommande,cloture) {
    // alert(idCommande+" | "+cloture);
    let etat;
    if (cloture==0){
      etat=1;
    }else{
      etat=0;
    }
      this.blockedDocument=true;
      this.tableCommandeAchat.action  = "UpdateCloure";
      this.tableCommandeAchat.DBase   = this.AuthenticationService.currentUserValue.DBase;
      this.tableCommandeAchat.etat    = etat;
      this.tableCommandeAchat.id      = idCommande;
      // console.log(this.tableCommandeAchat);
      this.data
        .achat(this.tableCommandeAchat)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            // alert(res.message);
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.blockedDocument=false;
            this.displayDialog = false;
            this.achat();
            this.achatDetail();
          } else {
            alert(res.message);
          }
        });
  }
  saveCommandeAchat() {
    this.tableCommandeAchat.action          = "Insert";
    this.tableCommandeAchat.DBase           = this.AuthenticationService.currentUserValue.DBase;
    this.tableCommandeAchat.commandeDetail  = this.commandeDetail;
    this.data
      .achat(this.tableCommandeAchat)
      .toPromise()
      .then((res: { message: string }) => {
        if (res.message == "Opération de modification réuissie") {
          this.achat();
          alert(res.message);
        } else {
          alert(res.message);
        }
      });
  }
  showDialogModePaiement(){
    this.displayModePaiement=true;
    this.tableAvance.dateEcheance = new Date().toISOString().split("T")[0];
    this.tableAvance.idModePaiement=1;
  }

  filterCommande(data, colone) {
    // console.log(data + colone);
    // alert(data + colone);
    let recherche = this.tableCommandeAchats.filter(
      (x) =>
        x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1
    );
    this.requetteCommandeAchats = recherche;
    // console.log(this.requetteCommandeAchats);
    // this.totalCommande();
  }
  alimenterAchat(){
    this.Achats.forEach((row ,index )=>{
      if(row.depot === null ){
        this.Achats[index].depot = '';
      }
    })
    this.filter();
  }

  filter() {
    if(this.Achats){
      if (this.nbon===undefined){this.nbon='';}
      if (this.idCommande===undefined){this.idCommande='';}
      if (this.nom===undefined){this.nom='';}
      if (this.nomAr===undefined){this.nomAr='';}
      if (this.designation===undefined){this.designation='';}

      let recherche = this.Achats.filter(
        (x) =>
          x.nbon.toString().includes(this.nbon) && 
          x.idCommande.toString().includes(this.idCommande) &&
          x.nom.toUpperCase().indexOf(this.nom.toUpperCase()) > -1 && 
          x.nomAr.toUpperCase().indexOf(this.nomAr.toUpperCase()) > -1 
      );
      if (recherche){      
        this.tableAchats = recherche;
        this.totalCommande();
      } 
    }
  }

  // filter() {
  //   if(this.Achats){
  //     let recherche = this.Achats.filter(
  //       (x) =>
  //         x.nom.toUpperCase().indexOf(this.nomFilter.toUpperCase()) > -1 && 
  //         x.depot.toUpperCase().indexOf(this.depotFilter.toUpperCase()) > -1 
  //     );
  //     if (recherche){      
  //       this.tableAchats = recherche;
  //       this.totalCommande();   
  //     }  
  //   }
  // }
  
  totalCommande() {
    this.total = 0;
    this.marge = 0;
    this.total = this.tableAchats.map((t) => t.total * 1).reduce((a, value) => a + value, 0);
    this.marge = this.tableAchats.map((t) => t.marge * 1).reduce((a, value) => a + value, 0);
      // console.log( this.Achats);
  }
  save() {
    this.prepareCommandeDetail();
    if (this.Achat.id && this.tableCommandeAchat!='') {
      // alert('update');
      this.blockedDocument                          = true;
      this.tableCommandeAchat.action                = "Update";
      this.tableCommandeAchat.DBase                 = this.AuthenticationService.currentUserValue.DBase;
      if (this.Achat.idUser){
        this.tableCommandeAchat.idUser              = this.Achat.idUser;
      }else{
          this.tableCommandeAchat.idUser            = this.AuthenticationService.currentUserValue.id;
      }
      this.tableCommandeAchat.facture               = this.Achat.facture;
      this.tableCommandeAchat.commandeDetail        = this.commandeDetail;
      this.tableCommandeAchat.commandeDetailVente   = this.commandeDetailVente;
      this.tableCommandeAchat.commandePaiement      = this.commandePaiement ;
      this.tableCommandeAchat.commandeDetailDepot   = this.commandeDetailDepot;
      this.tableCommandeAchat.prixArticle           = this.prixArticle;
      this.tableCommandeAchat.id                    = this.Achat.id;
      this.tableCommandeAchat.idFournisseur         = this.Achat.idFournisseur;
      this.tableCommandeAchat.nom                   = this.nom;
      // this.tableCommandeAchat.nomDepot              = this.nomDepot;
      this.tableCommandeAchat.dateCommande          = this.Achat.dateCommande;
      this.tableCommandeAchat.nbon                  = this.Achat.nbon;
      this.tableCommandeAchat.idDepot               = this.tableDepot.id;
      // this.tableCommandeAchat.idDepotAchat          = this.idDepotAchat;
      console.log(this.tableCommandeAchat);
      this.data
        .achat(this.tableCommandeAchat)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.blockedDocument=false;
            this.displayDialog = false;
            if(this.able || this.Global){
              this.achat();
            }else{
              this.achatMagazin(); 
            }
            this.alimenterAchat();
            this.achatDetail();
            this.paiementDetail();
          } else {
            alert("Nonnnn");
          }
        });
            // this.filter();
    } else {
      // console.log(this.Achat.idFournisseur+'|'+this.tableDepot.id+'|'+this.tableCommandeAchat);

      if (this.Achat.idFournisseur &&  this.tableCommandeAchat!=''  ) {
        this.blockedDocument                          = true;
        this.tableCommandeAchat.action                = "Insert";
        this.tableCommandeAchat.facture               = this.Achat.facture;
        this.tableCommandeAchat.DBase                 = this.AuthenticationService.currentUserValue.DBase;
        this.tableCommandeAchat.idUser                = this.AuthenticationService.currentUserValue.id;
        this.tableCommandeAchat.commandeDetail        = this.commandeDetail;
        this.tableCommandeAchat.commandeDetailVente   = this.commandeDetailVente;
        this.tableCommandeAchat.commandeDetailDepot   = this.commandeDetailDepot;
        this.tableCommandeAchat.commandePaiement      = this.commandePaiement ;
        this.tableCommandeAchat.prixArticle           = this.prixArticle;
        this.tableCommandeAchat.idFournisseur         = this.Achat.idFournisseur;
        this.tableCommandeAchat.nom                   = this.nom;
        // this.tableCommandeAchat.nomDepot              = this.nomDepot;
        this.tableCommandeAchat.dateCommande          = this.Achat.dateCommande;
        this.tableCommandeAchat.nbon                  = this.Achat.nbon;
        // this.tableCommandeAchat.idDepot               = this.tableDepot.id;
        this.tableCommandeAchat.idDepotAchat          = this.idDepotAchat;
        // console.log(this.tableCommandeAchat.commandeDetail);
        console.log(this.tableCommandeAchat);
        this.data
          .achat(this.tableCommandeAchat)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              if(this.able || this.Global){
                this.achat();
              }else{
                this.achatMagazin(); 
              }
              this.alimenterAchat();
              this.achatDetail();
              this.paiementDetail();
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.blockedDocument=false;
              this.displayDialog = false;
            } else {
              alert(res.message);
              this.displayDialog = false;
            }
          });
      } else {
        this.messageService.add({severity:'warn', summary: 'Sauvegarde', detail: 'Vous-avez oublier le fournisseur !!!'});
      }
    }
  }
  delete() {
    if (confirm("êtes vous sure?")) {
      this.Achat.action = "Delete";
      this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.data
        .achat(this.Achat)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
            this.achat();
            this.achatDetail();
            this.displayDialog = false;
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  newDonnee: boolean;
  showDialog() {
    this.newDonnee = true;
    this.Achat = {};
    this.displayDialog = true;
  }
  showDialogToAdd() {
    this.afficher=true;
    this.showButon = false;
    this.showButonSauvgarder = false;
    this.newDonnee = true;
    this.tableAvances=[];
    this.tableAvance={};
    this.calculTotalAvance();
    this.Achat = {};
    this.displayDialog = true;
    this.tableCommandeAchat = {};
    this.tableCommandeAchats = [];
    this.requetteCommandeAchats = [];
    this.Achat.dateCommande = new Date().toISOString().split("T")[0];
    this.tableCommandeAchat.idDepot=1;
    this.Achat.cloture=false;
    if(this.Achat.cloture==false || this.Achat.cloture==undefined ){
      this.autoCompleteObject.focusInput();
    }
  }
  prixHT(){
    this.tableCommandeAchat.prixAchat=this.tableCommandeAchat.prixAchatHT*1.2;
  }
  prixTTC(){
    this.tableCommandeAchat.prixAchatHT = this.tableCommandeAchat.prixAchat/1.2;
  }
  addArticle(data) {
    // console.log(data);
    this.findDepot(data.idDepot);
    data.codeBarre=this.tableCommandeAchat.autocomplete.codeBarre;
    data.reference=this.tableCommandeAchat.autocomplete.reference;
    if(this.showButon==false){
      data.total=data.prixAchat*data.quantite;
      data.marge=(data.prixVente-data.prixAchat*(1-data.remise/100))*data.quantite;
      if (this.tableCommandeAchat.idArticle) {
        this.tableCommandeAchats.push(data);
        var index = this.tableCommandeAchats.findIndex(
          (x) => x.idArticle == this.idArticle
        );
        this.showButon = false;
        this.totalQantite();
        this.prepareCommandeDetail();
        this.tableCommandeAchat = {};
        this.tableCommandeAchat.idDepot=data.idDepot;
        if(this.Achat.cloture==false || this.Achat.cloture==undefined ){
          this.autoCompleteObject.focusInput();
        }
      } else {
        alert("Entrer un Article !");
        if(this.Achat.cloture==false || this.Achat.cloture==undefined ){
          // this.setFocusDesignation();
          this.autoCompleteObject.focusInput();
        }
      }
    }else{
      // console.log(data);
      this.tableCommandeAchats[this.index].idArticle        = data.idArticle;
      this.tableCommandeAchats[this.index].designation      = data.designation;    
      this.tableCommandeAchats[this.index].idDepot          = data.idDepot;
      this.tableCommandeAchats[this.index].depot            = data.depot;
      this.tableCommandeAchats[this.index].quantite         = data.quantite;
      this.tableCommandeAchats[this.index].remise           = data.remise;
      this.tableCommandeAchats[this.index].prixVenteGros    = data.prixVenteGros;
      this.tableCommandeAchats[this.index].prixVenteDetail  = data.prixVenteDetail;
      this.tableCommandeAchats[this.index].prixVente        = data.prixVente;
      this.tableCommandeAchats[this.index].prixAchat        = data.prixAchat;
      this.tableCommandeAchats[this.index].total            = data.prixAchat*data.quantite;
      this.tableCommandeAchats[this.index].marge            = (data.prixVente-data.prixAchat)*data.quantite;
      this.tableCommandeAchats[this.index].codeBarre        = this.tableCommandeAchat.autocomplete.codeBarre;
      this.tableCommandeAchats[this.index].reference        = this.tableCommandeAchat.autocomplete.reference;
      this.idFournisseur                                    = this.tableCommandeAchat.idFournisseur;
      this.showButon = false;
      this.totalQantite();
      this.prepareCommandeDetail();
      // if(this.Achat.cloture==false){
        // this.setFocusCodeBarre();
      if(this.Achat.cloture==false || this.Achat.cloture==undefined || this.Achat.cloture==undefined ){
        this.autoCompleteObject.focusInput();
      }
      // }
      // console.log(this.tableCommandeAchats);
    }
    
  }
  addAvance(data) {
     console.log(data);
     console.log(this.showButonAvance);
    if(data.idModePaiement && data.montant){
        this.findModePaiement(data.idModePaiement);
        if(this.showButonAvance==false){
            this.tableAvances.push(data);
            this.showButonAvance = false;
            this.calculTotalAvance();
            this.tableAvance = {};
            this.tableAvance.dateEcheance = new Date().toISOString().split("T")[0];
            this.tableAvance.idModePaiement=1;
        }else{
          // alert(data.idModePaiement);
            this.tableAvances[this.indexAvance].idModePaiement    = data.idModePaiement;
            this.tableAvances[this.indexAvance].montant           = data.montant;
            this.tableAvances[this.indexAvance].modePaiement      = data.modePaiement;
            this.tableAvances[this.indexAvance].dateEcheance      = data.dateEcheance;
            this.tableAvances[this.indexAvance].reference         = data.reference;
            this.showButonAvance                                  = false;
            // this.totalQantite();
            this.calculTotalAvance();
            this.tableAvance = {};
            this.tableAvance.dateEcheance = new Date().toISOString().split("T")[0];
            this.tableAvance.idModePaiement=1;
        }
    }else{
      this.messageService.add({severity:'warn', summary: 'Atterntion !!!', detail: "Attention des Vides فارغ"});
    }
  }
  updateArticle(data) {
    
    this.tableCommandeAchats[this.index].idArticle        = data.idArticle;
    this.tableCommandeAchats[this.index].designation      = data.designation;    
    this.tableCommandeAchats[this.index].idDepot          = data.idDepot;
    this.tableCommandeAchats[this.index].depot            = data.depot;
    this.tableCommandeAchats[this.index].quantite         = data.quantite;
    this.tableCommandeAchats[this.index].prixVenteGros    = data.prixVenteGros;
    this.tableCommandeAchats[this.index].prixVenteDetail  = data.prixVenteDetail;
    this.tableCommandeAchats[this.index].prixVente        = data.prixVente;
    this.tableCommandeAchats[this.index].prixAchat        = data.prixAchat;
    this.tableCommandeAchats[this.index].total            = data.prixAchat*data.quantite;
    this.tableCommandeAchats[this.index].marge            = (data.prixVente-data.prixAchat)*data.quantite;
    this.idFournisseur                                    = this.tableCommandeAchat.idFournisseur;
    // this.tableCommandeAchat = {};
    // this.tableCommandeAchat.idFournisseur = this.idFournisseur;
    // this.tableCommandeAchat.dateCommande = new Date()
    //   .toISOString()
    //   .split("T")[0];
    this.showButon = false;
    this.totalQantite();
    this.prepareCommandeDetail();
    this.findDepot(data.idDepot);
    this.tableCommandeAchat.idDepot=data.idDepot;
    //  this.tableCommandeAchat = {};
  }
  findArticle(idArticle) {
    let recherche = this.Articles.find((x) => x.id == idArticle);
    this.designation = recherche.designation;
    this.idArticle = idArticle;
    // alert(this.idArticle);
    // if (recherche != undefined) {
    // 	return recherche.designation;
    // } else {
    // 	return '0';
    // }
  }
  totalQantite() {
    let totalMontant = 0;
    let totalQte = 0;
    let totalMarge = 0;
    for (let data of this.tableCommandeAchats) {
      totalMontant += data.quantite * data.prixAchat;
      totalMarge += data.marge * 1;
      totalQte += data.quantite * 1;

    }

    this.totalQte = totalQte;
    this.totalMontant = totalMontant;
    this.totalMarge = totalMarge;
    // return this._decimalPipe.transform(total, '1.2-2');
    // return total.toFixed(2);
  }
  deleteAvance(idModePaiement) {
    var index = this.tableAvances.findIndex((x) => x.idModePaiement == idModePaiement);
    this.tableAvances.splice(index, 1);
    
    this.calculTotalAvance();
  }
  initialiserAvance() {
    this.tableAvance={};
    this.showButonAvance=false;
    this.tableAvance.idModePaiement=1;
    this.tableAvance.dateEcheance = new Date().toISOString().split("T")[0];
  }
  deleteCommande(idDetail,idArticle) {

    if (idDetail !=""){
      var index = this.tableCommandeAchats.findIndex(
        (x) => x.idArticle == idArticle
      );
    }else{
      var index = this.tableCommandeAchats.findIndex(
        (x) => x.idDetail == idDetail
      );      
    }
    // alert(index);
    this.tableCommandeAchats.splice(index, 1);
    this.totalQantite();
    this.prepareCommandeDetail();
    // this.tableCommandeAchat = {};
    // console.log(this.vente);
    // var index = this.vente.findIndex((x) => x.idArticle == idArticle);
  }
  prepareCommandeDetail() {
    let prixArticleAchat= "";
    let prixArticleRemise= "";
    let prixArticleVenteGros= "";
    let prixArticleVenteDetail= "";
    let prixArticleVente= "";
    let prixArticle= "";
    this.prixArticle= "UPDATE article ";
    this.commandeDetail = "";
    for (let data of this.tableCommandeAchats) {
      if(data.prixAchat=='' || data.prixAchat== undefined )
      {
        data.prixAchat=0;
      }

      this.commandeDetail     +=
        "('idCommande','"     +
        data.idDepot          + "','" +
        data.idArticle        + "','" +
        data.quantite         + "','" +
        data.prixAchat        + "','" +
        data.remise           + "','" +
        data.prixVenteGros    + "','" +
        data.prixVenteDetail  +"','" +
        data.prixVente +
        "'),";

        //Detail Avances
        this.commandePaiement ="";
        for (let data of this.tableAvances) {
          this.commandePaiement         +=
            "('idCommande','"         +
            this.Achat.idFournisseur  + "','" +
            this.Achat.dateCommande  + "','" +
            data.montant              + "','" +
            data.idModePaiement         + "','" +
            data.reference            + "','" +
            data.dateEcheance         +
            "','o'),";
          };
          this.commandePaiement += "$";
          this.commandePaiement = this.commandePaiement.replace(",$", ";");
          this.commandePaiement = this.commandePaiement.replace("undefined", "");

        // this.prixArticle = 
        // "SET 'prixVente' = '" + data.prixVente + "', 'prixAchat' = '" + data.prixAchat +
        // "' WHERE id = "+ data.idArticle ;
        prixArticleAchat        += " when id = "+ data.idArticle +" then "+ data.prixAchat ;
        prixArticleRemise       += " when id = "+ data.idArticle +" then "+ data.remise ;
        prixArticleVenteGros    += " when id = "+ data.idArticle +" then "+ data.prixVenteGros ;
        prixArticleVenteDetail  += " when id = "+ data.idArticle +" then "+ data.prixVenteDetail ;
        prixArticleVente        += " when id = "+ data.idArticle +" then "+ data.prixVente ;
        prixArticle             += "'"+ data.idArticle +"', " ;
    }
    this.commandeDetail += "$";
    this.commandeDetail = this.commandeDetail.replace(",$", ";");
    // console.log(this.commandeDetail);
    //-----------------vers Variation du Stock ----------------//

this.commandeDetailDepot = "";
for (let data of this.tableCommandeAchats) {
    if(this.Achat.facture){
      this.commandeDetailDepot +=
        "('idCommande','"         +
        this.Achat.dateCommande   +  "','" +
        this.idDepot.id           +  "','" +
        data.idArticle            +  "','" +
        data.quantite             +  
        "','Achat Facture'),";
    }else{
      this.commandeDetailDepot +=
        "('idCommande','"         +
        this.Achat.dateCommande  +  "','" +
        data.idDepot              +  "','" +
        data.idArticle            +  "','" +
        data.quantite             +  
        "','Achat'),";
    }
}
this.commandeDetailDepot += "$";
this.commandeDetailDepot = this.commandeDetailDepot.replace(",$", ";");
this.commandeDetailDepot=this.commandeDetailDepot.replace("undefined", "0");
// console.log(this.commandeDetailDepot);

    // prixArticleAchat += " SET prixAchat = (case " + prixArticleAchat + " end),";
    // prixArticleVente += " SET prixVente = (case " + prixArticleVente + " end),";
    // prixArticle      += " WHERE id in ("          + prixArticle      + ") ";
    var str =  " WHERE id in (" + prixArticle + ") ";
    str = str.replace(", )", ")");
    this.prixArticle += 
    " SET prixAchat     = (case " + prixArticleAchat + " end)," + 
    " remise            = (case " + prixArticleRemise + " end)," + 
    " prixVenteGros     = (case " + prixArticleVenteGros + " end)," +  
    " prixVenteDetail   = (case " + prixArticleVenteDetail + " end)," +  
    " prixVente         = (case " + prixArticleVente + " end)" +  
    str;
    this.prixArticle=this.prixArticle.replace("undefined", "0");
    // console.log( this.prixArticle);
    this.commandeDetailVente = "";
    for (let data of this.tableCommandeAchats) {
      this.commandeDetailVente +=
        "('idCommande','" +
        data.idArticle +
        "','" +
        data.quantite +
        "','" +
        data.prixVente +
        "'),";
    }
    this.commandeDetailVente += "$";
    this.commandeDetailVente = this.commandeDetailVente.replace(",$", ";");

    this.initialiser();
    // alert(this.tableCommandeAchat.idFournisseur);
  }

  paiementDetail() {
    this.Paiement.action = "paiemenetParIdCommande";
    this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .recapFournisseur(this.Paiement)
      .toPromise()
      .then((x: Paiement[]) => (this.tableDetailPaiements = x,this.calculTotalAvance()));
  }

  onRowSelect(event) {
    // console.log(event.data.facture); 
    this.tableAvances=[];
    this.tableAvance={};
    this.displayDialog        = true;
    this.afficher             = false;
    this.Achat.idCommande     = event.data.idCommande;
    this.Achat.nbon           = event.data.nbon;
    this.Achat.id             = event.data.idCommande;
    this.Achat.idDepot        = event.data.idDepot;
    this.Achat.nom            = event.data.nom;
    this.Achat.nomDepot       = event.data.depot;
    this.Achat.cloture        = event.data.cloture;
    this.Achat.dateCommande   = event.data.dateCommande;
    this.Achat.facture        = event.data.facture;
    this.Achat.idFournisseur  = JSON.stringify(event.data.idFournisseur) ;
  //  console.log( this.tableDetailAchats);
    let recherche = this.tableDetailAchats.filter((x) => x.idCommande == event.data.idCommande);
    // console.log(recherche);
    this.tableCommandeAchats = recherche;
    this.requetteCommandeAchats = recherche;

    // let nomDepot = this.Depots.find((x) => x.designation == event.data.nom);
    
    // if(nomDepot){
    //   this.versDepot=true;
    // }else{
    //   this.versDepot=false;
    // }
      // alert( this.versDepot);
    // alert(this.requetteCommandeAchats.length)
    // console.log(this.requetteCommandeAchats);
    this.paiementDetail();
    this.tableAvances = [];
    this.totalAvance=0;
    if(this.tableDetailPaiements){
      let rechercher    = this.tableDetailPaiements.filter((x) => x.idCommande == event.data.idCommande);
      
      if(rechercher){
        this.tableAvances = rechercher;
      }
    }
    this.totalQantite();
    this.prepareCommandeDetail();
    this.tableCommandeAchat.idDepot=1;
    if(this.Achat.cloture==false || this.Achat.cloture==undefined ){
      // this.setFocusCodeBarre();
      this.autoCompleteObject.focusInput();
    }
    
    this.changeArticleFacture();
    // console.log(this.Achat);
    // this.newDonnee = false;
    // this.Achat = this.cloneDonnee(event.data);
    // alert(this.tableCommandeAchat.idFournisseur);
  }
  cloneDonnee(c: Achat): Achat {
    let Achat = {};
    for (let prop in c) {
      Achat[prop] = c[prop];
    }
    return Achat;
  } 
  onRowSelectAvance(event) {
    
    this.tableAvance.idModePaiement   = event.data.idModePaiement;
    this.tableAvance.modePaiement     = event.data.modePaiement;
    this.tableAvance.montant          = event.data.montant;
    this.tableAvance.dateEcheance     = event.data.dateEcheance;
    this.tableAvance.reference        = event.data.reference;
    this.showButonAvance              = true;
    this.showButonSauvgarder          = true;
    this.indexAvance = this.tableAvances.findIndex((x) => x.idModePaiement == event.data.idModePaiement && x.montant ==event.data.montant);
    this.setFocusAvance();
  }
  onRowSelectCommandeDetail(event) {
    // console.log(event.data);
    this.showButon = true;
    this.showButonSauvgarder = true;
    this.index = this.tableCommandeAchats.findIndex(
      (x) => x.idArticle == event.data.idArticle
    );
    // alert(this.index);
    this.newDonnee = false;
    this.tableCommandeAchat = this.cloneDonneeCommandeDetail(event.data);
    // alert(event.data.autocompleteCodeBarre);
    // if(typeof event.data.autocompleteCodeBarre =="object"){
    //   this.tableCommandeAchat.autocompleteCodeBarre=event.data.autocompleteCodeBarre;
    // }else{
      // this.tableCommandeAchat.autocompleteCodeBarre=event.data;
    // }
    // if(typeof event.data.autocomplete =="object"){
    //   this.tableCommandeAchat.autocomplete=event.data.autocomplete;
    // }else{
      this.tableCommandeAchat.autocomplete=event.data;
    // }
    this.dropdownArticle=false;
    this.displayDialog = true;
    if(this.Achat.cloture==false || this.Achat.cloture==undefined ){
      // this.setFocusQte();
      this.autoCompleteObject.focusInput();
    }
  }
  cloneDonneeCommandeDetail(c: Achat): Achat {
    let tableCommandeAchat = {};
    for (let prop in c) {
      tableCommandeAchat[prop] = c[prop];
    }
    return tableCommandeAchat;
  }
  close() {
    this.displayDialog = false;
    this.tableCommandeAchats = [];
    this.tableCommandeAchat = {};
    this.requetteCommandeAchats = [];
    this.requetteCommandeAchat = {};
    this.tableCommandeAchat.dateCommande = new Date()
      .toISOString()
      .split("T")[0];
    this.totalQantite();
    this.prepareCommandeDetail();
  }
  initialiser() {
    this.tableCommandeAchat = {};
    this.requetteCommandeAchats = this.tableCommandeAchats;
    // this.tableCommandeAchat.idArticle = null;
    // this.tableCommandeAchat.quantite = "";
    // this.tableCommandeAchat.prixAchat = "";
    // this.tableCommandeAchat.prixVente = "";
    // this.tableCommandeAchat.prixVenteDetail = "";
    // this.tableCommandeAchat.prixVenteGros = "";
    this.showButon = false;
    this.dropdownArticle = true;
    this.tableCommandeAchat.idDepot=1;
    if(this.Achat.cloture==false || this.Achat.cloture==undefined){     
      // this.setFocusCodeBarre();
      this.autoCompleteObject.focusInput();
    }
  }
}
