import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from "@angular/core";
import { DataService } from "../../data.service";
import { AuthenticationService } from "../../authentication.service";
import { environment } from "../../../environments/environment";
import { Article } from "../../model/article";
import { Achat } from "../../model/achat";
import { DecimalPipe, UpperCasePipe } from "@angular/common";
import * as XLSX from "xlsx";
import { Fournisseur } from "../../model/fournisseur";
import {Message,MessageService,ConfirmationService} from 'primeng/api';
import { Depot } from "src/app/model/depot";
// import {CheckboxModule} from 'primeng/checkbox';
@Component({
  selector: "app-avoirs-achat",
  templateUrl: "avoirs-achat.component.html",
  styleUrls: ["avoirs-achat.component.scss"],
  providers: [DecimalPipe,MessageService,ConfirmationService],
})
export class AvoirsAchatComponent implements OnInit {
  msgs1: Message[];    
  msgs2: Message[];

  idArticle: number;
  nbre:number;
  higthCol:string;
  idArticleTest: number;
  achatX: number;
  Article: Article = {};
  Articles: Article[];
  ArticlesRecherche: Article[];
  stock: Article[];
  Achat: Achat = {};
  Achats: Achat[];
  rechercheArticle: Article = {};
  rechercheArticles: Article[];
  selectedAchat: Achat;
  selectedArticle:string;
  tableAchats: Achat[];
  tableDetailAchats: Achat[];
  tableDetailAchatss: Achat[];
  artilcesFournisseur: Achat[];
  artilceFournisseur: Achat={};
  Depot: Depot = {};
  Depots: Depot[];
  selectedCommandeDetail: Achat;
  tableCommandeAchat: Achat = {};
  tableCommandeAchats: any = [];
  requetteCommandeAchat: Achat = {};
  requetteCommandeAchats: any = [];
  Fournisseur: Fournisseur = {};
  Fournisseurs: Fournisseur[];
  displayDialog: boolean;
  desablePrint: boolean ;
  showButon: boolean;
  showButonSauvgarder: boolean;
  blockedDocument: boolean;
  designation: string='';
  nomComplet:string='';
  nbon:string='';
  numm:string='';
  nom:string;
  totalMontant: number;
  totalMarge: number;
  totalQte: number;
  total: number;
  remise: number;
  margeTotal: number;
  idFournisseur: number;
  idCommande: number;
  quantiteAchatGros: number;
  quantiteAchatDetail: number;
  prixAchatGros: number;
  prixAchatDetail: number;
  prixAchat1: number;
  prixAchat: number;
  qte: number;
  Exclut:string;  
  index: string;
  commandeDetail: string;
  commandeDetailDepot: string;
  dateDebut: any;
  dateFin: any;
  dateSituationDebut: string;
  dateSituationFin: string;
  msgs: Message[] = [];
  position: string;
  displaybarcode: boolean;
  displayCharge: boolean;
  afficher: boolean;
  prepareRetour: any = [];
  BarCode:string;

  able: boolean =false;
  afficherCodebarre: boolean =false;
  myUrl: string = `${environment.urlApi}`;
  
	Charge: any = {};
  Charges: any[];
  totalCharge:number;
  nbreBon:number;

  Paiement: any = {};
  Paiements: any[];
  totalPaiement:number;
  filteredCodeBare: any[];
  filteredReference: any[];
  filteredArticles: any[];
  constructor(
    private data: DataService,
    private _decimalPipe: DecimalPipe,
    public AuthenticationService: AuthenticationService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}
  addSingle() {
    this.messageService.add({severity:'success', summary:'Service Message', detail:'Via MessageService'});
}

  ngOnInit() {
    if(this.AuthenticationService.currentUserValue.groupe=='Administrateur' || this.AuthenticationService.currentUserValue.groupe=='Administrateurs' ){
      this.able=true;
    }
    this.commandeDetail="";
    this.msgs1 = [
      {severity:'success', summary:'Success', detail:'Message Content'},
      {severity:'info', summary:'Info', detail:'Message Content'},
      {severity:'warn', summary:'Warning', detail:'Message Content'},
      {severity:'error', summary:'Error', detail:'Message Content'}
     ];
    this.dateSituationDebut = new Date().toISOString().split("T")[0];
    this.dateSituationFin = new Date().toISOString().split("T")[0];
    this.article();
    // this.getArticles();
    // this.achat();
    this.fournisseur();
    // this.achat();
    this.depot();
    this.achatDetail();
    this.tableCommandeAchat.dateCommandee = new Date().toISOString().split("T")[0];
    this.Achat.dateCommandee = new Date().toISOString().split("T")[0];
    this.Achat.nom="";
    this.Achat.nomComplet="";
  }

  getArticles() {
		this.Article.action = 'Select';
    this.Article.DBase  = this.AuthenticationService.currentUserValue.DBase;
		this.data.stock(this.Article).toPromise().then((x: Article[]) => (this.stock = x));
  }
  filterArticle(event) {
    let filtered : any[] = [];
    let query = event.query;
    // console.log(this.Articles);
    filtered = this.Articles.filter((x) => x.designation.toString().toLocaleLowerCase().includes(query.toLocaleLowerCase()));   
    this.filteredArticles = filtered;
  }
  filterCodeBare(event) {
    let filtered : any[] = [];
    let query = event.query;
    // console.log(event.query);
    // console.log(this.Articles);
    filtered = this.Articles.filter((x) => x.codeBarre.toString().toLocaleLowerCase().includes(query.toLocaleLowerCase()));   
    this.filteredCodeBare = filtered;
  }
  filterReference(event) {
    let filtered : any[] = [];
    let query = event.query;
    filtered = this.Articles.filter((x) => x.reference.toString().toLocaleLowerCase().includes(query.toLocaleLowerCase()));   
    this.filteredReference = filtered;
  }
  // showDialogCharge(){
  //   this.displayCharge=true;
  // }

  article() {
    this.Article.action = "Select";
    this.Article.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .article(this.Article)
      .toPromise()
      .then((x: Article[]) => (
        this.Articles = x,
        // console.log(x),
        this.ArticlesRecherche = x));
  }

  findArticle(idArticle,colone){
    if (idArticle!=undefined || this.tableCommandeAchat.autocomplete!=undefined){ 
      if(typeof idArticle =="string" && colone=="codeBarre-"){
        idArticle=this.tableCommandeAchat.autocomplete;
        colone="codeBarre";        
      }else{    
        colone="codeBarre-";
      }
      if (idArticle && this.idArticleTest!=idArticle){
        if (this.idArticleTest!=idArticle){
          this.idArticleTest=idArticle;
          this.setFocusQte();
        }
      }
      if(colone=="codeBarre"){
        this.rechercheArticle = this.Articles.find((x) => x.codeBarre == idArticle);
        this.tableCommandeAchat.autocomplete = this.rechercheArticle; 
      }else if(colone=="designation"){
        this.rechercheArticle = this.Articles.find((x) => x.id == idArticle.idArticle);
        this.tableCommandeAchat.autocomplete = idArticle; 
      }else{
        this.rechercheArticle = this.Articles.find((x) => x.id == idArticle.idArticle);
        this.tableCommandeAchat.autocomplete = idArticle; 
      }
      // console.log(this.tableCommandeAchat);
      // if(colone=='designation'){
      //   this.rechercheArticle = this.stock.find((x) => x.designation == idArticle.designation);
      // }else if(colone=='idArticle'){
      //   this.rechercheArticle = this.stock.find((x) => x.id == idArticle.idArticle);
      // }
        this.tableCommandeAchat.idArticle = this.tableCommandeAchat.autocomplete.id;
      if(this.Achat.idFournisseur && this.tableCommandeAchat.idArticle){
          this.tableDetailAchatss=[];
          this.Achat.idArticle=this.tableCommandeAchat.idArticle;
          this.Achat.action = "SelectDetailParFournisseurArticle";
          this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
          this.data
            .achat(this.Achat)
            .toPromise()
            .then((x: Achat[]) => (
              this.tableDetailAchatss = x
              ));
          if(!this.tableDetailAchatss){
            this.messageService.add({severity:'warn', summary: 'Sauvegarde', detail: "L'article choisi n'est pas dans la liste de votre Fournisseur !!!"});
          }
            
      }else{
        this.messageService.add({severity:'warn', summary: 'Sauvegarde', detail: "Choisir un fournisseur ET un article"});
      }
    }
  }

  findCommande(nbon){

    this.requetteCommandeAchats=[];
    this.totalQantite();
    this.prepareCommandeDetail();
    this.Achat.nbon=nbon;
    this.Achat.action = "SelectDetailParFournisseurBon";    
    this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
    // console.log( this.Achat);
    this.data
      .achat(this.Achat)
      .toPromise()
      .then((x: Achat[]) => (
        this.tableDetailAchatss = x, console.log(x)
        ));
        // console.log(this.tableDetailVentess);
    if(!this.tableDetailAchatss){
       this.messageService.add({severity:'warn', summary: 'Attention', detail: "L'article choisi n'est pas dans la liste de votre Client !!!"});
    }
  } 

  retourArticle(data) {
    this.prepareRetour              = [];
    this.prepareRetour.id           = data.id;
    this.prepareRetour.dateCommande = data.dateCommande;
    this.prepareRetour.idArticle    = data.idArticle;
    this.prepareRetour.designation  = data.designation;
    this.prepareRetour.idClient     = data.idClient;
    this.prepareRetour.idCommande   = data.idCommande;
    this.prepareRetour.idDetail     = data.idDetail;
    this.prepareRetour.marge        = data.marge;
    this.prepareRetour.nom          = data.nom;
    this.prepareRetour.num          = data.num;
    this.prepareRetour.numm         = data.numm;
    this.prepareRetour.prixAchat    = data.prixAchat;
    this.prepareRetour.prixVente    = data.prixVente;
    this.prepareRetour.remise       = data.remise;
    this.prepareRetour.total        = data.prixVente;
    this.prepareRetour.type         = data.type;
    this.idCommande                 = this.prepareRetour.idCommande;
    this.qte                        = data.quantite;
    let rechercheDepot = this.Depots.find((x) => x.id == 1);
    this.prepareRetour.depot = rechercheDepot.designation;
    this.prepareRetour.idDepot =1;
    this.prepareRetour.quantite =1;
      if(this.showButon==false){
          if( this.prepareRetour.hasOwnProperty('prixAchat')==false){
            this.prepareRetour.prixAchat=0;
          }
          this.prepareRetour.total=this.prepareRetour.prixVente;
          this.prepareRetour.marge=(this.prepareRetour.prixVente - this.prepareRetour.prixAchat);
          // alert(data.marge);
    
        // if (this.tableCommandeVente.idArticle || data.codeBarre || data.idArticle ) {
          
          let recherche = this.requetteCommandeAchats.find((x) => x.idArticle == data.idArticle);
          if(!recherche){
          this.requetteCommandeAchats.push(this.prepareRetour);
          }else{
            var index = this.requetteCommandeAchats.findIndex((x) => x.idArticle == data.idArticle);
            // alert(this.qte +' <> '+this.requetteCommandeVentes[index].quantite);
            if((this.requetteCommandeAchats[index].quantite) < this.qte){
              this.requetteCommandeAchats[index].quantite=recherche.quantite+1;
              this.requetteCommandeAchats[index].total = recherche.prixVente*this.requetteCommandeAchats[index].quantite;
            }else{
              this.messageService.add({severity:'warn', summary: 'Attention', detail: "La quantité est Supperieur a la commande !!"});
            }
          }
          this.showButon = false;
          this.totalQantite();
          this.prepareCommandeDetail();
          this.tableCommandeAchat = {};
          this.selectedArticle="";
    
        // } else {
        //   alert("Entrer un Article !");
        // }
        this.desablePrint=true;
        // this.setFocusArticle();
        // var index = this.tableCommandeAchats.findIndex((x) => x.index == index);
      }else{
        if( data.hasOwnProperty('prixAchat')==false){
          data.prixAchat=0;
        }
        this.tableCommandeAchats[this.index].idArticle = data.idArticle;
        this.tableCommandeAchats[this.index].designation = data.designation;
        this.tableCommandeAchats[this.index].quantite = data.quantite;
        this.tableCommandeAchats[this.index].prixVente = data.prixVente;
        this.tableCommandeAchats[this.index].total = data.prixVente*data.quantite;
        this.tableCommandeAchats[this.index].marge = (data.prixVente-data.prixAchat)*data.quantite;
        this.idFournisseur = this.tableCommandeAchat.idFournisseur;
        this.showButon = false;
        this.totalQantite();
        this.prepareCommandeDetail();
      }
}
  fournisseur() {
    this.Fournisseur.action = "Select";    
    this.Fournisseur.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .fournisseur(this.Fournisseur)
      .toPromise()
      .then((x: Fournisseur[]) => (this.Fournisseurs = x));
  }
  
  // exclut(idFournisseur){
  //   // alert(idFournisseur);
  //   let recherche = this.Achats.filter((x) => (x.idFournisseur == idFournisseur));
  //   this.nbreBon =recherche.length; 
  //   let recherchee = this.Fournisseurs.find((x) => x.id == idFournisseur);
  //   this.Exclut=recherchee.exclut;
  //   this.artilcesFournisseur = this.tableDetailAchats.filter((x) => x.idFournisseur == idFournisseur);
  // }

 

  // charge(dateDebut,dateFin) {
	// 	this.Charge.action = 'FilterChargeCaisse';
	// 	this.Charge.dateDebut = dateDebut;
	// 	this.Charge.dateFin   = dateFin;
	// 	this.data
	// 		.charge(this.Charge)
	// 		.toPromise()
	// 		.then(
	// 			(x: Charge[]) => (this.Charges = x, this.calculTotalCharge())
	// 		);
  // }
  
  // calculTotalCharge(){
  //   this.totalCharge = 0;    
  //   if(this.Charges.length>0){
  //     this.totalCharge = this.Charges
  //       .map((t) => t.montant * 1)
  //       .reduce((a, value) => a + value, 0);  
  //   }    
  // }

  // paiementRecap(dateDebut,dateFin) {
  //   this.Paiement.action = "RecapByDate";
  //   this.Paiement.dateDebut = dateDebut;
	// 	this.Paiement.dateFin   = dateFin;  
  //   this.data
  //     .paiementAchat(this.Paiement)
  //     .toPromise()
  //     .then((x: Paiement[]) => (this.Paiements = x,this.calculTotalPaiement()));
  // }
  // calculTotalPaiement() {
  //   this.totalPaiement = 0;    
  //   if(this.Paiements.length>0){
  //     this.totalPaiement = this.Paiements
  //       .map((t) => t.montant * 1)
  //       .reduce((a, value) => a + value, 0);  
  //   }  
  // }
  achatDetail() {
    this.Achat.action = "SelectDetail";
    this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .avoirAchat(this.Achat)
      .toPromise()
      .then((x: Achat[]) => (this.tableDetailAchats = x, this.achat()));
  }

  achat() {
    this.Achat.action = "Select";
    this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .avoirAchat(this.Achat)
      .toPromise()
      .then(
        (x: Achat[]) => (
          (this.Achats = x), 
          (this.tableAchats = x),           
          // console.log(x),
          // this.paiementRecap(this.dateSituationDebut,this.dateSituationFin),
          this.filterCommande(this.dateSituationDebut,this.dateSituationFin)
        )
      );
  }
  filterCommande(dateDebut,dateFin){
    if (this.nom==undefined){this.nom='';}
    if (this.nomComplet==undefined){this.nomComplet='';}

    let recherche = this.Achats.filter((x) => (
      x.dateAvoir >= dateDebut && x.dateAvoir <= dateFin) && 
      x.nom.toLocaleLowerCase().includes(this.nom.toLocaleLowerCase()) &&
      x.nomComplet.toLocaleLowerCase().includes(this.nomComplet.toLocaleLowerCase()) &&
      x.numm.toString().includes(this.numm)
      );
    this.tableAchats = recherche; 
    // console.log(this.tableAchats);
    this.totalCommande();
    // this.charge(dateDebut,dateFin);
    // this.paiementRecap(dateDebut,dateFin);
    // this.recherhceMarge();
  }

  totalCommande() {
    this.total = 0;
    this.total = this.tableAchats
      .map((t) => t.montant * 1)
      .reduce((a, value) => a + value, 0);
    this.remise = 0;
    this.remise = this.tableAchats
      .map((t) => t.remise * 1)
      .reduce((a, value) => a + value, 0);
    this.margeTotal = 0;
    this.margeTotal = this.tableAchats
      .map((t) => t.marge * 1)
      .reduce((a, value) => a + value, 0);
  }

  depot() {
    this.Depot.action = "SelectDepot";
    this.Depot.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .depot(this.Depot)
      .toPromise()
      .then((x: Depot[]) => (this.Depots = x));
  }

  // this.recherhceMarge()
  figer(){
    if (this.afficher!=true){
      this.afficher=true;
    }else{
      this.afficher=false;
    }
  }
  cloture(idCommande,cloture) {
    // alert(idCommande+" | "+cloture);
    let etat;
    if (cloture==0){
      etat=1;
    }else{
      etat=0;
    }
      this.blockedDocument=true;
      this.tableCommandeAchat.action  = "UpdateCloure";
      this.tableCommandeAchat.DBase   = this.AuthenticationService.currentUserValue.DBase;
      this.tableCommandeAchat.etat    = etat;
      this.tableCommandeAchat.id      = idCommande;
      this.data
        .avoirAchat(this.tableCommandeAchat)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            // alert(res.message);
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.blockedDocument=false;
            this.displayDialog = false;
            this.achat();
            this.achatDetail();
          } else {
            alert(res.message);
          }
        });
  }

  transformDecimal(num) {
    return this._decimalPipe.transform(num, "1.2-2");
  }
  recherhceMarge(){

        this.tableAchats.forEach((row)=>{
        let recherche = this.tableDetailAchats.filter((x) => x.idCommande == row.idCommande);

        let updateItem = this.tableAchats.find(item=>item.idCommande==row.idCommande);
        let index = this.tableAchats.indexOf(updateItem);
        let marge =  recherche
          .map((t) => t.marge)
          .reduce((a, value) => a + value, 0);
        this.tableAchats[index].marge = marge;
      }); 
      // console.log(this.tableAchats);
      this.totalCommande();
  }
  saveCommandeAchat() {
    this.tableCommandeAchat.action          = "Insert";
    this.tableCommandeAchat.DBase           = this.AuthenticationService.currentUserValue.DBase;
    this.tableCommandeAchat.commandeDetail  = this.commandeDetail;
    this.data
      .avoirAchat(this.tableCommandeAchat)
      .toPromise()
      .then((res: { message: string }) => {
        if (res.message == "Opération de modification réuissie") {
          this.achat();
          alert(res.message);
        } else {
          alert(res.message);
        }
      });
  }
  //   filter(data, colone) {
  //     console.log(data + colone);
  //     let recherche = this.Achats.filter(
  //       (x) =>
  //         x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1
  //     );
  //     this.tableAchats = recherche;
  //     this.total = this.tableAchats
  //       .map((t) => t.montant * 1)
  //       .reduce((a, value) => a + value, 0);
  //   }
  filter(data, colone) {
    let recherche;
    if (colone=="nbon" ){
       recherche = this.Achats.filter((x) => x.nbon.toString().includes(data));
    }else if ( colone =="numm"){
      recherche = this.Achats.filter((x) => x.numm.toString().includes(data));
    }else{
      recherche = this.Achats.filter(
      (x) =>
        x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1
    ); 
    }
    if (recherche!=undefined){
    this.tableAchats = recherche;
    }
    this.totalCommande();
  }
  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

  @ViewChild('input1', {static: false}) inputEl:ElementRef;

  barcode(){
    this.displaybarcode=true;
    this.close();
    // this.Achat.nbon='0';
    // this.Achat.remise=0;
    // setTimeout(() => this.inputEl.nativeElement.focus());
  }

  codebarre(){
    this.close();
    if (this.afficherCodebarre==true){
      this.afficherCodebarre=false;
      this.displaybarcode=false;
    }else{
      this.afficherCodebarre=true;
      this.displaybarcode=true;
    }
    // this.displaybarcode=false;
  }
  // setFocus(){
  //   setTimeout(() => this.inputEl.nativeElement.focus());
  // }
  // @ViewChild('inputArticle', {static: false}) inputArticle:ElementRef;
  // setFocusArticle(){
  //   setTimeout(() => this.inputArticle.nativeElement.focus());
  // }  
  @ViewChild('inputQte', {static: false}) inputQte:ElementRef;
  setFocusQte(){
    setTimeout(() => this.inputQte.nativeElement.focus());
  }
  barcodeHide(){
    this.displaybarcode=false;
  }
  factureSelect(event){
    this.displayDialog = false; 
    this.displaybarcode=true;       
    this.Achat.id = event.idCommande;
    this.Achat.num = event.num;
    this.Achat.numm = event.numm;
    this.Achat.dateCommandee = event.dateCommande;
    this.Achat.idFournisseur = JSON.stringify(event.idFournisseur);
    this.Achat.remise = event.remise;
    this.Achat.nom = event.nom;
    this.Achat.nbon = event.nbon;
    this.Achat.idUser = event.idUser;
    this.Achat.nomComplet = event.nomComplet;
    // this.Achat.cloture = event.cloture;
    // alert (this.Achat.idFournisseur);
    let recherche = this.tableDetailAchats.filter(
      (x) => x.id == event.idCommande
    );
    this.tableCommandeAchats = recherche;
    this.requetteCommandeAchats = recherche;
    this.totalQantite();
    this.prepareCommandeDetail();
  }
  save() {
    // if (this.Achat.nbon=='0' ){
    //   this.Achat.nbon=this.Achat.nbon.toString();
    // }
    // console.log('idclient' + this.Achat.idFournisseur);
    // console.log('nbon' + this.Achat.nbon);
    // console.log('commandeDetail' + this.commandeDetail);
    if (this.Achat.idFournisseur && this.commandeDetail!='') {
    this.blockedDocument=true;
    if (this.Achat.id) {
      // alert('update');
      this.tableCommandeAchat.action = "Update";
      this.tableCommandeAchat.DBase  = this.AuthenticationService.currentUserValue.DBase;
      if (this.Achat.idUser){
        this.tableCommandeAchat.idUser = this.Achat.idUser;
      }else{
          this.tableCommandeAchat.idUser = this.AuthenticationService.currentUserValue.id;
      }  
      this.tableCommandeAchat.commandeDetail = this.commandeDetail;
      this.tableCommandeAchat.commandeDetailDepot   = this.commandeDetailDepot  ;
      this.tableCommandeAchat.id = this.Achat.id;
      this.tableCommandeAchat.num = this.Achat.num;
      this.tableCommandeAchat.remise = this.Achat.remise;
      this.tableCommandeAchat.idFournisseur = this.Achat.idFournisseur;
      this.tableCommandeAchat.dateCommandee = this.Achat.dateCommandee;
      this.tableCommandeAchat.nbon = this.Achat.nbon;
      // this.tableCommandeAchat.nbreBon         = this.nbreBon;
      // console.log(this.tableCommandeAchat);
      this.data
        .avoirAchat(this.tableCommandeAchat)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            // alert(res.message);
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.displaybarcode   = false;  
            this.blockedDocument  = false;
            this.displayDialog    = false;
            this.desablePrint     = false;
            this.achat();
            this.getArticles();
            this.achatDetail();
            this.displaybarcode=false;
          } else {
            alert(res.message);
          }
        });
    } else {
      // alert('add')
        this.tableCommandeAchat.action          = "Insert";
        this.tableCommandeAchat.DBase           = this.AuthenticationService.currentUserValue.DBase;
        this.tableCommandeAchat.idUser          = this.AuthenticationService.currentUserValue.id;
        this.tableCommandeAchat.idCommande      = this.idCommande;
        this.tableCommandeAchat.remise          = this.Achat.remise;
        this.tableCommandeAchat.commandeDetail  = this.commandeDetail;
        this.tableCommandeAchat.commandeDetailDepot   = this.commandeDetailDepot  ;
        this.tableCommandeAchat.idFournisseur   = this.Achat.idFournisseur;
        this.tableCommandeAchat.dateCommandee   = this.Achat.dateCommandee;
        this.tableCommandeAchat.nbon            = this.Achat.nbon;
        // this.tableCommandeAchat.nbreBon         = this.nbreBon;
        // console.log(this.tableCommandeAchat);
        this.data
          .avoirAchat(this.tableCommandeAchat)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.achat();
              this.getArticles();
              this.achatDetail();
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displaybarcode   = false;  
              this.displayDialog = false;
              this.blockedDocument=false;
              this.desablePrint = false;
              this.displaybarcode=false;
            } else {
              alert(res.message);
              this.displayDialog = false;
            }
          });
      }
    } else {
        alert("Vous-avez oublier le clients !!!");
    }
  }
  delete() {
    // alert();
    //   this.confirmationService.confirm({
    //       message: 'Do you want to delete this record?',
    //       header: 'Delete Confirmation',
    //       icon: 'pi pi-info-circle',
    //       accept: () => {
      if (confirm("êtes vous sure?")) {
        // console.log(this.Achat);
            this.Achat.action = "Delete";
            this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
            this.data
              .avoirAchat(this.Achat)
              .toPromise()
              .then((res: { message: string }) => {
                if ((res.message = "Opération de supression réuissie")) {
                  this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
                  this.achat();
                  this.achatDetail();
                  this.displayDialog = false;
                } else {
                  alert("Nonnnn");
                }
              });
    // });
  }
  }
  
  exportexcel(): void {
  let fileName = "Situation des achats du "+ this.dateSituationDebut +  " au " +  this.dateSituationFin+".xlsx";  
    /* table id is passed over here */
    let element = document.getElementById("excel-table");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    var title =
      "Situation du " +
      this.dateSituationDebut +
      " au " +
      this.dateSituationFin;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      ws,
      "du " + this.dateSituationDebut + " au " + this.dateSituationFin
    );

    /* save to file */
    XLSX.writeFile(wb, fileName);
  }
  printSituation(): void {
    var params = [
      "height=" + screen.height,
      "width=" + screen.width,
      "fullscreen=yes", // only works in IE, but here for completeness
    ].join(",");
    let printContents, popupWin;
    printContents = document.getElementById("print-table").innerHTML;
    popupWin = window.open("", "_blank", params);
    popupWin.document.open();
    popupWin.document.write(`
			  <html>
				<head>
				  <title>Print tab</title>
				  <style>
				  table {
					// width: 750px;
					border-collapse: collapse;
					margin: 5px auto;
				}				
				/* Zebra striping */
				tr:nth-of-type(odd) {
					// background: #eee;
					
				}				
				th {
					background: #3498db;
					color: white;
					font-weight: bold;
					text-align: center;
				}				
				td,
				th {
					padding: 5px;
					// border: 1px solid #ccc;
					text-align: left;
					font-size: 12px;
				}
				  </style>
				</head>
			<body onload="window.print();window.close()" style="text-align:center;">${printContents}</body>
			  </html>`);
    popupWin.document.close();
  }
  printBL(idCommande) {
    this.displayDialog = false;
    // console.log(event);
    // this.Achat.id = event.data.idCommande;
    // this.Achat.dateCommandee = event.data.dateCommande;
    // this.Achat.idFournisseur = event.data.idFournisseur;
    // this.Achat.remise = event.data.remise;

    let recherche = this.tableDetailAchats.filter((x) => x.id == idCommande);
    this.tableCommandeAchats = recherche;
    this.requetteCommandeAchats = recherche;
    // console.log(this.requetteCommandeAchats);
    this.totalQantite();
    this.prepareCommandeDetail();
  }
  newDonnee: boolean;
  showDialog() {
    this.newDonnee = true;
    this.Achat = {};
    this.displayDialog = true;
  }
  showDialogToAdd() {
    this.afficher= true;
    this.tableCommandeAchat = {};
    this.tableCommandeAchats = [];
    this.tableDetailAchatss = [];
    this.requetteCommandeAchats = [];
    this.showButon = false;
    this.showButonSauvgarder = false;
    this.newDonnee = true;
    this.Achat = {};
    this.Achat.remise = 0;
    this.Achat.dateCommandee = new Date().toISOString().split("T")[0];
    this.displayDialog = true;
  }
  addArticle(data) {
      // console.log(data);
      // alert( data.prixAchat);
      // console.log(data.prixAchat)
      this.idCommande =data.idCommande;
        if(this.showButon==false){
            if( data.hasOwnProperty('prixAchat')==false){
              data.prixAchat=0;
            }
            data.total=data.prixAchat*data.quantite;
            data.marge=(data.prixAchat - data.prixAchat)*data.quantite;
            // alert(data.marge);
      
          // if (this.tableCommandeAchat.idArticle || data.codeBarre || data.idArticle ) {
          // console.log(data);
            this.tableCommandeAchats.push(data);
            this.showButon = false;
            this.totalQantite();
            this.prepareCommandeDetail();
            this.tableCommandeAchat = {};
            this.selectedArticle="";
      
          // } else {
          //   alert("Entrer un Article !");
          // }
          this.desablePrint=true;
          // this.setFocusArticle();
          // var index = this.tableCommandeAchats.findIndex((x) => x.index == index);
        }else{
                // console.log(data)
          if( data.hasOwnProperty('prixAchat')==false){
            data.prixAchat=0;
          }
          // console.log( this.tableCommandeAchats)
          // var index = this.tableCommandeAchats.findIndex((x) => x.idArticle == data.idArticle);
          // alert (index);
          this.tableCommandeAchats[this.index].idArticle = data.idArticle;
          this.tableCommandeAchats[this.index].designation = data.designation;
          this.tableCommandeAchats[this.index].quantite = data.quantite;
          this.tableCommandeAchats[this.index].prixAchat = data.prixAchat;
          this.tableCommandeAchats[this.index].total = data.prixAchat*data.quantite;
          this.tableCommandeAchats[this.index].marge = (data.prixAchat-data.prixAchat)*data.quantite;
          this.idFournisseur = this.tableCommandeAchat.idFournisseur;

          // this.tableCommandeAchat = {};
          // this.tableCommandeAchat.idFournisseur = this.idFournisseur;
          // this.tableCommandeAchat.dateCommande = new Date()
          //   .toISOString()
          //   .split("T")[0];
          this.showButon = false;
          this.totalQantite();
          this.prepareCommandeDetail();
          //  this.tableCommandeAchat = {};
        }
  }

  findDepot(idDepot) {
    let rechercheDepot = this.Depots.find((x) => x.id == idDepot);
    // this.tableDepot = rechercheDepot;
    this.tableCommandeAchat.depot = rechercheDepot.designation;
    // this.idDepotVente=this.tableDepot.idDepotVente
  }

  updateArticle(data) {
    // console.log(data)
    if( data.hasOwnProperty('prixAchat')==false){
      data.prixAchat=0;
    }
    // console.log( this.tableCommandeAchats)
    // var index = this.tableCommandeAchats.findIndex((x) => x.idArticle == data.idArticle);
    // alert (index);
    if(data.idArticle && data.idDepot && data.quantite && data.prixAchat){
      this.tableCommandeAchats[this.index].idArticle        = data.idArticle;
      this.tableCommandeAchats[this.index].designation      = data.designation;
      this.tableCommandeAchats[this.index].idDepot          = data.idDepot;    
      this.tableCommandeAchats[this.index].depot            = data.depot;
      this.tableCommandeAchats[this.index].quantite         = data.quantite;
      this.tableCommandeAchats[this.index].prixAchat        = data.prixAchat;
      this.tableCommandeAchats[this.index].total            = data.prixAchat*data.quantite;
      this.tableCommandeAchats[this.index].marge            = (data.prixAchat-data.prixAchat)*data.quantite;
      this.idFournisseur                                    = this.tableCommandeAchat.idFournisseur;
      this.showButon = false;
      this.totalQantite();
      this.prepareCommandeDetail();
    }else{
      if(!data.idArticle ){
        this.messageService.add({severity:'warn', summary: 'انتباه', detail: 'السلعة'});
      }
      if(!data.idDepot ){
        this.messageService.add({severity:'warn', summary: 'انتباه', detail: 'المستودع'});
      }
      if(!data.quantite ){
        this.messageService.add({severity:'warn', summary: 'انتباه', detail: 'العدد'});
      }
      if(!data.prixAchat ){
        this.messageService.add({severity:'warn', summary: 'انتباه', detail: 'ثمن الشراء'});
      }
    }
    // console.log(this.tableCommandeAchats);
  }

  addArticleBarCode(data,colonne){
    
    // setTimeout(()=>{ // this will make the execution after the above boolean has changed
    //   this.BarCode.nativeElement.focus();
    // },0); 
    // let codeBarre="";
    // if (colonne=="codeBarre"){
      let recherche:any;
      if (colonne=="codeBarre"){
        recherche = this.Articles.find((x) => x.codeBarre == data);
      }else{
        recherche = this.Articles.find((x) => x.idArticle == data.idArticle);
      }

      data=recherche;
      data.total=recherche.prixAchat;
      data.marge=(recherche.prixAchat-recherche.prixAchat);
      data.idArticle=recherche.idArticle;
      // data.quantite=1;
      data.nbon=0;
      let exist = this.requetteCommandeAchats.find((x) => x.idArticle == data.idArticle);
      if(!exist){
        data.quantite=1;
        this.addArticle(data);
      }else{      
        var index = this.tableCommandeAchats.findIndex((x) => x.idArticle == data.idArticle);
        this.tableCommandeAchats[index].quantite=exist.quantite+1;
        this.tableCommandeAchats[index].total = recherche.prixAchat*this.tableCommandeAchats[index].quantite;
        this.totalQantite();
        this.prepareCommandeDetail();
        this.tableCommandeAchat = {};
        this.selectedArticle="";
      }
    // console.log(this.tableCommandeAchats); 
    this.showButon = false;
    // this.totalQantite();
    // this.prepareCommandeDetail();
    // this.tableCommandeAchat = {};
    this.BarCode="";
  }

  totalQantite() {
    let totalMontant = 0;
    let totalMarge = 0;
    let totalQte = 0;
    for (let data of this.tableCommandeAchats) {
      totalMontant += data.total;
      totalMarge += data.marge * 1;
      totalQte += data.quantite * 1;
    }
    this.totalQte = totalQte;
    this.totalMarge = totalMarge;
    this.totalMontant = totalMontant;
    // return this._decimalPipe.transform(total, '1.2-2');
    // return total.toFixed(2);
  }

  deleteCommande(idDetail,idArticle) {
    // alert(idDetail +'|' +idArticle);
    this.desablePrint=true;
    // alert(this.desablePrint);
    if (idDetail !=""){
      var index = this.tableCommandeAchats.findIndex(
        (x) => x.idArticle == idArticle
      );
    }else{
      var index = this.tableCommandeAchats.findIndex(
        (x) => x.idDetail == idDetail
      );      
    }

    // alert(index);
    this.tableCommandeAchats.splice(index, 1);
    this.totalQantite();
    this.prepareCommandeDetail(); 
    // this.tableCommandeAchat = {};
    // console.log(this.achat);
    // var index = this.achat.findIndex((x) => x.idArticle == idArticle);
    // alert(this.desablePrint);
  }
  prepareCommandeDetail() {
    
    this.commandeDetail = "";
    for (let data of this.tableCommandeAchats) {
      this.commandeDetail +=
        "('idCommande','" +        
        data.idDepot      + "','" +
        data.dateCommande + "','" +
        data.idCommande   + "','" +
        data.idArticle    + "','" +
        data.quantite     + "','" +
        data.prixAchat +
        "'),";
    }
    this.commandeDetail += "$";
    this.commandeDetail = this.commandeDetail.replace(",$", ";");
    this.initialiser();
    // console.log(this.commandeDetail);
    // alert(this.tableCommandeAchat.idFournisseur);
        //-----------------vers Variation du Stock ----------------//

        this.commandeDetailDepot = "";
        for (let data of this.tableCommandeAchats) {
          this.commandeDetailDepot +=
            "('idCommande','"         +
            this.Achat.dateCommandee  +  "','" +
            data.idDepot              +  "','" +
            data.idArticle            +  "','" +
            data.quantite             +  
            "','Avoir Achat'),";
        }
        this.commandeDetailDepot += "$";
        this.commandeDetailDepot = this.commandeDetailDepot.replace(",$", ";");
  }

  onRowSelect(event) {
    
    this.afficher= false;
    this.tableDetailAchatss = [];
    // if (!event.data.cloture){
      // this.AuthenticationService.currentUserValue.username= this.AuthenticationService.currentUserValue.username.toUpperCase();
      if (this.able  || this.AuthenticationService.currentUserValue.groupe =="Admin" ) {
        this.displayDialog = true;
        // console.log(event.data);
        this.artilcesFournisseur=[];
        this.artilceFournisseur={}; 
        this.Achat.id = event.data.idCommande;
        this.Achat.num = event.data.num;
        this.Achat.numm = event.data.numm;
        this.Achat.cloture = event.data.cloture;
        console.log(event.data);
        // this.Achat.nbreBon = event.data.nbreBon;
        // alert(this.Achat.nbreBon);
        this.Achat.dateCommandee = event.data.dateAvoir;

        // let vvv=JSON.stringify(event.data.idFournisseur);
        this.Achat.idFournisseur = JSON.stringify(event.data.idFournisseur);
        this.Achat.remise = event.data.remise;
        this.Achat.nom = event.data.nom;
        this.Achat.nbon = event.data.nbon;
        this.Achat.adresse = event.data.adresse;
        this.Achat.ville = event.data.ville;
        this.Achat.idUser = event.data.idUser;
        this.Achat.nomComplet = event.data.nomComplet;
        // this.Achat.cloture = event.data.cloture;
        this.Exclut = event.data.exclut;
        // alert (this.Achat.idFournisseur);
        // console.log(this.requetteCommandeAchats);
        let recherche = this.tableDetailAchats.filter((x) => x.id == event.data.idCommande);
        this.tableCommandeAchats = recherche;
        this.requetteCommandeAchats = recherche;
        // console.log(this.requetteCommandeAchats.lenght);
        this.nbre=this.tableCommandeAchats.length-1;
        if(this.tableCommandeAchats.length==1){
          this.higthCol='500px';
        }else if(this.tableCommandeAchats.length>1) {
          let hight=500-(this.tableCommandeAchats.length-1)*20;
          this.higthCol=hight + 'px';
        }
        this.totalQantite();
        this.prepareCommandeDetail();
      }
    // }requetteCommandeAchats
  }
  cloneDonnee(c: Achat): Achat {
    let Achat = {};
    for (let prop in c) {
      Achat[prop] = c[prop];
    }
    return Achat;
  }
  onRowSelectCommandeDetail(event) {
    // console.log(event.data);
    // this.setFocusArticle();
    this.rechercheArticle     = this.Articles.find((x) => x.id == event.data.idArticle);
    this.prixAchat            = this.rechercheArticle.prixAchat;
    this.designation          = this.rechercheArticle.designation;
    this.showButon            = true;
    this.showButonSauvgarder  = true;
    this.index                = this.tableCommandeAchats.findIndex(
      (x) => x.idArticle == event.data.idArticle
    );
    // // alert(this.index);
    this.newDonnee = false;
    this.tableCommandeAchat   = this.cloneDonneeCommandeDetail(event.data);
    this.displayDialog = true;
    // console.log( this.tableCommandeAchat);
  }
  cloneDonneeCommandeDetail(c: Achat): Achat {
    let tableCommandeAchat = {};
    for (let prop in c) {
      tableCommandeAchat[prop] = c[prop];
    }
    return tableCommandeAchat;
  }
  close() {
    // this.setFocusArticle();
    this.displayDialog = false;
    this.displayCharge = false;
    this.tableCommandeAchats = [];
    this.tableCommandeAchat = {};
    this.Charge={};
    this.Charges=[];
    this.requetteCommandeAchats = [];
    this.requetteCommandeAchat = {};
    this.Achat.id=null;
    this.Achat.idFournisseur="";
    this.Achat.dateCommandee = new Date()
    .toISOString()
    .split("T")[0];
    this.tableCommandeAchat.dateCommandee = new Date()
      .toISOString()
      .split("T")[0];
    this.totalQantite();
    this.prepareCommandeDetail();
    // this.Achat.nbon='0';
    this.Achat.remise=0;
    // setTimeout(() => this.inputEl.nativeElement.focus());
  }
  initialiser() {
    // this.desablePrint=false;
    this.tableCommandeAchat = {};
    this.requetteCommandeAchats = this.tableCommandeAchats;
    this.showButon=false;
    this.prixAchatGros=undefined;
    this.prixAchatDetail=undefined;
    this.prixAchat1=undefined;
    // this.tableCommandeAchat.idArticle = null;
    // this.tableCommandeAchat.quantite = "";
    // this.tableCommandeAchat.prixAchat = "";
    // this.tableCommandeAchat.prixAchat = "";
    // this.tableCommandeAchat.prixAchatDetail = "";
    // this.tableCommandeAchat.prixAchatGros = "";
    // this.showButon = false;
  }
}
