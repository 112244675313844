import { Component, OnInit } from "@angular/core";
import { DecimalPipe } from "@angular/common";
import { DataService } from "../../data.service";
import { Vente } from "../../model/vente";
import { Achat } from "../../model/achat";
import * as XLSX from "xlsx";
import * as FileSaver from 'file-saver';
import { FormBuilder, FormGroup, FormsModule } from "@angular/forms";
import { AuthenticationService } from "../../authentication.service";
import { Charge } from "../../model/charge";

@Component({
  selector: "app-situation-facture",
  templateUrl: "situation-facture.component.html",
  styleUrls: ["situation-facture.component.scss"],
  providers: [DecimalPipe],
})
export class SituationFactureComponent implements OnInit {
  registerForm: FormGroup;
  //   dateSituationn = Date.now();
  dateSituation: any;
  dateSituationDebut: any;
  dateSituationFin: any;
  total: number;
  totalServie: number;
  selectedVente: Vente[];
  Vente: Vente = {};
  Ventes: Vente[];
  Achat: Achat = {};
  Achats: Achat[];
  Charge: Charge = {};
  Charges: Charge[];
  tableArticle: Vente = {};
  tableArticles: Vente[];
  displayDialog: boolean;
  selectedOption: string;
  selectedType: number;
  margeTotal: number;
  totalAchat: number;
  modePaiement: string;
  able: boolean;
  nom: string;
  nomComplet: string;
  designation: string;
  nbon: number;
  numm: string;
  constructor(
    private data: DataService,
    public AuthenticationService: AuthenticationService,
    private _decimalPipe: DecimalPipe,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.dateSituation = new Date().toISOString().split("T")[0];
    this.dateSituationDebut = new Date().toISOString().split("T")[0];
    this.dateSituationFin = new Date().toISOString().split("T")[0];    
    this.vente();
    this.achatPrixMax();
    // this.charge();
    if(this.AuthenticationService.currentUserValue.groupe==('Administrateur' || 'Administrateurs' )){
      this.able=true;
    }
  }
  vente() {
    this.Vente.action = "SelectDetail";
    this.Vente.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .facture(this.Vente)
      .toPromise()
      .then(
        (x: Vente[]) => (
          (this.Ventes = x),
          (this.tableArticles = x),
          // console.log(x),
          this.filterCommande(this.dateSituationDebut, this.dateSituationFin)
          //   this.calculTotalService(),
          // this.filterTPE
        )
      );
  }
  filterCommande(dateDebut,dateFin){

    // console.log(dateDebut +" / "+ dateFin + " / " + this.nom + " / " + this.designation);

    let recherche = this.Ventes.filter((x) => 
     (x.dateCommande >= dateDebut && x.dateCommande <= dateFin) 
    //  && 
    //   x.nom.toLocaleLowerCase().includes(this.nom.toLocaleLowerCase()) &&
    //   x.designation.toLocaleLowerCase().includes(this.designation.toLocaleLowerCase()) &&
    //   // x.nbon.toString().includes(this.nbon) && 
    //   x.numm.toString().includes(this.numm)
      );
    this.tableArticles = recherche; 
    // console.log(this.tableArticles);
    this.calculTotal();
  }
  achatPrixMax() {
    this.Achat.action = "SelectMax";
    this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .achat(this.Achat)
      .toPromise()
      .then(
        (x: Achat[]) => (
          (this.Achats = x)
          // ,(console.log(x))
        )
      );
  }

  filter(data, colone) {
    // console.log(data+"+"+colone);
      let recherche;
      if (colone=="numm"){
        recherche = this.Ventes.filter((x) => x.numm.toString().includes(data));
      }else{
        recherche = this.Ventes.filter(
        (x) =>
          x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1
      ); 
      }
    
    this.tableArticles = recherche;
    this.calculTotal();
  }
findPrixMax(idArticle){
  let recherche = this.Achats.find((x) => x.idArticle == idArticle);
  if (recherche != undefined) {
  	return recherche.prixAchat;
  } else {
  	return '0';
  }
}
  calculTotal() {
    // if (this.tableArticles) {
    //   this.total = this.tableArticles
    //     .map((t) => t.prixVente * t.quantite)
    //     .reduce((a, value) => a + value, 0);
    // }
    // return 0;
    this.total = 0;
    this.total = this.tableArticles
      .map((t) => t.total * 1)
      .reduce((a, value) => a + value, 0);
    // this.remise = 0;
    // this.remise = this.tableArticles
    //   .map((t) => t.remise * 1)
    //   .reduce((a, value) => a + value, 0);
    this.margeTotal = 0;
    this.margeTotal = this.tableArticles
      .map((t) => t.marge * 1)
      .reduce((a, value) => a + value, 0);

    this.totalAchat = 0;
    this.totalAchat = this.tableArticles
      .map((t) => t.quantite * t.prixAchat )
      .reduce((a, value) => a + value, 0);

  }
  //   calculTotalService() {
  //     if (this.tableArticles) {
  //       this.totalServie = 0;
  //       this.totalServie = this.tableArticles
  //         .map((t) => t.prixService * t.quantite)
  //         .reduce((a, value) => a + value, 0);
  //       this.tableArticles.forEach((obj) => {
  //         if (obj.prixService < 1) {
  //           this.totalServie +=
  //             obj.prixService * obj.quantite * obj.prixVente -
  //             obj.prixService * obj.quantite;
  //         }
  //       });
  //     }
  //     return 0;
  //   }filterCommande
  
  transformDecimal(num) {
    return this._decimalPipe.transform(num, "1.2-2");
  }
  /*name of the excel-file which will be downloaded. */

  fileName = "Situation des ventes du  au .xlsx";

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById("excel-table");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    var title =
      "Situation du " +
      this.dateSituationDebut +
      " au " +
      this.dateSituationFin;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      ws,
      "du " + this.dateSituationDebut + " au " + this.dateSituationFin
    );

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }
  exportExcel() {
    alert('kk');
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.tableArticles);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "products");
    });
}

saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
}
  printSituation(): void {
    var params = [
      "height=" + screen.height,
      "width=" + screen.width,
      "fullscreen=yes", // only works in IE, but here for completeness
    ].join(",");
    let printContents, popupWin;
    printContents = document.getElementById("print-section").innerHTML;
    popupWin = window.open("", "_blank", params);
    popupWin.document.open();
    popupWin.document.write(`
			  <html>
				<head>
				  <title>Print tab</title>
				  <style>
				  table {
					// width: 750px;
					border-collapse: collapse;
					margin: 5px auto;
				}				
				/* Zebra striping */
				tr:nth-of-type(odd) {
					// background: #eee;
					
				}				
				th {
					background: #3498db;
					color: white;
					font-weight: bold;
					text-align: center;
				}				
				td,
				th {
					padding: 5px;
					// border: 1px solid #ccc;
					text-align: left;
					font-size: 12px;
				}
				  </style>
				</head>
			<body onload="window.print();window.close()" style="text-align:center;">${printContents}</body>
			  </html>`);
    popupWin.document.close();
  }
  onRowSelect(event) {}
}
