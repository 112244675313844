import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { sousType } from '../../model/sousType';
import { Type } from '../../model/Type';

@Component({
	selector: 'app-sous-types',
	templateUrl: 'sous-Types.component.html',
	styleUrls: [ 'sous-Types.component.scss' ]
})
export class SousTypesComponent implements OnInit {
	Type: Type = {};
	Types: Type[];
	selectedType: any;
	sousType: sousType = {};
	sousTypes: sousType[];
	selectedSousType: sousType;
	displayDialog: boolean;
	constructor(private data: DataService) {}

	ngOnInit() {
		this.getSousTypes();
		this.getTypes();
	}
	getSousTypes() {
		this.data.getSousTypes().toPromise().then((x: sousType[]) => (this.sousTypes = x));
	}
	getTypes() {
		this.data.getTypes().toPromise().then((x: Type[]) => (this.Types = x));
	}
	save() {
		if (this.sousType.id) {
			// alert("save");
			this.data.updateSousType(this.sousType).toPromise().then((res: { message: string }) => {
				if (res.message == 'Opération de modification réuissie') {
					alert(res.message);
					this.displayDialog = false;
					this.getSousTypes();
				} else {
					alert(res.message);
				}
			});
		} else {
			this.data.addSousType(this.sousType).toPromise().then((res: { message: string }) => {
				if (res.message == 'Opération de modification réuissie') {
					alert(res.message);
					this.displayDialog = false;
					this.getSousTypes();
				} else {
					alert(res.message);
					this.displayDialog = false;
				}
			});
		}
	}
	delete() {
		if (confirm('êtes vous sure?')) {
			this.data.deleteSousType(this.sousType).toPromise().then((res: { message: string }) => {
				if ((res.message = 'Opération de supression réuissie')) {
					alert(res.message);
					this.displayDialog = false;
					this.getSousTypes();
				} else {
					alert('Nonnnn');
				}
			});
		}
	}
	newDonnee: boolean;
	showDialogToAdd() {
		this.newDonnee = true;
		this.sousType = {};
		this.displayDialog = true;
	}
	onRowSelect(event) {
		console.log(event);
		this.sousType.idType = this.selectedType;
		this.newDonnee = false;
		this.sousType = this.cloneDonnee(event.data);
		this.displayDialog = true;
	}
	cloneDonnee(c: sousType): sousType {
		let Type = {};
		for (let prop in c) {
			Type[prop] = c[prop];
		}
		return Type;
	}
}
