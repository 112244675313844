import { Component, OnInit,ElementRef,ViewChild } from "@angular/core";
import { DataService } from "../../data.service";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Depot } from "../../model/depot";
import {Message,MessageService} from 'primeng/api';
import { Router } from '@angular/router';
import { AuthenticationService } from "src/app/authentication.service";
import { HostListener } from '@angular/core';
import { AutoComplete } from 'primeng/autocomplete';
import { DataBase } from "src/app/model/dataBase";
import { Article } from "src/app/model/article";
import { Vente } from "src/app/model/vente";
// import { ConfirmDialogModule } from "primeng/confirmdialog";
// import { ConfirmationService } from "primeng/api";
@Component({
  selector: "app-codeBarrePrint",
  templateUrl: "codeBarrePrint.component.html",
  styleUrls: ["codeBarrePrint.component.scss"],
  providers: [MessageService],
})
export class CodeBarrePrintComponent implements OnInit {
  @ViewChild('autoCompleteObject') private autoCompleteObject: AutoComplete ;  key:string;
  @ViewChild('inputQte', {static: false}) inputQte:ElementRef;
  setFocusQte(){
    setTimeout(() => this.inputQte.nativeElement.focus());
  }
  myUrl: string = `${environment.urlApi}`;
  imageUrl: string = this.myUrl + "/API/API/uploads/default.png";
  fileToUpload: File = null;
  numberColomn: any[];
  numberRow: any[];
  Donnees     : DataBase[];
  Donnee      : DataBase = {};
  tableCommandeVente: Vente = {};
  tableCommandeVentes: any = [];
  tableEtiquette: any = [];
  rechercheArticle: Article = {};
  rechercheArticles: Article[];
  dataTable   : string = "etiquette";
  DonneeId   : string ;
  designation: string='COIN AVEC PORTE TRANSPARENT SMART';
  prixVente: number;
  nbre: number=0;
  selectedDepot: Depot;
  displayDialog: boolean;
  showButonSauvgarder: boolean;
  dropdownArticle: boolean =false;
  showButon: boolean=false;
  idArticleTest: number;
  selectedOption: string;
  index: string;
  largeur: number;
  totalQte: number;
  widthPage: number;
  heightPage: number;
  EtiquetteV: number=13;
  EtiquetteH: number=5;
  tiquetteMarginR: number;
  tiquetteMarginB: number;
  tiquetteRadius: number;
  sizeDesignation: number=12;
  CodeBarreTaille: number=10;
  Xquantite: number;
  divWidth: number;
  divHeight: number;
  pagePaddingT: number;
  pagePaddingL: number;
  wPage: string;
  hPage: string;
  
  barreCodeLabelPaddingB: number;
  nbreRows: number;
  nbreColumns: number;
  hauteur: number;
  optionsBarreCode: any[];
  optionsBarreCodeLabelGras: any[];
  barreCodePriceSize: number;
  CodeBarreHeight: number;
  CodeBarrevWidth: number;
  barreCodePriceAlign: any;
  barreCode       : boolean =true;
  barreCodeLabel  : boolean =true;
  barreCodeLabelGras  : boolean =true;
  barreCodePrice  : boolean =true;
  barreCodePriceGras  : boolean =true;

  justifyOptions: any[];

  Article: Article = {};
  Articles: Article[];
  filteredArticles: any[];
  filteredCodeBare: any[];
  // numberColomns: any[];
  selectedFile = null;
  // fontOptions = '';
  // font = 'monospace';
  // textAlign = 'center';
  // textPosition = 'bottom';
  // textMargin = 5;
  // fontSize = 20;
  // background = '#ffffff';
  // margin = 0;
  // marginTop = 0;
  // marginBottom = 17;
  marginBottomPx = '17px';
  // marginLeft = 7;
  marginLeftPx = '7px';
  // marginRight = 13;
  marginRightPx = '13px';
  elementType = 'SVG';
  value = '6110085048829';
  format = 'CODE128';
  // format = 'EAN13';
  lineColor = '#000000';
  width = 1;
  height = 30;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 20;
  background = '#ffffff';
  margin = 0;
  marginTop = 0;
  marginBottom = 0;
  marginLeft = 0;
  marginRight = 0;

  codeList: string[] = [
    '', 'CODE128',
    'CODE128A', 'CODE128B', 'CODE128C',
    'UPC', 'EAN8', 'EAN5', 'EAN2',
    'CODE39',
    'ITF14',
    'MSI', 'MSI10', 'MSI11', 'MSI1010', 'MSI1110',
    'pharmacode',
    'codabar'
  ];
  
  constructor(
    private data: DataService, private http: HttpClient,
    private messageService: MessageService,
    public AuthenticationService: AuthenticationService,
    private router: Router) {
      this.justifyOptions = [
        {icon: 'pi pi-align-left', justify: 'Left'},
        {icon: 'pi pi-align-center', justify: 'Center'},
        {icon: 'pi pi-align-right', justify: 'Right'}
    ];
    this.optionsBarreCode = [{label: 'On', value: true},{label: 'Off', value: false}];
    this.optionsBarreCodeLabelGras = [{label: 'Normal', value: false}, {label: 'Gras', value: true}];}
  ngOnInit() {
    this.donnee();
    this.article();
    this.conteHorizontal(this.EtiquetteV);
    this.conteVertical(this.EtiquetteH);
    
  }

  get values(): string[] {
    return this.value.split('\n');
  }
 conteHorizontal(element){
    // A4	210 x 297 mm / largeur minimale Code Barre de 29.83 mm
    //1 Millimètre est égal à 3.7795 Pixel.   
    this.numberColomn=[];
    this.numberColomn=Array.apply(null, Array(element)).map(function (x, i) { return i; });
    // console.log(this.numberColomn);
    this.marginBottomPx=this.marginBottom +'px';
    this.marginRightPx=this.marginRight +'px';
    this.marginLeftPx=this.marginLeft +'px';
    this.largeur=160-(this.marginLeft*1)/3.77795*element*1;
    // largeur-
    // 7.2=>largeur+MarginCodeBarre+MarginPage
    this.widthPage=this.largeur/element/29;
    this.wPage=this.widthPage+"mm";
  }
  conteVertical(element){
    // A4	210 x 297 mm
    
    this.numberRow=[];
    this.numberRow=Array.apply(null, Array(element)).map(function (x, i) { return i; });
    this.heightPage=420/element;
    this.hPage=this.heightPage+"mm";
  }
  numberColomns(n: number): Array<number> {
    return Array(n);
  }

  getCodeBarre(v,h) {
    // this.tableCommandeVentes.forEach((row,index)=>{
    //   return row.codeBarre;
    // });
    // console.log(v);
    // console.log(h);
  }
  generate(){
    if(this.EtiquetteH && this.EtiquetteV){
      // alert(document.getElementById("table").rows[0].cells[0].innerHTML);
      // let text = document.getElementById("table").rows[0].cells[0].innerHTML;
      // let result = text.replace("6194013860912", "6110085000155");
      // console.log(result);
      // document.getElementById("table").rows[0].cells[1].innerHTML=result;
      // alert(document.getElementById("table").rows[0].cells[0].innerHTML);
      // var table = document.getElementById('className');
      // for (var i = 0, row; row = table.rows[i]; i++) {
      // //rows would be accessed using the "row" variable assigned in the for loop
      //  for (var j = 0, col; col = row.cells[j]; j++) {
      //  //columns would be accessed using the "col" variable assigned in the for loop
      //     alert('col html>>'+col.innerHTML);    //Will give you the html content of the td
      //     alert('col>>'+col.innerText);    //Will give you the td value
      //     }
      //   }
      // console.log(this.tableEthiquette);
      var tbody= document.getElementById("tbody");
      // for (var j=0 ; j<this.tableCommandeVentes.length; j++){
        var tr="";
        var nbre=0;
        for(var t=0 ; t<this.EtiquetteV-1; t++){
          tr+="<tr>";
          for(var i=0 ; i<this.EtiquetteH-1; i++){
            tr+=
            "<td>"+
            "<ngx-barcode [bc-element-type]='elementType' [bc-value]='"
            +this.tableEtiquette[nbre].codeBarre
            +"' bc-format='EAN13' [bc-line-color]='lineColor' [bc-width]='widthPage' [bc-height]='heightPage' [bc-display-value]='true' [bc-font-options]='fontOptions'[bc-font]='font'  [bc-text-align]='textAlign' [bc-text-position]='textPosition' [bc-text-margin]='textMargin' [bc-font-size]='fontSize' [bc-background]='background' [bc-margin]='0' [bc-margin-top]='0' [bc-margin-bottom]='0' [bc-margin-left]='0' [bc-margin-right]='0' ></ngx-barcode>"
            +"</td>";
            // console.log(nbre);
            nbre++;
            if (nbre >  this.totalQte-1) { 
            //  tr+="</tr>";
              break; 
            }
          }
          tr+="</tr>";
            if (nbre >  this.totalQte-1) { break; };
          i=0; 
          // console.log(tr);
            // console.log(nbre);
          // nbre+=nbre+1; 
        }
        tbody.innerHTML+=tr;
        // console.log(tr);
      // }
    }

    // this.tableCommandeVentes.forEach((row,index)=>{
    //   for (let i = 0; i < row.quantite; i++) {
    //     this.tableEthiquette.push(row);
    //   }      
    // });
  }
  divMesur(){

  }
  addElement(id){
    console.log(id);
    let rechercheDepot = this.Donnees.find((x) => x.id == id);
    if(rechercheDepot){
      this.nbreColumns=rechercheDepot.nbreColumns;
      this.nbreRows=rechercheDepot.nbreRows;
      this.tiquetteMarginB=rechercheDepot.tiquetteMarginB;
      this.tiquetteMarginR=rechercheDepot.tiquetteMarginR;
      this.CodeBarreTaille=rechercheDepot.CodeBarreTaille;
      this.sizeDesignation=rechercheDepot.sizeDesignation;
      this.divHeight=rechercheDepot.divHeight;
      this.divWidth=rechercheDepot.divWidth;
      this.pagePaddingT=rechercheDepot.pageMarginT;
      this.pagePaddingL=rechercheDepot.pageMarginL;
      this.barreCodePriceSize=rechercheDepot.barreCodePriceSize;
      this.barreCode=rechercheDepot.barreCode;
      this.barreCodeLabel=rechercheDepot.barreCodeLabel;
      this.barreCodeLabelGras=rechercheDepot.barreCodeLabelGras;
      this.barreCodePrice=rechercheDepot.barreCodePrice;
      this.barreCodePriceGras=rechercheDepot.barreCodePriceGras;
      // if(rechercheDepot.barreCode==1){
      //   this.barreCode=true;
      // }else{
      //   this.barreCode=false;
      // }
      // if(rechercheDepot.barreCodeLabel==1){
      //   this.barreCodeLabel=true;
      // }else{
      //   this.barreCodeLabel=false;
      // }
      // if(rechercheDepot.barreCodeLabelGras==1){
      //   this.barreCodeLabelGras=true;
      // }else{
      //   this.barreCodeLabelGras=false;
      // }
      // if(rechercheDepot.barreCodePrice==1){
      //   this.barreCodePrice=true;
      // }else{
      //   this.barreCodePrice=false;
      // }
      // if(rechercheDepot.barreCodePriceGras==1){
      //   this.barreCodePriceGras=true;
      // }else{
      //   this.barreCodePriceGras=false;
      // }
      console.log(this.barreCodePrice);
      this.CodeBarrevWidth=rechercheDepot.CodeBarrevWidth;
      this.CodeBarreHeight=rechercheDepot.CodeBarreHeight;
      this.barreCodePriceAlign = this.justifyOptions.find((x) => x.justify == rechercheDepot.barreCodePriceAlign);

      this.EtiquetteH=rechercheDepot.nbreColumns;
      this.EtiquetteV=rechercheDepot.nbreRows;
      this.tiquetteMarginB=rechercheDepot.tiquetteMarginB;
      this.tiquetteMarginR=rechercheDepot.tiquetteMarginR;
      this.CodeBarreTaille=rechercheDepot.CodeBarreTaille;
      this.sizeDesignation=rechercheDepot.sizeDesignation;
      this.divHeight=rechercheDepot.divHeight;
      this.divWidth=rechercheDepot.divWidth;
      this.pagePaddingT=rechercheDepot.pageMarginT;
      this.pagePaddingL=rechercheDepot.pageMarginL;
      this.marginTop=rechercheDepot.tiquetteMarginT;
      this.marginLeft=rechercheDepot.tiquetteMarginL;
      // this.numberRow=this.numberColomns(rechercheDepot.nbreColumns);
      // this.numberColomn=this.numberColomns(rechercheDepot.nbreRows);
      this.numberRow=Array.apply(null, Array(rechercheDepot.nbreRows)).map(function (x, i) { return i; });
      this.numberColomn=Array.apply(null, Array(rechercheDepot.nbreColumns)).map(function (x, i) { return i; });
      console.log(this.numberColomn);
      this.conteHorizontal(rechercheDepot.nbreColumns);
      this.conteVertical(rechercheDepot.nbreRows);
      this.autoCompleteObject.focusInput();
    }
  }

  PrintElem(div){
    var mywindow = window.open('', 'PRINT', 'height=800,width=1200');

    mywindow.document.write('<html><head><title>' + document.title  + '</title>');    
    mywindow.document.write('<link rel="stylesheet" href="css/midday_receipt.css" type="text/css" />');
    mywindow.document.write('</head><body >');
    mywindow.document.write('<div style="display: flex;flex-wrap: wrap;width: 210mm !important; margin-top:'+this.pagePaddingT+'mm !important;margin-left: '+this.pagePaddingL+'mm !important; ">');
    mywindow.document.write(document.getElementById(div).innerHTML);
    mywindow.document.write('</div></body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    // setTimeout(function(){mywindow.print();},3000);
    mywindow.print();
    mywindow.close();  
    // return true;
}
  
  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('tbody').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=210mm');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          // You need to insert the stylesheet to the print function
          <link rel="stylesheet" href="correct href to your stylesheet"> 
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
}
  filterArticle(event) {
    let filtered : any[] = [];
    let query = event.query;
    // console.log(this.Articles);
    filtered = this.Articles.filter((x) => x.designation.toString().toLocaleLowerCase().includes(query.toLocaleLowerCase()));   
    this.filteredArticles = filtered;
  }
  filterCodeBare(event) {
    let filtered : any[] = [];
    let query = event.query;
    filtered = this.Articles.filter((x) => x.codeBarre.toString().toLocaleLowerCase().includes(query.toLocaleLowerCase()));   
    this.filteredCodeBare = filtered;
  }
  findPrix(idArticle,colone) { 
  if (idArticle!=undefined || this.tableCommandeVente.autocompleteCodeBarre!=undefined){
    this.dropdownArticle=false;

    if(typeof idArticle =="string" && colone=="codeBarre-"){
      idArticle=this.tableCommandeVente.autocomplete;
      colone="codeBarre";        
    }else{    
      colone="codeBarre-";
    }
    
    if (idArticle && this.idArticleTest!=idArticle){
      if (this.idArticleTest!=idArticle){
        this.idArticleTest=idArticle;
        this.setFocusQte();
      }
    }
      this.tableCommandeVente.stock             = 0;
      this.tableCommandeVente.designation       = idArticle.designation;
      this.tableCommandeVente.prixVente         = idArticle.prixVente;
      this.tableCommandeVente.idArticle         = idArticle.id;

    if(colone=="codeBarre"){
      this.rechercheArticle = this.Articles.find((x) => x.codeBarre == idArticle);
      this.tableCommandeVente.autocomplete = this.rechercheArticle; 
    }else if(colone=="designation"){
      this.rechercheArticle = this.Articles.find((x) => x.designation == idArticle.designation);
      this.tableCommandeVente.autocomplete = idArticle; 
    }else {
      this.rechercheArticle = this.Articles.find((x) => x.id === idArticle.idArticle*1);
      this.tableCommandeVente.autocomplete = idArticle; 
    }
      // this.tableCommandeVente.idArticle         = this.rechercheArticle.idArticle;               
      // this.tableCommandeVente.designation       = this.rechercheArticle.designation;
    }
}
  addArticle(data) {
    // console.log(data);
    if(data.idArticle && data.quantite){
      if(this.showButon==false){
        if((data.quantite*1+this.totalQte*1)>(this.EtiquetteH*1*this.EtiquetteV*1)){
          this.messageService.add({severity:'warn', summary: 'Impression', detail: "Nombre d'etiquette est supperieur Etiquette => " + this.EtiquetteH*1*this.EtiquetteV*1});
        }else{
          if(this.tableCommandeVente.autocomplete){
            data.codeBarre=this.tableCommandeVente.autocomplete.codeBarre;
            data.reference=this.tableCommandeVente.autocomplete.reference;
          }
          if (this.tableCommandeVente.idArticle || data.codeBarre || data.idArticle ) {
            this.tableCommandeVentes.push(data);
            this.showButon = false;
          } else {
            this.messageService.add({severity:'warn', summary: 'Impression', detail: "Entrer un Article !"});
          }
          // this.desablePrint=true;
          // this.setFocusArticle();
          this.totalQantite();          
        }
      }else{
        // alert(this.totalQte*1-this.Xquantite*1+this.tableCommandeVente.quantite*1);
          if((this.totalQte*1-this.Xquantite*1+this.tableCommandeVente.quantite*1)>(this.EtiquetteH*1*this.EtiquetteV*1)){
            this.messageService.add({severity:'warn', summary: 'Impression', detail: "Nombre d'etiquette est supperieur Etiquette => " + this.EtiquetteH*1*this.EtiquetteV*1});
          }else{
            this.tableCommandeVentes[this.index].idArticle        = data.idArticle;
            this.tableCommandeVentes[this.index].designation      = data.designation;
            this.tableCommandeVentes[this.index].quantite         = data.quantite;
            this.showButon = false;
            this.totalQantite(); 
          }
      }

    }else{
      this.messageService.add({severity:'warn', summary: 'Impression', detail: "Article ou Quantité ?"});
    }
  }
  totalQantite() {
    let totalQte = 0;
    for (let data of this.tableCommandeVentes) {
      totalQte += data.quantite * 1;
    }
    this.totalQte = totalQte;
    this.tableEtiquette=[];
    this.tableCommandeVentes.forEach((row,index)=>{
      for (let i = 0; i < row.quantite; i++) {
        this.tableEtiquette.push(row);
      }
    });
    this.initialiser();
  }
  onRowSelectCommandeDetail(event) {
    this.rechercheArticle = this.Articles.find((x) => x.id == event.data.idArticle);
    this.prixVente                           = this.rechercheArticle.prixVente;
    this.designation                          = this.rechercheArticle.designation;
    this.showButon = true;
    this.showButonSauvgarder = true;
    this.dropdownArticle=false;
    this.index = this.tableCommandeVentes.findIndex(
      (x) => x.idArticle == event.data.idArticle
    );
    this.newDonnee = false;
    this.tableCommandeVente = this.cloneDonneeCommandeDetail(event.data);
    this.tableCommandeVente.autocompleteCodeBarre=event.data;
    this.tableCommandeVente.autocomplete=event.data;
    this.Xquantite=event.data.quantite;
  } 
  cloneDonneeCommandeDetail(c: Vente): Vente {
    let tableCommandeVente = {};
    for (let prop in c) {
      tableCommandeVente[prop] = c[prop];
    }
    return tableCommandeVente;
  }
  donnee() {
    this.Donnee.action = "SelectA4";
    this.Donnee.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .donnee(this.dataTable,this.Donnee)
      .toPromise()
      .then((x: DataBase[]) => (this.Donnees = x));
  }
  article() {
    this.Article.action = "Select";
    this.Article.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .article(this.Article)
      .toPromise()
      .then((x: Article[]) => (
        this.Articles = x,
        this.filteredArticles = x,
        this.generateArticle()
        ));
  }
  generateArticle(){
    // this.Articles.forEach((row,index)=>{
    //   if(!row.codeBarre) {this.Articles[index].codeBarre=""};
    //   if(!row.reference) {this.Articles[index].reference=""};
    // });
  }
  showConfirm() {
      // this.messageService.clear();
      this.messageService.add({key: 'c', sticky: true, severity:'warn', summary:'Vous ete sur de suprimmer?', detail:'Confirmation'});
  }
  clear() {
      this.messageService.clear();
  }
  onConfirm() {
      this.delete();
    this.messageService.clear('c');
  }

  onReject() {
      this.messageService.clear('c');
  }
  delete() {
    // if (confirm("êtes vous sure?")) {
      this.Donnee.action = "Delete";
      this.Donnee.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.data
        .donnee(this.dataTable,this.Donnee)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
            this.displayDialog = false;
            this.donnee();
          } else {
            alert("Nonnnn");
          }
        });
    // }
  }
  actionLink(lien) {
    this.router.navigate(["/"+lien]);
  }
  close() {
    this.displayDialog = false;
    this.Donnee = {};
  }
  newDonnee: boolean;
  showDialogToAdd() {
    this.newDonnee = true;
    this.imageUrl = this.myUrl + "/API/API/uploads/default.png";
    this.Donnee = {};
    this.displayDialog = true;
  }
  onRowSelect(event) {
    this.displayDialog = true;
    this.newDonnee = false;
    this.EtiquetteH=event.data.nbreColumns;
    this.EtiquetteV=event.data.nbreRows;
    this.Donnee = this.cloneDonnee(event.data);
  }
  cloneDonnee(c: DataBase): DataBase {
    let Depot = {};
    for (let prop in c) {
      Depot[prop] = c[prop];
    }
    return Depot;
  }
  deleteCommande(idDetail,idArticle) {
    if (idDetail !=undefined){
      var index = this.tableCommandeVentes.findIndex(
        (x) => x.idDetail == idDetail 
      );
    }else{
     var index = this.tableCommandeVentes.findIndex(
        (x) => x.idArticle == idArticle
      );      
    }
    this.tableCommandeVentes.splice(index, 1);
    this.totalQantite();
  }

  initialiser() {
    this.tableCommandeVente = {};
    this.Donnee = {};
    // this.Donnee.autocomplete = '';
    this.showButon=false;
    this.prixVente=undefined;
    this.tableCommandeVente.idDepot=1;
    this.autoCompleteObject.focusInput();
  }
}
