import { Component, OnInit, ElementRef, ViewChild,  ViewEncapsulation } from "@angular/core";
import { DataService } from "../../data.service";
import { AuthenticationService } from "../../authentication.service";
import { environment } from "../../../environments/environment";
import { Article } from "../../model/article";
import { Vente } from "../../model/vente";
import { DecimalPipe } from "@angular/common";
import * as XLSX from "xlsx";
import { Client } from "../../model/client";
import { Achat } from "../../model/achat";
import {Message,MessageService,ConfirmationService} from 'primeng/api';
// import {CheckboxModule} from 'primeng/checkbox';
@Component({
  selector: "app-devis",
  templateUrl: "devis.component.html",
  styleUrls: ["devis.component.scss"],
  providers: [DecimalPipe,MessageService,ConfirmationService],
})
export class DevisComponent implements OnInit {
  msgs1: Message[];    
  msgs2: Message[];
  prixVenteGros: number;
  prixVenteDetail: number;
  prixVente1: number;
  Exclut:string;  
  idArticle: number;
  idArticleTest: number;
  Article: Article = {};
  Articles: Article[];
  filteredArticles: any[];
  stock: Article[];
  Achat: Achat = {};
  Achats: Achat[];
  rechercheArticle: Article = {};
  rechercheArticles: Article[];
  Vente: Vente = {};
  Ventes: Vente[];
  selectedVente: Vente;
  tableVentes: Vente[];
  tableDetailVentes: Vente[];
  selectedCommandeDetail: Vente;
  tableCommandeVente: Vente = {};
  tableCommandeVentes: any = [];
  requetteCommandeVente: Vente = {};
  requetteCommandeVentes: any = [];
  Client: Client = {};
  Clients: Client[];
  displayDialog: boolean;
  showButon: boolean;
  showButonSauvgarder: boolean;
  dropdownArticle: boolean;
  blockedDocument: boolean;
  designation: string;
  totalMontant: number;
  totalMarge: number;
  totalQte: number;
  quantiteVenteGros: number;
  quantiteVenteDetail: number;
  total: number;
  remise: number;
  margeTotal: number;
  idClient: number;
  index: string;
  commandeDetail: string;
  devisDetail: string;
  dateDebut: any;
  dateFin: any;
  dateSituationDebut: string;
  dateSituationFin: string;
  msgs: Message[] = [];
  position: string;
  myUrl: string = `${environment.urlApi}`;
  t:any;
  t2:any;
  t3:any;
  nbre2Lettre: string;
  higthCol:string;
  nbre:number;
  able: boolean =false;
  constructor(
    private data: DataService,
    private _decimalPipe: DecimalPipe,
    public AuthenticationService: AuthenticationService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}
  addSingle() {
    this.messageService.add({severity:'success', summary:'Service Message', detail:'Via MessageService'});
}

  ngOnInit() {
    if(this.AuthenticationService.currentUserValue.groupe=='Administrateur' || this.AuthenticationService.currentUserValue.groupe=='Administrateurs' ){
      this.able=true;
    }
    this.t=["","Un","Deux","Trois","Quatre","Cinq","Six","Sept","Huit","Neuf"];
    this.t2=["Dix","Onze","Douze","Treize","Quatorze","Quinze","Seize","Dix-sept","Dix-Huit","Dix-Neuf"];
    this.t3=["","","Vingt","Trente","Quarante","Cinquante","Soixante","Soixante","Quatre-Vingt","Quatre-Vingt"];
    this.msgs1 = [
      {severity:'success', summary:'Success', detail:'Message Content'},
      {severity:'info', summary:'Info', detail:'Message Content'},
      {severity:'warn', summary:'Warning', detail:'Message Content'},
      {severity:'error', summary:'Error', detail:'Message Content'}
     ];
     this.dateSituationDebut = new Date().toISOString().split("T")[0];
     this.dateSituationFin = new Date().toISOString().split("T")[0];
    this.article();
    this.getArticles();
    this.achat();
    this.client();
    this.vente();
    this.tableCommandeVente.dateCommandee = new Date()
      .toISOString()
      .split("T")[0];
  }
  getArticles() {
		this.Article.action = 'Select';
		this.Article.DBase  = this.AuthenticationService.currentUserValue.DBase;
		this.data.stock(this.Article).toPromise().then((x: Article[]) => (this.stock = x));
	}

//   addMessages() {
//     this.msgs2 = [
//         {severity:'success', summary:'Success', detail:'Message Content'},
//         {severity:'info', summary:'Info', detail:'Message Content'},
//         {severity:'warn', summary:'Warning', detail:'Message Content'},
//         {severity:'error', summary:'Error', detail:'Message Content'}
//     ];
// }
// showSuccess() {
//   this.messageService.add({severity:'success', summary: 'Success', detail: 'Message Content'});
// }
// clearMessages() {
//     this.msgs2 = [];
// }

// showViaService() {
//     this.messageService.add({severity:'success', summary:'Service Message', detail:'Via MessageService'});
// }
  article() {
    this.Article.action = "Select";
		this.Article.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .article(this.Article)
      .toPromise()
      .then((x: Article[]) => (this.Articles = x,this.filteredArticles = x));
  }
  filterArticle(event) {
    let filtered : any[] = [];
    let query = event.query;
    // console.log(this.Articles);
    filtered = this.Articles.filter((x) => x.designation.toString().toLocaleLowerCase().includes(query.toLocaleLowerCase()));   
    this.filteredArticles = filtered;
  }
  achat() {
    this.Achat.action = "Select";
		this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .achat(this.Achat)
      .toPromise()
      .then((x: Achat[]) => (this.Achats = x));
  }
  onEnter() {}
  rechercheDesignation(idArticle) {
    // this.rechercheArticle = this.Achats.find((x) => x.idArticle === idArticle);
    // this.tableCommandeVente.designation = this.rechercheArticle.designation;
  }
  setDropdownArticle(){
    // alert('kkk');
    this.dropdownArticle=true;
    this.setFocusDesignation();
  }
  
  findPrix(idArticle,colone) {
    if (idArticle!=undefined){
      this.dropdownArticle=false;
      if (this.idArticleTest!=idArticle){
        this.idArticleTest=idArticle;
        this.setFocusQte();
      }
      // console.log(idArticle);
      if(colone=="codeBarre"){
        this.rechercheArticle = this.stock.find((x) => x.codeBarre == idArticle);
      }else if(colone=="designation"){
        this.rechercheArticle = this.stock.find((x) => x.designation == idArticle.designation);
      }else {
        this.rechercheArticle = this.stock.find((x) => x.id === idArticle.idArticle);
      }
        this.tableCommandeVente.idArticle         = this.rechercheArticle.id;
        let rechercheArticleStock = this.stock.filter((x) => x.id == this.rechercheArticle.id);
        
        // console.log(this.rechercheArticle);
        // let rechercheArticle = this.stock.find((x) => x.id == idArticle);
        // console.log(rechercheArticle);
        
        // this.tableCommandeVente.stock             = rechercheArticle.stockTotal;
        this.tableCommandeVente.designation       = this.rechercheArticle.designation;
        this.tableCommandeVente.prixAchat         = this.rechercheArticle.prixAchat;
        this.tableCommandeVente.prixVenteGros     = this.rechercheArticle.prixVenteGros;
        this.tableCommandeVente.prixVenteDetail   = this.rechercheArticle.prixVenteDetail;
        this.tableCommandeVente.prixVente         = this.rechercheArticle.prixVente;
        this.prixVenteGros                        = this.rechercheArticle.prixVenteGros;
        this.prixVenteDetail                      = this.rechercheArticle.prixVenteDetail;
        this.quantiteVenteGros                    = this.rechercheArticle.quantiteVenteGros;
        this.quantiteVenteDetail                  = this.rechercheArticle.quantiteVenteDetail;
        this.prixVente1                           = this.rechercheArticle.prixVente;
        // this.prixAchat                            = rechercheArticle.prixAchat;
      // console.log(this.tableCommandeVente);
        // let recherche =this.tableCommandeVentes.find((x) => x.idArticle == idArticle);
        // this.requetteCommandeVentes = this.tableCommandeVentes.filter((x) => x.idArticle == idArticle);
        // console.log(recherche);
        // alert("1");
        // alert(this.Exclut);
    
        // if(this.Exclut !='o'){
        //     this.tableCommandeVente.quantite          = recherche.quantite;
        //     this.tableCommandeVente.prixVente         = recherche.prixVente;
        //     // this.showButon=true;
             
        // }else{
          
        //   // this.showButon=false;
        //   this.Vente.quantite=0;
        //   this.Vente.prixVente=0;
        // }
        // alert("2");
        // let idArtic=this.artilcesClient.filter((x) => x.idArticle == idArticle);
        
        // if(typeof idArtic !== 'undefined' && idArtic.length > 0){
        //   let idPrixVentX = Math.max.apply(Math, idArtic.map(function(o) { return o.id; }));
        //   this.artilceClient = idArtic.find((x) => x.id == idPrixVentX); 
        //   this.venteX = this.artilceClient.prixVente;     
        // }else{
        //   this.venteX = 0;
        // } 
        // console.log(idArtic);
        // if(idArtic!= undefined){
          
        //   let idPrixVentX = Math.max.apply(Math, idArtic.map(function(o) { return o.id; }));
        //   // alert(idPrixVentX);
        //   this.artilceClient = idArtic.find((x) => x.id == idPrixVentX);      
        // }
      }
  }

  // findPrix(idArticle) {

  //   if (idArticle && this.idArticleTest!=idArticle){
  //     this.idArticleTest=idArticle;
  //     this.setFocusQte();
  //   }
  //   let rechercheArticle = this.stock.find((x) => x.id == idArticle);
  //   this.tableCommandeVente.idArticle         = rechercheArticle.idArticle;
  //   this.tableCommandeVente.stock             = rechercheArticle.stockTotal;
  //   this.tableCommandeVente.designation       = rechercheArticle.designation;
  //   this.tableCommandeVente.prixAchat         = rechercheArticle.prixAchat;
  //   this.tableCommandeVente.prixVente       = this.rechercheArticle.prixVente;
  //   this.prixVenteGros                        = rechercheArticle.prixVenteGros;
  //   this.prixVenteDetail                      = rechercheArticle.prixVenteDetail;
  //   this.quantiteVenteGros                    = rechercheArticle.quantiteVenteGros;
  //   this.quantiteVenteDetail                  = rechercheArticle.quantiteVenteDetail;
  //   this.prixVente1                           = rechercheArticle.prixVente;

  //   // this.prixVente100                         = rechercheArticle.prixVente100;
  //   // this.prixVente10                          = rechercheArticle.prixVente10;
  //   // this.prixVente1                           = rechercheArticle.prixVente;

  //   let recherche =this.tableCommandeVentes.find((x) => x.idArticle == idArticle);
  //   this.requetteCommandeVentes = this.tableCommandeVentes.filter((x) => x.idArticle == idArticle);

  // }
  testPrix(qte){
    var Qte = parseInt(qte);
    // if(Qte>=100){
    //   this.tableCommandeVente.prixVente=this.prixVenteGros;
    // }else if(Qte>=10){
    //   this.tableCommandeVente.prixVente=this.prixVenteDetail;
    // }else{
    //   this.tableCommandeVente.prixVente=this.prixVente1;
    // }
    this.tableCommandeVente.prixVente=this.prixVente1;
  }
  @ViewChild('inputDesignation', {static: false}) inputDesignation:ElementRef;
  setFocusDesignation(){
    setTimeout(() => this.inputDesignation.nativeElement.focus());
  }
  
  @ViewChild('inputQte', {static: false}) inputQte:ElementRef;
  setFocusQte(){
    setTimeout(() => this.inputQte.nativeElement.focus());
  }
  client() {
    this.Client.action = "Select";
		this.Client.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .client(this.Client)
      .toPromise()
      .then((x: Client[]) => (this.Clients = x));
  }
  vente() {
    this.Vente.action = "Select";
		this.Vente.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .devis(this.Vente)
      .toPromise()
      .then(
        (x: Vente[]) => (
          (this.Ventes = x), 
          (this.tableVentes = x), 
          // console.log(x),
          this.venteDetail()          
        )
      );
  }
  venteDetail() {
    this.Vente.action = "SelectDetail";
		this.Vente.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .devis(this.Vente)
      .toPromise()
      .then((x: Vente[]) => (this.tableDetailVentes = x,this.recherhceMarge()));
  }
  cloture(idCommande,cloture) {
    // alert(idCommande+" | "+cloture);
    let etat;
    if (cloture==0){
      etat=1;
    }else{
      etat=0;
    }
      this.blockedDocument=true;
      this.tableCommandeVente.action = "UpdateCloure";
      this.tableCommandeVente.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.tableCommandeVente.etat = etat;
      this.tableCommandeVente.id = idCommande;
      this.data
        .devis(this.tableCommandeVente)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            // alert(res.message);
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.blockedDocument=false;
            this.displayDialog = false;
            this.vente();
            this.venteDetail();
          } else {
            alert(res.message);
          }
        });
  }

  transformDecimal(num) {
    return this._decimalPipe.transform(num, "1.2-2");
  }
  recherhceMarge(){
        this.tableVentes.forEach((row)=>{
        let recherche = this.tableDetailVentes.filter((x) => x.idCommande == row.idCommande);

        let updateItem = this.tableVentes.find(item=>item.idCommande==row.idCommande);
        let index = this.tableVentes.indexOf(updateItem);
        let marge =  recherche
          .map((t) => t.marge) 
          .reduce((a, value) => a + value, 0);
        this.tableVentes[index].marge = marge;
      }); 
      // console.log(this.tableVentes);
      this.totalCommande();
  }
  saveCommandeVente() {
    this.tableCommandeVente.action = "Insert";
		this.tableCommandeVente.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.tableCommandeVente.devisDetail = this.commandeDetail;
    this.data
      .devis(this.tableCommandeVente)
      .toPromise()
      .then((res: { message: string }) => {
        if (res.message == "Opération de modification réuissie") {
          this.vente();
          alert(res.message);
        } else {
          alert(res.message);
        }
      });
  }
  //-------Debut NbreToLettre ----------------------
 
nombreToLettre(n) {
  
  // n = n.toString();
  // n=n*1.2;
  // vérification de la valeur saisie
  if(!/^\d+[.,]?\d*$/.test(n)){
    this.nbre2Lettre = "L'expression entrée n'est pas un nombre."
  }
 
  // séparation entier + décimales
  n=n.replace(/(^0+)|(\.0+$)/g,"");
  n=n.replace(/([.,]\d{2})\d+/,"$1");
  let n1=n.replace(/[,.]\d*/,"");
  let n2= n1!=n ? n.replace(/\d*[,.]/,"") : false;
  
  // variables de mise en forme
  let ent= !n1 ? "" : this.decint(n1);
  let deci= !n2 ? "" : this.decint(n2);
   
 
  if(!n1 && !n2){
    this.nbre2Lettre =  "Zéro Dirham. (Mais, de préférence, entrez une valeur non nulle!)"
  }
 
  var conj= !n2 || !n1 ? "" : "  et ";
  var euro= !n1 ? "" : !/[23456789]00$/.test(n1) ? " Dirham" : "s Dirham";
  var centi= !n2 ? "" : " Centime";
  var pl=  n1>1 ? "s" : "";
  var pl2= n2>1 ? "s" : "";
 
  // expression complète en toutes lettres
  this.nbre2Lettre = (ent + euro + pl + conj + deci + centi + pl2).replace(/\s+/g," ").replace("Cents E","CSents E");
}
// traitement des deux parties du nombre;
decint(n){
  switch(n.length){
      case 1 : return this.dix(n);
      case 2 : return this.dix(n);
      case 3 : return this.cent(n.charAt(0)) + " " + this.decint(n.substring(1));
      default: var mil=n.substring(0,n.length-3);
          if(mil.length<4){
              var un= (mil==1) ? "" : this.decint(mil);
              return un + this.mille(mil)+ " " + this.decint(n.substring(mil.length));
          }
          else{    
              var mil2=mil.substring(0,mil.length-3);
              return this.decint(mil2) + this.million(mil2) + " " + this.decint(n.substring(mil2.length));
          }
  }
}
// traitement des nombres entre 0 et 99, pour chaque tranche de 3 chiffres;
dix(n){
 
  if(n<10){
      return this.t[parseInt(n)]
  }
  else if(n>9 && n<20){
      return this.t2[n.charAt(1)]
  }
  else {
      var plus= n.charAt(1)==0 && n.charAt(0)!=7 && n.charAt(0)!=9 ? "" : (n.charAt(1)==1 && n.charAt(0)<8) ? " et " : "-";
      var diz= n.charAt(0)==7 || n.charAt(0)==9 ? this.t2[n.charAt(1)] : this.t[n.charAt(1)];
      var s= n==80 ? "s" : "";

      return this.t3[n.charAt(0)] + s + plus + diz;
  }
}
// traitement des mots "cent", "mille" et "million"
cent(n){
  return n>1 ? this.t[n]+ " Cent" : (n==1) ? " Cent" : "";
  }
   
mille(n){
  return n>=1 ? " Mille" : "";
  }
   
million(n){
  return n>=1 ? " Millions" : " Million";
  }
//-------Fin NbreToLettre ---------------------- 

  filter(data, colone) {
   
    let recherche;
    // if (colone=="nbon" ){
    //    recherche = this.Ventes.filter((x) => x.nbon.toString().includes(data));
    // }else 
    if ( colone =="numm"){
      recherche = this.Ventes.filter((x) => x.numm.toString().includes(data));
    }else{
      recherche = this.Ventes.filter(
      (x) =>
        x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1
    ); 
    }
    
    this.tableVentes = recherche;
    this.totalCommande();  
  
  }
  filterCommande(startDate,endDate){
    let recherche = this.Ventes.filter((x) => x.dateCommande >= startDate && x.dateCommande <= endDate );
    this.tableVentes = recherche; 
    this.totalCommande();
  }
  totalCommande() {
    this.total = 0;
    this.total = this.tableVentes
      .map((t) => t.montant * 1)
      .reduce((a, value) => a + value, 0);
    this.margeTotal = 0;
    this.margeTotal = this.tableVentes
      .map((t) => t.marge * 1)
      .reduce((a, value) => a + value, 0);
  }
  export() {
    this.blockedDocument=true;
    if (this.Vente.id) {
      // alert('update');
      this.tableCommandeVente.action = "Insert";
      this.tableCommandeVente.DBase  = this.AuthenticationService.currentUserValue.DBase;
      if (this.Vente.idUser){
        this.tableCommandeVente.idUser = this.Vente.idUser;
      }else{
          this.tableCommandeVente.idUser = this.AuthenticationService.currentUserValue.id;
      } 
      this.tableCommandeVente.commandeDetail = this.commandeDetail;
      this.tableCommandeVente.idClient = this.Vente.idClient;
      this.tableCommandeVente.dateCommandee = this.Vente.dateCommandee;
      this.tableCommandeVente.nbon = 0;
      this.tableCommandeVente.remise = 0;
      // console.log(this.tableCommandeVente);
      this.data
        .vente(this.tableCommandeVente)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            // alert(res.message);
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.blockedDocument=false;
            this.displayDialog = false;
            this.cloture(this.Vente.id,this.Vente.cloture);
          } else {
            alert(res.message);
          }
        });
    }  
  }
  save() {
    if (this.Vente.idClient) {
    this.blockedDocument=true;
    if (this.Vente.id) {
      // alert('update');
      this.tableCommandeVente.action = "Update";
      this.tableCommandeVente.DBase  = this.AuthenticationService.currentUserValue.DBase;
      if (this.Vente.idUser){
        this.tableCommandeVente.idUser = this.Vente.idUser;
      }else{
          this.tableCommandeVente.idUser = this.AuthenticationService.currentUserValue.id;
      }
  
      this.tableCommandeVente.devisDetail = this.commandeDetail;
      this.tableCommandeVente.id = this.Vente.id;
      this.tableCommandeVente.num = this.Vente.num;
      this.tableCommandeVente.idClient = this.Vente.idClient;
      this.tableCommandeVente.dateCommandee = this.Vente.dateCommandee;
      // this.tableCommandeVente.nbon = this.Vente.nbon;
      // console.log(this.tableCommandeVente);
      this.data
        .devis(this.tableCommandeVente)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            // alert(res.message);
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.blockedDocument=false;
            this.displayDialog = false;
            this.vente();
            this.venteDetail();
          } else {
            alert(res.message);
          }
        });
    } else {
        this.tableCommandeVente.action          = "Insert";
        this.tableCommandeVente.DBase           = this.AuthenticationService.currentUserValue.DBase;
        this.tableCommandeVente.idUser          = this.AuthenticationService.currentUserValue.id;
        this.tableCommandeVente.devisDetail     = this.commandeDetail;
        this.tableCommandeVente.idClient        = this.Vente.idClient;
        this.tableCommandeVente.dateCommandee   = this.Vente.dateCommandee;
        // console.log(this.tableCommandeVente);
        this.data
          .devis(this.tableCommandeVente)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.vente();
              this.venteDetail();
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
              this.blockedDocument=false;
            } else {
              // alert(res.message);
              console.log(res);
              this.displayDialog = false;
            }
          });
      } 
    }else {
        alert("Vous-avez oublier le clients !!!");
    }
  }
  delete() {
    // alert();
    //   this.confirmationService.confirm({
    //       message: 'Do you want to delete this record?',
    //       header: 'Delete Confirmation',
    //       icon: 'pi pi-info-circle',
    //       accept: () => {
      if (confirm("êtes vous sure?")) {
            this.Vente.action = "Delete";
            this.Vente.DBase  = this.AuthenticationService.currentUserValue.DBase;
            this.data
              .devis(this.Vente)
              .toPromise()
              .then((res: { message: string }) => {
                if ((res.message = "Opération de supression réuissie")) {
                  this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
                  this.vente();
                  this.venteDetail();
                  this.displayDialog = false;
                } else {
                  alert("Nonnnn");
                }
              });
            // this.messageService.add({severity:'info', summary:'Confirmed', detail:'Record deleted'});
              
          // },
          // reject: () => {
          //     // this.messageService.add({severity:'info', summary:'Rejected', detail:'لقذ تم حذف'});
          //     this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
          // }
      // });
    //
    // });
  }
  }
  
  exportexcel(): void {
  let fileName = "Situation des ventes du "+ this.dateSituationDebut +  " au " +  this.dateSituationFin+".xlsx";  
    /* table id is passed over here */
    let element = document.getElementById("excel-table");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    var title =
      "Situation du " +
      this.dateSituationDebut +
      " au " +
      this.dateSituationFin;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      ws,
      "du " + this.dateSituationDebut + " au " + this.dateSituationFin
    );

    /* save to file */
    XLSX.writeFile(wb, fileName);
  }
  printSituation(): void {
    var params = [
      "height=" + screen.height,
      "width=" + screen.width,
      "fullscreen=yes", // only works in IE, but here for completeness
    ].join(",");
    let printContents, popupWin;
    printContents = document.getElementById("print-table").innerHTML;
    popupWin = window.open("", "_blank", params);
    popupWin.document.open();
    popupWin.document.write(`
			  <html>
				<head>
				  <title>Print tab</title>
				  <style>
				  table {
					// width: 750px;
					border-collapse: collapse;
					margin: 5px auto;
				}				
				/* Zebra striping */
				tr:nth-of-type(odd) {
					// background: #eee;
					
				}				
				th {
					background: #3498db;
					color: white;
					font-weight: bold;
					text-align: center;
				}				
				td,
				th {
					padding: 5px;
					// border: 1px solid #ccc;
					text-align: left;
					font-size: 12px;
				}
				  </style>
				</head>
			<body onload="window.print();window.close()" style="text-align:center;">${printContents}</body>
			  </html>`);
    popupWin.document.close();
  }
  printBL(idCommande) {
    alert (idCommande);
    // 
    // console.log(event);
    // this.Vente.id = event.data.idCommande;
    // this.Vente.dateCommandee = event.data.dateCommande;
    // this.Vente.idClient = event.data.idClient;
    // this.Vente.remise = event.data.remise;

    let recherche = this.tableDetailVentes.filter((x) => x.id == idCommande);
    this.tableCommandeVentes = recherche;
    this.requetteCommandeVentes = recherche;
    // console.log(this.requetteCommandeVentes);
    this.totalQantite();
    this.prepareCommandeDetail();
    this.displayDialog = false;
  }
  newDonnee: boolean;
  showDialog() {
    this.newDonnee = true;
    this.Vente = {};
    this.displayDialog = true;
  }
  showDialogToAdd() {
    this.showButon = false;
    this.showButonSauvgarder = false;
    this.dropdownArticle=false;
    this.newDonnee = true;
    this.Vente = {};
    this.Vente.remise = 0;
    this.tableCommandeVente = {};
    this.tableCommandeVentes = [];
    this.requetteCommandeVentes = [];
    this.Vente.dateCommandee = new Date().toISOString().split("T")[0];
    this.displayDialog = true;
  }
  addArticle(data) {
    if(this.showButon==false){
        if( data.hasOwnProperty('prixAchat')==false){
          data.prixAchat=0;
        }
        data.total=data.prixVente*data.quantite;
        data.marge=(data.prixVente-data.prixAchat)*data.quantite;   
      if (this.tableCommandeVente.idArticle) {
        // console.log(data);
        this.tableCommandeVentes.push(data);

        var index = this.tableCommandeVentes.findIndex(
          (x) => x.idArticle == this.idArticle
        );

        // alert(data.designation);
        // alert("article : " + this.idArticle);
        // this.tableCommandeAchats[index].designation = data.designation;
        // alert("1");
        // this.idFournisseur = this.tableCommandeAchat.idFournisseur;
        // this.tableCommandeAchat.idFournisseur = this.idFournisseur;
        // this.tableCommandeAchat.dateCommande = new Date()
        // .toISOString()
        // .split("T")[0];

        this.showButon = false;
        this.totalQantite();
        this.prepareCommandeDetail();
        this.tableCommandeVente = {};
        // alert("2");
      } else {
        alert("Entrer un Article !");
      }

      // var index = this.tableCommandeAchats.findIndex((x) => x.index == index);
  }else{
    if( data.hasOwnProperty('prixAchat')==false){
      data.prixAchat=0;
    }
    this.tableCommandeVentes[this.index].idArticle = data.idArticle;
    this.tableCommandeVentes[this.index].designation = data.designation;
    this.tableCommandeVentes[this.index].quantite = data.quantite;
    this.tableCommandeVentes[this.index].prixVenteDetail = data.prixVenteDetail;
    this.tableCommandeVentes[this.index].prixVenteGros = data.prixVenteGros;
    this.tableCommandeVentes[this.index].prixVente = data.prixVente;
    this.tableCommandeVentes[this.index].total = data.prixVente*data.quantite;
    this.tableCommandeVentes[this.index].marge = (data.prixVente-data.prixAchat)*data.quantite;
    this.idClient = this.tableCommandeVente.idClient;
    // this.tableCommandeAchat = {};
    // this.tableCommandeAchat.idFournisseur = this.idFournisseur;
    // this.tableCommandeAchat.dateCommande = new Date()
    //   .toISOString()
    //   .split("T")[0];
    this.showButon = false;
    this.totalQantite();
    this.prepareCommandeDetail();
    //  this.tableCommandeAchat = {};
  }
  }
  updateArticle(data) {
    this.tableCommandeVentes[this.index].idArticle = data.idArticle;
    this.tableCommandeVentes[this.index].designation = data.designation;
    this.tableCommandeVentes[this.index].quantite = data.quantite;
    this.tableCommandeVentes[this.index].prixVenteDetail = data.prixVenteDetail;
    this.tableCommandeVentes[this.index].prixVenteGros = data.prixVenteGros;
    this.tableCommandeVentes[this.index].prixVente = data.prixVente;
    this.tableCommandeVentes[this.index].total = data.prixVente*data.quantite;
    this.tableCommandeVentes[this.index].marge = (data.prixVente-data.prixAchat)*data.quantite;
    this.idClient = this.tableCommandeVente.idClient;
    // this.tableCommandeAchat = {};
    // this.tableCommandeAchat.idFournisseur = this.idFournisseur;
    // this.tableCommandeAchat.dateCommande = new Date()
    //   .toISOString()
    //   .split("T")[0];
    this.showButon = false;
    this.totalQantite();
    this.prepareCommandeDetail();
    //  this.tableCommandeAchat = {};
  }
  findArticle(idArticle) {
    let recherche = this.Articles.find((x) => x.id == idArticle);
    this.designation = recherche.designation;
    this.idArticle = idArticle;
    // alert(this.idArticle);
    // if (recherche != undefined) {
    // 	return recherche.designation;
    // } else {
    // 	return '0';
    // }
  }
  totalQantite() {
    let totalMontant = 0;
    let totalMarge = 0;
    let totalQte = 0;
    for (let data of this.tableCommandeVentes) {
      totalMontant += data.total;
      totalMarge += data.marge * 1;
      totalQte += data.quantite * 1;
    }

    this.totalQte = totalQte;
    this.totalMarge = totalMarge;
    this.totalMontant = totalMontant;
    // return this._decimalPipe.transform(total, '1.2-2');
    // return total.toFixed(2);
    this.nombreToLettre((this.totalMontant).toString());
  }

  deleteCommande(idDetail) {
    // alert(idArticle);
    var index = this.tableCommandeVentes.findIndex(
      (x) => x.idDetail == idDetail
    );
    // alert(index);
    this.tableCommandeVentes.splice(index, 1);
    this.totalQantite();
    this.prepareCommandeDetail();
    // this.tableCommandeAchat = {};
    // console.log(this.vente);
    // var index = this.vente.findIndex((x) => x.idArticle == idArticle);
  }
  prepareCommandeDetail() {
    this.commandeDetail = "";
    for (let data of this.tableCommandeVentes) {
      this.commandeDetail +=
        "('idCommande','" +
        data.idArticle +
        "','" +
        data.quantite +
        "','" +
        data.prixVente +
        "'),";
    }
    this.commandeDetail += "$";
    this.commandeDetail = this.commandeDetail.replace(",$", ";");
    this.initialiser();
    // alert(this.tableCommandeVente.idFournisseur);
  }

  onRowSelect(event) {
    // if (!event.data.cloture){
      // console.log(event);
      this.AuthenticationService.currentUserValue.username= this.AuthenticationService.currentUserValue.username.toUpperCase();
      // if (this.able == true) {
        this.displayDialog = true;
        // console.log(event.data);
        
        this.Vente.id = event.data.idCommande;
        this.Vente.num = event.data.num;
        this.Vente.numm = event.data.numm;
        this.Vente.dateCommandee = event.data.dateCommande;
        this.Vente.cloture = event.data.cloture;

        // let vvv=JSON.stringify(event.data.idClient);
        this.Vente.idClient = JSON.stringify(event.data.idClient);
        this.Vente.remise = event.data.remise;
        this.Vente.nom                = event.data.nom;
        // this.Vente.nbon = event.data.nbon;
        this.Vente.idUser             = event.data.idUser;
        this.Vente.nomComplet         = event.data.nomComplet;
        this.Vente.ice                = event.data.ice.replace('Entretien',' ');;
        this.Vente.idClientFacture    = event.data.idClientFacture.replace('Entretien',' ');
        // alert (this.Vente.idClient);
        let recherche = this.tableDetailVentes.filter(
          (x) => x.id == event.data.idCommande
        );
        this.tableCommandeVentes = recherche;
        this.requetteCommandeVentes = recherche;
        this.nbre=this.requetteCommandeVentes.length-1;
        if(this.requetteCommandeVentes.length==1){
          this.higthCol='300px';
        }else if(this.requetteCommandeVentes.length>1) {
          let hight=300-(this.requetteCommandeVentes.length-1)*20;
          this.higthCol=hight + 'px';       
        }
        // console.log(this.requetteCommandeVentes);
        this.totalQantite();
        this.prepareCommandeDetail();
        // this.newDonnee = false;
        // this.Achat = this.cloneDonnee(event.data);
        // alert(this.tableCommandeAchat.idFournisseur);
      // }
    // }  
  }
  cloneDonnee(c: Vente): Vente {
    let Vente = {};
    for (let prop in c) {
      Vente[prop] = c[prop];
    }
    return Vente;
  }
  onRowSelectCommandeDetail(event) {
    // console.log(event);
    this.showButon = true;
    this.showButonSauvgarder = true;
    this.index = this.tableCommandeVentes.findIndex(
      (x) => x.idArticle == event.data.idArticle
    );
    // alert(this.index);
    this.newDonnee = false;
    this.tableCommandeVente = this.cloneDonneeCommandeDetail(event.data);
    this.displayDialog = true;
  }
  cloneDonneeCommandeDetail(c: Vente): Vente {
    let tableCommandeVente = {};
    for (let prop in c) {
      tableCommandeVente[prop] = c[prop];
    }
    return tableCommandeVente;
  }
  close() {
    this.displayDialog = false;
    this.tableCommandeVentes = [];
    this.tableCommandeVente = {};
    this.requetteCommandeVentes = [];
    this.requetteCommandeVente = {};
    this.tableCommandeVente.dateCommandee = new Date()
      .toISOString()
      .split("T")[0];
    this.totalQantite();
    this.prepareCommandeDetail();
  }
  initialiser() {
    this.tableCommandeVente = {};
    this.requetteCommandeVentes = this.tableCommandeVentes;
    // this.tableCommandeAchat.idArticle = null;
    // this.tableCommandeAchat.quantite = "";
    // this.tableCommandeAchat.prixAchat = "";
    // this.tableCommandeAchat.prixVente = "";
    // this.tableCommandeAchat.prixVenteDetail = "";
    // this.tableCommandeAchat.prixVenteGros = "";
    this.showButon = false;
  }
}
