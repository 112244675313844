import { Component, ElementRef,ViewChild, OnInit } from "@angular/core";
import { DataService } from "../../data.service";
import { Article } from "../../model/article";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { DecimalPipe } from '@angular/common';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuthenticationService } from "../../authentication.service";
// import {
//   trigger,
//   state,

//   style,
//   transition,
//   animate,
// } from "@angular/animations";

@Component({
  selector: "app-articlesRestore",
  templateUrl: "articlesRestore.component.html",
  styleUrls: ["articlesRestore.component.scss"],
  providers: [ConfirmationService,DecimalPipe],
})
export class ArticlesRestoreComponent implements OnInit {
  //----------------------Début Parametre CODE BARRE
  elementType = 'svg';
  value = '6933256621283';
  // format = 'EAN13';
  // lineColor = '#000000';
  // width = 1.5;
  // height = 50;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 20;
  background = '#ffffff';
  margin = 10;
  marginTop = 10;
  marginBottom = 10;
  marginLeft = 10;
  marginRight = 10;

  get values(): string[] {
    return this.value.split('\n');
  }
  codeList: string[] = [
    '',
    'CODE128',
    'CODE128A',
    'CODE128B',
    'CODE128C',
    'UPC',
    'EAN8',
    'EAN5',
    'EAN13',
    'EAN12',
    'EAN2',
    'CODE39',
    'ITF14',
    'MSI',
    'MSI10',
    'MSI11',
    'MSI1010',
    'MSI1110',
    'pharmacode',
    'codabar',
  ];
  //----------------------Fin Parametre CODE BARRE
  myUrl: string = `${environment.urlApi}`;
  imageUrl: string = this.myUrl + "/API/API/uploads/default.png";
  fileToUpload: File = null;
  // activite: string = "Service";
  Article: Article = {};
  Articles: Article[];
  tableArticle: Article[];
  selectedArticle: Article;
  displayDialog: boolean;
  selectedOption: string;
  selectedType: number;
  blockedDocument: boolean = false;
  able: boolean =false;
  designation: string;
  codeBarre: string;
  type: string;
  dateNow?: string;
  file?: string;
  dropdownArticle: boolean = true;
  requetteCommandeAchats: any = [];
  filteredArticles: any[];
  rechercheArticle: Article = {};
  tableCommandeAchats: any = [];
  showButon: boolean =false;

  constructor(
    private data: DataService, 
    private http: HttpClient,
    public AuthenticationService: AuthenticationService,
    private _decimalPipe: DecimalPipe,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
    ) {}

  ngOnInit() {
    this.dateNow = new Date().toISOString().split("T")[0];
    if(this.AuthenticationService.currentUserValue.groupe=='Administrateur' || this.AuthenticationService.currentUserValue.groupe=='Administrateurs' ){
      this.able=true;
    }
    this.article();
    // $('#elemId').val("8003511428570");
  }
  // getArticles() {
  // 	this.data.getArticles().toPromise().then((x: Article[]) => ((this.Articles = x), (this.tableArticle = x)));
  // }
  article() {
    // this.Article.action = "SelectStockArticle";
    // this.Article.action = "RecapDepot";
    this.Article.action = "SelectDeleted";
    this.Article.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.data
      // .stock(this.Article)
      // .stockDepot(this.Article)
      .article(this.Article)
      .toPromise()
      .then((x: Article[]) => (this.Articles = x, this.tableArticle = x));
  }
  
  filterArticle(event) {
    let filtered : any[] = [];
    let query = event.query;
    // console.log(this.Articles);
    filtered = this.Articles.filter((x) => x.designation.toString().toLocaleLowerCase().includes(query.toLocaleLowerCase()));   
    this.filteredArticles = filtered;
  }
  @ViewChild('inputDesignation', {static: false}) inputDesignation:ElementRef;
  setFocusDesignation(){
    setTimeout(() => this.inputDesignation.nativeElement.focus());
  }
  setDropdownArticle(){
    // alert('kkk');
    this.dropdownArticle=true;
      this.setFocusDesignation();
  }

  transformDecimal(num) {
    return this._decimalPipe.transform(num, "1.2-2");
  }
  retourArticle(id) {
    // alert(id);
    this.Article.action   = "Restor";
    this.Article.id       = id;
    this.Article.DBase    = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .article(this.Article)
      .toPromise()
      .then((res: { message: string }) => {
        if (res.message == "Opération de modification réuissie") {
          this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
          this.blockedDocument=false;
          this.displayDialog = false;
          this.article();
      }
    })
  }
  save() {
    if(this.Article.designation   && this.Article.idType      
       && this.Article.prixVente  && this.Article.tva ){
      this.blockedDocument=true;
      if (this.Article.id) {
        this.Article.action           = "Update";
        this.Article.DBase            = this.AuthenticationService.currentUserValue.DBase;
        this.Article.dateNow          = this.dateNow;
        this.data
          .article(this.Article)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.blockedDocument=false;
              this.displayDialog = false;
              this.article();
            } else {
              this.messageService.add({severity:'warn', summary: 'Attention !!', detail: res.message});
              this.blockedDocument=false;
            }
          });
      } else {
        this.Article.action = "Insert";
        this.Article.DBase = this.AuthenticationService.currentUserValue.DBase;
        this.data
          .article(this.Article)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.blockedDocument=false;
              this.displayDialog = false;
              this.article();
            } else {
              this.messageService.add({severity:'warn', summary: 'Attention !!', detail: res.message});
              this.blockedDocument=false;
              // this.displayDialog = false;
            }
          });
      }
    }else{
      alert("Vous avez oublier un champ !!!")
    }
    
  }

  close() {
    this.displayDialog = false;
    this.Article = {};
    this.file="";
  }
}
