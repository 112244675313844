import { Component, OnInit } from "@angular/core";
import { DataService } from "../../data.service";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Depot } from "../../model/depot";
import {Message,MessageService} from 'primeng/api';
import { Router } from '@angular/router';
import { AuthenticationService } from "src/app/authentication.service";
// import { ConfirmDialogModule } from "primeng/confirmdialog";
// import { ConfirmationService } from "primeng/api";
@Component({
  selector: "app-depots",
  templateUrl: "depots.component.html",
  styleUrls: ["depots.component.scss"],
  providers: [MessageService],
})
export class DepotsComponent implements OnInit {
  myUrl: string = `${environment.urlApi}`;
  imageUrl: string = this.myUrl + "/API/API/uploads/default.png";
  fileToUpload: File = null;
  Depot: Depot = {};
  Depots: Depot[];
  selectedDepot: Depot;
  displayDialog: boolean;
  selectedOption: string;
  selectedFile = null;
  constructor(
    private data: DataService, private http: HttpClient,
    private messageService: MessageService,
    public AuthenticationService: AuthenticationService,
    private router: Router) {}
  ngOnInit() {
    this.depot();
  }

  depot() {
    this.Depot.action = "Select";
    this.Depot.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .depot(this.Depot)
      .toPromise()
      .then((x: Depot[]) => (this.Depots = x));
  }
  save() {
    if (this.Depot.idDepot == null) {
      this.messageService.add({severity:'warn', summary: 'Sauvegarde', detail: 'classement est vide - رقم المخزن فارغ'});
    } else if (this.Depot.designation == null) {
      this.messageService.add({severity:'warn', summary: 'Sauvegarde', detail: 'Désignation est vide - الاسم فارغ'});
    } else {
      if (this.Depot.id) {
        this.Depot.action = "Update";
        this.Depot.DBase  = this.AuthenticationService.currentUserValue.DBase;
        this.data
          .depot(this.Depot)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
              this.depot();
            } else {
              alert(res.message);
              alert("Nonnnn");
            }
          });
      } else {
        this.Depot.action = "Insert";
        this.Depot.DBase  = this.AuthenticationService.currentUserValue.DBase;
        this.data
          .depot(this.Depot)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
              this.depot();
            } else {
              alert(res.message);
              this.displayDialog = false;
            }
          });
      }
    }
  }
  showConfirm() {
      // this.messageService.clear();
      this.messageService.add({key: 'c', sticky: true, severity:'warn', summary:'Vous ete sur de suprimmer?', detail:'Confirmation'});
  }
  clear() {
      this.messageService.clear();
  }
  onConfirm() {
      this.delete();
    this.messageService.clear('c');
  }

  onReject() {
      this.messageService.clear('c');
  }
  delete() {
    // if (confirm("êtes vous sure?")) {
      this.Depot.action = "Delete";
      this.Depot.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.data
        .depot(this.Depot)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
            this.displayDialog = false;
            this.depot();
          } else {
            alert("Nonnnn");
          }
        });
    // }
  }
  actionLink(lien) {
    this.router.navigate(["/"+lien]);
  }
  close() {
    this.displayDialog = false;
    this.Depot = {};
  }
  newDonnee: boolean;
  showDialogToAdd() {
    this.newDonnee = true;
    this.imageUrl = this.myUrl + "/API/API/uploads/default.png";
    this.Depot = {};
    this.displayDialog = true;
  }
  onRowSelect(event) {
    this.displayDialog = true;
    this.newDonnee = false;
    this.Depot = this.cloneDonnee(event.data);
  }
  cloneDonnee(c: Depot): Depot {
    let Depot = {};
    for (let prop in c) {
      Depot[prop] = c[prop];
    }
    return Depot;
  }
}
