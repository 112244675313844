import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from "@angular/core";
import { DataService } from "../../data.service";
import { AuthenticationService } from "../../authentication.service";
import { environment } from "../../../environments/environment";
import { Article } from "../../model/article";
import { Avoir } from "../../model/avoir";
import { DecimalPipe, UpperCasePipe } from "@angular/common";
import * as XLSX from "xlsx";
import { Client } from "../../model/client";
import { Achat } from "../../model/achat";
import {Message,MessageService,ConfirmationService} from 'primeng/api';
import Swal from 'sweetalert2';
import { Vente } from "../../model/vente";
// import {CheckboxModule} from 'primeng/checkbox';
@Component({
  selector: "app-avoir",
  templateUrl: "avoir.component.html",
  styleUrls: ["avoir.component.scss"],
  providers: [DecimalPipe,MessageService,ConfirmationService],
})
export class AvoirComponent implements OnInit {
  msgs1: Message[];    
  msgs2: Message[];

  idArticle: number;
  idArticleTest: number;
  venteX: number;
  Article: Article = {};
  Articles: Article[];
  ArticlesRecherche: Article[];
  stock: Article[];
  Achat: Achat = {};
  Achats: Achat[];
  rechercheArticle: Article = {};
  rechercheArticles: Article[];
  Avoir: Avoir = {};
  Avoirs: Avoir[];
  selectedVente: Vente;
  selectedAvoir: Avoir;
  selectedArticle:string;
  tableAvoirs: Avoir[];
  tableVentes: Vente[];
  Vente: Vente = {};
  Ventes: Vente[];
  tableArticles: Vente[];
  tableDetailVentes: Avoir[];
  tableDetailAvoirs: Avoir[];
  tableDetailAvoir: Avoir[];
  artilcesClient: Avoir[];
  artilceClient: Avoir={};
  selectedCommandeDetail: Avoir;
  tableCommandeVente: Avoir = {};
  tableCommandeVentes: any = [];
  tableCommandeAvoirs: any = [];
  requetteCommandeVente: Avoir = {};
  requetteCommandeVentes: any = [];
  Client: Client = {};
  Clients: Client[];
  displayDialog: boolean;
  displayDialogCommande: boolean;
  desablePrint: boolean =false;
  showButon: boolean;
  showButonSauvgarder: boolean;
  blockedDocument: boolean;
  designation: string='';
  nomComplet:string='';
  nomCompletCommande:string='';
  nbon:string='';
  nbonCommande:string='';
  numm:string='';
  nummCommande:string='';
  nom:string;
  nomCommande:string;
  totalMontant: number;
  totalMarge: number;
  totalQte: number;
  total: number;
  remise: number;
  margeTotal: number;
  idClient: number;
  quantiteVenteGros: number;
  quantiteVenteDetail: number;
  prixVenteGros: number;
  prixVenteDetail: number;
  prixVente1: number;
  Exclut:string;  
  index: string;
  commandeDetail: string;
  dateDebut: any;
  dateFin: any;
  dateSituationDebut: string;
  dateSituationFin: string;
  msgs: Message[] = [];
  position: string;
  displaybarcode: boolean;
  displayCharge: boolean;
  afficher: boolean;
  BarCode:string;

  able: boolean =false;
  afficherCodebarre: boolean =false;
  myUrl: string = `${environment.urlApi}`;
  
	Charge: any = {};
  Charges: any[];
  totalCharge:number;
  nbreBon:number;

  Paiement: any = {};
  Paiements: any[];
  totalPaiement:number;
  
  filteredArticles: any[];
  constructor(
    private data: DataService,
    private _decimalPipe: DecimalPipe,
    public AuthenticationService: AuthenticationService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}
  addSingle() {
    this.messageService.add({severity:'success', summary:'Service Message', detail:'Via MessageService'});
}

  ngOnInit() {
    if(this.AuthenticationService.currentUserValue.groupe=='Administrateur' || this.AuthenticationService.currentUserValue.groupe=='Administrateurs' ){
      this.able=true;
    }
    this.commandeDetail="";
    this.dateSituationDebut = new Date().toISOString().split("T")[0];
    this.dateSituationFin = new Date().toISOString().split("T")[0];
    // this.article();
    // this.getArticles();
    // this.achat();
    // this.client();
    // this.avoir();
    this.avoirDetail();
    this.venteDetail();
    this.tableCommandeVente.dateCommandee = new Date()
      .toISOString()
      .split("T")[0];
    this.Avoir.dateCommandee = new Date().toISOString().split("T")[0];
    this.Avoir.nom=""
    this.Avoir.nomComplet=""
  }

  // getArticles() {
	// 	this.Article.action = 'Select';
	// 	this.data.stock(this.Article).toPromise().then((x: Article[]) => (this.stock = x));
  // }
  // filterArticle(event) {
  //   let filtered : any[] = [];
  //   let query = event.query;
  //   // console.log(this.Articles);
  //   filtered = this.Articles.filter((x) => x.designation.toString().toLocaleLowerCase().includes(query.toLocaleLowerCase()));   
  //   this.filteredArticles = filtered;
  // }

  // showDialogCharge(){
  //   this.displayCharge=true;
  // }

  // article() {
  //   this.Article.action = "Select";
  //   this.data
  //     .article(this.Article)
  //     .toPromise()
  //     .then((x: Article[]) => (
  //       this.Articles = x,
  //       // console.log(x),
  //       this.ArticlesRecherche = x));
  // }
  // achat() {
  //   this.Achat.action = "Select";
  //   this.data
  //     .achat(this.Achat)
  //     .toPromise()
  //     .then((x: Achat[]) => (this.Achats = x));
  // }
  // onEnter() {}
  // rechercheDesignation(idArticle) {
  //   // this.rechercheArticle = this.Achats.find((x) => x.idArticle === idArticle);
  //   // this.tableCommandeVente.designation = this.rechercheArticle.designation;
  // }
  // testPrix(qte){
  //   var Qte = parseInt(qte);
  //   this.tableCommandeVente.prixVente=this.prixVente1;
  // }
  // findPrix(idArticle) {

  //   if (idArticle && this.idArticleTest!=idArticle){
  //     this.idArticleTest=idArticle;
  //     this.setFocusQte();
  //   }
  //   let rechercheArticle = this.stock.find((x) => x.id == idArticle);
  //   this.tableCommandeVente.idArticle         = rechercheArticle.idArticle;
  //   this.tableCommandeVente.stock             = rechercheArticle.stockTotal;
  //   this.tableCommandeVente.designation       = rechercheArticle.designation;
  //   this.tableCommandeVente.prixAchat         = rechercheArticle.prixAchat;
  //   this.prixVenteGros                        = rechercheArticle.prixVenteGros;
  //   this.prixVenteDetail                      = rechercheArticle.prixVenteDetail;
  //   this.quantiteVenteGros                    = rechercheArticle.quantiteVenteGros;
  //   this.quantiteVenteDetail                  = rechercheArticle.quantiteVenteDetail;
  //   this.prixVente1                           = rechercheArticle.prixVente;
  //   this.tableCommandeVente.prixVente         = rechercheArticle.prixVente;
  //   // this.prixAchat                            = rechercheArticle.prixAchat;

  //   let recherche =this.tableCommandeVentes.find((x) => x.idArticle == idArticle);
  //   // this.requetteCommandeVentes = this.tableCommandeVentes.filter((x) => x.idArticle == idArticle);
  //   // console.log(recherche);
  //   // alert("1");
  //   // alert(this.Exclut);

  //   // if(this.Exclut !='o'){
  //   //     this.tableCommandeVente.quantite          = recherche.quantite;
  //   //     this.tableCommandeVente.prixVente         = recherche.prixVente;
  //   //     // this.showButon=true;
         
  //   // }else{
      
  //   //   // this.showButon=false;
  //   //   this.Avoir.quantite=0;
  //   //   this.Avoir.prixVente=0;
  //   // }
  //   // alert("2");
  //   let idArtic=this.artilcesClient.filter((x) => x.idArticle == idArticle);
    
  //   if(typeof idArtic !== 'undefined' && idArtic.length > 0){
  //     let idPrixVentX = Math.max.apply(Math, idArtic.map(function(o) { return o.id; }));
  //     this.artilceClient = idArtic.find((x) => x.id == idPrixVentX); 
  //     this.venteX = this.artilceClient.prixVente;     
  //   }else{
  //     this.venteX = 0;
  //   }
  //   // console.log(idArtic);
  //   // if(idArtic!= undefined){
      
  //   //   let idPrixVentX = Math.max.apply(Math, idArtic.map(function(o) { return o.id; }));
  //   //   // alert(idPrixVentX);
  //   //   this.artilceClient = idArtic.find((x) => x.id == idPrixVentX);      
  //   // }
  // }
  // client() {
  //   this.Client.action = "Select";
  //   this.data
  //     .client(this.Client)
  //     .toPromise()
  //     .then((x: Client[]) => (this.Clients = x));
  // }
  
  // exclut(idClient){
  //   // alert(idClient);
  //   let recherche = this.Avoirs.filter((x) => (
  //     x.idClient.toString().includes(idClient)
  //     ));
  //   this.nbreBon =recherche.length; 
  //   // alert(this.nbreBon);
  //   let recherchee = this.Clients.find((x) => x.id == idClient);
  //   this.Exclut=recherchee.exclut;
  //   this.artilcesClient = this.tableDetailVentes.filter((x) => x.idClient == idClient);
  // }
  avoir() {
    this.Avoir={};
    this.Avoir.action = "Select";
    this.data
      .avoir(this.Avoir)
      .toPromise()
      .then(
        (x: Avoir[]) => (
          (this.Avoirs = x), 
          (this.tableAvoirs = x)           
          
          // this.paiementRecap(this.dateSituationDebut,this.dateSituationFin),
          // this.filterCommande(this.dateSituationDebut,this.dateSituationFin)
        // )
      ));
  }

  filterCommande(dateDebut,dateFin){
    // console.log(dateDebut+ " + " + dateFin)
    if (this.nom==undefined){
      this.nom="";
    }
    if (this.nomComplet==undefined){
      this.nomComplet="";
    }
    if (this.designation==undefined){
      this.designation="";
    }
    if (this.numm==undefined){
      this.numm="";
    }
    // console.log(dateDebut +" / "+ dateFin + " / " + this.nom + " / " + this.designation);

    let recherche = this.tableDetailAvoir.filter((x) =>  
      (x.dateAvoir >= dateDebut && x.dateAvoir <= dateFin) && 
      x.nom.toLocaleLowerCase().includes(this.nom.toLocaleLowerCase()) &&
      x.nomComplet.toLocaleLowerCase().includes(this.nomComplet.toLocaleLowerCase()) &&
      x.designation.toLocaleLowerCase().includes(this.designation.toLocaleLowerCase()) &&
      x.numm.toString().includes(this.numm)
      );
    this.tableDetailAvoirs = recherche; 
    // console.log(this.tableArticles);
    // this.calculTotal();
  }
  filterCommandeDetail(){
    if (this.nom==undefined){
      this.nom="";
    }
    if (this.designation==undefined){
      this.designation="";
    }
    if (this.numm==undefined){
      this.numm="";
    }
    // console.log(dateDebut +" / "+ dateFin + " / " + this.nom + " / " + this.designation);

    let recherche = this.Ventes.filter((x) =>  
      x.nom.toLocaleLowerCase().includes(this.nom.toLocaleLowerCase()) &&
      x.designation.toLocaleLowerCase().includes(this.designation.toLocaleLowerCase()) &&
      x.numm.toString().includes(this.numm)
      );
    this.tableArticles = recherche; 
  }

  vente() {
    this.Vente.action = "SelectDetail";
    this.data
      .vente(this.Vente)
      .toPromise()
      .then(
        (x: Vente[]) => (
          (this.Ventes = x),
          (this.tableArticles = x)
        )
      );
  }

  filterCommandeCommande(){
    if (this.nomCommande==undefined){
      this.nomCommande='';
    }
    if (this.nomCompletCommande==undefined){
      this.nomCompletCommande='';
    }
    // console.log(dateDebut +" / "+ dateFin + " / " + this.nom + " / " + this.nomComplet)

    let recherche = this.Ventes.filter((x) => (
      x.nom.toLocaleLowerCase().includes(this.nomCommande.toLocaleLowerCase()) &&
      x.nomComplet.toLocaleLowerCase().includes(this.nomCompletCommande.toLocaleLowerCase()) &&
      x.nbon.toString().includes(this.nbonCommande) && 
      x.numm.toString().includes(this.nummCommande)
      ));
    this.tableVentes = recherche; 
  }

  totalCommande() {
    this.total = 0;
    this.total = this.tableVentes
      .map((t) => t.montant * 1)
      .reduce((a, value) => a + value, 0);
    this.remise = 0;
    this.remise = this.tableVentes
      .map((t) => t.remise * 1)
      .reduce((a, value) => a + value, 0);
    this.margeTotal = 0;
    this.margeTotal = this.tableVentes
      .map((t) => t.marge * 1)
      .reduce((a, value) => a + value, 0);
  }

  avoirDetail() {
    this.Avoir.action = "SelectDetail";
    this.data
      .avoir(this.Avoir)
      .toPromise()
      .then((x: Avoir[]) => (
        this.tableDetailAvoir = x, 
        this.tableDetailAvoirs = x,
        this.filterCommande(this.dateSituationDebut,this.dateSituationFin)
        ));
  }
  venteDetail() {
    this.Avoir.action = "SelectDetail";
    this.data
      .vente(this.Avoir)
      .toPromise()
      .then((x: Avoir[]) => (this.tableDetailVentes = x,this.vente()));
  }
  // this.recherhceMarge()
  // figer(){
  //   if (this.afficher!=true){
  //     this.afficher=true;
  //   }else{
  //     this.afficher=false;
  //   }
  // }
  cloture(idCommande,cloture) {
    // alert(idCommande+" | "+cloture);
    let etat;
    if (cloture==0){
      etat=1;
    }else{
      etat=0;
    }
      this.blockedDocument=true;
      this.tableCommandeVente.action = "UpdateCloure";
      this.tableCommandeVente.etat = etat;
      this.tableCommandeVente.id = idCommande;
      this.data
        .avoir(this.tableCommandeVente)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            // alert(res.message);
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.blockedDocument=false;
            this.displayDialog = false;
            this.avoir();
            this.avoirDetail();
          } else {
            alert(res.message);
          }
        });
  }

  transformDecimal(num) {
    return this._decimalPipe.transform(num, "1.2-2");
  }
  recherhceMarge(){

        this.tableVentes.forEach((row)=>{
        let recherche = this.tableDetailVentes.filter((x) => x.idCommande == row.idCommande);

        let updateItem = this.tableVentes.find(item=>item.idCommande==row.idCommande);
        let index = this.tableVentes.indexOf(updateItem);
        let marge =  recherche
          .map((t) => t.marge)
          .reduce((a, value) => a + value, 0);
        this.tableVentes[index].marge = marge;
      }); 
      // console.log(this.tableVentes);
      this.totalCommande();
  }
  saveCommandeVente() {
    this.tableCommandeVente.action = "Insert";
    this.tableCommandeVente.commandeDetail = this.commandeDetail;
    this.data
      .avoir(this.tableCommandeVente)
      .toPromise()
      .then((res: { message: string }) => {
        if (res.message == "Opération de modification réuissie") {
          this.avoir();
          alert(res.message);
        } else {
          alert(res.message);
        }
      });
  }
  //   filter(data, colone) {
  //     console.log(data + colone);
  //     let recherche = this.Achats.filter(
  //       (x) =>
  //         x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1
  //     );
  //     this.tableAchats = recherche;
  //     this.total = this.tableAchats
  //       .map((t) => t.montant * 1)
  //       .reduce((a, value) => a + value, 0);
  //   }
  // filter(data, colone) {
  //   let recherche;
  //   if (colone=="nbon" ){
  //      recherche = this.Avoirs.filter((x) => x.nbon.toString().includes(data));
  //   }else if ( colone =="numm"){
  //     recherche = this.Avoirs.filter((x) => x.numm.toString().includes(data));
  //   }else{
  //     recherche = this.Avoirs.filter(
  //     (x) =>
  //       x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1
  //   ); 
  //   }
  //   if (recherche!=undefined){
  //   this.tableVentes = recherche;
  //   }
  //   this.totalCommande();
  // }
  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

  @ViewChild('input1', {static: false}) inputEl:ElementRef;

  barcode(){
    this.displaybarcode=true;
    this.close();
    // this.Avoir.nbon='0';
    // this.Avoir.remise=0;
    // setTimeout(() => this.inputEl.nativeElement.focus());
  }

  codebarre(){
    this.close();
    if (this.afficherCodebarre==true){
      this.afficherCodebarre=false;
      this.displaybarcode=false;
    }else{
      this.afficherCodebarre=true;
      this.displaybarcode=true;
    }
    // this.displaybarcode=false;
  }
  setFocus(){
    setTimeout(() => this.inputEl.nativeElement.focus());
  }
  @ViewChild('inputArticle', {static: false}) inputArticle:ElementRef;
  setFocusArticle(){
    setTimeout(() => this.inputArticle.nativeElement.focus());
  }  
  @ViewChild('inputQte', {static: false}) inputQte:ElementRef;
  setFocusQte(){
    setTimeout(() => this.inputQte.nativeElement.focus());
  }
  barcodeHide(){
    this.displaybarcode=false;
  }
  factureSelect(event){
    this.displayDialog = false; 
    this.displaybarcode=true;       
    this.Avoir.id = event.idCommande;
    this.Avoir.num = event.num;
    this.Avoir.numm = event.numm;
    this.Avoir.dateCommandee = event.dateCommande;
    this.Avoir.idClient = JSON.stringify(event.idClient);
    this.Avoir.remise = event.remise;
    this.Avoir.nom = event.nom;
    this.Avoir.nbon = event.nbon;
    this.Avoir.idUser = event.idUser;
    this.Avoir.nomComplet = event.nomComplet;
    this.Avoir.cloture = event.cloture;
    // alert (this.Avoir.idClient);
    let recherche = this.tableDetailVentes.filter(
      (x) => x.id == event.idCommande
    );
    this.tableCommandeVentes = recherche;
    this.requetteCommandeVentes = recherche;
    this.totalQantite();
    this.prepareCommandeDetail();
    this.setFocus();
  }
  save() {

    // console.log('idclient' + this.Avoir.idClient);
    // console.log('nbon' + this.Avoir.nbon);
    // console.log('commandeDetail' + this.commandeDetail);
    if (this.Avoir.idClient && this.commandeDetail!='') {
    this.blockedDocument=true;
    if (this.Avoir.id) {
      // alert('update');
      this.tableCommandeVente.action = "Update";
      if (this.Avoir.idUser){
        this.tableCommandeVente.idUser = this.Avoir.idUser;
      }else{
          this.tableCommandeVente.idUser = this.AuthenticationService.currentUserValue.id;
      }
  
      this.tableCommandeVente.commandeDetail = this.commandeDetail;
      this.tableCommandeVente.id = this.Avoir.id;
      this.tableCommandeVente.idCommande = this.Avoir.idCommande;
      this.tableCommandeVente.num = this.Avoir.num;
      this.tableCommandeVente.remise = this.Avoir.remise;
      this.tableCommandeVente.idClient = this.Avoir.idClient;
      this.tableCommandeVente.dateCommandee = this.Avoir.dateCommandee;
      this.tableCommandeVente.nbon = this.Avoir.nbon;
      // this.tableCommandeVente.nbreBon = this.nbreBon;
      console.log(this.tableCommandeVente);
      this.data
        .avoir(this.tableCommandeVente)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            // alert(res.message);
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.displaybarcode   = false;  
            this.blockedDocument  = false;
            this.displayDialog    = false;
            this.desablePrint     = false;
            this.avoir();
            // this.getArticles();
            this.avoirDetail();
            this.nom="";
            this.designation="";
            this.numm="";
            this.displaybarcode=false;
          } else {
            alert(res.message);
          }
        });
    } else {
      // alert('add')
        // alert(idClient);

        this.tableCommandeVente.action          = "Insert";
        this.tableCommandeVente.idUser          = this.AuthenticationService.currentUserValue.id;        
        this.tableCommandeVente.idCommande      = this.Avoir.idCommande;
        this.tableCommandeVente.commandeDetail  = this.commandeDetail;
        this.tableCommandeVente.idClient        = this.Avoir.idClient;
        this.tableCommandeVente.dateCommandee   = this.Avoir.dateCommandee;
        console.log(this.tableCommandeVente);
        this.data
          .avoir(this.tableCommandeVente)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.commandeDetail='';
              this.tableCommandeAvoirs=[];
              this.avoir();
              this.avoirDetail();
              this.tableCommandeVente={};
              this.Avoir={};
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displaybarcode   = false;  
              this.displayDialog = false;
              this.blockedDocument=false;
              this.desablePrint = false;
              this.displaybarcode=false;
            } else {
              alert(res.message);
              this.displayDialog = false;
            }
          });
      }
    } else {
        alert("Vous-avez oublier le clients !!!");
    }
  }
  delete(id) {
    // alert();
    //   this.confirmationService.confirm({
    //       message: 'Do you want to delete this record?',
    //       header: 'Delete Confirmation',
    //       icon: 'pi pi-info-circle',
    //       accept: () => {
      if (confirm("êtes vous sure?")) {
        this.Avoir.id=id;
            this.Avoir.action = "Delete";
            console.log(this.Avoir);
            this.data
              .avoir(this.Avoir)
              .toPromise()
              .then((res: { message: string }) => {
                if ((res.message = "Opération de supression réuissie")) {
                  this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
                  this.avoir();
                  this.avoirDetail();
                  this.displayDialog = false;
                } else {
                  alert("Nonnnn");
                }
              });
            // this.messageService.add({severity:'info', summary:'Confirmed', detail:'Record deleted'});
              
          // },
          // reject: () => {
          //     // this.messageService.add({severity:'info', summary:'Rejected', detail:'لقذ تم حذف'});
          //     this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
          // }
      // });
    //
    // });
  }
  }
  
  exportexcel(): void {
  let fileName = "Situation des avoirs du "+ this.dateSituationDebut +  " au " +  this.dateSituationFin+".xlsx";  
    /* table id is passed over here */
    let element = document.getElementById("excel-table");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    var title =
      "Situation du " +
      this.dateSituationDebut +
      " au " +
      this.dateSituationFin;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      ws,
      "du " + this.dateSituationDebut + " au " + this.dateSituationFin
    );

    /* save to file */
    XLSX.writeFile(wb, fileName);
  }
  printSituation(): void {
    var params = [
      "height=" + screen.height,
      "width=" + screen.width,
      "fullscreen=yes", // only works in IE, but here for completeness
    ].join(",");
    let printContents, popupWin;
    printContents = document.getElementById("print-table").innerHTML;
    popupWin = window.open("", "_blank", params);
    popupWin.document.open();
    popupWin.document.write(`
			  <html>
				<head>
				  <title>Print tab</title>
				  <style>
				  table {
					// width: 750px;
					border-collapse: collapse;
					margin: 5px auto;
				}				
				/* Zebra striping */
				tr:nth-of-type(odd) {
					// background: #eee;
					
				}				
				th {
					background: #3498db;
					color: white;
					font-weight: bold;
					text-align: center;
				}				
				td,
				th {
					padding: 5px;
					// border: 1px solid #ccc;
					text-align: left;
					font-size: 12px;
				}
				  </style>
				</head>
			<body onload="window.print();window.close()" style="text-align:center;">${printContents}</body>
			  </html>`);
    popupWin.document.close();
  }
  printBL(idCommande) {
    this.displayDialog = false;
    // console.log(event);
    // this.Avoir.id = event.data.idCommande;
    // this.Avoir.dateCommandee = event.data.dateCommande;
    // this.Avoir.idClient = event.data.idClient;
    // this.Avoir.remise = event.data.remise;

    let recherche = this.tableDetailVentes.filter((x) => x.id == idCommande);
    this.tableCommandeVentes = recherche;
    this.requetteCommandeVentes = recherche;
    // console.log(this.requetteCommandeVentes);
    this.totalQantite();
    this.prepareCommandeDetail();
  }
  newDonnee: boolean;
  showDialog() {
    this.newDonnee = true;
    this.Avoir = {};
    this.displayDialog = true;
  }
  showDialogToAdd() {
    this.afficher= true;
    this.displayDialog = true;
    this.showButon = false;
    this.showButonSauvgarder = false;
    this.newDonnee = true;
    this.Avoir = {};
    this.Avoir.remise = 0;
    this.tableCommandeVente = {};
    this.tableCommandeVentes = [];
    this.requetteCommandeVentes = [];
    this.Avoir.dateCommandee = new Date().toISOString().split("T")[0];
    
  }
  addArticle(data) {
      // console.log(data);
      // alert( data.prixAchat);
      // console.log(data.prixAchat)
      if(this.showButon==false){
          if( data.hasOwnProperty('prixAchat')==false){
            data.prixAchat=0;
          }
          data.total=data.prixVente*data.quantite;
          data.marge=(data.prixVente - data.prixAchat)*data.quantite;
          // alert(data.marge);
    
        if (this.tableCommandeVente.idArticle || data.codeBarre || data.idArticle ) {
        // console.log(data);
          this.tableCommandeVentes.push(data);
          this.showButon = false;
          this.totalQantite();
          this.prepareCommandeDetail();
          this.tableCommandeVente = {};
          this.selectedArticle="";
    
        } else {
          alert("Entrer un Article !");
        }
        this.desablePrint=true;
        this.setFocusArticle();
        // var index = this.tableCommandeAchats.findIndex((x) => x.index == index);
      }else{
              // console.log(data)
        if( data.hasOwnProperty('prixAchat')==false){
          data.prixAchat=0;
        }
        // console.log( this.tableCommandeVentes)
        // var index = this.tableCommandeVentes.findIndex((x) => x.idArticle == data.idArticle);
        // alert (index);
        this.tableCommandeVentes[this.index].idArticle = data.idArticle;
        this.tableCommandeVentes[this.index].designation = data.designation;
        this.tableCommandeVentes[this.index].quantite = data.quantite;
        this.tableCommandeVentes[this.index].prixVente = data.prixVente;
        this.tableCommandeVentes[this.index].total = data.prixVente*data.quantite;
        this.tableCommandeVentes[this.index].marge = (data.prixVente-data.prixAchat)*data.quantite;
        this.idClient = this.tableCommandeVente.idClient;

        // this.tableCommandeAchat = {};
        // this.tableCommandeAchat.idFournisseur = this.idFournisseur;
        // this.tableCommandeAchat.dateCommande = new Date()
        //   .toISOString()
        //   .split("T")[0];
        this.showButon = false;
        this.totalQantite();
        this.prepareCommandeDetail();
        //  this.tableCommandeAchat = {};
      }

  }

  updateArticle(data) {
    // console.log(data)
    if( data.hasOwnProperty('prixAchat')==false){
      data.prixAchat=0;
    }
    // console.log( this.tableCommandeVentes)
    // var index = this.tableCommandeVentes.findIndex((x) => x.idArticle == data.idArticle);
    // alert (index);
    this.tableCommandeVentes[this.index].idArticle = data.idArticle;
    this.tableCommandeVentes[this.index].designation = data.designation;
    this.tableCommandeVentes[this.index].quantite = data.quantite;
    this.tableCommandeVentes[this.index].prixVente = data.prixVente;
    this.tableCommandeVentes[this.index].total = data.prixVente*data.quantite;
    this.tableCommandeVentes[this.index].marge = (data.prixVente-data.prixAchat)*data.quantite;
    this.idClient = this.tableCommandeVente.idClient;

    // this.tableCommandeAchat = {};
    // this.tableCommandeAchat.idFournisseur = this.idFournisseur;
    // this.tableCommandeAchat.dateCommande = new Date()
    //   .toISOString()
    //   .split("T")[0];
    this.showButon = false;
    this.totalQantite();
    this.prepareCommandeDetail();
    //  this.tableCommandeAchat = {};
  }

  addArticleBarCode(data,colonne){
    
    // setTimeout(()=>{ // this will make the execution after the above boolean has changed
    //   this.BarCode.nativeElement.focus();
    // },0); 
    // let codeBarre="";
    // if (colonne=="codeBarre"){
      let recherche:any;
      if (colonne=="codeBarre"){
        recherche = this.Articles.find((x) => x.codeBarre == data);
      }else{
        recherche = this.Articles.find((x) => x.idArticle == data.idArticle);
      }

      data=recherche;
      data.total=recherche.prixVente;
      data.marge=(recherche.prixVente-recherche.prixAchat);
      data.idArticle=recherche.idArticle;
      // data.quantite=1;
      data.nbon=0;
      let exist = this.requetteCommandeVentes.find((x) => x.idArticle == data.idArticle);
      if(!exist){
        data.quantite=1;
        this.addArticle(data);
        this.setFocus()
      }else{      
        var index = this.tableCommandeVentes.findIndex((x) => x.idArticle == data.idArticle);
        this.tableCommandeVentes[index].quantite=exist.quantite+1;
        this.tableCommandeVentes[index].total = recherche.prixVente*this.tableCommandeVentes[index].quantite;
        this.totalQantite();
        this.prepareCommandeDetail();
        this.tableCommandeVente = {};
        this.selectedArticle="";
      }
    // console.log(this.tableCommandeVentes); 
    this.showButon = false;
    // this.totalQantite();
    // this.prepareCommandeDetail();
    // this.tableCommandeVente = {};
    this.BarCode="";
  }

  findArticle(idArticle) {
    let recherche = this.Articles.find((x) => x.id == idArticle);
    this.designation = recherche.designation;
    this.idArticle = idArticle;
    // alert(this.idArticle);
    // if (recherche != undefined) {
    // 	return recherche.designation;
    // } else {
    // 	return '0';
    // }
  }
  totalQantite() {
    let totalMontant = 0;
    let totalMarge = 0;
    let totalQte = 0;
    for (let data of this.tableCommandeVentes) {
      totalMontant += data.total;
      totalMarge += data.marge * 1;
      totalQte += data.quantite * 1;
    }
    this.totalQte = totalQte;
    this.totalMarge = totalMarge;
    this.totalMontant = totalMontant;
    // return this._decimalPipe.transform(total, '1.2-2');
    // return total.toFixed(2);
  }

  retourArticle(designation,dateCommande,idCommande,nbon,idDetail,idArticle,idClient,prixVente,quantite) {

    // alert(dateCommande+"|" +idCommande+"|" +idArticle+"|" +idClient+"|" +prixVente+"|" +quantite);
    Swal.fire({
      title: 'Quantité retourne',
      input: 'number',
      inputValue:quantite,
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'OK',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        var qte =result.value;
      //  alert(resultt);
      if (qte == null || qte == "" || qte>quantite) {
        alert ("Quantité n'est pas identique !");
      } else {
        this.Avoir={};
        this.Avoir.idCommande    = idCommande;
        // alert (this.Avoir.idCommande);
        this.Avoir.nbon          = nbon;
        this.Avoir.designation   = designation;
        this.Avoir.idArticle     = idArticle;
        this.Avoir.quantite      = qte;
        this.Avoir.prixVente     = prixVente;
        this.Avoir.dateCommandee = dateCommande;
        // alert(idClient);
        this.Avoir.idClient = JSON.stringify(idClient);
        // this.Avoir.idClient      = idClient;
        this.tableCommandeAvoirs.push(this.Avoir);
        // this.commandeDetail = "";
        // this.commandeDetail = "('idCommande','" + idArticle + "','" + qte + "','" + prixVente + "')";
        Swal.fire({
          title: `${result.value} Quantité retourner !!`          
        })
        // this.deleteCommande(idDetail,idArticle); 
        this.prepareCommandeDetail();
        this.commandeDetail += "$";
        this.commandeDetail = this.commandeDetail.replace(",$", ";");
        // this.initialiser();
      }
      this.save();
    }
    })
    // var qte = prompt("Quantité retourne", quantite);
    // alert (qte);
  }

  deleteVente(idArticle) {
		// alert(idArticle);
		var index = this.tableCommandeAvoirs.findIndex((x) => x.idArticle == idArticle);
		if (index != undefined) {
			this.tableCommandeAvoirs.splice(index, 1);
		}
		this.prepareCommandeDetail();
		// console.log(this.vente);
		// var index = this.vente.findIndex((x) => x.idArticle == idArticle);
	}
    
  deleteCommande(idDetail,idArticle) {
    // alert(idDetail +'|' +idArticle);
    this.desablePrint=true;
    // alert(this.desablePrint);
    if (idDetail !=""){
      var index = this.tableCommandeVentes.findIndex(
        (x) => x.idArticle == idArticle
      );
    }else{
      var index = this.tableCommandeVentes.findIndex(
        (x) => x.idDetail == idDetail
      );      
    }

    // alert(index);
    this.tableCommandeVentes.splice(index, 1);
    this.totalQantite();
    this.prepareCommandeDetail();
    this.setFocus();    
    // this.tableCommandeAchat = {};
    // console.log(this.avoir);
    // var index = this.avoir.findIndex((x) => x.idArticle == idArticle);
    // alert(this.desablePrint);
  }
  prepareCommandeDetail() {
    this.commandeDetail = "";
    for (let data of this.tableCommandeAvoirs) {
      this.commandeDetail +=
        "('idCommande','" +
        data.idArticle +
        "','" +
        data.quantite +
        "','" +
        data.prixVente +
        "'),";
    }
    this.commandeDetail += "$";
    this.commandeDetail = this.commandeDetail.replace(",$", ";");
    this.initialiser();
    // alert(this.tableCommandeVente.idFournisseur);
  }

  onRowSelect(event) {
    // this.afficher= false;
    // if (!event.data.cloture){
      // this.AuthenticationService.currentUserValue.username= this.AuthenticationService.currentUserValue.username.toUpperCase();
      if (this.able  || this.AuthenticationService.currentUserValue.groupe =="Admin" ) {
        // this.displayDialog = true;
        // console.log(event.data);
        this.artilcesClient=[];
        this.artilceClient={}; 
        this.Avoir.id = event.data.idCommande;
        this.Avoir.num = event.data.num;
        this.Avoir.numm = event.data.numm;
        this.Avoir.dateCommandee = event.data.dateCommande;

        // let vvv=JSON.stringify(event.data.idClient);
        this.Avoir.idClient = JSON.stringify(event.data.idClient);
        this.Avoir.remise = event.data.remise;
        this.Avoir.nom = event.data.nom;
        this.Avoir.nbon = event.data.nbon;
        this.Avoir.idUser = event.data.idUser;
        this.Avoir.nomComplet = event.data.nomComplet;
        this.Avoir.cloture = event.data.cloture;
        this.Exclut = event.data.exclut;
        // alert (this.Avoir.idClient);
        let recherche = this.tableDetailVentes.filter((x) => x.id == event.data.idCommande);
        this.tableCommandeVentes = recherche;
        this.requetteCommandeVentes = recherche;
        this.totalQantite();
        this.prepareCommandeDetail();
      }
    // }
  }
  onRowSelectAvoir(event) {
    this.desablePrint= false;
    
    // if (!event.data.cloture){
      // this.AuthenticationService.currentUserValue.username= this.AuthenticationService.currentUserValue.username.toUpperCase();
      if (this.able  || this.AuthenticationService.currentUserValue.groupe =="Admin" ) {
        // this.displayDialogCommande = true;
        console.log(event.data);
        this.tableCommandeAvoirs=[];
        this.artilcesClient=[];
        this.artilceClient={}; 
        this.Avoir.id = event.data.idCommande; 
        this.Avoir.idClient = JSON.stringify(event.data.idClient);
        this.Avoir.num = event.data.num;
        this.Avoir.numm = event.data.numm;
        this.Avoir.dateCommandee = event.data.dateCommande;

        // let vvv=JSON.stringify(event.data.idClient);
        this.Avoir.idClient = JSON.stringify(event.data.idClient);
        this.Avoir.remise = event.data.remise;
        this.Avoir.nom = event.data.nom;
        this.Avoir.nbon = event.data.nbon;
        this.Avoir.idUser = event.data.idUser;
        this.Avoir.nomComplet = event.data.nomComplet;
        this.Avoir.cloture = event.data.cloture;
        this.Exclut = event.data.exclut;
        // alert (this.Avoir.idClient);
        // let recherche = this.tableDetailVentes.filter((x) => x.id == event.data.idCommande);
        // this.tableCommandeVentes = recherche;
        // this.requetteCommandeVentes = recherche;
        // this.totalQantite();
        // this.prepareCommandeDetail();
        // alert(this.desablePrint  +' | '+  this.Avoir.id +' | '+ this.Avoir.cloture);
      }
    // }
  }
  onRowSelectCommande(event) {
    // this.afficher= false;
    // if (!event.data.cloture){
      // this.AuthenticationService.currentUserValue.username= this.AuthenticationService.currentUserValue.username.toUpperCase();
      if (this.able  || this.AuthenticationService.currentUserValue.groupe =="Admin" ) {
        this.displayDialogCommande = true;
        // console.log(event.data);
        this.tableCommandeAvoirs=[];
        this.artilcesClient=[];
        this.artilceClient={}; 
        this.Avoir.id = event.data.idCommande; 
        this.Avoir.idClient = JSON.stringify(event.data.idClient);
        this.Avoir.num = event.data.num;
        this.Avoir.numm = event.data.numm;
        this.Avoir.dateCommandee = event.data.dateCommande;

        // let vvv=JSON.stringify(event.data.idClient);
        this.Avoir.idClient = JSON.stringify(event.data.idClient);
        this.Avoir.remise = event.data.remise;
        this.Avoir.nom = event.data.nom;
        this.Avoir.nbon = event.data.nbon;
        this.Avoir.idUser = event.data.idUser;
        this.Avoir.nomComplet = event.data.nomComplet;
        this.Avoir.cloture = event.data.cloture;
        this.Exclut = event.data.exclut;
        // alert (this.Avoir.idClient);
        let recherche = this.tableDetailVentes.filter((x) => x.id == event.data.idCommande);
        this.tableCommandeVentes = recherche;
        this.requetteCommandeVentes = recherche;
        this.totalQantite();
        this.prepareCommandeDetail();
      }
    // }
  }
  cloneDonnee(c: Avoir): Avoir {
    let Avoir = {};
    for (let prop in c) {
      Avoir[prop] = c[prop];
    }
    return Avoir;
  }
  onRowSelectCommandeDetail(event) {
    // console.log(event.data);
    this.setFocusArticle();
    this.rechercheArticle = this.Articles.find((x) => x.id == event.data.idArticle);
    this.quantiteVenteGros                    = this.rechercheArticle.quantiteVenteGros;
    this.quantiteVenteDetail                  = this.rechercheArticle.quantiteVenteDetail;
    this.prixVenteGros                        = this.rechercheArticle.prixVenteGros;
    this.prixVenteDetail                      = this.rechercheArticle.prixVenteDetail;
    this.prixVente1                           = this.rechercheArticle.prixVente;
    this.designation                          = this.rechercheArticle.designation;
    this.showButon = true;
    this.showButonSauvgarder = true;
    this.index = this.tableCommandeVentes.findIndex(
      (x) => x.idArticle == event.data.idArticle
    );
    // // alert(this.index);
    this.newDonnee = false;
    this.tableCommandeVente = this.cloneDonneeCommandeDetail(event.data);
    // this.displayDialog = true;
    // console.log( this.tableCommandeVente);
  }
  cloneDonneeCommandeDetail(c: Avoir): Avoir {
    let tableCommandeVente = {};
    for (let prop in c) {
      tableCommandeVente[prop] = c[prop];
    }
    return tableCommandeVente;
  }
  close() {
    this.setFocusArticle();
    this.displayDialog = false;
    this.displayCharge = false;
    this.tableCommandeVentes = [];
    this.tableCommandeVente = {};
    this.Charge={};
    this.Charges=[];
    this.requetteCommandeVentes = [];
    this.requetteCommandeVente = {};
    this.Avoir.id=null;
    this.Avoir.idClient="";
    this.nom="";
    this.designation="";
    this.numm="";
    this.Avoir.dateCommandee = new Date()
    .toISOString()
    .split("T")[0];
    this.tableCommandeVente.dateCommandee = new Date()
      .toISOString()
      .split("T")[0];
    this.totalQantite();
    this.prepareCommandeDetail();
    this.Avoir.nbon='0';
    this.Avoir.remise=0;
    // setTimeout(() => this.inputEl.nativeElement.focus());
  }
  closeCommande() {
    this.setFocusArticle();
    this.displayDialogCommande = false;
    this.tableCommandeVentes = [];
    this.tableCommandeVente = {};
    this.requetteCommandeVentes = [];
    this.requetteCommandeVente = {};
    this.Vente.id=null;
    this.tableCommandeVente.dateCommandee = new Date()
      .toISOString()
      .split("T")[0];
    this.totalQantite();
    this.prepareCommandeDetail();
    this.Avoir.nbon='0';
    this.Avoir.remise=0;
    // setTimeout(() => this.inputEl.nativeElement.focus());
  }
  initialiser() {
    // this.desablePrint=false;
    this.tableCommandeVente = {};
    this.requetteCommandeVentes = this.tableCommandeVentes;
    this.showButon=false;
    // this.prixVenteGros=undefined;
    // this.prixVenteDetail=undefined;
    // this.prixVente1=undefined;
    // this.tableCommandeAchat.idArticle = null;
    // this.tableCommandeAchat.quantite = "";
    // this.tableCommandeAchat.prixAchat = "";
    // this.tableCommandeAchat.prixVente = "";
    // this.tableCommandeAchat.prixVenteDetail = "";
    // this.tableCommandeAchat.prixVenteGros = "";
    // this.showButon = false;
  }
}
