import { Component, OnInit } from "@angular/core";
import { DataService } from "../data.service";
import { Achat } from "../model/achat";
import { Article } from "../model/article";
import { Paiement } from "../model/paiement";
import { Fournisseur } from "../model/fournisseur";
import { DecimalPipe } from "@angular/common";
import { environment } from "../../environments/environment";

@Component({
  selector: "app-situation-paiement",
  templateUrl: "situation-paiement.component.html",
  styleUrls: ["situation-paiement.component.scss"],
  providers: [DecimalPipe],
})
export class SituationPaiementComponent implements OnInit {
  Achat: Achat = {};
  Achats: Achat[];
  tableAchats: Achat[];
  // Achat.avance=0;
  Article: Article = {};
  Articles: Article[];
  Paiement: Paiement = {};
  Paiements: Paiement[];
  PaiementEncourr: Paiement = {};
  PaiementEncours: Paiement[];
  situationPaiements: Paiement[];
  situationPaiement: Paiement[];
  tablePaiements: Paiement[];
  Fournisseur: Fournisseur = {};
  Fournisseurs: Fournisseur[];
  dateEcheance: any;
  selectedAchat: Achat;
  selectedPaiement: Paiement;
  displayDialog: boolean;
  displayDialogPaiement: boolean;
  designation: string;
  total: number;
  totalCommande: number;
  totalAvance: number;
  totalReliquat: number;
  Avances: number;
  myUrl: string = `${environment.urlApi}`;
  constructor(private data: DataService, private _decimalPipe: DecimalPipe) {}

  ngOnInit() {
    this.achat();
    this.paiement();
    // this.paiementEncour();
    this.paiementSituation();
    // this.getProduits();
    this.fournisseur();
    this.displayDialogPaiement = true;
  }
  achat() {
    this.Achat.action = "situationPaiement";
    this.data
      .achat(this.Achat)
      .toPromise()
      .then(
        (x: Achat[]) => (
          (this.Achats = x),
          (this.tableAchats = x),
          this.calculTotal(),
          console.log(x)
        )
      );
  }
  // getProduits() {
  // 	this.data.getProduits().toPromise().then((x: Article[]) => (this.Articles = x));
  // }
  paiement() {
    this.Paiement.action = "Select";
    this.data
      .paiement(this.Paiement)
      .toPromise()
      .then((x: Paiement[]) => (this.Paiements = x));
    // console.log(this.Paiements);
  }
  // paiementEncour() {
  // 	this.Paiement.action = 'paiementEncour';
  // 	this.data.paiement(this.Paiement).toPromise().then((x: Paiement[]) => (this.PaiementEncours = x));
  // 	// console.log(this.Paiements);
  // }
  paiementSituation() {
    this.Paiement.action = "situationPaiementDetail";
    this.data
      .paiement(this.Paiement)
      .toPromise()
      .then(
        (x: Paiement[]) => (
          (this.situationPaiement = x), (this.situationPaiements = x)
        )
      );
  }
  recherchePaiement(idAchat) {
    // console.log(idAchat + '{}' + regle);
    let recherche = this.situationPaiements.filter(
      (x) => x.id === idAchat && x.regle == 1
    );
    // this.totalAvance = recherche.map((t) => t.avance * 1).reduce((a, value) => a + value, 0);
    // alert(this.totalAvance);
    if (recherche != undefined) {
      return recherche
        .map((t) => t.avance * 1)
        .reduce((a, value) => a + value, 0);
    } else {
      return "0";
    }
  }
  fournisseur() {
    this.Fournisseur.action = "Select";
    this.data
      .fournisseur(this.Fournisseur)
      .toPromise()
      .then((x: Fournisseur[]) => (this.Fournisseurs = x));
  }
  rechercheFournisseur(idFournisseur) {
    // alert(idFournisseur);
    let recherche = this.Fournisseurs.find((x) => x.id == idFournisseur);
    if (recherche != undefined) {
      return recherche.nom;
    } else {
      return "vide";
    }
  }
  filter(data, colone) {
    // console.log(data + colone);
    // alert(data + '**' + colone);

    let recherche = this.Achats.filter(
      (x) =>
        x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1
    );
    this.tableAchats = recherche;
    this.calculTotal();
    let rechercheAvance = this.situationPaiements.filter(
      (x) => x.idFournisseur == data && x.regle == 1
    );
    // let rechercheAvance = this.Paiements.filter((x) => (x.idFournisseur = colone && x.regle === 1));

    if (rechercheAvance != undefined) {
      // this.calculTotalAvance();

      let AvanceTotal = rechercheAvance
        .map((t) => t.avance * 1)
        .reduce((a, value) => a + value, 0);
      AvanceTotal += AvanceTotal;
      console.log(AvanceTotal);
      return rechercheAvance
        .map((t) => t.avance * 1)
        .reduce((a, value) => a + value, 0);
    } else {
      return "0";
    }

    // this.situationPaiements = rechercheAvance;
    // console.log('zzz' + this.situationPaiements);

    // this.calculTotalAvance();
    // this.calculTotalReliquat();
    // this.total = this.tableAchats.map((t) => t.prixAchat * t.quantite).reduce((a, value) => a + value, 0);
    // this.totalAvance = this.tableAchats.map((t) => t.avance * 1).reduce((a, value) => a + value, 0);
  }
  transformDecimal(num) {
    return this._decimalPipe.transform(num, "1.2-2");
  }
  calculTotal() {
    if (this.tableAchats) {
      this.total = this.tableAchats
        .map((t) => t.montant * 1)
        .reduce((a, value) => a + value, 0);
    }
    return 0;
  }
  calculTotalAvance() {
    if (this.tableAchats) {
      let recherche = this.situationPaiements.filter((x) => x.regle == 1);
      // let recherche = this.Achats.filter(
      // 	(x) => x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1
      // );
      console.log(recherche);
      this.totalAvance = recherche
        .map((t) => t.avance * 1)
        .reduce((a, value) => a + value, 0);
      this.totalReliquat = this.total - this.totalAvance;
    }
    return 0;
  }
  // calculTotalAvance() {
  // 	if (this.tableAchats) {
  // 		this.totalAvance = this.tableAchats.map((t) => t.avance * 1).reduce((a, value) => a + value, 0);
  // 	}
  // 	return 0;
  // }
  // calculTotalReliquat() {
  // 	if (this.tableAchats) {
  // 		this.totalReliquat = this.tableAchats
  // 			.map((t) => t.prixAchat * t.quantite - t.avance * 1)
  // 			.reduce((a, value) => a + value, 0);
  // 	}
  // 	return 0;
  // }

  save() {
    // alert('update');
    this.Paiement.action = "Update";
    this.Paiement.idAchat = this.Achat.id;
    this.data
      .paiement(this.Paiement)
      .toPromise()
      .then((res: { message: string }) => {
        if (res.message == "Opération de modification réuissie") {
          alert(res.message);
          // this.displayDialog = false;
          this.achat();
          this.paiement();
          this.paiementSituation();
        } else {
          alert(res.message);
        }
      });
  }
  addPaiement() {
    // alert('add');
    this.Paiement.action = "Insert";
    this.Paiement.idAchat = this.Achat.id;
    this.data
      .paiement(this.Paiement)
      .toPromise()
      .then((res: { message: string }) => {
        if (res.message == "Opération de modification réuissie") {
          alert(res.message);
          this.achat();
          this.paiement();
          this.paiementSituation();
          // this.displayDialog = false;
        } else {
          alert(res.message);
          // this.displayDialog = false;
        }
      });
  }

  deletePaiement(id) {
    if (confirm("êtes vous sure?" + id)) {
      this.Paiement.action = "Delete";
      this.Paiement.id = id;
      // console.log(this.Paiement);
      this.data
        .paiement(this.Paiement)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            alert(res.message);
            // this.displayDialog = false;
            this.achat();
            this.paiement();
            this.paiementSituation();
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  newDonnee: boolean;
  showDialogToAdd() {
    this.newDonnee = true;
    this.Achat = {};
    this.displayDialog = true;
  }
  onRowSelect(event) {
    // console.log(event);
    this.displayDialog = true;
    this.Paiement.idAchat = event.data.id;
    this.Achat.id = event.data.id;
    this.Achat.dateCommande = event.data.dateCommande;
    this.totalCommande = event.data.montant;
    let recherche = this.Paiements.filter((x) => x.idAchat == event.data.id);
    if (recherche != undefined) {
      this.tablePaiements = recherche;
      this.Avances = this.tablePaiements
        .map((t) => t.avance * 1)
        .reduce((a, value) => a + value, 0);
      // return recherche;
    } else {
      // return 'vide';
    }
    // alert(this.totalCommande);
    // console.log(this.tablePaiements + '*-*');
    // this.newDonnee = false;
    // this.Paiement = this.cloneDonnee(event.data);
  }
  // cloneDonnee(c: Paiement): Paiement {
  // 	let Paiement = {};
  // 	for (let prop in c) {
  // 		Paiement[prop] = c[prop];
  // 	}
  // 	return Paiement;
  // }
  onRowSelectPaiement(event) {
    console.log(event);
    this.newDonnee = false;
    this.Paiement = this.cloneDonneePaiement(event.data);
    this.displayDialogPaiement = true;
  }
  cloneDonneePaiement(c: Paiement): Paiement {
    let Paiement = {};
    for (let prop in c) {
      Paiement[prop] = c[prop];
    }
    return Paiement;
  }
  close() {
    this.displayDialog = false;
    this.Paiement = {};
  }
  initialiser() {
    this.Paiement = {};
    // this.requetteCommandeAchats = this.tableCommandeAchats;
    // this.tableCommandeAchat.idArticle = null;
    // this.tableCommandeAchat.quantite = "";
    // this.tableCommandeAchat.prixAchat = "";
    // this.tableCommandeAchat.prixVente = "";
    // this.tableCommandeAchat.prixVenteDetail = "";
    // this.tableCommandeAchat.prixVenteGros = "";
    // this.showButon = false;
  }
  // objectKey(obj) {
  // 	return Object.keys(obj);
  // }

  // formatedCerts() {
  // 	return this.Paiements.reduce((prev, now) => {
  // 		if (!prev[now.id]) {
  // 			prev[now.id] = [];
  // 		}

  // 		prev[now.id].push(now);
  // 		return prev;
  // 	}, {});

  // 	/*
  // 	   Now your data : { "1050 AJ": [ .... ], "X332.0 AC": [...], ... }
  // 	*/
  // }
}
